import { useEffect, useRef } from "react";
import "./AnchorLink.scss";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import Title from "../Global/Title/Title";
import { HEADING_LEVELS } from "../Global/GlobalConstants";

const isValid = (value) => {
  if (!value) return false;

  // Odd edge case where CMS param returns undefined as string
  if (value === "undefined") return false;

  // Match any whitespace characters, returns null if none are found
  const match = !!value.match(/\s/g);

  return !match;
};

function AnchorLink({ component, page }) {
  let mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
  }, []);

  try {
    const { anchorIdentifier = "" } = component.getParameters();
    const identifier = anchorIdentifier.trim();
    const valid = isValid(identifier);

    if (page.isPreview()) {
      return (
        <div className="anchor-link-preview">
          <Title
            defaultLevel={HEADING_LEVELS.THREE}
            className={`anchor-link-preview__title ${
              mounted.current && !valid ? "anchor-link-preview__title--invalid" : ""
            }`}
          >
            Anchor Link Identifier
          </Title>{" "}
          <span className="anchor-link-preview__value">
            {identifier === "undefined" ? "" : identifier}
          </span>
          {mounted.current && !valid && (
            <p>Please make sure there is no whitespace in the value entered or it's not empty</p>
          )}
        </div>
      );
    }

    if (!valid) return null;

    return <div className="anchor-link" id={identifier} />;
  } catch (e) {
    console.error("AnchorLink error", e);
    return <ErrorComponent error={e} componentName={"AnchorLink"} />;
  }
}

export default AnchorLink;
