import { useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { MessagesContext } from "./context";
import { useCookieService } from "./hooks";
import makeReducer from "./reducer";
import {
    FILTER_BY_VISIBILITY,
    FILTER_BY_LOCATION,
    FILTER_OUT_BY_ID,
} from "./constants";
import { HOME_PATH } from "../Global/GlobalConstants";

// Component which provides (via context) site messages to display
// and function to update state when a message is hidden
// Uses reducer for state - passes cookie service for reference.

function SiteMessagesProvider({ children, siteMessages }) {
    const { pathname } = useLocation();

    const cookieService = useCookieService();

    const [state, dispatch] = useReducer(
        makeReducer(cookieService),
        siteMessages
    );

    // When location or siteMessages change, update state
    useEffect(() => {
        if (pathname !== HOME_PATH) {
            return dispatch({ type: FILTER_BY_LOCATION });
        }

        return dispatch({
            type: FILTER_BY_VISIBILITY,
            payload: siteMessages,
        });
    }, [siteMessages, pathname]);

    const hideMessage = (alert) => {
        dispatch({ type: FILTER_OUT_BY_ID, payload: alert });
    };

    // NOTE: MIGHT need to memoize this value, read http://kcd.im/optimize-context
    const value = { messages: state, hideMessage };

    return (
        <MessagesContext.Provider value={value}>
            {children}
        </MessagesContext.Provider>
    );
}

export default SiteMessagesProvider;
