import { useSearchFilterContext } from "./hooks";
import "./MobileFilterHeading.scss";

function MobileFilterHeading({ title, groupType, showBackBtn }) {
  const {
    facetGroups: { updateSelectedFacetGroup, updateSelectedToggleGroup },
    closeFilters,
  } = useSearchFilterContext();

  function closeOptions() {
    if (groupType) {
      updateSelectedToggleGroup(null);
    } else {
      updateSelectedFacetGroup(null);
    }
  }

  return (
    <div className="filter-heading">
      <div className="filter-heading__wrapper">
        <div className="filter-heading__content">
          <button
            className={`filter-heading__btn darticon-arrow-back ${showBackBtn ? "" : "filter-heading__btn--hidden"}`}
            aria-label="back"
            onClick={closeOptions}
          />
          <span className="filter-heading__title">{title}</span>
          <button className="filter-heading__btn darticon-close" aria-label="close" onClick={closeFilters} />
        </div>
      </div>
    </div>
  );
}

export default MobileFilterHeading;
