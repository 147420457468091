import { STATUS } from "./constants";

export const getLabel = (key) => {
    switch (key) {
        case STATUS.IDLE:
            return window.globalProperties?.["addToListLabel"] || "";
        case STATUS.ADDED:
            return window.globalProperties?.["addedToListLabel"] || "";
        case STATUS.REMOVED:
            return window.globalProperties?.["removedFromListLabel"] || "";
        case STATUS.SAVED:
            return window.globalProperties?.["removeFromListLabel"] || "";
        default:
            return "";
    }
};
