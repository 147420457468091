import { BrComponent } from "@bloomreach/react-sdk";
import GlobalSiteMessages from "../../components/SiteMessages/GlobalSiteMessages";
import "./BaseLayout.scss";

function BaseLayout() {
  return (
    <>
      <BrComponent path="header" />
      <GlobalSiteMessages />
      <BrComponent path="layout">
        <div className="app-wrapper">
          <BrComponent path="main" />
        </div>
      </BrComponent>
      <BrComponent path="footer" />
    </>
  );
}

export default BaseLayout;
