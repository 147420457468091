import React, { useEffect, useReducer, useState } from "react";
import { BrPageContext } from "@bloomreach/react-sdk";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { reducer } from "./reducer";
import SavedProducts from "../../../services/SavedProducts";
import { getLabel } from "./helpers";
import {
  NOTIFICATION_TIMEOUT,
  STATUS,
  ACTIONS,
  SIZE,
  INITIAL_STATE,
  HOVERABLE_STATUS_ICON_MAP,
} from "./constants";
import "./AddProduct.scss";
import ErrorComponent from "../../Common/ErrorComponent/ErrorComponent";
import { getPageTitle } from "../../../helpers/GlobalHelpers";

function AddProduct({
  productCode = "",
  btnSize = SIZE.LARGE,
  addToListAnalytics,
  removeFromListAnalytics,
  productName,
  discontinuedProduct,
}) {
  const sendDataToGTM = useGTMDispatch();
  const page = React.useContext(BrPageContext);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [expanded, setExpanded] = useState(false);
  const { add, remove, has } = SavedProducts();

  try {
    useEffect(() => {
      if (has(productCode)) {
        dispatch({ type: ACTIONS.SAVED });
      }
    }, [productCode]);

    const { status } = state;

    const addProduct = (code) => {
      if (status !== STATUS.IDLE) return;

      add(code)
        .then(() => {
          dispatch({ type: ACTIONS.ADD_PRODUCT_SUCCESS });
          //notify analytics
          if (addToListAnalytics) {
            sendDataToGTM(addToListAnalytics);
          } else {
            sendDataToGTM({
              event: "listAdd",
              listType: "Other",
              label: `${getPageTitle(page)}`,
            });
          }

          setTimeout(() => {
            dispatch({ type: ACTIONS.SAVED });
          }, NOTIFICATION_TIMEOUT);
        })
        .catch((e) => {
          console.error(e);
          dispatch({ type: ACTIONS.ADD_PRODUCT_ERROR });
        });
    };

    const removeProduct = (code) => {
      if (status !== STATUS.SAVED) return;

      remove(code)
        .then(() => {
          dispatch({ type: ACTIONS.REMOVE_PRODUCT_SUCCESS });
          //notify analytics
          if (removeFromListAnalytics) {
            sendDataToGTM(removeFromListAnalytics);
          } else {
            sendDataToGTM({
              event: "removeFromList",
              listType: "Other",
              label: `${getPageTitle(page)}`,
            });
          }
          setTimeout(() => {
            dispatch({ type: ACTIONS.RESET });
          }, NOTIFICATION_TIMEOUT);
        })
        .catch((e) => {
          console.error(e);
          dispatch({ type: ACTIONS.REMOVE_PRODUCT_ERROR });
        });
    };

    const handleClick = (e) => {
      e.stopPropagation();
      e.preventDefault();

      const {
        currentTarget: {
          dataset: { code },
        },
      } = e;

      if (code) {
        if (has(code)) {
          if (status === STATUS.SAVED) {
            removeProduct(code);
          } else {
            dispatch({ type: ACTIONS.SAVED });
          }
        } else {
          if (status === STATUS.SAVED) {
            dispatch({ type: ACTIONS.RESET });
          } else {
            addProduct(code);

            window.BrTrk.getTracker().logEvent("cart", "click-add", {'prod_id': code, 'sku' : ''});
          }
        }
      }
    };

    const { icon, iconOnHover } = HOVERABLE_STATUS_ICON_MAP[status];
    const label = getLabel(status);

    if (discontinuedProduct && status !== STATUS.SAVED) {
      return null;
    }

    return (
      <div
        className={`add-product add-product--${btnSize} ${
          status === STATUS.ADDED || status === STATUS.SAVED ? "add-product--saved" : ""
        }`}
      >
        <button
          className="add-product__add-link"
          aria-label={label + " " + productName}
          data-code={productCode}
          onClick={handleClick}
          disabled={status === STATUS.ADDED || status === STATUS.REMOVED}
          type="button"
          onMouseEnter={()=>setExpanded(true)}
          onMouseLeave={()=>setExpanded(false)}
          aria-expanded={expanded ? "true" : "false"}
        >
          <span className={`add-product__add-link__icon ${icon}`} />
          <span
            className={`add-product__add-link__icon add-product__add-link__icon--hover ${iconOnHover}`}
          />
          {label && <span className="add-product__add-link__label bold-extra-bold">{label}</span>}
        </button>
      </div>
    );
  } catch (e) {
    console.error("AddProduct error", e);
    return <ErrorComponent error={e} componentName={"AddProduct"} />;
  }
}

export default AddProduct;
