import React from "react";
import { BrPageContext } from "@bloomreach/react-sdk";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { getPageTitle } from "../../../../helpers/GlobalHelpers";
import { GetGeneralLinkUrl } from "../../../../helpers/LinkHelper";
import "./DetailedAuthoredTile.scss";
import LinkWrapper from "../../../Global/LinkWrapper";
import Image from "../../../Global/Image/Image";
import { XsToMobile, XsMobile, Desktop } from "../../../../media-queries/MediaQueries";
import sanitizeHtml from 'sanitize-html'

function DetailedAuthoredTile({ tile, componentStyle, basicHeight, basicWidth }) {
  const sendDataToGTM = useGTMDispatch();
  const page = React.useContext(BrPageContext);
  if (!tile) return null;
  let linkRef = GetGeneralLinkUrl(tile.link, page);
  let link = tile.link;

  const maxXsW = 438;
  const maxXsH = 290;
  const maxMobileImgW = 725;
  const maxMobileImgH = 480;

  const handleTileClick = () => {
    sendDataToGTM({
      event: "tileClick",
      tileTitle: `${tile.primaryTitle ? tile.primaryTitle : tile.secondaryTitle}`,
      label: `${getPageTitle(page)}`,
    });
  };

  //remove the label so the wrapping anchor doesn't get an aria-label
  link.label = null;
  return (
    <LinkWrapper
      link={link}
      className={`${
        linkRef ? "detailed-authored-tile--cta" : ""
      } detailed-authored-tile detailed-authored-tile--border-radius detailed-authored-tile--${componentStyle}`}
      onClick={() => handleTileClick()}
    >
      {tile.image && (
        <>
          <Desktop>
            <Image
              src={tile.image}
              className="detailed-authored-tile__image"
              alt={tile.imageAltText || ""}
              width={basicWidth}
              height={basicHeight}
              aria-hidden="true"
            />
          </Desktop>
          <XsToMobile>
            <Image
              src={tile.image}
              className="detailed-authored-tile__image"
              alt={tile.imageAltText || ""}
              width={basicWidth === 0 ? maxMobileImgW : basicWidth}
              height={basicHeight === 0 ? maxMobileImgH : basicHeight}
              aria-hidden="true"
            />
          </XsToMobile>
          <XsMobile>
            <Image
              src={tile.image}
              className="detailed-authored-tile__image"
              alt={tile.imageAltText || ""}
              width={basicWidth === 0 ? maxXsW : basicWidth}
              height={basicHeight === 0 ? maxXsH : basicHeight}
              aria-hidden="true"
            />
          </XsMobile>
        </>
      )}

      {tile.primaryTitle && (
        <h3 className="detailed-authored-tile__heading detailed-authored-tile--padding-side">
          {tile.primaryTitle}
        </h3>
      )}
      {tile.description?.value && (
        <div
          className="detailed-authored-tile__description detailed-authored-tile--padding-side"
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(tile.description.value) ?? "",
          }}
        />
      )}
    </LinkWrapper>
  );
}

export default DetailedAuthoredTile;
