import React from "react";
import { BrPageContext, BrComponentContext } from "@bloomreach/react-sdk";
import "./GlobalProperties.scss";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";

//this gets put on all pages, and pushes a window var window.globalProperties
function GlobalProperties({ product }) {
  const page = React.useContext(BrPageContext);
  const component = React.useContext(BrComponentContext);

  try {
    const { document: documentRef } = component.getModels();
    const document = page.getContent(documentRef);

    if (!document) return null;

    return page.isPreview() ? (
      <div className={`has-edit-button global-props`}>
        <span>Global Properties - Click to View and Edit Values</span>
        {/* {<ul>
        {Object.keys(window.globalProperties).map(key =>
          <li key={key}>key:{key} value:{window.globalProperties[key]}</li>
        )}
      </ul>} */}
      </div>
    ) : null;
  } catch (e) {
    console.error("GlobalProperties error", e);
    return <ErrorComponent error={e} componentName={"GlobalProperties"} />;
  }
}

export default GlobalProperties;
