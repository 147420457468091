import React from "react";
import { BrPageContext, BrComponentContext } from "@bloomreach/react-sdk";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import { Helmet, HelmetData } from "react-helmet-async";

function NoIndex() {
  const page = React.useContext(BrPageContext);
  const component = React.useContext(BrComponentContext);
  const { noIndex } = component.getModels();

  const helmetData = new HelmetData({});

  try {
    const isPreviewMode = page?.isPreview();

    if (isPreviewMode) return <h2>No Index Component</h2>;

    if (!noIndex) return null;

    return (
      <Helmet helmetData={helmetData}>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
    );
  } catch (e) {
    console.error("NoIndex error", e);
    return <ErrorComponent error={e} componentName={"No Index"} />;
  }
}

export default NoIndex;
