import { useContext } from "react";
import { BrPageContext } from "@bloomreach/react-sdk";
import { Link } from "react-router-dom";
import { GLOBAL_PROPS } from "../../Global/GlobalConstants";
import "./BreadCrumbs.scss";
import ErrorComponent from "../ErrorComponent/ErrorComponent";

const MOBILE_CRUMBS_PLACEHOLDER = "...";

function BreadCrumbItemContent({ label, url, onClick = undefined, lastCrumb = false }) {
  if (onClick) {
    return (
      <button className="breadcrumbs__item__expand-button btn--link" onClick={onClick}>
        {label}
      </button>
    );
  }

  if (url) {
    return (
      <Link to={url} className="bold-normal">
        {label}
      </Link>
    );
  }

  if (lastCrumb) {
    return <span aria-current="page" tabIndex="0">{label}</span>;
  }

  return label;
}

function BreadCrumbItem({ label, url, showIcon = true, onClick = undefined, lastCrumb = false }) {
  return (
    <li className="breadcrumbs__item bold-normal">
      <BreadCrumbItemContent label={label} url={url} onClick={onClick} lastCrumb={lastCrumb} />
      {showIcon && <span className="darticon-arrow breadcrumbs__item__icon" />}
    </li>
  );
}

function BreadCrumbs({ breadcrumbs = [], condensed, expandCondensed }) {
  const page = useContext(BrPageContext);

  try {
    const getCondensedBreadcrumbs = () => {
      const crumbs = [{ ...breadcrumbs[0] }];

      if (breadcrumbs.length > 1) {
        crumbs.push({
          label: MOBILE_CRUMBS_PLACEHOLDER,
          url: "",
          showIcon: true,
          onClick: expandCondensed,
        });
      }

      return crumbs;
    };

    const crumbs = !condensed ? [...breadcrumbs] : getCondensedBreadcrumbs();
    const homePageLabel = window?.globalProperties[GLOBAL_PROPS.BREADCRUMB_HOME_PAGE_LABEL] || "";
    const breadcrumbLabel = window?.globalProperties[GLOBAL_PROPS.BREADCRUMB_LABEL] || "breadcrumb";
    const lastCrumb = crumbs.length;
    let name = page.getTitle();

    return (
      <div className="breadcrumbs__wrapper">
        <nav aria-label={breadcrumbLabel}>
          <ul className="breadcrumbs">
            {crumbs.map(({ label, externalLink, onClick }, index) => {
              return (
                <BreadCrumbItem
                  key={index}
                  label={index === 0 ? homePageLabel : label}
                  url={externalLink}
                  onClick={onClick}
                  showIcon = {!name && index === crumbs.length - 1 ? false : true}
                  lastCrumb = {!name && index === crumbs.length - 1}
                />
              );
            })}
            {name && <BreadCrumbItem key={lastCrumb} label={name} showIcon={false} lastCrumb={true} />}
          </ul>
        </nav>
      </div>
    );
  } catch (e) {
    console.error("BreadCrumbs error", e);
    return <ErrorComponent error={e} componentName={"BreadCrumbs"} />;
  }
}

export default BreadCrumbs;
