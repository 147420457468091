export const ALERT = "alert";
export const NORMAL = "normal";

export const SEVERITY = {
    [ALERT]: {
        class: "error",
    },
    [NORMAL]: {
        class: "informational",
    },
};

export const SITEWIDE_ALERT = "sitewide";

// ACTIONS
export const FILTER_BY_VISIBILITY = "FILTER_BY_VISIBILITY";
export const FILTER_BY_LOCATION = "FILTER_BY_LOCATION";
export const FILTER_OUT_BY_ID = "FILTER_OUT_BY_ID";
