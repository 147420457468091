import AddProduct from "../../../../Global/AddProduct/AddProduct";
import LinkWrapper from "../../../../Global/LinkWrapper";
import Image from "../../../../Global/Image/Image";
import { IsDesktopNav } from "../../../../../media-queries/MediaQueries";
import { SIZE } from "../../../../Global/AddProduct/constants";
import { Link } from "react-router-dom";
import "./NonFeaturedProductTile.scss";

function ProductTile({ product }) {
  const { image, title, productCode, url } = product;

  const isDesktop = IsDesktopNav();

  return (
    <Link to={url} className="product-detail-product-tile">
      <AddProduct
        productCode={productCode}
        btnSize={isDesktop ? SIZE.LARGE : SIZE.MEDIUM}
        productName={title}
      />
      <div className="product-detail-product-tile__image-wrapper">
        <Image
          className="product-detail-product-tile__image"
          src={image}
          loading="lazy"
          alt={title}
        />
      </div>
      <p className="product-detail-product-tile__name bold-extra-bold">{title}</p>
    </Link>
  );
}

export function AnchorProductTile({ link = {}, product }) {
  if (Object.keys(link).length <= 0) {
    return <ProductTile product={product} />;
  }

  return (
    <LinkWrapper className="product-detail-anchor-tile" link={link}>
      <ProductTile product={product} />
    </LinkWrapper>
  );
}

export default ProductTile;
