import { BrPageContext } from "@bloomreach/react-sdk";
import SiteMessages from "./SiteMessages";
import CookieServiceProvider from "./CookieServiceProvider";
import SiteMessagesProvider from "./SiteMessagesProvider";
import { useContext } from "react";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";

// Used when 'sitemessages' component provided as global component
function GlobalSiteMessages() {
  const page = useContext(BrPageContext);
  const component = page.getComponent("sitemessages");

  try {
    const { document: documentRef } = component.getModels();
    const document = page.getContent(documentRef);

    if (!document) return null;

    const { siteMessages = null } = document.getData();

    return (
      <CookieServiceProvider>
        <SiteMessagesProvider siteMessages={siteMessages}>
          <SiteMessages />
        </SiteMessagesProvider>
      </CookieServiceProvider>
    );
  } catch (e) {
    console.error("GlobalSiteMessages error", e);
    return <ErrorComponent error={e} componentName={"GlobalSiteMessages"} />;
  }
}

export default GlobalSiteMessages;
