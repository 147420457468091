import React from "react";
import { BrPageContext, BrComponentContext, BrManageContentButton } from "@bloomreach/react-sdk";
import "./Example.scss";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";

import { GetGeneralLinkUrl } from "../../helpers/LinkHelper";
//import Filter from "../Search/Filter/Filter";
//import { Desktop, Mobile } from "../../media-queries/MediaQueries";

function Example() {
  //const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  const page = React.useContext(BrPageContext);
  const component = React.useContext(BrComponentContext);

  try {
    const { document: documentRef } = component.getModels();
    const document = page.getContent(documentRef);

    if (!document) return null;

    const {
      primaryTitle = null,
      description = null,
      link = null,
      image: imageLink = null,
    } = document.getData();

    return (
      <>
        {/* <Mobile>
        <div className="darticon-filter" onClick={() => setFilterMenuOpen(!filterMenuOpen)}></div>
        {filterMenuOpen && <Filter setFilterMenuOpen={setFilterMenuOpen} />}
      </Mobile>
      <Desktop>
        <Filter />
      </Desktop> */}
        <div
          className={`${page.isPreview() ? "has-edit-button" : ""} example`}
          style={{ backgroundImage: `url("` + imageLink + `")` }}
        >
          <BrManageContentButton content={document} />
          {primaryTitle && <h1 className="example__header">{primaryTitle}</h1>}
          {description?.value && (
            <div
              className="example__description-wrapper"
              dangerouslySetInnerHTML={{
                __html: page?.rewriteLinks(description.value) ?? "",
              }}
            />
          )}
          {link.label && (
            <a
              href={GetGeneralLinkUrl(link, page)}
              className="example__cta"
              target={link.newWindow ? "_blank" : "_self"}
              rel={link.newWindow ? "noopener noreferrer" : ""}
            >
              {link.label}
            </a>
          )}
        </div>
      </>
    );
  } catch (e) {
    console.error("Example error", e);
    return <ErrorComponent error={e} componentName={"Example"} />;
  }
}

export default Example;
