import React from "react";
import { BrPageContext, BrComponentContext } from "@bloomreach/react-sdk";
import Description from "../Global/Description";
import { FEATURESBENEFITSTABID, HEADING_LEVELS } from "../Global/GlobalConstants";
import "./ProductDetailFeaturesAndBenefits.scss";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import Title from "../Global/Title/Title";
import Image from "../Global/Image/Image";

function ProductDetailFeaturesAndBenefits() {
  const page = React.useContext(BrPageContext);
  const component = React.useContext(BrComponentContext);

  try {
    const { product } = page.getComponent().getModels();
    const { document: documentRef } = component.getModels();

    if (!product) return null;
    const document = page.getContent(documentRef);
    if (!document) return null;

    const TEXTLEFT = "left"; // Text positioned left, image right
    const TEXTRIGHT = "right"; // Text positioned right, image left

    const { primaryTitle = null } = document.getData();

    // Sets conditions for values and returns feature / benefit
    const featureBenefitOutput = () => {
      const featuresAndBenefits = [
        {
          imageUrl: product.productData.enhancedContentImage1 || null,
          title: product.productData.enhancedContentHeader1 || null,
          description: product.productData.enhancedContentCopy1 || null,
          textPosition: TEXTRIGHT,
        },
        {
          imageUrl: product.productData.enhancedContentImage2 || null,
          title: product.productData.enhancedContentHeader2 || null,
          description: product.productData.enhancedContentCopy2 || null,
          textPosition: TEXTLEFT,
        },
        {
          imageUrl: product.productData.enhancedContentImage3 || null,
          title: product.productData.enhancedContentHeader3 || null,
          description: product.productData.enhancedContentCopy3 || null,
          textPosition: TEXTRIGHT,
        },
      ];

      const displayfeaturesAndBenefits = featuresAndBenefits
        .filter(
          (item) => item.description !== null && item.title !== null && item.imageUrl !== null
        )
        .map((item, index) => {
          return (
            <div
              key={index}
              className={`product-detail-features-benefits__content-wrapper ${
                item.textPosition === TEXTLEFT ? "text-left" : ""
              }`}
            >
              {/* Benefit / Feature - Image */}
              {item.imageUrl && item.imageUrl.length > 0 && (
                <div className="product-detail-features-benefits__image-wrapper">
                  <Image
                    className="product-detail-features-benefits__image"
                    src={item.imageUrl}
                    alt={item.title}
                  />
                </div>
              )}

              {/* Benefit / Feature - Text Content */}
              {((item.title && item.title.length > 0) ||
                (item.description && item.description.length > 0)) && (
                <div className="product-detail-features-benefits__text-wrapper">
                  {/* Benefit / Feature - Header */}
                  {item.title && item.title.length > 0 && (
                    <Title
                      defaultLevel={HEADING_LEVELS.THREE}
                      className="product-detail-features-benefits__content-header"
                    >
                      {item.title}
                    </Title>
                  )}

                  {/* Benefit / Feature - Description */}
                  {item.description && item.description.length > 0 && (
                    <Description
                      className="product-detail-features-benefits__content-body"
                      value={item.description}
                    />
                  )}
                </div>
              )}
            </div>
          );
        });

      return displayfeaturesAndBenefits;
    };

    return (
      <div className="product-detail-features-benefits" id={FEATURESBENEFITSTABID}>
        <div className="product-detail-features-benefits__wrapper">
          {primaryTitle && (
            <Title
              defaultLevel={HEADING_LEVELS.TWO}
              className="product-detail-features-benefits__title"
            >
              {primaryTitle}
            </Title>
          )}
          {/* Retrieve features and benefits */}
          {product && product.productData && featureBenefitOutput()}
        </div>
      </div>
    );
  } catch (e) {
    console.error("ProductDetailFeaturesAndBenefits error", e);
    return <ErrorComponent error={e} componentName={"ProductDetailFeaturesAndBenefits"} />;
  }
}

export default ProductDetailFeaturesAndBenefits;
