import "./MobileFilter.scss";
import MobileFilterHeading from "./MobileFilterHeading";
import FilterOptions from "./FilterOptions";
import { useSearchFilterContext } from "./hooks";

function MobileFilter() {
  const {
    filterTitle,
    facetGroups: {
      groups,
      selectedFacetGroup,
      selectedToggleGroup,
      updateSelectedFacetGroup,
      updateSelectedToggleGroup,
    },
    filterMenuOpen,
  } = useSearchFilterContext();

  function updateFilterGroup(facetGroup) {
    if (selectedFacetGroup && selectedFacetGroup.name === facetGroup.name) {
      updateSelectedFacetGroup(null);
    } else {
      updateSelectedFacetGroup(facetGroup);
    }
  }

  function updateToggleGroup(toggleGroup) {
    if (selectedToggleGroup && selectedToggleGroup.name === toggleGroup.name) {
      updateSelectedToggleGroup(null);
    } else {
      updateSelectedToggleGroup(toggleGroup);
    }
  }

  function outputToggleMenu() {
    return (
      <div className={`mobile-filter ${filterMenuOpen ? "" : "mobile-filter--hidden"}`}>
        <MobileFilterHeading title={selectedFacetGroup.name} showBackBtn={true} />
        <div className="mobile-filter__filter">
          <ul className="mobile-filter__filter-list">
            {selectedFacetGroup.toggleGroups.map((item, index) => (
              <li className="mobile-filter__filter-list-item" key={`${index}-${item.id}`}>
                <button className="mobile-filter__filter-list-item-link" onClick={() => updateToggleGroup(item)}>
                  {item.mobileName}
                  <span className="mobile-filter__filter-list-item-link-icon darticon-arrow"></span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  function outputOptions() {
    let selectedGroup = null;
    if (selectedToggleGroup) {
      selectedGroup = selectedToggleGroup;
    } else {
      selectedGroup = selectedFacetGroup;
    }
    return (
      <div className="mobile-filter__option-wrapper">
        <FilterOptions group={selectedGroup} />
      </div>
    );
  }

  return (
    <div className={`mobile-filter ${filterMenuOpen ? "" : "mobile-filter--hidden"}`}>
      <MobileFilterHeading title={filterTitle} />

      {groups && (
        <div className="mobile-filter__filter">
          <ul className="mobile-filter__filter-list">
            {groups.map((item, index) => (
              <li className="mobile-filter__filter-list-item" key={`${index}-${item.id}`}>
                <button className="mobile-filter__filter-list-item-link" onClick={() => updateFilterGroup(item)}>
                  {item.name}
                  <span className="mobile-filter__filter-list-item-link-icon darticon-arrow"></span>
                </button>
              </li>
            ))}
          </ul>

          {selectedFacetGroup && selectedFacetGroup.toggleGroups && outputToggleMenu()}

          {((selectedFacetGroup && !selectedFacetGroup.toggleGroups) || selectedToggleGroup) && outputOptions()}
        </div>
      )}
    </div>
  );
}

export default MobileFilter;
