import CtaLink from "../Global/CtaLink";
import IconGridItem from "./IconGridItem";
import Description from "../Global/Description";
import { isAuthored } from "../../helpers/LinkHelper";
import "./IconGrid.scss";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import Title from "../Global/Title/Title";
import { HEADING_LEVELS } from "../Global/GlobalConstants";

const FIVE_COLUMN_PROP_NAME = "fiveColumns";
const THREE_COLUMN_PROP_NAME = "threeColumns";
const CENTERED_PROP_NAME = "centered";

function IconGrid({ component, page }) {
  try {
    const { document: documentRef } = component.getModels();
    const { gridLayout = FIVE_COLUMN_PROP_NAME } = component.getParameters();
    const document = page.getContent(documentRef);

    const GENERALLINKEDLISTTYPE = "dartcontainercombrxm:GeneralLinkList";

    if (!document) return null;

    const {
      title = null,
      description = null,
      callToAction: link = null,
      listContents = [],
      listLinks = [],
      contentType = null,
    } = document.getData();
    const showCta = isAuthored(link);

    const gridItems = contentType === GENERALLINKEDLISTTYPE ? listLinks : listContents;
    var gridClassname = "icon-grid__grid--five-columns";
    if (gridLayout === THREE_COLUMN_PROP_NAME) {
      gridClassname = "icon-grid__grid--three-columns";
    } else if (gridLayout === THREE_COLUMN_PROP_NAME) {
      gridClassname = "icon-grid__grid--five-columns";
    } else if (gridLayout === CENTERED_PROP_NAME) {
      gridClassname = "icon-grid__grid--centered";
    }

    return (
      <div className="icon-grid">
        <div className="icon-grid__inner">
          {title && (
            <Title defaultLevel={HEADING_LEVELS.TWO} className="icon-grid__title">
              {title}
            </Title>
          )}
          {description?.value && (
            <Description value={description.value} className="icon-grid__description" />
          )}
          {gridItems.length > 0 && (
            <ul className={`icon-grid__grid ${gridClassname}`}>
              {gridItems.map((listItem, i) => (
                <IconGridItem key={i} item={listItem} contentType={contentType} />
              ))}
            </ul>
          )}
          {showCta && <CtaLink link={link} className="icon-grid__cta btn btn--tertiary" />}
        </div>
      </div>
    );
  } catch (e) {
    console.error("IconGrid error", e);
    return <ErrorComponent error={e} componentName={"IconGrid"} />;
  }
}

export default IconGrid;
