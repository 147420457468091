import { useState, useRef, useContext } from "react";
import { BrPageContext, BrComponentContext, BrManageContentButton } from "@bloomreach/react-sdk";
import HeaderToolbar from "../Common/HeaderToolbar/HeaderToolbar";
import LinkWrapper from "../Global/LinkWrapper";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import { isAuthored } from "../../helpers/LinkHelper";
import { HEADING_LEVELS, PRIMARY, TERTIARY } from "../Global/GlobalConstants";
import Image from "../Global/Image/Image";
import "./PageHeader.scss";
import Title from "../Global/Title/Title";
import { IsMobileContent } from "../../media-queries/MediaQueries";

const BASIC_CONTENT_DOC_TYPE = "dartcontainercombrxm:BasicContent";
const PAGE_HEADER_DOC_TYPE = "dartcontainercombrxm:PageHeader";
const PRODUCT_FAMILY_DOC_TYPE = "dartcontainercombrxm:ProductFamilyHeader";
const DEFAULT_CONTENT_HEIGHT_MIN = 325;
const HeroType = "hero";
const OFF = "off";

function PageHeader() {
  const DEFAULTCHARACTERSTOSHOW = 180;

  const [contentHeight, setContentHeight] = useState("auto");
  const [showFullDescription, setShowFullDescription] = useState(false);

  const page = useContext(BrPageContext);
  const component = useContext(BrComponentContext);

  const contentRef = useRef();
  const imageRef = useRef();

  const readMoreLabel = window.globalProperties["readMoreLabel"] || "Read More";
  const readLessLabel = window.globalProperties["readLessLabel"] || "Read Less";

  const videoPauseLabel = window.globalProperties["videoPauseLabel"] || "Pause";
  const videoPlayLabel = window.globalProperties["videoPlayLabel"] || "Play";
  const videoPauseAriaLabel = window.globalProperties["videoPauseAriaLabel"] || "Pause the autoplay video";
  const videoPlayAriaLabel = window.globalProperties["videoPlayAriaLabel"] || "Play the autoplay video";
  const videoMuteLabel = window.globalProperties["videoMuteLabel"] || "Mute";
  const videoUnmuteLabel = window.globalProperties["videoUnmuteLabel"] || "Unmute";
  const videoMuteAriaLabel = window.globalProperties["videoMuteAriaLabel"] || "Mute the autoplay video";
  const videoUnmuteAriaLabel = window.globalProperties["videoUnmuteAriaLabel"] || "Unmute the autoplay video";

  const handlePlayPauseClick = () => {
    const video = document.getElementById("header-video");
    const button = document.getElementById("header-video-play-pause");

    if (video.paused) {
        video.play();
        button.textContent = videoPauseLabel;
        button.ariaLabel = videoPauseAriaLabel;
    } else {
        video.pause();
        button.textContent = videoPlayLabel;
        button.ariaLabel = videoPlayAriaLabel;
    }
  }

  const handleMuteUnmuteClick = () => {
    const video = document.getElementById("header-video");
    const button = document.getElementById("header-video-mute-unmute");

    if (video.muted) {
        video.muted = false;
        button.textContent = videoMuteLabel;
        button.ariaLabel = videoMuteAriaLabel;
    } else {
        video.muted = true;
        button.textContent = videoUnmuteLabel;
        button.ariaLabel = videoUnmuteAriaLabel;
    }
  }

  try {
    const { document: documentRef, breadcrumbs } = component.getModels();

    const {
      componentStyle = PRIMARY,
      hideBreadcrumbs,
      pageDetailsImageHeight,
      pageDetailsImageWidth,
      type,
      textPosition,
      hasTextAreaBackground = OFF,
      readMoreCharacterCount = DEFAULTCHARACTERSTOSHOW,
      showReadMore,
    } = component.getParameters();

    const document = page.getContent(documentRef);
    if (!document) return null;

    const getContent = (data) => {
      if(!data) { data = {}; }
      const { contentType = "" } = data;

      switch (contentType) {
        case PAGE_HEADER_DOC_TYPE:
          const { pageDetails = {} } = data;
          return pageDetails;
        case PRODUCT_FAMILY_DOC_TYPE:
          const { familyDetails = {} } = data;
          return familyDetails;
        case BASIC_CONTENT_DOC_TYPE:
        default:
          return data;
      }
    };


    const _pushAllChildren = (arr, element) => {
      for(var i = 0 ; i < element.children.length ; i++){
        arr.push(element.children[i]);
      }
    }

    const _computeReadMore = (longDescription) => {
      const descriptionHtml = window.document.createElement('div');
      descriptionHtml.innerHTML = longDescription;

      const hasReadMore = showReadMore && descriptionHtml.textContent.length > readMoreCharacterCount;

      if(hasReadMore){

        const elementStack = [];
        let charCount = 0;
        let foundEnd = false;

        _pushAllChildren(elementStack, descriptionHtml);

        while(elementStack !== undefined && elementStack.length > 0)
        {
          let currentChild = elementStack.shift();
          let currentChildText = currentChild.text || currentChild.textContent || "";
          if(foundEnd)
          {
            currentChild.parentElement.removeChild(currentChild);
          }
          else if((currentChildText.length + charCount) <= readMoreCharacterCount){
            charCount += currentChildText.length;
          }
          else
          {
            if(currentChild.children.length > 0){
              _pushAllChildren(elementStack, currentChild);
            }
            else{
              currentChild.innerHTML = currentChild.innerHTML.substr(0, readMoreCharacterCount - charCount) + "...";
              charCount = readMoreCharacterCount;
              foundEnd = true;
            }
          }
        }
        return [descriptionHtml.innerHTML, hasReadMore];
      }

      return [longDescription, hasReadMore];
    };

    const {
      primaryTitle = "",
      description = {},
      link = {},
      image: imageLink = "",
      imageAltText = "",
      video = null,
    } = getContent(document.getData());
    const videoMuteHide = getContent(document.getData().videoMuteHide);
    
    const longDescription = page?.rewriteLinks(description.value) || "";
    const [shortDescription, hasReadMore] = _computeReadMore(longDescription);

    // get the content height and set max image height to match UNLESS
    // there are image overrides and then those always take precedence
    function _imageLoadedMatchHeight() {
      const actualContentHeight =
        contentRef && contentRef.current && contentRef.current.clientHeight
          ? contentRef.current.clientHeight
          : "auto";

      // IF there are no image height overrides
      if (!pageDetailsImageHeight) {
        // if content height is larger than defaultl, use the actual content height
        if (actualContentHeight > DEFAULT_CONTENT_HEIGHT_MIN) {
          setContentHeight(actualContentHeight);
        } else {
          setContentHeight(DEFAULT_CONTENT_HEIGHT_MIN);
        }
      }

      // IF there are image overrides
      if (pageDetailsImageHeight) {
        // if content height is smaller than the image, make it the size of the image
        if (pageDetailsImageHeight > actualContentHeight) {
          setContentHeight(pageDetailsImageHeight);
        }
      }
    }
    const showCta = isAuthored(link);
    
    return (
      <>
        <HeaderToolbar
          hideBreadcrumbs={hideBreadcrumbs}
          breadcrumbs={breadcrumbs}
        />
        <div
          className={`${
            page.isPreview() ? "has-edit-button" : ""
          } page-header page-header--${componentStyle} ${(imageLink || hasTextAreaBackground != OFF) ? "" : " page-header--no-image-no-text-bg"}${type === HeroType ? " page-header--hero": " page-header--banner"}`}
        >
          <BrManageContentButton content={document} />
          <div className={`page-header__inner page-header__inner--${textPosition}`}>
              {video && (
                <div className={`page-header__video-container ${type === HeroType ? " page-header__video-container--hero" : ""}`}>
                  {!videoMuteHide && (
                    <button
                      type="button"
                      id="header-video-mute-unmute" 
                      className={`page-header__video-control-button page-header__video-control-button--${componentStyle}`}
                      onClick={handleMuteUnmuteClick}
                      aria-label={videoUnmuteAriaLabel}
                    >
                      {videoUnmuteLabel}
                    </button>
                  )}
                  <button
                    type="button"
                    id="header-video-play-pause"
                    className={`page-header__video-control-button page-header__video-control-button--${componentStyle}`}
                    onClick={handlePlayPauseClick}
                    aria-label={videoPauseAriaLabel}
                  >
                    {videoPauseLabel}
                  </button>
                  <video id="header-video" className="page-header__video" loop muted autoPlay controlsList="nodownload" disablePictureInPicture onClick={handlePlayPauseClick}>
                    <source src={video} type="video/mp4" />
                  </video>
                </div>
              )}
            <div
              ref={contentRef}
              className={`page-header__content page-header--bg-${hasTextAreaBackground}`}
              style={(IsMobileContent() && type === HeroType) ? {height: "auto"} : { height: contentHeight }}
            >
              {primaryTitle && (
                <Title
                  defaultLevel={HEADING_LEVELS.ONE}
                  className={`page-header__header page-header__header--${componentStyle}`}
                >
                  {primaryTitle}
                </Title>
              )}
              {description?.value && (
                <div
                  className={`page-header__description-rte-wrapper page-header__description-rte-wrapper--${componentStyle}`}
                >
                  <span dangerouslySetInnerHTML={{
                    __html: hasReadMore ? (
                      showFullDescription ? longDescription : shortDescription
                    ) : longDescription,
                  }} />
                  {hasReadMore && (
                    <button
                      type="button"
                      className="page-header__description-rte-wrapper-toggle"
                      onClick={() => setShowFullDescription(!showFullDescription)}
                    >
                      <span className="page-header__description-rte-wrapper-toggle-text">
                        {showFullDescription ? readLessLabel : readMoreLabel}
                      </span>
                      {showFullDescription ? (
                        <span className="page-header__description-rte-wrapper-toggle-icon darticon-arrow-up"></span>
                      ) : (
                        <span className="page-header__description-rte-wrapper-toggle-icon darticon-arrow-down"></span>
                      )}
                    </button>
                  )}
                </div>
              )}
              {showCta && (
                <LinkWrapper
                  className={`page-header__content__cta btn ${
                    componentStyle === TERTIARY ? "btn--" + componentStyle : ""
                  }`}
                  link={link}
                />
              )}
            </div>
            {!video && imageLink && (
              <Image
                className="page-header__image"
                src={imageLink}
                alt={imageAltText || ""}
                onLoad={_imageLoadedMatchHeight}
                style={pageDetailsImageHeight ? {} : { height: contentHeight }}
                height={pageDetailsImageHeight}
                width={pageDetailsImageWidth}
                imageRef={imageRef}
              />
            )}
            
          </div>
        </div>
      </>
    );
  } catch (e) {
    console.error("PageHeader error", e);
    return <ErrorComponent error={e} componentName={"PageHeader"} />;
  }
}

export default PageHeader;
