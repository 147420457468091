import React, { useState } from "react";
import { useSearchInputFilterContext } from "./hook";
import "./SearchInputFilter.scss";

function SearchInputFilter() {
  const [searchTerm, setSearchTerm] = useState("");
  const [lastSearchedTerm, setLastSearchedTerm] = useState("");
  const ENTERKEYCODE = 13;
  const { search } = useSearchInputFilterContext();

  const clearSearchLabelText = window.globalProperties?.["clearSearchAriaLabel"]
    ? window.globalProperties?.["clearSearchAriaLabel"]
    : "clear search";
  const searchInputId = "search-input-filter";

  function handleEnter(event) {
    const key = event.keyCode;
    // If keyCode = 13 or 'Enter', perform search
    if (key === ENTERKEYCODE) {
      handleSearch();
    }
  }

  // Will be an API call in the future
  function handleSearch() {
    setLastSearchedTerm(searchTerm);
    search(searchTerm);
  }

  function resetSearch() {
    setSearchTerm("");
    // prevents unnecessary API calls
    if (lastSearchedTerm !== "") {
      search("");
      setLastSearchedTerm("");
    }
    //refocus search input
    const searchInput = document.getElementById(searchInputId);
    if(searchInput){
      searchInput.focus();
    }
  }

  return (
    <div className="search-input-filter">
      <label className="search-input-filter__label" htmlFor="search-input-filter">
        {window.globalProperties?.["searchWithinResultsLabel"]
          ? window.globalProperties?.["searchWithinResultsLabel"]
          : "Search within results"}
        :
      </label>

      <div className="search-input-filter__input-icon-container">
        <span
          className="darticon-search search-input-filter__search-icon"
          onClick={() => handleSearch()}
        ></span>
        <input
          value={searchTerm}
          id={searchInputId}
          className="search-input-filter__input"
          type="text"
          aria-label={
            window.globalProperties?.["searchPlaceholder"]
              ? window.globalProperties?.["searchPlaceholder"]
              : "Search"
          }
          placeholder={
            window.globalProperties?.["searchPlaceholder"]
              ? window.globalProperties?.["searchPlaceholder"]
              : "Search"
          }
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyUp={handleEnter}
        />
         {searchTerm.length > 0 && (
          <button className="search-input-filter__clear-button"
          title={clearSearchLabelText}
          aria-label={clearSearchLabelText}
          onClick={() => resetSearch()}
          type="button">
            <span className="darticon-close search-input-filter__clear-icon"></span>
          </button>
        )}
      </div>
    </div>
  );
}

export default SearchInputFilter;
