import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import { animateScroll } from "react-scroll";
import { useLocation } from "react-router-dom";
import "./ScrollTop.scss";
import ErrorComponent from "../../Common/ErrorComponent/ErrorComponent";

function ScrollTop({StickyButtonsContext}) {
  const isScrolled = useContext(StickyButtonsContext);

  let location = useLocation();

  useLayoutEffect(() => {
    // Reset scroll position to top of screen on local page change
    // using native window method since we don't need animation
    if(window.noScrollTo){
      window.noScrollTo = false;
    }
    else{
      window.scrollTo(0, 0);
    }
  }, [location]);

  try {

    const { backToTopLabel = "" } = window.globalProperties || {};

    const scrollToTop = () => {
      animateScroll.scrollToTop();
      // Move focus back to top of the page
      document.getElementById("goTop").focus();
    };

    return (
      <div className={`scroll-top ${isScrolled ? "" : "scroll-top--hidden"}`}>
        <button className="scroll-top__button" onClick={scrollToTop} title={backToTopLabel}>
          <i className="scroll-top__button__icon darticon-backtotop" />
          {backToTopLabel && (
            <span className="scroll-top__button__label bold-bold">{backToTopLabel}</span>
          )}
        </button>
      </div>
    );
  } catch (e) {
    console.error("ScrollTop error", e);
    return <ErrorComponent error={e} componentName={"ScrollTop"} />;
  }
}

export default ScrollTop;
