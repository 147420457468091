import { useMediaQuery } from "react-responsive";

// These are used to conditionally render any child components in the JSX code based off media-queries.
// Check react-responsive NPM page for examples.

export const DesktopNav = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1150 });
  return isDesktop ? children : null;
};
export const IsDesktopNav = () => useMediaQuery({ minWidth: 1150 });

export const MobileNav = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1149 });
  return isMobile ? children : null;
};

export const IsMobileNav = () => useMediaQuery({ maxWidth: 1149 });

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  return isDesktop ? children : null;
};

export const IsDesktopContent = () => useMediaQuery({ minWidth: 768 });

export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

export const XsToMobile = ({ children }) => {
  const isMobile = useMediaQuery({ minWidth: 481, maxWidth: 767 });
  return isMobile ? children : null;
};

export const XsMobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  return isMobile ? children : null;
};

export const IsMobileContent = () => useMediaQuery({ maxWidth: 767 });

export const IsTabletOrSmallerContent = () => useMediaQuery({ maxWidth: 991 });

export const SmallMedium = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 991 });
  return isMobile ? children : null;
};

export const Small = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  return isMobile ? children : null;
};

export const Medium = ({ children }) => {
  const isMedium = useMediaQuery({ minWidth: 481, maxWidth: 991});
  return isMedium ? children : null;
};

export const Large = ({ children }) => {
  const isLarge = useMediaQuery({ minWidth: 992 });
  return isLarge ? children : null;
};

export const DesktopFooter = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
export const MobileFooter = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 991 });
  return isMobile ? children : null;
};

//hooks
export const useDesktopMediaQuery = () => useMediaQuery({ minWidth: 768 });
export const useDesktopFooterMediaQuery = (onChange) =>
  useMediaQuery(
    { minWidth: 992 },
    undefined,
    typeof onChange === "function" ? onChange : undefined
  );
