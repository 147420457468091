import { parseURL, removeQueryParam, setQueryParam } from "../../../helpers/LinkHelper";

export const WIDEN_DAM = {
  ORIGIN: "https://dart.widen.net",
  QUERY_PARAMS: {
    WIDTH: "w",
    HEIGHT: "h",
    QUALITY: "quality",
    RETINA: "retina",
  },
};

export const isWidenResource = (source) => {
  const {
    result,
    parsed: { origin },
  } = parseURL(source);

  if (result === "SUCCESS" && origin === WIDEN_DAM.ORIGIN) {
    return true;
  }

  return false;
};

// Determines if values were provided for the Image Overries properties of a component
// The value of 0 means none were provided
export const hasDimensionsToOverride = (width, height) => {
  return width !== 0 || height !== 0;
};

// Removes specific search query param. from a Widen URI
const stripWidenQueryParam = (uri) => (param) => removeQueryParam(uri, param);

export const stripWidenQueryWidthParam = (uri) =>
  stripWidenQueryParam(uri)(WIDEN_DAM.QUERY_PARAMS.WIDTH);

export const stripWidenQueryHeightParam = (uri) =>
  stripWidenQueryParam(uri)(WIDEN_DAM.QUERY_PARAMS.HEIGHT);

export const stripWidenQueryDimensionParams = (uri) => {
  let url = uri;

  url = stripWidenQueryWidthParam(url);
  url = stripWidenQueryHeightParam(url);

  return url;
};

export const replaceWidenQueryRetina = (uri, value) => {
  let url = uri;
  url = setQueryParam(url, WIDEN_DAM.QUERY_PARAMS.RETINA, value)
  return url
};

export const replaceWidenQueryQuality = (uri, value) => {
  let url = uri;
  url = setQueryParam(url, WIDEN_DAM.QUERY_PARAMS.QUALITY, value)
  return url;
};

// Returns inline css styles for an html element which applies
// a decorative image via the backgroundImage css property
export const getDesktopBackgroundImageStyles = (imageURI, width, height, isDesktopViewport) => {
  let styles = { backgroundImage: "none", backgroundSize: "cover" };

  if (!imageURI || !isDesktopViewport) {
    return styles;
  }

  let url = imageURI;

  const DEFAULT_SIZE = "auto";
  let backgroundWidth = DEFAULT_SIZE;
  let backgroundHeight = DEFAULT_SIZE;

  const isWidenImage = isWidenResource(url);

  if (hasDimensionsToOverride(width, height)) {
    if (width !== 0) {
      backgroundWidth = `${width}px`;
    }

    if (height !== 0) {
      backgroundHeight = `${height}px`;
    }
  }

  if (isWidenImage) {
    url = url.replace("/jpeg/", "/webp/");
    url = url.replace("/png/", "/webp/");
    url = stripWidenQueryDimensionParams(url);
    url = replaceWidenQueryRetina(url, "true");
    url = replaceWidenQueryQuality(url, "100");
  }

  styles = {
    ...styles,
    backgroundImage: `url(${url})`,
    ...((backgroundWidth !== DEFAULT_SIZE || backgroundHeight !== DEFAULT_SIZE) && {
      backgroundSize: `${backgroundWidth} ${backgroundHeight}`,
    }),
  };

  return styles;
};
