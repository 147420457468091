import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import RedirectPreview from "./RedirectPreview";
import { parseURL } from "../../helpers/LinkHelper";
import "./PageRedirect.scss";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";

const PROTOCOLS = {
  HTTP: "http://",
  HTTPS: "https://",
};

const PATH_CHAR = "/";

const isValid = (url) => {
  if (!url) return false;

  return (
    url.startsWith(PROTOCOLS.HTTP) || url.startsWith(PROTOCOLS.HTTPS) || url.startsWith(PATH_CHAR)
  );
};

function ExternalRedirect({ to }) {
  useEffect(() => {
    if (!to) return;

    window.location.replace(to);
  }, [to]);

  return null;
}

function PageRedirect({ component, page }) {
  try {
    const isPreviewMode = page?.isPreview();
    const { redirectURL } = component?.getParameters() || {};
    const valid = isValid(redirectURL);

    const {
      result,
      parsed: { internal = false, pathname, search, hash, href },
    } = parseURL(redirectURL);

    if (isPreviewMode) {
      return <RedirectPreview valid={valid} parsingResult={result} url={redirectURL} />;
    }

    if (!valid || result === "ERROR") return null;

    if (internal) {
      return (
        <Redirect
          to={{
            pathname,
            search,
            hash,
            state: { redirect: true, referrer: href },
          }}
        />
      );
    }

    return <ExternalRedirect to={href} />;
  } catch (e) {
    console.error("PageRedirect error", e);
    return <ErrorComponent error={e} componentName={"PageRedirect"} />;
  }
}

export default PageRedirect;
