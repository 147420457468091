import { useState } from "react";
import { ENTERKEYCODE } from "./GlobalConstants";
import "./SearchTextbox.scss";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";

function SearchTextbox({ submitUrl, queryParameter, placeholder, theme, newWindow}) {
  const [searchText, setSearchText] = useState("");

  try {
    function handleEnter(event) {
      const key = event.keyCode;
      // If keyCode = 13 or 'Enter', perform search
      if (key === ENTERKEYCODE) {
        search();
      }
    }

    function search() {
      let url = `${submitUrl}?${queryParameter}=${encodeURIComponent(searchText)}`;
      if (newWindow) {
        newWindow === true ? window.open(url) : window.open( url , "_self");       
      } else {
        window.open( url , "_self")
      }
    }

    return (
      <div className="search-text__input-group">
        <input
          type="text"
          className="search-text__input"
          placeholder={placeholder}
          aria-label={placeholder}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyUp={handleEnter}
        />
        <div className="search-text__btn-wrapper">
          <button
            className={`search-text__btn btn btn--${theme}`}
            type="button"
            onClick={search}
            aria-label="search"
          >
            <span className="darticon-search"></span>
          </button>
        </div>
      </div>
    );
  } catch (e) {
    console.error("SearchTextbox error", e);
    return <ErrorComponent error={e} componentName={"SearchTextbox"} />;
  }
}

export default SearchTextbox;
