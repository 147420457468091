import React, { useEffect, useState } from "react";

import mapIcon from "../../icons/icon-location.svg";
import mapActiveIcon from "../../icons/icon-active-location.svg";
import mapMexicoIcon from "../../icons/icon-mexico-location.svg";
import "./PlantMap.scss";
import { Marker, Popup, useMapEvents } from "react-leaflet";
import { PRIMARYCOLORBLUE } from "../Global/GlobalConstants";
import * as L from "leaflet";

function PlantMap({ activeLocations, itemAddress, activeLatLong, setActiveLatLong, maxZoom }) {
  const [activePopup, setActivePopup] = useState(null);
  const iconSize = 24;
  const LeafIcon = L.Icon.extend({
    options: {},
    createIcon: function (oldIcon) {
   	  var img = this._createIcon('icon', oldIcon);

   	  img.setAttribute("alt", this.options.locationTitle);
   	  img.setAttribute("aria-label", this.options.locationTitle);
   	  img.setAttribute("aria-roledescription", "button");

   	  return img;
   	}
  });

  const map = useMapEvents({
    // Use leaflet map event as the key and a call back with the
    // map method as the value:
    click: () => {
      setActiveLatLong(null);
      if (activePopup) {
        map.closePopup(activePopup);
      }
    },
    popupopen: (p) => {
      if (p && p.popup && p.popup._latlng) {
        setActivePopup(p);
      }
    },
    popupclose: (p) => {},
  });

  useEffect(() => {
    //init fit bounds here
    var res = activeLocations.map((item) => [item.plantLatitude, item.plantLongitude]);
    map.fitBounds(res, { maxZoom: maxZoom });
    if (activeLatLong) {
      map.openPopup(L.latLng(activeLatLong[0], activeLatLong[1]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (activePopup) {
      setActiveLatLong([
        Number.parseFloat(activePopup.popup._latlng.lat),
        Number.parseFloat(activePopup.popup._latlng.lng),
      ]);
    } else {
      setActiveLatLong(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePopup]);

  return (
    <>
      {activeLocations &&
        activeLocations.map((item, index) => {
          return (
            <Marker
              key={`marker__${index}`}
              position={[item.plantLatitude, item.plantLongitude]}
              eventHandlers={{
                click: (e) => {
                  //have to retrigger opening of the map when you click the open popup icon again
                  const { lat, lng } = e.latlng;

                  if (activeLatLong && activeLatLong[0] === lat && activeLatLong[1] === lng) {
                    map.eachLayer(function (layer) {
                      if (
                        activeLatLong &&
                        layer._latlng &&
                        layer._latlng.lat === activeLatLong[0] &&
                        layer._latlng.lng === activeLatLong[1]
                      ) {
                        layer.openPopup();
                      }
                    });
                  }
                },
              }}
              icon={
                new LeafIcon({
                  iconUrl: activeLatLong &&
                   Number.parseFloat(item.plantLatitude) === activeLatLong[0] &&
                   Number.parseFloat(item.plantLongitude) === activeLatLong[1]
                     ? mapActiveIcon
                     : window.globalProperties &&
                       window.globalProperties.primarySiteColor !== PRIMARYCOLORBLUE
                     ? mapMexicoIcon
                     : mapIcon,
                  iconSize: iconSize,
                  locationTitle: item.plantTitle
                })
              }
            >
              {/* popups have been hidden in the _global.scss file, remove the css line if you want them back */}
              <Popup>
                {
                  <>
                    <span className="plant-locations__current-results-item-content-name">
                      {item.plantTitle}
                    </span>
                    {itemAddress(item)}
                  </>
                }
              </Popup>
            </Marker>
          );
        })}
      ;
    </>
  );
}
export default PlantMap;
