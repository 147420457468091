import React from "react";
import { BrPageContext } from "@bloomreach/react-sdk";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { getPageTitle } from "../../../../helpers/GlobalHelpers";
import { Desktop, Mobile } from "../../../../media-queries/MediaQueries";
import { GetGeneralLinkUrl } from "../../../../helpers/LinkHelper";
import LinkWrapper from "../../../Global/LinkWrapper";
import Description from "../../../Global/Description";
import "./BasicContentTile.scss";
import Image from "../../../Global/Image/Image";
import Title from "../../../Global/Title/Title";
import { HEADING_LEVELS } from "../../../Global/GlobalConstants";

function BasicContentTile({ tile, componentStyle, basicHeight, basicWidth, isCondensed, condensedStyle, tileWidthOverride }) {
  const sendDataToGTM = useGTMDispatch();
  const page = React.useContext(BrPageContext);
  if (!tile) return null;

  const NO_IMAGE_CONDENSED = "textOnly";

  condensedStyle = isCondensed ? condensedStyle : "";

  const noImage = condensedStyle == NO_IMAGE_CONDENSED;

  const maxImgH = 250;
  const maxImgW = 400;

  const imageWrapperH = condensedStyle === "feature" ? 150 : 100;

  const overrideDimensions = isCondensed ? {
    width: basicWidth,
    height: basicHeight,
    top: typeof(basicHeight) == "number" ?
     -(basicHeight - imageWrapperH) / 2.0
     : null
  } : null;

  const tileWidthOverrideF = parseFloat(tileWidthOverride) || 100

  const mobileOverride = isCondensed ? {
    width: tileWidthOverrideF + "%",
    right: ((100.0 - tileWidthOverrideF)/ 2.0) + "%",
  } : null;

  const {
    primaryTitle,
    description,
    link,
    image: desktopImageSrc,
    mobileImage: mobileImageSrc,
    altText,
    mobileAltText,
  } = tile.getData();

  if (!link) return null;

  let linkRef = GetGeneralLinkUrl(link, page);

  const handleTileClick = () => {
    sendDataToGTM({
      event: "tileClick",
      tileTitle: `${tile.primaryTitle ? tile.primaryTitle : tile.secondaryTitle}`,
      label: `${getPageTitle(page)}`,
    });
  };

  return (
    <LinkWrapper
      link={link}
      className={`${linkRef ? "tile--cta" : ""} tile tile--border-radius tile--${componentStyle} ${isCondensed ? "condensed" : ""} ${condensedStyle}`}
      onClick={() => handleTileClick()}
    >
      {isCondensed && !noImage && desktopImageSrc && (
        <Desktop>
          <div className={`tile__image-wrapper-head ${isCondensed ? "condensed" : ""} ${condensedStyle}`}>
            <Image
              src={desktopImageSrc}
              className="tile__image"
              alt={altText}
              height={basicHeight}
              width={basicWidth}
              overrideDimensions={mobileOverride}
            />
          </div>
        </Desktop>
      )}
      {isCondensed && !noImage && mobileImageSrc && (
        <Mobile>
          <div className={`tile__image-wrapper-head ${isCondensed ? "condensed" : ""} ${condensedStyle}`}>
            <Image
              src={mobileImageSrc}
              className="tile__image"
              alt={!mobileAltText ? altText : mobileAltText}
              height={basicHeight === 0 ? maxImgH : basicHeight}
              width={basicWidth === 0 ? maxImgW : basicWidth}
              overrideDimensions={mobileOverride}
            />
          </div>
        </Mobile>
      )}

      {primaryTitle && (
        <Title defaultLevel={HEADING_LEVELS.THREE} className={`tile__header tile--padding-side ${isCondensed ? "condensed" : ""}  ${condensedStyle}`}>
          {primaryTitle}
        </Title>
      )}
      {description?.value && (
        <Description className={`tile__description tile--padding-side ${isCondensed ? "condensed" : ""}  ${condensedStyle}`} value={description.value} />
      )}
      {!noImage && desktopImageSrc && (
        <Desktop>
          <div className={`tile__image-wrapper ${isCondensed ? "condensed" : ""} ${condensedStyle}`}>
            <Image
              src={desktopImageSrc}
              className={`tile__image tile--border-radius-bottom ${isCondensed ? "condensed" : ""}  ${condensedStyle}`}
              alt={altText}
              height={basicHeight}
              width={basicWidth}
              overrideDimensions={overrideDimensions}
            />
          </div>
        </Desktop>
      )}
      {!noImage && mobileImageSrc && (
        <Mobile>
          <div className={`tile__image-wrapper ${isCondensed ? "condensed" : ""} ${condensedStyle}`}>
            <Image
              src={mobileImageSrc}
              className={`tile__image tile--border-radius-bottom ${isCondensed ? "condensed" : ""}  ${condensedStyle}`}
              alt={!mobileAltText ? altText : mobileAltText}
              height={basicHeight === 0 ? maxImgH : basicHeight}
              width={basicWidth === 0 ? maxImgW : basicWidth}
            />
          </div>
        </Mobile>
      )}
      {isCondensed && (
        <div className="tile__condensed-action-arrow">&rsaquo;</div>
      )}
    </LinkWrapper>
  );
}

export default BasicContentTile;
