import { useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { BrPageContext } from "@bloomreach/react-sdk";
import { GetGeneralLinkUrl, parseURL } from "../../helpers/LinkHelper";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";

//icon is for a font family icon, it is the className on the span inside the link, and goes before link
function CtaLink({ link, icon, iconPost, queryString, children, ...props }) {
  const page = useContext(BrPageContext);
  const { newWindow = false, label = "" } = link || {};

  try {
    const url = GetGeneralLinkUrl(link, page);
    const {
      result,
      parsed: { internal, path, href, subPageNav },
    } = parseURL(url);

    // If unable to retrieve url or parse it, wrap content in div
    if (!url || result !== "SUCCESS") {
      return <div {...props}>{children}</div>;
    }

    const newTabAttributes = newWindow ? { target: "_blank", rel: "noopener noreferrer" } : {};
    let focusEvt = subPageNav ? () =>{window.noScrollTo = true;} : () => {};
    if (internal) {
        if(props.onFocus && typeof props.onFocus === "function"){
            focusEvt = props.onFocus;
        }
        return (
        <Link to={queryString ? `${path}${queryString}` : path} {...newTabAttributes} {...props} aria-label={label} onFocus={focusEvt}>
          {children ? (
            children
          ) : (
            <>
              {icon && <span aria-hidden="true" className={icon}></span>}
              <span aria-hidden="true">{label}</span>
              {iconPost && <span aria-hidden="true" className={iconPost}></span>}
            </>
          )}
        </Link>
      );
    }

    return (
      <a href={queryString ? `${href}${queryString}` : href} {...newTabAttributes} {...props} aria-label={label}>
        {children ? (
          children
        ) : (
          <>
            {icon && <span aria-hidden="true" className={icon}></span>}
            <span aria-hidden="true">{label}</span>
            {iconPost && <span aria-hidden="true" className={iconPost}></span>}
          </>
        )}
      </a>
    );
  } catch (e) {
    console.error("CtaLink error", e);
    return <ErrorComponent error={e} componentName={"CtaLink"} />;
  }
}

CtaLink.propTypes = {
  link: PropTypes.object,
};

export default CtaLink;
