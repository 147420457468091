import React, { useRef } from "react";
import { CookieServiceContext } from "./context";
import AlertCookies from "../../services/AlertCookies";

// Component which provides an instance of AlertCookies Service
function CookieServiceProvider({ children }) {
    const serviceReference = useRef(AlertCookies());

    return (
        <CookieServiceContext.Provider value={serviceReference.current}>
            {children}
        </CookieServiceContext.Provider>
    );
}

export default CookieServiceProvider;
