import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { BrPageContext, BrComponentContext } from "@bloomreach/react-sdk";
import "./ContentTileContainer.scss";
import BasicContentTile from "../BasicContentTile/BasicContentTile";
import { Desktop, Mobile, IsTabletOrSmallerContent } from "../../../../media-queries/MediaQueries";
import CtaLink from "../../../Global/CtaLink";
import { COMPONENT_THEME, HEADING_LEVELS } from "../../../Global/GlobalConstants";
import ArticleTile from "../ArticleTile/ArticleTile";
import ProductTile from "../ProductTile/ProductTile";
import { isAuthored } from "../../../../helpers/LinkHelper";
import SearchTile from "../SearchTile/SearchTile";
import ErrorComponent from "../../ErrorComponent/ErrorComponent";
import Title from "../../../Global/Title/Title";
import { getProductsByCodes } from "../../../../helpers/GlobalHelpers";

function ContentTileContainer() {
  const page = React.useContext(BrPageContext);
  const component = React.useContext(BrComponentContext);

  const CONTENTTYPEARTICLEHEADER = "dartcontainercombrxm:ArticleHeader";
  const CONTENTTYPEPRODUCTTILE = "dartcontainercombrxm:ProductTile";
  const CONTENTTYPESEARCHFORM = "dartcontainercombrxm:SimpleSearchForm";

  const CONDENSED_CONTENT_TILE_CONTAINER = "Condensed Content Tiles";

  const TWO_ACROSS = "two_across";

  const [products, setProducts] = useState([]);
  const {
    document: documentRef,
    firstProductTileCode,
    secondProductTileCode,
    thirdProductTileCode,
    productServletURL,
  } = component.getModels();

  const {
    tile1Style,
    tile1MobileWidthOverride,
    tile2Style,
    tile2MobileWidthOverride,
    tile3Style,
    tile3MobileWidthOverride,
  } = ((component.model || {}).meta || {}).paramsInfo || {}

  const isCondensed = component.model.label == CONDENSED_CONTENT_TILE_CONTAINER;
  const condensed_styles = [tile1Style, tile2Style, tile3Style];
  const condensed_override_widths = [tile1MobileWidthOverride, tile2MobileWidthOverride, tile3MobileWidthOverride];
  const minTileHeight = 120;
  const isTabletOrSmaller = IsTabletOrSmallerContent();

  const tileCodes = [firstProductTileCode, secondProductTileCode, thirdProductTileCode];
  const containerRef = useRef(null);

  useEffect(() => {
    const getProductData = async () => {
      await getProductsByCodes(tileCodes, productServletURL).then((data) => {
        setProducts(data);
      });
    };
    const areAllUndefined = (prod) => prod === undefined;
    if (!tileCodes.every(areAllUndefined) && productServletURL) {
      getProductData();
    }
  }, []);

  const resizeTiles = () => {
    if(!isCondensed || !containerRef.current) return;
    var maxTileHeight = minTileHeight

    // Remove max height (so clientHeight will read the real height)
    for(var i = 0 ; i < containerRef.current.children.length ; i++) {
      const child = containerRef.current.children[i];
      child.style["min-height"] = null;
    }

    // Only enforce max height on > tablet
    if(isTabletOrSmaller) {
      return;
    }

    // Find max height
    for(var i = 0 ; i < containerRef.current.children.length ; i++) {
      const child = containerRef.current.children[i];
      if (maxTileHeight < child.clientHeight) {
        maxTileHeight = child.clientHeight;
      }
    }

    // Apply max height
    for(var i = 0 ; i < containerRef.current.children.length ; i++) {
      const child = containerRef.current.children[i];
      child.style["min-height"] = "" + maxTileHeight + "px";
    }
  };

  useLayoutEffect(()=> {
    resizeTiles()
    window.addEventListener('resize', resizeTiles);
    return () => window.removeEventListener('resize', resizeTiles);
  }, [isTabletOrSmaller]);

  try {
    const document = page.getContent(documentRef);
    if (!document) return null;

    const { componentStyle } = component.getParameters();
    const theme = COMPONENT_THEME.find((e) => e.ComponentStyle === componentStyle);

    const {
      title = null,
      callToAction = null,
      firstTileDocument = null,
      secondTileDocument = null,
      thirdTileDocument = null,
      firstTileImageHeightOverride = null,
      firstTileImageWidthOverride = null,
      secondTileImageHeightOverride = null,
      secondTileImageWidthOverride = null,
      thirdTileImageHeightOverride = null,
      thirdTileImageWidthOverride = null,
    } = document.getData();

    const tileWidthHint = (isCondensed && !thirdTileDocument) ? TWO_ACROSS : "";

    // set product height and width
    if (products[0]) {
      products[0].height = firstTileImageHeightOverride;
      products[0].width = firstTileImageWidthOverride;
    }
    if (products[1]) {
      products[1].height = secondTileImageHeightOverride;
      products[1].width = secondTileImageWidthOverride;
    }
    if (products[2]) {
      products[2].height = thirdTileImageHeightOverride;
      products[2].width = thirdTileImageWidthOverride;
    }

    // set article height and width
    if (firstTileDocument) {
      firstTileDocument.height = firstTileImageHeightOverride;
      firstTileDocument.width = firstTileImageWidthOverride;
    }
    if (secondTileDocument) {
      secondTileDocument.height = secondTileImageHeightOverride;
      secondTileDocument.width = secondTileImageWidthOverride;
    }
    if (thirdTileDocument) {
      thirdTileDocument.height = thirdTileImageHeightOverride;
      thirdTileDocument.width = thirdTileImageWidthOverride;
    }

    const tileDocuments = [firstTileDocument, secondTileDocument, thirdTileDocument];

    const showCta = isAuthored(callToAction);

    function getContentType(ref, product = undefined, condensedStyle, tileWidthOverride) {
      const tileDocument = page.getContent(ref);
      const { contentType = null } = tileDocument.getData();

      if (contentType === CONTENTTYPEARTICLEHEADER) {
        return (
          <ArticleTile
            tile={tileDocument}
            componentStyle={theme.ComponentStyle}
            height={ref.height}
            width={ref.width}
          />
        );
      } else if (contentType === CONTENTTYPEPRODUCTTILE) {
        if (product === undefined) {
          return null;
        }
        return (
          <ProductTile
            tile={tileDocument}
            product={product}
            componentStyle={theme.ComponentStyle}
          />
        );
      } else if (contentType === CONTENTTYPESEARCHFORM) {
        return (
          <SearchTile
            tile={tileDocument}
            componentStyle={theme.ComponentStyle}
            isCondensed={isCondensed}
          />
        );
      } else {
        return (
          <BasicContentTile
            tile={tileDocument}
            componentStyle={theme.ComponentStyle}
            basicHeight={ref.height}
            basicWidth={ref.width}
            isCondensed={isCondensed}
            condensedStyle={condensedStyle}
            tileWidthOverride={tileWidthOverride}
          />
        );
      }
    }

    return (
      <div className={`tile-container theme-${theme.ComponentStyle}`}>
        <div className="tile-container__wrapper">
          <div className="tile-container__header">
            {title && (
              <Title
                defaultLevel={HEADING_LEVELS.TWO}
                className={`tile-container__heading--${theme.ComponentStyle}`}
              >
                {title}
              </Title>
            )}
            {showCta && (
              <Desktop>
                <div className="tile-container__cta-wrapper">
                  <CtaLink
                    link={callToAction}
                    className={`tile-container__cta ${theme.CtaClasses} btn--secondary`}
                  />
                </div>
              </Desktop>
            )}
          </div>

          <div className={`tile-container__tiles ${tileWidthHint} ${isCondensed ? "condensed" : ""}`} ref={containerRef}>
            {firstTileDocument && getContentType(firstTileDocument, products[0], condensed_styles[0], condensed_override_widths[0])}
            {secondTileDocument && getContentType(secondTileDocument, products[1], condensed_styles[1], condensed_override_widths[1])}
            {thirdTileDocument && getContentType(thirdTileDocument, products[2], condensed_styles[2], condensed_override_widths[2])}
          </div>

          {showCta && (
            <Mobile>
              <div className="tile-container__cta-wrapper">
                <CtaLink
                  link={callToAction}
                  className={`tile-container__cta ${theme.CtaClasses} btn--secondary`}
                />
              </div>
            </Mobile>
          )}
        </div>
      </div>
    );
  } catch (e) {
    console.error("ContentTileContainer error", e);
    return <ErrorComponent error={e} componentName={"ContentTileContainer"} />;
  }
}

export default ContentTileContainer;
