import React from "react";
import { HEADING_LEVELS } from "../../Global/GlobalConstants";
import Title from "../../Global/Title/Title";

///used to show error in space where comoponent is broken
function ErrorComponent({ error = null, componentName = "unknown" }) {
  return (
    <>
      <div className={`error-component`}>
        <Title defaultLevel={HEADING_LEVELS.FIVE}>error in: {componentName}</Title>
        {error && (
          <>
            <p>Error Message</p>
            <pre>{error.message}</pre>
          </>
        )}
      </div>
    </>
  );
}

export default ErrorComponent;
