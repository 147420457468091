import Cookies from "js-cookie";

const { REACT_APP_ENV } = process.env;

const config =
    REACT_APP_ENV !== "LocalDevelopement"
        ? {
              sameSite: "strict",
              secure: true,
          }
        : {};

const parseValue = function (value) {
    return value.split("|");
};

const toDate = function (epoch) {
    return new Date(epoch);
};

const AlertCookies = function () {
    return {
        config: config,
        get: function (name) {
            return Cookies.get(name);
        },
        set: function ({ id, expirationDate }) {
            return Cookies.set(
                `${id}`,
                `${Date.now()}|${expirationDate ?? ""}`,
                {
                    ...(expirationDate && {
                        expires: toDate.call(this, expirationDate),
                    }),
                    ...this.config,
                }
            );
        },
        remove: function (name, attributes) {
            return Cookies.remove(name, attributes);
        },
        update: function (alert) {
            const value = this.get(alert.id);
            const { 1: expiration } = parseValue.call(this, value);

            if (parseInt(expiration) !== alert.expirationDate) {
                this.set(alert);
            }
        },
    };
};

export default AlertCookies;
