import { BrComponentContext, BrPageContext } from "@bloomreach/react-sdk";
import React, { useState } from "react";
import { Desktop, Mobile, XsMobile, XsToMobile } from "../../media-queries/MediaQueries";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import Description from "../Global/Description";
import { HEADING_LEVELS } from "../Global/GlobalConstants";
import Image from "../Global/Image/Image";
import Title from "../Global/Title/Title";
import "./ProductPatentCard.scss";

function InternationalNote({ title = "", description = "", locale = "" }) {
  description = description.replace("{}", locale);

  return (
    <div className="patent-card__note-wrapper">
      <span className="patent-card__note-icon darticon-region"></span>
      <div className="patent-card__note patent-card__note">
        <p className="patent-card__note-title bold-extra-bold">{title}</p>
        <Description
          value={description}
          className="patent-card__note-description bold-normal-plus"
        />
      </div>
    </div>
  );
}

function ProductPatentTable({
  patents = [],
  column1Label = "",
  column2Label = "",
  viewLessLabel = "",
  viewMoreLabel = "",
  caption = "",
  childProductName = "",
}) {
  const NBR_TBL_ROWS_DISPLAYED = 5;
  const [viewMorePressed, setViewMorePressed] = useState(false);
  const [tblRowCount, setTblRowCount] = useState(NBR_TBL_ROWS_DISPLAYED);

  const handleViewMore = () => {
    if (viewMorePressed === false) {
      setTblRowCount(patents.length);
      setViewMorePressed(true);
    } else if (viewMorePressed === true) {
      setTblRowCount(NBR_TBL_ROWS_DISPLAYED);
      setViewMorePressed(false);
    }
  };

  const cleanedColumn1Label = headerTextToId(column1Label);
  const cleanedColumn2Label = headerTextToId(column2Label);

  return (
    <>
      {patents && patents.length > 0 && (
        <table className="patent-card__table">
          {caption && <caption className="patent-card__description">{caption}</caption>}
          {column1Label && column2Label ? (
            <thead className="product-spec__table--header">
              <tr>
                <th className="bold-bold" id={cleanedColumn1Label} scope="col">{column1Label}</th>
                <th className="bold-bold" id={cleanedColumn2Label} scope="col">{column2Label}</th>
              </tr>
            </thead>
          ) : null}
          <tbody>
            {patents.slice(0, tblRowCount).map((item) => {
              const cleanedItemLabel = headerTextToId(item.issuer);
              return (
                <tr key={`${item.issuer}-${item.patentNumber}`}>
                  <th className="patent-card__table-left bold-medium" id={cleanedItemLabel} header={cleanedColumn1Label}>{item.issuer}</th>
                  <td className="bold-medium" header={`${cleanedColumn2Label} ${cleanedItemLabel}`}>{item.patentNumber}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {patents.length > NBR_TBL_ROWS_DISPLAYED && (
        <button className="patent-card__view-more-less-cta" onClick={handleViewMore}
          aria-label={`${viewMorePressed ? viewLessLabel : viewMoreLabel} patents for ${childProductName}`}
        >
          <p className="patent-card__view-more-less-text">
            {viewMorePressed ? viewLessLabel : viewMoreLabel}
          </p>
          <span
            className={`patent-card__button-icon ${
              viewMorePressed ? "darticon-arrow-up" : "darticon-arrow-down"
            }`}
          ></span>
        </button>
      )}
    </>
  );
}

function headerTextToId(text) {
  return (text.replace(/\W/g,'').toLowerCase());
}

function ProductPatentCard({ product = {}, productImageHeight, productImageWidth }) {
  const page = React.useContext(BrPageContext);
  const component = React.useContext(BrComponentContext);
  const { document: documentRef } = component.getModels();
  const document = page.getContent(documentRef);
  const xsMobileSize = 170;
  const mobileSize = 282;

  let imgClass = "standard";
  if (productImageHeight || productImageWidth) {
    imgClass = "custom";
  }

  try {
    if (!document || !product) return null;

    const {
      column1Label = "",
      column2Label = "",
      internationalProduct = "",
      internationalProductDescription = "",
      viewMorePatentDetailsLabel = "",
      viewLessPatentDetailsLabel = "",
      viewProductsDetailLabel = "",
      productCatalogs = [],
      contentCatalogs = [],
    } = document.getData();

    const {
      childProductName = "",
      productCode = "",
      catalogImage = "",
      patents = [],
      url = "",
      isInternational = false,
      originCatalog = "",
    } = product;

    //Get the brSM catalog name for the current product.  This catalog name is used in the international product message when a product matches to an international catalog.
    //The content catalog is used for Solo products. Product Catalog is used for Dart products.
    const contentCatalogLocale = contentCatalogs.find((i) => i.key === originCatalog)?.value;
    const productCatalogLocale = productCatalogs.find((i) => i.key === originCatalog)?.value;
    const locale = contentCatalogLocale || productCatalogLocale;

    return (
      <div className="patent-card">
        <div className="patent-card__wrapper">
          <div className={`patent-card__image-wrapper patent-card__image-wrapper-${imgClass}`}>
            {catalogImage && catalogImage.length > 0 && (
              <>
                <Desktop>
                  <Image
                    className={`patent-card__image patent-card__image-${imgClass} `}
                    src={catalogImage}
                    alt={childProductName}
                    width={productImageWidth}
                    height={productImageHeight}
                  />
                </Desktop>
                <XsToMobile>
                  <Image
                    className={`patent-card__image patent-card__image-${imgClass} `}
                    src={catalogImage}
                    alt={childProductName}
                    width={productImageWidth === 0 ? mobileSize : productImageWidth}
                    height={productImageHeight === 0 ? mobileSize : productImageHeight}
                  />
                </XsToMobile>
                <XsMobile>
                  <Image
                    className={`patent-card__image patent-card__image-${imgClass} `}
                    src={catalogImage}
                    alt={childProductName}
                    width={productImageWidth === 0 ? xsMobileSize : productImageWidth}
                    height={productImageHeight === 0 ? xsMobileSize : productImageHeight}
                  />
                </XsMobile>
              </>
            )}
          </div>
          <div className="patent-card__info">
            {childProductName && (
              <Title defaultLevel={HEADING_LEVELS.FIVE} className="patent-card__title">
                {childProductName}
              </Title>
            )}
            {product.url && (
              <Mobile>
                <a href={url} className="btn patent-card__btn" aria-label={`${viewProductsDetailLabel} ${childProductName}`}>
                  {viewProductsDetailLabel}
                </a>
              </Mobile>
            )}

            {patents && (
              <Desktop>
                <ProductPatentTable
                  patents={patents}
                  column1Label={column1Label}
                  column2Label={column2Label}
                  viewMoreLabel={viewMorePatentDetailsLabel}
                  viewLessLabel={viewLessPatentDetailsLabel}
                  caption={productCode}
                  childProductName={childProductName}
                />
              </Desktop>
            )}
          </div>

          <div className="patent-card__cta">
            {isInternational && (
              <Desktop>
                <InternationalNote
                  title={internationalProduct}
                  description={internationalProductDescription}
                  locale={locale}
                />
              </Desktop>
            )}
            {product.url && (
              <Desktop>
                <a href={url} className="btn patent-card__btn" aria-label={`${viewProductsDetailLabel} ${childProductName}`}>
                  {viewProductsDetailLabel}
                </a>
              </Desktop>
            )}
          </div>
        </div>
        {(isInternational || patents) && (
          <Mobile>
            <div className="patent-card__info">
              {isInternational && (
                <InternationalNote
                  title={internationalProduct}
                  description={internationalProductDescription}
                  locale={locale}
                />
              )}
              {patents && (
                <ProductPatentTable
                  patents={patents}
                  column1Label={column1Label}
                  column2Label={column2Label}
                  viewMoreLabel={viewMorePatentDetailsLabel}
                  viewLessLabel={viewLessPatentDetailsLabel}
                  caption={productCode}
                />
              )}
            </div>
          </Mobile>
        )}
      </div>
    );
  } catch (e) {
    return (
      <ErrorComponent
        error={e}
        componentName={ProductPatentCard.displayName || ProductPatentCard.name}
      />
    );
  }
}

export default ProductPatentCard;
