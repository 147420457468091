
import React from 'react';
import { BrComponent } from '@bloomreach/react-sdk';
import './GlobalConfigLayout.scss';

function GlobalConfigLayout() {
    return (
        <BrComponent path="layout">
            <div className="layout global-config-layout">
                <BrComponent path="header" />
                <BrComponent path="sitemessages" />
                <BrComponent path="globalproperties"></BrComponent>
                <div className="app-wrapper">
                    {/* intentionally empty TODO maybe route to root if page.isPreview() is true*/}
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
                <BrComponent path="footer" />
            </div>
        </BrComponent>
    );
};

export default GlobalConfigLayout;