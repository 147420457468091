import { Mobile, Desktop } from "../../media-queries/MediaQueries";
import LinkWrapper from "../../components/Global/LinkWrapper";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import Image from "../Global/Image/Image";
import { GLOBAL_PROPS } from "../Global/GlobalConstants";
import "./MaterialGuideCard.scss";

function MaterialAttribute({ checked = false, label = "" }) {
  const doesNotApply = window.globalProperties?.["doesNotApply"] ? window.globalProperties?.["doesNotApply"] : "Does Not Apply";
  return (
    <>
      <div
        className={`material-guide-card__body__material-attributes__item bold-medium ${
          checked ? "material-guide-card__body__material-attributes__item--checked bold-bold" : ""
        }`}
         aria-hidden={checked ? "false" : "true"}
      >
        {checked && (
          <>
            <span className="darticon-checkmark material-guide-card__body__material-attributes__item__checkmark" />
            {label}
          </>
        )}
        {!checked && (
          <del aria-label={doesNotApply}>{label}</del>
        )}
      </div>
    </>
  );
}

function MaterialIcon({ name = "" }) {
  return (
    <li className="material-guide-card__body__material-type-icons__item">
      <span className={`dart${name}`} aria-label={name} title={name} role="img" />
    </li>
  );
}

function MaterialGuideCard({ materialType = {} }) {
  try {
    const {
      title = "",
      image = "",
      link = {},
      icons: materialIcons = [],
      doesAccessRecyclingExists = false,
      isMadeWithRenewableResources = false,
      isCommerciallyCompostable = false,
      hasRecycledContent = false,
      isSourceReduced = false,
    } = materialType;

    const viewAllLabel =
      window.globalProperties?.[GLOBAL_PROPS.MATERIAL_LISTING.VIEW_ALL_BUTTON_LABEL];

    const getLabel = (key) => {
      switch (key) {
        case "accessRecyclingExists":
          return window.globalProperties?.[GLOBAL_PROPS.MATERIAL_LISTING.ACCESS_RECYCLING_LABEL];
        case "commerciallyCompostable":
          return window.globalProperties?.[
            GLOBAL_PROPS.MATERIAL_LISTING.COMMERCIAL_COMPOSTING_LABEL
          ];
        case "containsRecycledContent":
          return window.globalProperties?.[GLOBAL_PROPS.MATERIAL_LISTING.CONTAINS_RECYCLABES_LABEL];
        case "madeWithRenewableResources":
          return window.globalProperties?.[GLOBAL_PROPS.MATERIAL_LISTING.RENEWABLE_RESOURCES_LABEL];
        case "sourceReduced":
          return window.globalProperties?.[GLOBAL_PROPS.MATERIAL_LISTING.SOURCE_REDUCED_LABEL];
        default:
          return "";
      }
    };

    const attributes = {
      containsRecycledContent: { checked: hasRecycledContent },
      accessRecyclingExists: { checked: doesAccessRecyclingExists },
      madeWithRenewableResources: {
        checked: isMadeWithRenewableResources,
      },
      commerciallyCompostable: { checked: isCommerciallyCompostable },
      sourceReduced: { checked: isSourceReduced },
    };

    const labeledAttributes = Object.entries(attributes).map(({ 0: key, 1: value }) => {
      value.label = getLabel(key);
      return value;
    });

    const showCta = link.externalLink || link.internalLink;

    return (
      <div className="material-guide-card">
        <div className="material-guide-card__media">
          <Image className="material-guide-card__media__image" src={image} alt="" loading="lazy" />
          {showCta && (
            <Mobile>
              <LinkWrapper
                className="btn btn--primary material-guide-card__media__button"
                link={link}
              >
                {viewAllLabel}
              </LinkWrapper>
            </Mobile>
          )}
        </div>
        <div className="material-guide-card__body">
          <div className="material-guide-card__body__content">
            <h2 className="material-guide-card__body__title bold-extra-bold">{title}</h2>
            {labeledAttributes.length > 0 && (
              <>
                {labeledAttributes.map(({ checked, label }, index) => {
                  return (
                    <MaterialAttribute key={`${label}-${index}`} checked={checked} label={label} />
                  );
                })}
              </>
            )}
          </div>
          <div className="material-guide-card__body__material-type-icons__wrapper">
            {materialIcons.length > 0 && (
              <ul className="material-guide-card__body__material-type-icons">
                {materialIcons.map((name, index) => (
                  <MaterialIcon name={name} key={index} />
                ))}
              </ul>
            )}
            {showCta && (
              <Desktop>
                <LinkWrapper
                  className="material-guide-card__body__material-type-icons__wrapper__button btn btn--primary"
                  link={link}
                >
                  {viewAllLabel}
                </LinkWrapper>
              </Desktop>
            )}
          </div>
        </div>
      </div>
    );
  } catch (e) {
    return (
      <ErrorComponent
        error={e}
        componentName={MaterialGuideCard.displayName || MaterialGuideCard.name}
      />
    );
  }
}

export default MaterialGuideCard;
