import "./VideoIFrame.scss";

function VideoIFrame({ video, title }) {
  return (
    <div className="video-iframe__wrapper" tabIndex="0">
        <video className="video-iframe" title={title} loop muted autoPlay controls controlsList="nodownload" disablePictureInPicture onBlur={()=>{document.querySelector(".modal--open > .modal__main").focus()}}>
            <source src={video} type="video/mp4" />
        </video>
    </div>
  );
}

export default VideoIFrame;
