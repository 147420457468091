import React, {useEffect, useState} from "react"
import "./Toggle.scss";

function Toggle({ selected, label1, label2, ariaLabel, ...props }) {
  const [disabled, setDisabled] = useState(false);

  useEffect(()=>{
    setDisabled(label1 && label2 ? false : true);
  }, [label1, label2])

  return (
    <fieldset aria-label={ariaLabel} role="radiogroup" disabled={disabled}>
      <div className="toggle">
        <label htmlFor={label1}>{label1}</label>
        <span className="toggle__switch-wrapper">
          <input
            className="toggle__switch-checkbox__left"
            type="radio"
            id={label1}
            value={label1}
            checked={selected === label1}
            {...props}  />
          <input
            className="toggle__switch-checkbox__right"
            type="radio"
            id={label2}
            value={label2}
            checked={selected === label2}
            {...props}  />
          <span aria-hidden="true" className="toggle__switch-background"></span>
          <span aria-hidden="true" className="toggle__switch"></span>
        </span>
        <label htmlFor={label2}>{label2}</label>
      </div>
    </fieldset>
  );
}

export default Toggle;
