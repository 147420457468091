import React, { useEffect, useRef } from "react";
import { BrPage, BrPageContext, BrComponentContext } from "@bloomreach/react-sdk";
import { GTMProvider, useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import "./App.scss";
import axios from "axios";
import SetPrimaryColor from "./services/SetPrimaryColor";
import { Redirect } from "react-router-dom";
import { Helmet, HelmetData } from "react-helmet-async";

import Example from "./components/Example/Example.jsx";
import Navigation from "./components/Navigation/Navigation";
import Footer from "./components/Footer/Footer";
import PromoBanner from "./components/PromoBanner/PromoBanner";
import BasicContentTile from "./components/Common/Tiles/BasicContentTile/BasicContentTile.jsx";
import ContentTileContainer from "./components/Common/Tiles/ContentTileContainer/ContentTileContainer.jsx";
import HeroBanner from "./components/HeroBanner/HeroBanner";
import FiftyFiftyTextImage from "./components/Common/FiftyFiftyTextImage/FiftyFiftyTextImage";
import GlobalProperties from "./components/Global/GlobalProperties";
import GlobalSearchProperties from "./components/Global/GlobalSearchProperties";
import StickyButtons from "./components/StickyButtons/StickyButtons";
import ScreenReaderAlert from "./components/Global/ScreenReaderAlert/ScreenReaderAlert";
import ConsumeHashChange from "./components/ConsumeHashChange/ConsumeHashChange";
import RichText from "./components/Common/RichText/RichText";
import PageHeader from "./components/PageHeader/PageHeader";
import ContainerSiteMessages from "./components/SiteMessages/ContainerSiteMessages";
import VideoBanner from "./components/Common/VideoBanner/VideoBanner";
import IconGrid from "./components/IconGrid/IconGrid";
import ArticleHeader from "./components/ArticleHeader/ArticleHeader";
import AuthoredTileContainer from "./components/Common/Tiles/AuthoredTileContainer/AuthoredTileContainer";
import ContentTabs from "./components/Common/ContentTabs/ContentTabs";
import SearchBanner from "./components/SearchBanner/SearchBanner";
import SubscriptionForm from "./components/Common/SubscriptionForm/SubscriptionForm";
import RelatedDocuments from "./components/RelatedDocuments/RelatedDocuments";
import RegionSelector from "./components/RegionSelector/RegionSelector";
import ProductDetailHeader from "./components/ProductDetails/ProductDetailHeader";
import ProductDetailFeaturesAndBenefits from "./components/ProductDetails/ProductDetailFeaturesAndBenefits";
import PageRedirect from "./components/PageRedirect/PageRedirect";
import ProductDetailTabs from "./components/ProductDetails/ProductDetailTabs";
import ProductCollectionCarousel from "./components/ProductDetails/CollectionCarousel";
import ProductDetailCompatibleProducts from "./components/ProductDetails/CompatibleProducts";
import SavedProductsController from "./components/SavedProducts/SavedProductsController";
import ProductDetailSpecifications from "./components/ProductDetails/ProductDetailSpecifications";
import AnchorLink from "./components/AnchorLink/AnchorLink";
import SiteSearchResults from "./components/SiteSearchResults/SiteSearchResults";
import SingleTypeSearchListing from "./components/SingleTypeSearchListing/SingleTypeSearchListing";
import GatedContent from "./components/Common/SubscriptionForm/GatedContent";
import SEOComponent from "./components/SEOComponent/SEOComponent";
import NoIndex from "./components/NoIndex/NoIndex";
import CategoryPage from "./components/CategoryPage/CategoryPage";
import ShopTheLook from "./components/ShopTheLook/ShopTheLook";

import BaseLayout from "./layouts/BaseLayout/BaseLayout";
import CampaignPageLayout from "./layouts/CampaignPageLayout/CampaignPageLayout";
import GlobalConfigLayout from "./layouts/GlobalConfigLayout/GlobalConfigLayout";
import GlobalStyleSheetLayout from "./layouts/GlobalStyleSheetLayout/GlobalStyleSheetLayout";
import SearchController from "./components/Search/SearchController";
import PlantLocations from "./components/PlantLocations/PlantLocations";

import ErrorComponent from "./components/Common/ErrorComponent/ErrorComponent";
import PatentSearch from "./components/Search/PatentSearch";

import DartTraffic from "./helpers/DartTraffic";
import { getPageTitle, removeSpecialCharacters } from "./helpers/GlobalHelpers";
import { PRIMARYCOLORBLUE, regexpLocale } from "./components/Global/GlobalConstants";

const GlobalConfigLayoutClass =
  "com.dartcontainer.brxm.layouts.GlobalConfigLayout.GlobalConfigLayout";
const GlobalStyleSheetLayoutClass =
  "com.dartcontainer.brxm.layouts.GlobalStyleSheetLayout.GlobalStyleSheetLayout";
const CampaignPageLayoutClass =
  "com.dartcontainer.brxm.layouts.CampaignPageLayout.CampaignPageLayout";
const ProductPageLayoutClass =
  "com.dartcontainer.brxm.layouts.ProductDetailPageLayout.ProductDetailPageLayout";

//There are redirects from the old site that start with ca-fr.
//We do not want ca-fr to be considered a locale match
const oldFrenchCanadianLocale = 'ca-fr';

const {
  REACT_APP_BASE_URL,
  REACT_APP_RESOURCE_API_ENDPOINT,
} = process.env;

// Removing file extensions for URL rewriter so that '.pdf' urls can be rewritten
function RemoveFileExtensions ({ location }) {
  const regex = /\.\w{3,4}$/gmi;
  let resultArray = regex.exec(location.pathname);
  if (resultArray) {
      location.pathname = location.pathname.substring(0, location.pathname.length - resultArray[0].length + 1);
  }
}

function App({ location }) {
  RemoveFileExtensions({ location });
  const isFirstRender = useRef(true);
  const GTM_ID = "GTM-WFQP2JD";
  const DATALAYERNAME = "dataLayer";
  const helmetData = new HelmetData({});
  //get path and parse by regex
  const firstPathSegment = window.location.pathname.split("/")[1];
  const matchLocale = firstPathSegment !== oldFrenchCanadianLocale && firstPathSegment.match(regexpLocale);
  var currentChannelPath = "";
  var locationPathSearch = `${location.pathname}${location.search}`;
  var contentSecurityPolicy = "";
  //two different logic paths:
  // if we can find the OneTrust.changeLanguage function, we can use that to cleanly change the language of the cookie banner
  // if we can't we use a location.reload to refresh the page on locale change
  var newLocale = false;

  //check if we are under a special locale
  //if we are update the config values
  var _REACT_APP_RESOURCE_API_ENDPOINT = REACT_APP_RESOURCE_API_ENDPOINT;

  var _REACT_APP_BASE_URL = REACT_APP_BASE_URL;

  if (matchLocale) {
    _REACT_APP_RESOURCE_API_ENDPOINT = REACT_APP_RESOURCE_API_ENDPOINT.replace(
      "{0}",
      matchLocale[0] + "/"
    );
    locationPathSearch = locationPathSearch.replace("/" + matchLocale[0], "");
    currentChannelPath = "/" + matchLocale[0];
    _REACT_APP_BASE_URL += "/" + matchLocale[0];
  } else {
    _REACT_APP_RESOURCE_API_ENDPOINT = REACT_APP_RESOURCE_API_ENDPOINT.replace("{0}", "");
  }

  const configuration = {
    baseUrl: _REACT_APP_BASE_URL,
    endpoint: _REACT_APP_RESOURCE_API_ENDPOINT,
    endpointQueryParameter: "endpoint",
    httpClient: axios,
    path: currentChannelPath + locationPathSearch,
    request: {
      path: locationPathSearch,
    },
  };

  // Checking if app is being rendered in environment agnostic Prod CMS.
  // If so, setting origin url to correct domain so requests to resourceapi
  // are successful.
  if (window.location.href.includes("prod-cms")) {
    configuration.origin = "https://prod-cms.dart.bloomreach.cloud";
  }

  const mapping = {
    Example,
    "Content Tiles": ContentTileContainer,
    "Condensed Content Tiles": ContentTileContainer,
    BasicContentTile,
    "Global Header": Navigation, // For Authoring
    header: Navigation, // For Displaying
    "Global Footer": Footer, // For Authoring
    footer: Footer, // For Displaying
    "Hero Banner": HeroBanner,
    "Promo Banner": PromoBanner,
    "Fifty Fifty Image And Text": FiftyFiftyTextImage,
    "Global Properties": GlobalProperties,
    "Global Search Properties": GlobalSearchProperties,
    "Rich Text": RichText,
    "Page Header": PageHeader,
    "Site Messages": ContainerSiteMessages,
    "Video Banner": VideoBanner,
    "Icon Grid": IconGrid,
    "Article Header": ArticleHeader,
    "Authored Tiles": AuthoredTileContainer,
    "Content Tabs": ContentTabs,
    "Search Banner": SearchBanner,
    "Curated Listing": SearchController,
    "Subscription Form": SubscriptionForm,
    "Related Documents": RelatedDocuments,
    "Region Selector": RegionSelector,
    "Plant Location": PlantLocations,
    "Product Detail Features And Benefits": ProductDetailFeaturesAndBenefits,
    "Product Detail Tabs": ProductDetailTabs,
    "Product Detail Header": ProductDetailHeader,
    "Product Detail Collection Carousel": ProductCollectionCarousel,
    Redirect: PageRedirect,
    "Redirect URL": PageRedirect,
    "Product Detail Compatible Products": ProductDetailCompatibleProducts,
    "Saved Products": SavedProductsController,
    "Product Detail Specification": ProductDetailSpecifications,
    "Anchor Link": AnchorLink,
    "Site Search Results": SiteSearchResults,
    "Gated Content": GatedContent,
    "Single Type Search Listing": SingleTypeSearchListing,
    "Patent Search": PatentSearch,
    "SEO Component": SEOComponent,
    "No Index": NoIndex,
    "Category Page Indicator": CategoryPage,
    "Shop The Look": ShopTheLook
  };

  useEffect(() => {
    if(newLocale) {
      //reload the page to reload cookie banner with the correct lang
      window.location.reload();
    }
  }, [matchLocale]);

  //sets the page title and update html lang for cookie
  const cookieCheckerPageTitle = (page) => {
    if (
      page.getComponent("globalproperties") &&
      page.getComponent("globalproperties").getModels()
    ) {
      // Getting and setting browser page title
      const pageTitle = getPageTitle(page);
      if (pageTitle) {
        window.document.title = pageTitle;
      }

      if(window.document.documentElement.getAttribute("lang") != ((matchLocale && matchLocale.input) || 'en')) {
        newLocale = true;
      }

      //update language for cookie
      if (matchLocale) {
        window.document.documentElement.setAttribute("lang", matchLocale);
      }
      else {
        window.document.documentElement.setAttribute("lang", "en");
      }
    }
    return null;
  };

  const buildContentSecurityPolicy = (cspData) => {
      var contentSecurityPolicy = "";
      if (cspData && cspData.items) {
        cspData.items.forEach((item) => contentSecurityPolicy += `${item.key} ${item.label};`);
      }

      return contentSecurityPolicy;
  }

  //setup global properties at window.globalProperties
  const setupGlobalProperties = (page) => {
    //set all the global props to a global objects
    const component = page.getComponent("globalproperties");
    const { document: documentRef, channelLanguage } = component.getModels();
    const document = page.getContent(documentRef);
    if (!document) return null;
    const documentData = document.getData();

    var contentSecurityPolicyDocument = page.getContent(documentData.contentSecurityPolicyDocument);
    contentSecurityPolicy = page.getContent(documentData.contentSecurityPolicyDocument) ? buildContentSecurityPolicy(contentSecurityPolicyDocument.getData()) : "";

    var globalProperties = {};
    for (const globalProp in documentData) {
      if (
        globalProp !== "id" &&
        globalProp !== "contentType" &&
        globalProp !== "name" &&
        globalProp !== "displayName"
      ) {
        globalProperties[globalProp] = documentData[globalProp];
      }
    }
    if (channelLanguage === "en") {
      globalProperties["channelLanguage"] = "en-us";
    } else {
      globalProperties["channelLanguage"] = channelLanguage;
    }
    //values set to window object
    window.globalProperties = globalProperties;
    // Setting Primary Site Color
    if (window.globalProperties && window.globalProperties.primarySiteColor) {
      // Getting color from Global Properties, will be either 'Blue' or 'Green'
      const primaryColorToUse = window.globalProperties.primarySiteColor;
      if (primaryColorToUse === PRIMARYCOLORBLUE) {
        // console.log("set color blue");
        SetPrimaryColor.setBluePrimary();
      } else {
        // console.log("set color green");
        SetPrimaryColor.setGreenPrimary();
      }
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      // Update the document title using the browser API
      //adding the bicyclette adoboe font to system
      DartTraffic();
      const scriptFonts = window.document.createElement("script");

      scriptFonts.src = "https://use.typekit.net/itu4hpv.js";

      window.document.body.appendChild(scriptFonts);
      //this is used to 'activate' the font
      //gets caught in catch but still works
      //wait till typekit stuff loaded
      var checkFontStuffExist = setInterval(function () {
        if (window.Typekit) {
          window.Typekit.load({ async: true });
          clearInterval(checkFontStuffExist);
        }
      }, 100); // check every 100ms
      isFirstRender.current = false;
    }
    return;
  });

  return (
    <GTMProvider
      state={{
        id: GTM_ID,
        dataLayerName: DATALAYERNAME,
      }}
    >
      <BrPage configuration={configuration} mapping={mapping}>
        <BrPageContext.Consumer>
          {(page) => (
            <ScreenReaderAlert>
              {setupGlobalProperties(page)}
              <Layout currentChannelPath={currentChannelPath} />
              {cookieCheckerPageTitle(page)}
              <Helmet helmetData={helmetData}>
                <meta http-equiv="Content-Security-Policy" content={contentSecurityPolicy} />
                <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" data-document-language="true" type="text/javascript" charset="UTF-8" data-domain-script="a220a074-4c1c-42b9-9279-6b4ef1c8664d" ></script>
              </Helmet>
              <StickyButtons />
              <ConsumeHashChange listenFor={"#cookie-preferences"} onTrigger={() => window.OneTrust.ToggleInfoDisplay()} />
            </ScreenReaderAlert>
          )}
        </BrPageContext.Consumer>
      </BrPage>
    </GTMProvider>
  );
}
//determine what layout to use
//BaseLayout is default
const Layout = ({ currentChannelPath }) => {
  const sendDataToGTM = useGTMDispatch();
  const page = React.useContext(BrPageContext);
  const component = React.useContext(BrComponentContext);
  const urlParams = new URLSearchParams(window.location.search);
  const searchTerm = urlParams.get("q");

  // useEffect for Pixel Tracking insertion on each page
  useEffect(() => {
    const { pixelProps } = component.getModels(); // getting Pixel Props object
    const scriptPixels = document.createElement("script"); // creating script element

    if (pixelProps && pixelProps.pType) {
      if (pixelProps.pType === "product") {
        scriptPixels.innerHTML = `
          var br_data = {
            test_data: "${pixelProps.testData}",
            acct_id: "${pixelProps.accountId}",
            ptype: "${pixelProps.pType}",
            title: "${pixelProps.title}",
            domain_key: "${pixelProps.productCatalog}",
            view_id: "${pixelProps.viewId}",
            tms: "",
            prod_id: "${pixelProps.prodId}",
            prod_name: "${pixelProps.prodName}",
          };
          `;
      } else if (pixelProps.pType === "content") {
        scriptPixels.innerHTML = `
          var br_data = {
            test_data: "${pixelProps.testData}",
            acct_id: "${pixelProps.accountId}",
            ptype: "${pixelProps.pType}",
            title: "${pixelProps.title}",
            domain_key: "${pixelProps.productCatalog}",
            view_id: "${pixelProps.viewId}",
            tms: "",
            catalogs: "[ { name : ${pixelProps.productCatalog} } ]",
            item_id: "${pixelProps.itemId}",
            item_name: "${pixelProps.itemName}",
          };
          `;
      } else if (pixelProps.pType === "search") {
        if(searchTerm) {
          scriptPixels.innerHTML = `
            var br_data = {
              test_data: "${pixelProps.testData}",
              acct_id: "${pixelProps.accountId}",
              ptype: "${pixelProps.pType}",
              title: "${pixelProps.title}",
              domain_key: "${pixelProps.productCatalog}",
              view_id: "${pixelProps.viewId}",
              tms: "",
              search_term: "${searchTerm}",
            };
            `;
        }
      } else if (pixelProps.pType === "category") {
        scriptPixels.innerHTML = `
          var br_data = {
            test_data: "${pixelProps.testData}",
            acct_id: "${pixelProps.accountId}",
            ptype: "${pixelProps.pType}",
            title: "${pixelProps.title}",
            domain_key: "${pixelProps.productCatalog}",
            view_id: "${pixelProps.viewId}",
            tms: "",
            catalogs: "[ { name : ${pixelProps.productCatalog} } ]",
            cat: "${pixelProps.category}",
            cat_id: "${removeSpecialCharacters(pixelProps.category)}",
          };
          `;
      } else {
        scriptPixels.innerHTML = `
          var br_data = {
            test_data: "${pixelProps.testData}",
            acct_id: "${pixelProps.accountId}",
            ptype: "${pixelProps.pType}",
            title: "${pixelProps.title}",
            domain_key: "${pixelProps.productCatalog}",
            view_id: "${pixelProps.viewId}",
            tms: "",
          };
          `;
      }

      document.head.appendChild(scriptPixels);

      const brPixelTrack = document.createElement("script");
      brPixelTrack.type = "text/javascript";
      brPixelTrack.async = true;
      brPixelTrack.src = `https://cdn.brcdn.com/v1/br-trk-${pixelProps.accountId}.js`;

      const firstScript = document.getElementsByTagName("script")[0];
      firstScript.parentNode.insertBefore(brPixelTrack, firstScript);
    }
  });

  //getting page info didn't work if we don't parse/stringify it
  var _page = JSON.parse(JSON.stringify(page));
  // console.log("page", _page);
  // console.log("_page.links", _page.links);
  // console.log("_page.links.site", _page.links.site);
  // console.log("_page.links.site.href", _page.links.site.href);
  //to account for analytics we need to only have the page load onces for Link types
  //to do this i have to check if the page links href is the same as the pathname
  //i had to make a copy of the page var because regular page didn't work
  //doesn't do gtm if we are on page preview
  try {
    //check for redirects
    const { channelLanguage = "", channelLocale = "", dartRedirectLocation, dartRedirectStatus } = page
      .getComponent("globalproperties")
      .getModels();

    if (dartRedirectLocation && dartRedirectStatus) {  
      if (dartRedirectLocation.startsWith("http")) {
        //navigating to an external site
        window.location.href = dartRedirectLocation;
      } 
      
      return <Redirect to={{ pathname: dartRedirectLocation, state: { status: parseInt(dartRedirectStatus) } }} />;
    }
    
    var pathNameAccountingForChannel = window.location.pathname.replace(currentChannelPath, "");
    if (pathNameAccountingForChannel === "") {
      pathNameAccountingForChannel = "/";
    }
    var pageHref = _page.links.site.href.split("?")[0];
    // this needs to happen due to how we handle the dynamic products mapping in Bloomreach
    if (pageHref.startsWith("/products-redirect/")) {
      pageHref = pageHref.replace("/products-redirect/", "/products/");
    }
    if (
      (_page && _page.links && _page.links.site && pageHref === pathNameAccountingForChannel) ||
      page
    ) {
      if (
        page.getComponent() &&
        page.getComponent().model &&
        page.getComponent().model.componentClass
      ) {
        // console.log(
        //   "page.getComponent().model.componentClass",
        //   page.getComponent().model.componentClass
        // );
        switch (page.getComponent().model.componentClass) {
          case CampaignPageLayoutClass:
            if (page.isPreview()) {
              return <CampaignPageLayout />;
            } else {
              sendDataToGTM({
                event: "pageLoad",
                value: {
                  pageType: "campaign page",
                  pageTitle: getPageTitle(page),
                  language: channelLanguage,
                  locale: channelLocale,
                },
              });
              return <CampaignPageLayout />;
            }
          case ProductPageLayoutClass:
            if (page.isPreview()) {
              return <BaseLayout />;
            } else {
              sendDataToGTM({
                event: "pageLoad",
                value: {
                  pageType: "product detail page",
                  pageTitle: getPageTitle(page),
                  language: channelLanguage,
                  locale: channelLocale,
                },
              });
              return <BaseLayout />;
            }
          case GlobalConfigLayoutClass:
            return <GlobalConfigLayout />;
          case GlobalStyleSheetLayoutClass:
            return <GlobalStyleSheetLayout />;
          default:
            if (page.isPreview()) {
              return <BaseLayout />;
            } else {
              sendDataToGTM({
                event: "pageLoad",
                value: {
                  pageType: "general content page",
                  pageTitle: getPageTitle(page),
                  language: channelLanguage,
                  locale: channelLocale,
                },
              });
              return <BaseLayout />;
            }
        }
      } else {
        if (page.isPreview()) {
          return <BaseLayout />;
        } else {
          sendDataToGTM({
            event: "pageLoad",
            value: {
              pageType: "general content page",
              pageTitle: getPageTitle(page),
              language: channelLanguage,
              locale: channelLocale,
            },
          });
          return <BaseLayout />;
        }
      }
    } else {
      return <ErrorComponent error={null} componentName={"App"} />;
    }
  } catch (e) {
    console.error("app error", e);
    return <ErrorComponent error={e} componentName={"App"} />;
  }
};

export default App;
