import { useState, useEffect } from "react";
import { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import {
  DesktopNav as Desktop,
  MobileNav as Mobile,
  IsDesktopNav,
} from "../../media-queries/MediaQueries";
import AddProduct from "../Global/AddProduct/AddProduct";
import { SIZE } from "../Global/AddProduct/constants";
import "./CollectionCarousel.scss";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import { HEADING_LEVELS, PRODCOLLECTIONTABID } from "../Global/GlobalConstants";
import { Link, useLocation } from "react-router-dom";
import { getUrlPath } from "../../helpers/LinkHelper";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { getProductsByCodes } from "../../helpers/GlobalHelpers";
import Image from "../Global/Image/Image";

import "swiper/swiper.scss";
import "swiper/modules/a11y/a11y.scss";
import "swiper/modules/navigation/navigation.scss";
import Title from "../Global/Title/Title";
import LoadingSpinner from "../Global/LoadingSpinner/LoadingSpinner";

const MAXPRODUCTS = 6;
const MOBILEPRODUCTSLIDES = 1;
const TABLETPRODUCTSLIDES = 2;
const DESKTOPPRODUCTSLIDES = 3;

function ProductTile({ product }) {
  const { productCode, childProductName, catalogImage, catalogImageAlternateText, url } = product;
  const sendDataToGTM = useGTMDispatch();
  const isDesktop = IsDesktopNav();
  const viewDetailsForProduct = ((window.globalProperties && window.globalProperties['viewDetailsForProduct']) || 'View Details for') + ' ' +  productCode;

  const handleProductClick = () => {
    sendDataToGTM({
      event: "carouselClick",
      productName: childProductName,
      listType: "Collection",
    });
  };

  return (
    <div className="product-collection__carousel__card">
      <Link
        className="product-collection__carousel__card__link"
        onClick={handleProductClick}
        to={getUrlPath(url)}
      >
        <div className="product-collection__carousel__card__image-wrapper">
          <Image
            className="product-collection__carousel__card__image"
            src={catalogImage}
            loading="lazy"
            alt={viewDetailsForProduct}
          />
          <p className="product-collection__carousel__card__name bold-extra-bold">{childProductName}</p>
        </div>
      </Link>
      <AddProduct
        productCode={productCode}
        productName={childProductName}
        btnSize={isDesktop ? SIZE.LARGE : SIZE.MEDIUM}
        addToListAnalytics={{
          event: "listAdd",
          listType: "Collection",
          productName: `${childProductName}`,
        }}
        removeFromListAnalytics={{
          event: "removeFromList",
          listType: "Collection",
          productName: `${childProductName}`,
        }}
      />
    </div>
  );
}

function ProductCarousel({ products }) {
  if (products.length > MAXPRODUCTS) {
    products = products.slice(0, MAXPRODUCTS);
  }

  return (
    <Swiper
      navigation
      modules={[Navigation, A11y]}
      allowSlideNext={false}
      allowSlidePrev={false}
      loop
      a11y={{
        prevSlideMessage: (window.globalProperties && window.globalProperties["prevSlideMessage"]) || "Previous slide",
        nextSlideMessage: (window.globalProperties && window.globalProperties["nextSlideMessage"]) || "Next slide"
      }}

      breakpoints={{
        0: {
          slidesPerView: products.length <= MOBILEPRODUCTSLIDES ? products.length : MOBILEPRODUCTSLIDES,
          allowSlideNext: products.length > MOBILEPRODUCTSLIDES ? true : false,
          allowSlidePrev: products.length > MOBILEPRODUCTSLIDES ? true : false,
          spaceBetween: 10,
          touchRatio: 1,
        },
        640: {
          slidesPerView: products.length <= TABLETPRODUCTSLIDES ? products.length : TABLETPRODUCTSLIDES,
          allowSlideNext: products.length > TABLETPRODUCTSLIDES ? true : false,
          allowSlidePrev: products.length > TABLETPRODUCTSLIDES ? true : false,
          spaceBetween: 10,
          touchRatio: 1,
        },
        992: {
          slidesPerView: products.length <= DESKTOPPRODUCTSLIDES ? products.length : DESKTOPPRODUCTSLIDES,
          allowSlideNext: products.length > DESKTOPPRODUCTSLIDES ? true : false,
          allowSlidePrev: products.length > DESKTOPPRODUCTSLIDES ? true : false,
          spaceBetween: 10,
          touchRatio: 1,
        },
        1150: {
          slidesPerView: products.length <= DESKTOPPRODUCTSLIDES ? products.length : DESKTOPPRODUCTSLIDES,
          allowSlideNext: products.length > DESKTOPPRODUCTSLIDES ? true : false,
          allowSlidePrev: products.length > DESKTOPPRODUCTSLIDES ? true : false,
          spaceBetween: 40,
          touchRatio: 0,
        },
      }}
    >
      {products.map((product) => {
        return (
          <SwiperSlide key={product.productCode}>
            <ProductTile
              product={product}
              addToListAnalytics={{
                event: "listAdd",
                listType: "Collection",
                productName: `${product.name}`,
              }}
              removeFromListAnalytics={{
                event: "removeFromList",
                listType: "Collection",
                productName: `${product.name}`,
              }}
            />         
          </SwiperSlide>        
        );
      })}
    </Swiper>
  );
}

function ProductCollectionCarousel({ component, page }) {
  const { document: documentRef, carouselProductCodes } = component.getModels();
  const document = page.getContent(documentRef);
  const { productServletURL } = page.getComponent("header").getModels();
  const [carouselProducts, setCarouselProducts] = useState(null);
  const [loadingCarousel, setLoadingCarousel] = useState(true);

  useEffect(() => {
    getCompatibleProducts();
  }, [page]);
  useEffect(() => {
    if (carouselProductCodes) {
      getCompatibleProducts();
    }
  }, []);

  const getCompatibleProducts = async () => {
    await getProductsByCodes(carouselProductCodes.slice(0, MAXPRODUCTS), productServletURL).then(
      (data) => {
        setCarouselProducts(data);
        setLoadingCarousel(false);
      }
    );
  };

  try {
    if (!document) return null;

    const { primaryTitle = "", link = {} } = document.getData();

    const showCta = link.label ? true : false;

    let ctaLink = getUrlPath(component.model.models.productCollectionLink);
    if(ctaLink === undefined) ctaLink = window.location.href;
    return (
      <section className="product-collection" id={PRODCOLLECTIONTABID}>
        <div className="product-collection__inner">
          <div className="product-collection__title-wrapper theme-tertiary">
            <Title defaultLevel={HEADING_LEVELS.THREE} className="product-collection__title">
              {primaryTitle}
            </Title>
            {link && link.label && (
              <Desktop>
                <div className="product-collection__cta-wrapper theme-tertiary">
                  <Link to={ctaLink} className="btn btn--tertiary" id={`${PRODCOLLECTIONTABID}_focus`}>
                    {link.label}
                  </Link>
                </div>
              </Desktop>
            )}
          </div>

          <div className="product-collection__carousel">
            {loadingCarousel && <LoadingSpinner className="lds-default lds-blue" />}
            {carouselProducts && carouselProducts.length > 0 && (
              <ProductCarousel products={carouselProducts} />
            )}
          </div>
        </div>
        {link && link.label && (
          <Mobile>
            <div className="product-collection__cta-wrapper theme-tertiary">
              <Link to={ctaLink} className="btn btn--tertiary" id={`${PRODCOLLECTIONTABID}_focus`}>
                {link.label}
              </Link>
            </div>
          </Mobile>
        )}
      </section>
    );
  } catch (e) {
    console.error("ProductCollectionCarousel error", e);
    return <ErrorComponent error={e} componentName={"ProductCollectionCarousel"} />;
  }
}

export default ProductCollectionCarousel;
