import ResultsCount from "./ResultsCount";
import "./SearchToolbar.scss";

function SearchToolbarInfo() {

  return (
    <div className="curated-list-toolbar-info">
      <div className="curated-list-toolbar__sidebar">
        <ResultsCount />
      </div>
    </div>
  );
}

export default SearchToolbarInfo;
