import React from 'react';
import {
    BrComponent,
} from '@bloomreach/react-sdk';
import './CampaignPageLayout.scss';

function CampaignPageLayout() {
    return (
        <div>
            <BrComponent path="layout">
                <div className="app-wrapper">
                    <BrComponent path="main" />
                </div>
            </BrComponent>
        </div>
    );
};

export default CampaignPageLayout;