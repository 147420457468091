import { useState, useEffect, useContext, useRef } from "react";
import { BrComponentContext, BrPageContext } from "@bloomreach/react-sdk";
import { Link, useHistory } from "react-router-dom";
import NavigationProductTile from "./NavigationProductTile";
import CtaLink from "../Global/CtaLink";
import Description from "../Global/Description";
import Image from "../Global/Image/Image";
import { GetGeneralLinkUrl, getUrlPath } from "../../helpers/LinkHelper";
import { IsMobileNav } from "../../media-queries/MediaQueries";
import { useOverrideDimensions } from "./hooks";
import { ENTERKEYCODE } from "../Global/GlobalConstants";
import NavigationContext from "./context";
import "./NavigationSearch.scss";

function NavigationSearch({ setSearchOpen, navDocument }) {
  const MINNUMBERCHARACTERS = 3;
  const TYPEAHEADDELAY = 300;
  const MAXSUGGESTIONS = 6;
  const MAXNAVIGATIONPRODUCTS = 2;
  const MAXSUGGESTEDARTICLES = 3;
  const MAXTITLELENGTH = 60;
  const MAXDESCRIPTIONLENGTH = 160;

  const page = useContext(BrPageContext);
  const component = useContext(BrComponentContext);
  const navContext = useContext(NavigationContext);
  const { pixelProps } = page.getComponent().getModels();

  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [typeAheadSearchResults, setTypeAheadSearchResults] = useState(null);
  const searchTimeout = useRef(null);

  const clearSearchLabelText = window.globalProperties?.["clearSearchAriaLabel"]
    ? window.globalProperties?.["clearSearchAriaLabel"]
    : "clear search";

  const {
    featuredProductMobileWidth,
    featuredProductMobileHeight,
    featuredProductDesktopWidth,
    featuredProductDesktopHeight,
    searchSuggestionsArticleWidth,
    searchSuggestionsArticleHeight,
  } = useOverrideDimensions();

  const {
    searchFieldPlaceholder = null,
    searchSuggestedArticlesTitle = null,
    searchSuggestedProductsTitle = null,
    searchSuggestionsTitle = null,
    popularArticles = null,
    popularArticlesTitle = null,
    popularProductsTitle = null,
    popularSearchTermsTitle = null,
    popularSearchTerms = null,
    viewAllLink = null,
  } = navDocument.getData();

  const { brsmSuggestURL, componentConfigurationPath } = component.getModels();

  const handleSearchEnter = (event) => {
    const key = event.keyCode;
    // If keyCode = 13 or 'Enter', perform search
    if (key === ENTERKEYCODE && searchText && searchText.length > 0) {
      var brSearchData = {};
      const BrTrk = window.BrTrk;
      brSearchData["q"] = searchText;
      brSearchData["catalogs"] = [{ name: pixelProps.productCatalog }];

      if (BrTrk) {
        BrTrk.getTracker().logEvent("suggest", "submit", brSearchData, {}, true);
        gotoSearchPage();
      }
    }
  };
  const handleInputBlur = (event) => {
    var items = document.querySelectorAll(".navigation-search__article-title");
    if (event.key === "Tab" && event.shiftKey === true) {
      event.preventDefault();
      if(items.length > 0){
          items[items.length - 1].focus();
      }else{
          items = document.querySelectorAll(".navigation-product-tile")
          items[items.length - 1].querySelectorAll(".add-product__add-link")[0].focus();
      }
    }
  };

  function searchSuggestClick(term) {
    var brSearchData = {};
    const BrTrk = window.BrTrk;
    brSearchData["aq"] = searchText;
    brSearchData["q"] = term;
    brSearchData["catalogs"] = [{ name: pixelProps.productCatalog }];

    if (BrTrk) {
        BrTrk.getTracker().logEvent("suggest", "click", brSearchData, {}, true);
    }

    setSearchOpen(false);
  }

  const gotoSearchPage = () => {
    if (viewAllLink && viewAllLink.internalLink) {
      history.push(
        getUrlPath(GetGeneralLinkUrl(viewAllLink, page)) + "?q=" + encodeURIComponent(searchText)
      );
      setSearchOpen(false);
    } else if (viewAllLink && viewAllLink.externalLink) {
      window.location =
        GetGeneralLinkUrl(viewAllLink, page) + "?q=" + encodeURIComponent(searchText);
    }
  };
  const runTypeAheadSearch = () => {
    // const mockDocumentUrl =
    //   "https://private-7d5bd-dartcontainer.apiary-mock.com/suggest?q=" + encodeURIComponent(searchText);
    const completeSuggestURL =
      brsmSuggestURL +
      "?componentConfigPath=" +
      componentConfigurationPath +
      "&q=" +
      encodeURIComponent(searchText);
    fetch(completeSuggestURL)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setTypeAheadSearchResults(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    // Condense breadcrumbs on page navigation when on mobile
    //do search if over x characters
    if (searchText && searchText.length === MINNUMBERCHARACTERS) {
      searchTimeout.current = setTimeout(runTypeAheadSearch, TYPEAHEADDELAY);
    } else if (searchText && searchText.length >= MINNUMBERCHARACTERS) {
      clearTimeout(searchTimeout.current);
      searchTimeout.current = setTimeout(runTypeAheadSearch, TYPEAHEADDELAY);
    } else {
      setTypeAheadSearchResults(null);
    }
    document.querySelectorAll(".navigation-search__input")[0].focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  //if we have results and there are terms to show we show them...else we use what the resource api has for popular
  //if neither we show nothing
  const outputSuggestionPopularSearches = () => {
    //check if we have suggestions...if not use the popular ones
    //popular is massaged to make it like the suggestinos
    const suggestionsSearches =
      typeAheadSearchResults &&
      typeAheadSearchResults.terms &&
      typeAheadSearchResults.terms.length > 0
        ? typeAheadSearchResults.terms
        : popularSearchTerms && popularSearchTerms.length > 0
        ? popularSearchTerms.map((str) => ({ query: str, displayText: str }))
        : null;
    if (suggestionsSearches) {
      return (
        <ul className="navigation-search__suggestions-list">
          {suggestionsSearches.slice(0, MAXSUGGESTIONS).map((item, index) => (
            <li
              key={`suggestion-item__${index}`}
              className="navigation-search__suggestions-list-item"
            >
              {/* //put strong around the search query */}
              <Link
                className="navigation-search__suggestions-list-item-link"
                onClick={() => searchSuggestClick(item.query)}
                to={`${getUrlPath(GetGeneralLinkUrl(viewAllLink, page))}?q=${encodeURIComponent(
                  item.query
                )}`}
                dangerouslySetInnerHTML={{
                  __html:
                    typeAheadSearchResults && typeAheadSearchResults.query
                      ? item.displayText
                          .replace(
                            typeAheadSearchResults.query,
                            "&nbsp;<strong>" + typeAheadSearchResults.query + "</strong>"
                          )
                          .trim()
                      : item.displayText,
                }}
              ></Link>
            </li>
          ))}
        </ul>
      );
    } else {
      return null;
    }
  };
  const outputSuggestionPopularProducts = () => {
    //with the documents popular products we get the header level popular product that matches the code
    //get pop articles and normalize them to be like the other returned articles
    const _popularProducts = navContext.popularProducts
      .slice(0, MAXNAVIGATIONPRODUCTS)
      .map((item) => {
        if (item.productCode && item.childProductName) {
          return {
            content_type: "product",
            title: item.childProductName,
            thumb_image: item.catalogImage,
            url: item.url,
            id: item.productCode,
            isProPlanet: item.isProPlanet,
            isCompostable: item.isCompostable,
          };
        } else {
          return null;
        }
      });
    //check if we have suggestions...if not use the popular ones
    const suggestionsProducts =
      typeAheadSearchResults &&
      typeAheadSearchResults.products &&
      typeAheadSearchResults.products.length > 0
        ? typeAheadSearchResults.products
        : _popularProducts;

    if (suggestionsProducts) {
      const width = Number(
        IsMobileNav() ? featuredProductMobileWidth : featuredProductDesktopWidth
      );
      const height = Number(
        IsMobileNav() ? featuredProductMobileHeight : featuredProductDesktopHeight
      );

      return suggestionsProducts.slice(0, MAXNAVIGATIONPRODUCTS).map((item, index) =>
        item && item.title ? (
          <NavigationProductTile
            key={`suggestion-products__${index}`}
            product={{
              name: item.title,
              location: item.url,
              image: item.thumb_image,
              imageWidth: width,
              imageHeight: height,
              isProPlanet: item.isProPlanet,
              isPrintable: item.isPrintable,
              isEmbossable: item.isEmbossable,
              isNewProduct: item.isNewProduct,
              isCompostable: item.isCompostable,
              id: item.id ? item.id : item.productCode,
              onClickProduct: () => {
                searchSuggestClick(item.title);
              },
            }}
          ></NavigationProductTile>
        ) : null
      );
    } else {
      return null;
    }
  };
  const outputSuggestionPopularArticles = () => {
    //get pop articles and normalize them to be like the other returned articles
    const _popularArticles = popularArticles.map((itemRef, index) => {
      let itemDoc = page.getContent(itemRef);
      let item = itemDoc.getData();

      return {
        contentType: "article",
        title: item.articleDetails.displayName,
        description: item.articleDetails.description.value,
        publishedDate: item.articleDetails.publishedDate,
        promoImage: item.promoImage ? item.promoImage : null,
        promoImageAltText: item.promoImageAltText ? item.promoImageAltText : "",
        urlLink:
          item.articleDetails.link && item.articleDetails.link ? item.articleDetails.link : null,
        url: null,
        categories: item.articleDetails.categories ? item.articleDetails.categories : null,
      };
    });
    //decide if use returned or popular results
    const suggestionsArticles =
      typeAheadSearchResults &&
      typeAheadSearchResults.articles &&
      typeAheadSearchResults.articles.length > 0
        ? typeAheadSearchResults.articles
        : _popularArticles;

    if (suggestionsArticles) {
      const width = Number(searchSuggestionsArticleWidth);
      const height = Number(searchSuggestionsArticleHeight);

      return (
        <div className="navigation-search__articles">
          {suggestionsArticles.slice(0, MAXSUGGESTEDARTICLES).map((item, index) => (
            <div key={`suggestion-articles__${index}`} className="navigation-search__article">
              {item.promoImage && (
                <Image
                  className="navigation-search__article-image"
                  src={item.promoImage}
                  alt={`${item.promoImageAltText ? item.promoImageAltText : item.title}`}
                  width={width}
                  height={height}
                  loading="eager"
                />
              )}
              <div className="navigation-search__article-info">
                {item.url && (
                  <Link
                    to={item.url}
                    className="navigation-search__article-title"
                    onClick={() => {
                      setSearchOpen(false);
                    }}
                  >
                    {item.title.length <= MAXTITLELENGTH
                      ? item.title
                      : item.title.substring(0, MAXTITLELENGTH) + "..."}
                  </Link>
                )}
                {item.urlLink && (
                  <CtaLink
                    link={item.urlLink}
                    className="navigation-search__article-title"
                    onClick={() => {
                      setSearchOpen(false);
                    }}
                  ></CtaLink>
                )}
                <Description
                  className="navigation-search__article-description"
                  value={
                    item.description.length <= MAXDESCRIPTIONLENGTH
                      ? item.description
                      : item.description.substring(0, MAXDESCRIPTIONLENGTH) + "..."
                  }
                />
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return null;
    }
  };
  document.addEventListener("keydown", (event) => {
    if (event.key === "Escape") {
      setSearchOpen(false);
    }
  });
  return (
    <div className={`navigation-search`}>
      <div className="navigation-search__wrapper">
        <div className="navigation-search__input-wrapper">
          <div className="navigation-search__input-wrapper-inner">
            <span className="navigation-search__search-icon darticon-search"></span>
            <input
              className="navigation-search__input"
              placeholder={searchFieldPlaceholder ? searchFieldPlaceholder : ""}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyUp={handleSearchEnter}
              onKeyDown={handleInputBlur}
            />
            <button
              className="navigation-search__close darticon-close"
              type="button"
              title={clearSearchLabelText}
              aria-label={clearSearchLabelText}
              onClick={() => {
                setSearchOpen(false);
              }}
            ></button>
          </div>
        </div>
        <div className="navigation-search__all-suggestions-wrapper">
          <div className="navigation-search__suggestions-wrapper">
            <h3 className="navigation-search__header">
              {typeAheadSearchResults &&
              typeAheadSearchResults.terms &&
              typeAheadSearchResults.terms.length > 0
                ? searchSuggestionsTitle
                : popularSearchTermsTitle}
            </h3>
            {outputSuggestionPopularSearches()}
          </div>
          <div className="navigation-search__suggested-products-wrapper">
            <h3 className="navigation-search__header">
              {typeAheadSearchResults &&
              typeAheadSearchResults.products &&
              typeAheadSearchResults.products.length > 0
                ? searchSuggestedProductsTitle
                : popularProductsTitle}
            </h3>
            <div className="navigation-search__product-tiles">
              {outputSuggestionPopularProducts()}
            </div>
          </div>
          <div className="navigation-search__suggested-articles-wrapper">
            <h3 className="navigation-search__header">
              {typeAheadSearchResults &&
              typeAheadSearchResults.articles &&
              typeAheadSearchResults.articles.length > 0
                ? searchSuggestedArticlesTitle
                : popularArticlesTitle}
            </h3>
            {outputSuggestionPopularArticles()}
            <div
              className="tabreset"
              tabIndex={0}
              onFocus={(e) => {
                e.preventDefault();
                document.querySelectorAll(".navigation-search__input")[0].focus();
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavigationSearch;
