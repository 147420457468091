import React from "react";
import { BrPageContext } from "@bloomreach/react-sdk";
import "./HeaderGeneralFlyout.scss";
import CtaLink from "../Global/CtaLink";
import { IsMobileNav } from "../../media-queries/MediaQueries";

function HeaderGeneralFlyout({ flyoutHeaderLabel, flyoutRef, closeActiveSubNav, setMenuOpen }) {
  const isMobileNav = IsMobileNav();
  const page = React.useContext(BrPageContext);
  const flyoutRefDocument = page.getContent(flyoutRef);
  const {
    id = null,
    columnOneHeader = null,
    columnOneLinks = null,
    columnTwoHeader = null,
    columnTwoLinks = null,
    columnThreeHeader = null,
    columnThreeLinks = null,
    columnFourHeader = null,
    columnFourLinks = null,
  } = flyoutRefDocument.getData();

  // Handles Link Click
  // If internal link, close hamburger menu and reset to primary nav list
  const handleLinkClick = (item) => {
    if (item.internalLink) {
      if(setMenuOpen){
        setMenuOpen(false);
      }
      closeActiveSubNav("");
    }
  };

  return (
    <div className={`general-flyout`}>
      <div className="general-flyout__header-wrapper">
        <div className="general-flyout__header-line-wrapper">
          <button className="general-flyout__back-btn" onClick={() => closeActiveSubNav("")}>
            <span className="general-flyout__back-btn-icon darticon-arrow-back"></span>
          </button>
          <span className="general-flyout__header">{flyoutHeaderLabel}</span>
        </div>
      </div>
      <div className="general-flyout__columns">
        {columnOneHeader && columnOneLinks && (
          <div className="general-flyout__column">
            {columnOneHeader && (
              <h3 className="general-flyout__column-header">{columnOneHeader}</h3>
            )}
            {columnOneLinks && (
              <ul className="general-flyout__column-list">
                {columnOneLinks.map((item, index) => (
                  <li className="general-flyout__column-list-item" key={`${index}_${id}`}>
                    <CtaLink
                      link={item}
                      className={`general-flyout__column-list-item-link`}
                      onClick={() => handleLinkClick(item)}
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
        {columnTwoHeader && columnTwoLinks && (
          <div className="general-flyout__column">
            {columnTwoHeader && (
              <h3 className="general-flyout__column-header">{columnTwoHeader}</h3>
            )}
            {columnTwoLinks && (
              <ul className="general-flyout__column-list">
                {columnTwoLinks.map((item, index) => (
                  <li className="general-flyout__column-list-item" key={`${index}_${id}`}>
                    <CtaLink
                      link={item}
                      className={`general-flyout__column-list-item-link`}
                      onClick={() => handleLinkClick(item)}
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
        {columnThreeHeader && columnThreeLinks && (
          <div className="general-flyout__column">
            {columnThreeHeader && (
              <h3 className="general-flyout__column-header">{columnThreeHeader}</h3>
            )}
            {columnThreeLinks && (
              <ul className="general-flyout__column-list">
                {columnThreeLinks.map((item, index) => (
                  <li className="general-flyout__column-list-item" key={`${index}_${id}`}>
                    <CtaLink
                      link={item}
                      className={`general-flyout__column-list-item-link`}
                      onClick={() => handleLinkClick(item)}
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
        {columnFourHeader && columnFourLinks && (
          <div className="general-flyout__column">
            {columnFourHeader && (
              <h3 className="general-flyout__column-header">{columnFourHeader}</h3>
            )}
            {columnFourLinks && (
              <ul className="general-flyout__column-list">
                {columnFourLinks.map((item, index) => (
                  <li className="general-flyout__column-list-item" key={`${index}_${id}`}>
                    <CtaLink
                      link={item}
                      className={`general-flyout__column-list-item-link`}
                      onClick={() => handleLinkClick(item)}
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default HeaderGeneralFlyout;
