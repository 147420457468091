import { useSearchToolbarContext } from "./hooks";
import "./ActiveFilterPills.scss";
import { Mobile, Desktop } from "../../../media-queries/MediaQueries";

function ActiveFilterPills() {
  const {
    filters: { facets, removeFacet, removeAllFacets },
    urlFilters: { filters, removeFilter },
    showUrlFilters,
    clearURLFiltersOnRemoveAllFacets
  } = useSearchToolbarContext();

  const { clearAllLabel = "" } = window.globalProperties || {};

  if (facets.length < 1 && filters.length < 1) return null;

  const hasFacets = facets && facets.length
  const target = {
    filters: hasFacets ? facets : showUrlFilters ? filters : [],
    removeFunction: hasFacets ? removeFacet : removeFilter
  };

  return (
    <div className="filter-pills__wrapper">
      <ul className="filter-pills">
        {target.filters.map((facet) => {
          return (
            <li key={facet.id} className="filter-pills__item">
              <button
                className="filter-pills__item__button bold-semi-bold darticon-close"
                value={facet.id}
                onClick={target.removeFunction.bind(this, facet.id)}
                title="remove filter"
              >
                <Mobile>
                  {facet.mobileName ? facet.mobileName : facet.name}
                </Mobile>
                <Desktop>
                  {facet.desktopName ? facet.desktopName : facet.name}
                </Desktop>
              </button>
            </li>
          );
        })}
      </ul>
      {facets && facets.length > 0 &&
        (<button
            className="btn btn--link filter-pills__clear-button bold-extra-bold"
            onClick={removeAllFacets.bind(this, clearURLFiltersOnRemoveAllFacets)}
          >
            {clearAllLabel}
          </button>)}
    </div>
  );
}

export default ActiveFilterPills;
