import RelatedDocumentList from "./RelatedDocumentList";
import { PRIMARY, GLOBAL_TAXONOMY_MAP } from "../Global/GlobalConstants";
import "./RelatedDocuments.scss";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";

function RelatedDocuments({ component, page }) {
  try {
    const { document: documentRef } = component.getModels();
    const document = page.getContent(documentRef);

    if (!document) return null;

    const { componentStyle = PRIMARY } = component.getParameters();
    const { title = null, listLinks = null } = document.getData();

    const taxonomyComponent = page.getComponent(GLOBAL_TAXONOMY_MAP);
    const { taxonomy: taxonomies = {} } = taxonomyComponent.getModels();

    const getRelatedDocumentData = (link) => {
      const { internalLink: relatedDocRef, newWindow, label } = link;
      if (!relatedDocRef) return null;

      const document = page.getContent(relatedDocRef);
      if (!document) return null;

      const {
        categories: { 0: firstCategory = "" } = [],
        title = null,
        image = null,
        previewLink = null,
      } = document.getData();

      return {
        image,
        category: taxonomies[firstCategory] || "",
        title,
        link: { externalLink: previewLink, newWindow, label },
      };
    };

    const documents = listLinks
      .map((link) => getRelatedDocumentData(link))
      .filter((document) => document);

    return <RelatedDocumentList theme={componentStyle} title={title} documents={documents} />;
  } catch (e) {
    console.error("RelatedDocuments error", e);
    return <ErrorComponent error={e} componentName={"RelatedDocuments"} />;
  }
}

export default RelatedDocuments;
