import { useState } from "react";
import { useMessages } from "./hooks";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import "./SiteMessages.scss";
import Message from "./Message";

// Hook into MessageContext to retrieve function to update messages in state
// when a user clicks button to hide the message
function MessageWrapper({ alert = {} }) {
  const { hideMessage } = useMessages();

  const [isClosed, setIsClosed] = useState(false);

  const closeMessage = () => {
    setIsClosed(true);
    hideMessage(alert);
  };

  return (
    <Message
      message={alert.message}
      alertLevel={alert.level}
      canExpire={true}
      expDate={alert.expirationDate}
      canClose={true}
      hidden={isClosed}
      handleClose={closeMessage}
    />
  );
}

// Hook into MessageContext to retrieve designated messages to render
function SiteMessages() {
  const { messages = [] } = useMessages();

  try {
    return (
      <div className="site-messages">
        {messages.length > 0 &&
          messages.map((message) => {
            return <MessageWrapper key={message.id} alert={message} />;
          })}
      </div>
    );
  } catch (e) {
    console.error("SiteMessages error", e);
    return <ErrorComponent error={e} componentName={"SiteMessages"} />;
  }
}

export default SiteMessages;
