import { useContext, useEffect } from "react";
import { BrPageContext } from "@bloomreach/react-sdk";
import { SEVERITY, ALERT } from "./constants";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import "./SiteMessages.scss";
import sanitizeHtml from 'sanitize-html'

function Message({
  message = "",
  alertLevel = "",
  canClose = true,
  canExpire = true,
  expDate = null,
  hidden = false,
  handleClose,
}) {
  const page = useContext(BrPageContext);

  const currDate = Date.now(); // Getting date of present day (milliseconds)

  useEffect(() => {
    let expiration = new Date(expDate).getTime();
    if (canExpire && expDate && currDate >= expiration) {
      // If current date is greater than equal to exp date
      // Set isClosed to true, don't display
      handleClose();
    }
  });
  try {
    return (
      <div
        className={`site-messages__message site-messages__message--${
          SEVERITY[alertLevel].class ?? ""
        } ${hidden ? "site-site-messages__message--hidden" : ""}`}
      >
        <div className="site-messages__message__inner">
          {alertLevel === ALERT && (
            <span className="darticon-alert site-messages__message__icon"></span>
          )}
          <div className="site-messages__message__inner-content">
            <div
              className="site-messages__message__text"
              dangerouslySetInnerHTML={{
                __html: page?.rewriteLinks(sanitizeHtml(message?.value)),
              }}
            />
            {canClose && (
              <button
                className="site-messages__message__button"
                onClick={handleClose}
                aria-label="close"
              >
                <span className="darticon-close"></span>
              </button>
            )}
          </div>
        </div>
      </div>
    );
  } catch (e) {
    console.error("Message error", e);
    return <ErrorComponent error={e} componentName={"Message"} />;
  }
}

export default Message;
