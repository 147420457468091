import React from "react";
import { BrPageContext } from "@bloomreach/react-sdk";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";

function CategoryPage() {
  const page = React.useContext(BrPageContext);

  try {
    return page?.isPreview() ? <h2>Category Page Indicator</h2> : null;

  } catch (e) {
    console.error("CategoryPage error", e);
    return <ErrorComponent error={e} componentName={"CategoryPage"} />;
  }
}

export default CategoryPage;
