import React from "react";
import { Desktop, Mobile } from "../../../media-queries/MediaQueries";
import DesktopSearchRefinement from "./DesktopSearchRefinement";
import MobileSearchRefinement from "./MobileSearchRefinement";
import { BrPageContext, BrComponentContext } from "@bloomreach/react-sdk";
import { useSearchRefinementContext } from "./hook";
import ErrorComponent from "../../Common/ErrorComponent/ErrorComponent";

function SearchRefinementsController() {
  const {
    refinements: { addRefinement, options, count },
    searchTerm,
    labels,
  } = useSearchRefinementContext();

  const page = React.useContext(BrPageContext);
  const component = React.useContext(BrComponentContext);

  try {
    const { document: documentRef } = component.getModels();
    const document = page.getContent(documentRef);

    if (!document) return null;

    return (
      <div className="search-refinements-controller">
        <div className="search-refinements-controller__wrapper">
          {options && options.length > 0 && (
            <>
              <Desktop>
                <DesktopSearchRefinement
                  addRefinement={addRefinement}
                  refinements={options}
                  labels={labels}
                />
              </Desktop>

              <Mobile>
                <MobileSearchRefinement
                  addRefinement={addRefinement}
                  count={count}
                  refinements={options}
                  searchTerm={searchTerm}
                  labels={labels}
                />
              </Mobile>
            </>
          )}
        </div>
      </div>
    );
  } catch (e) {
    console.error("SearchRefinementsController error", e);
    return <ErrorComponent error={e} componentName={"SearchRefinementsController"} />;
  }
}

export default SearchRefinementsController;
