import { useContext } from "react";
import { SearchRefinementContext } from "./context";

export const useSearchRefinementContext = () => {
  const context = useContext(SearchRefinementContext);
  if (!context) {
    throw new Error(
      "useSearchRefinementContext must be used within a SearchRefinementContext.provider"
    );
  }

  return context;
};
