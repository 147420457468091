function LoadingSpinner({ showAlert, alertMessage, ...props }) {
  return (
    <>
    { showAlert && (
      <span role="alert" style={{opacity:0}}>{alertMessage}</span>
    ) }
    <div className="lds-default" {...props}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    </>
  );
}

export default LoadingSpinner;
