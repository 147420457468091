import { useDesktopMediaQuery, Desktop, XsToMobile, XsMobile } from "../../media-queries/MediaQueries";
import { PRIMARY, TERTIARY, TEXT_ALIGNMENT, HEADING_LEVELS } from "../Global/GlobalConstants";
import CtaLink from "../Global/CtaLink";
import Image from "../Global/Image/Image";
import { isAuthored } from "../../helpers/LinkHelper";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import { getDesktopBackgroundImageStyles } from "../Global/Image/helpers";
import "./HeroBanner.scss";
import Title from "../Global/Title/Title";
import sanitizeHtml from 'sanitize-html'

function HeroBanner({ component, page }) {
  const isDesktop = useDesktopMediaQuery();
  const xsMobileMaxH = 220;
  const xsMobileMaxW = 375;
  const mobileMaxH = 312;
  const mobileMaxW = 500;

  try {
    const {
      componentStyle = PRIMARY,
      textPosition = TEXT_ALIGNMENT.LEFT,
      desktopImageWidth = 0,
      desktopImageHeight = 0,
      mobileImageWidth = 0,
      mobileImageHeight = 0,
      headerLevel,
      hasTextAreaBackground,
    } = component.getParameters();

    const { document: documentRef } = component.getModels();
    const document = page.getContent(documentRef);

    if (!document) return null;

    const {
      primaryTitle = null,
      description = null,
      link = null,
      image = "",
      imageAltText = "",
      mobileImage = "",
      mobileImageAltText = "",
    } = document.getData();

    const desktopImageStyles = getDesktopBackgroundImageStyles(
      image,
      desktopImageWidth,
      desktopImageHeight,
      isDesktop
    );

    const showCta = isAuthored(link);

    let imgClass = "normal";
    if (mobileImageHeight > 0 || mobileImageWidth > 0) {
      imgClass = "custom";
    }

    return (
      <div
        className={`hero-banner theme-${componentStyle} hero-banner--text-${textPosition} ${
          componentStyle !== PRIMARY ? "hero-banner--" + componentStyle : ""
        } ${page.isPreview() ? "has-edit-button" : ""}`}
      >
        <div
          className={`banner__outer ${isDesktop ? "hero-banner__outer--with-image" : ""}`}
          style={desktopImageStyles}
        >
          <div className="hero-banner__inner">
            {isDesktop && image && <span role="img" aria-label={imageAltText} />}
            <div className={`hero-banner__left hero-banner__left--bg-${hasTextAreaBackground}`}>
              <div className="hero-banner__text-wrapper">
                {primaryTitle && (
                  <div className="hero-banner__heading-wrapper">
                    <Title
                      defaultLevel={
                        componentStyle === PRIMARY ? HEADING_LEVELS.ONE : HEADING_LEVELS.TWO
                      }
                      headingLevel={headerLevel}
                      className={`hero-banner__heading`}
                    >
                      {primaryTitle}
                    </Title>
                  </div>
                )}
                {description.value && (
                  <div
                    className="hero-banner__description"
                    dangerouslySetInnerHTML={{
                      __html: page?.rewriteLinks(sanitizeHtml(description.value)),
                    }}
                  />
                )}
                {showCta && (
                  <CtaLink
                    link={link}
                    className={`btn hero-banner__cta ${
                      componentStyle === TERTIARY ? "btn--" + componentStyle : ""
                    }`}
                  />
                )}
              </div>
            </div>
          </div>
          {mobileImage && (
            <>
              <XsToMobile>
                <div className="hero-banner__right">
                  <Image
                    src={mobileImage}
                    className={`hero-banner__image hero-banner__image-${imgClass}`}
                    alt={mobileImageAltText || imageAltText}
                    width={mobileImageWidth === 0 ? mobileMaxW : mobileImageWidth}
                    height={mobileImageHeight === 0 ? mobileMaxH : mobileImageHeight}
                    role="presentation"
                  />
                </div>
              </XsToMobile>
              <XsMobile>
                <div className="hero-banner__right">
                  <Image
                    src={mobileImage}
                    className={`hero-banner__image hero-banner__image-${imgClass}`}
                    alt={mobileImageAltText || imageAltText}
                    width={mobileImageWidth === 0 ? xsMobileMaxW : mobileImageWidth}
                    height={mobileImageHeight === 0 ? xsMobileMaxH : mobileImageHeight}
                    role="presentation"
                  />
                </div>
              </XsMobile>
            </>
          )}
        </div>
      </div>
    );
  } catch (e) {
    console.error("HeroBanner error", e);
    return <ErrorComponent error={e} componentName={"HeroBanner"} />;
  }
}

export default HeroBanner;
