import { useState } from "react";
import { BrManageContentButton } from "@bloomreach/react-sdk";
import CtaLink from "../../Global/CtaLink";
import { Desktop, XsToMobile, XsMobile } from "../../../media-queries/MediaQueries";
import Modal from "../Modal/Modal";
import VideoIFrame from "../VideoIFrame/VideoIFrame";
import Title from "../../Global/Title/Title";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
import { isAuthored } from "../../../helpers/LinkHelper";
import { COMPONENT_THEME, HEADING_LEVELS } from "../../Global/GlobalConstants";
import "./FiftyFiftyTextImage.scss";
import Image from "../../Global/Image/Image";
import sanitizeHtml from 'sanitize-html'

function FiftyFiftyTextImage({ component, page }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const xsMobileMaxH = 294;
  const xsMobileMaxW = 440;
  const mobileMaxH = 400;
  const mobileMaxW = 600;

  const WordsToShow= 15;
  const [showFullDescription, setShowFullDescription] = useState(false);

  try {
    const { document: documentRef } = component.getModels();
    const document = page.getContent(documentRef);

    if (!document) return null;

    const { contentStyle, componentStyle, headerLevel, imageWidth, imageHeight, showReadMore } =
      component.getParameters();
    const contentStyleImageRight = "imageRight";
    const theme = COMPONENT_THEME.find((e) => e.ComponentStyle === componentStyle);

    const {
      primaryTitle = null,
      description = null,
      link = null,
      image = null,
      imageAltText = null,
      mobileImage = null,
      mobileImageAltText = null,
      secondImage = null,
      secondImageAltText = null,
      mobileSecondImage = null,
      mobileSecondImageAltText = null,
      videoLink = null,
      videoPlayButtonAriaLabel = null,
      videoTitle = null,
    } = document.getData();
    let videoAriaLabel = videoPlayButtonAriaLabel;

    if(videoLink && !videoAriaLabel){
      videoAriaLabel = window.globalProperties["playButtonAriaLabel"] !== undefined ? window.globalProperties["playButtonAriaLabel"] : "Play Button Aria Label Default"
    }


    function toggleModal() {
      setModalOpen(!modalOpen);
      setIsVideo(!isVideo);
    }
    const videoUrl = isVideo === true ? videoLink : "";
    const showCta = isAuthored(link);

    const outputDescription = () => {
      if (showFullDescription || description.value.split(" ").length < WordsToShow || !showReadMore) {
        return description.value;
      } else {
        return `${description.value.split(" ").slice(0, WordsToShow).join(" ")}...`;
      }
    };

    return (
      <div
        className={`${
          page.isPreview() ? "has-edit-button" : ""
        } fifty-fifty fifty-fifty--${componentStyle}`}
      >
        <BrManageContentButton content={document} />

        <div
          className={`fifty-fifty__wrapper${
            contentStyle === contentStyleImageRight ? " fifty-fifty--image-right" : ""
          }${secondImage !== null ? " fifty-fifty--twoImages":""}`}
        >
          <div className="fifty-fifty__content">
            {image && (
              <Desktop>
                <Image
                  src={image}
                  alt={imageAltText}
                  className="fifty-fifty__image"
                  width={imageWidth}
                  height={imageHeight}
                />
              </Desktop>
            )}
            {mobileImage && (
              <>
                <XsToMobile>
                  <Image
                    src={mobileImage}
                    alt={!mobileImageAltText ? imageAltText : mobileImageAltText}
                    className="fifty-fifty__image"
                    height={imageHeight === 0 ? mobileMaxH : imageHeight}
                    width={imageWidth === 0 ? mobileMaxW : imageWidth}
                  />
                </XsToMobile>
                <XsMobile>
                  <Image
                    src={mobileImage}
                    alt={!mobileImageAltText ? imageAltText : mobileImageAltText}
                    className="fifty-fifty__image"
                    height={imageHeight === 0 ? xsMobileMaxH : imageHeight}
                    width={imageWidth === 0 ? xsMobileMaxW : imageWidth}
                  />
                </XsMobile>
              </>
            )}
            {videoLink && (
              <button
                aria-label={videoAriaLabel}
                onClick={toggleModal}
                className="fifty-fifty__video-btn btn btn--play-video darticon-play" 
              ></button>
            )}
          </div>
          <div className="fifty-fifty__content">
            {primaryTitle && (
              <Title
                defaultLevel={HEADING_LEVELS.THREE}
                headingLevel={headerLevel}
                className={`fifty-fifty__heading fifty-fifty__heading--${componentStyle}`}
              >
                {primaryTitle}
              </Title>
            )}
            {description?.value && (
              <div className="fifty-fifty__description-wrapper">
                <div
                  className={`fifty-fifty__description fifty-fifty__description--${componentStyle}`}
                  dangerouslySetInnerHTML={{
                    __html: page?.rewriteLinks(sanitizeHtml(outputDescription())) ?? "",
                  }}
                />
                 {description.value.split(" ").length >= WordsToShow && showReadMore && (
                  <button
                    className={`fifty-fifty__description-toggle fifty-fifty__description-toggle--${componentStyle}`}
                    onClick={() => setShowFullDescription(!showFullDescription)}
                  >
                    <span className="fifty-fifty__description-toggle-text">
                      {showFullDescription
                        ? window.globalProperties["readLessLabel"]
                        : window.globalProperties["readMoreLabel"]}
                    </span>
                    {showFullDescription ? (
                      <span className="fifty-fifty__description-toggle-icon darticon-arrow-up"></span>
                    ) : (
                      <span className="fifty-fifty__description-toggle-icon darticon-arrow-down"></span>
                    )}
                  </button>  
                )}    
              </div>
            )}
            {showCta && <CtaLink link={link} className={`fifty-fifty__cta ${theme.CtaClasses}`} />}            
            {secondImage && (
              <Desktop>
                <Image
                  src={secondImage}
                  alt={secondImageAltText}
                  className="fifty-fifty__image fifty-fifty__secondImage"
                  width={imageWidth}
                  height={imageHeight}
                />
              </Desktop>
            )}
            {mobileSecondImage && (
              <>
                <XsToMobile>
                  <Image
                    src={mobileSecondImage}
                    alt={!mobileSecondImageAltText ? secondImageAltText : mobileSecondImageAltText}
                    className="fifty-fifty__image fifty-fifty__secondImage"
                    height={imageHeight === 0 ? mobileMaxH : imageHeight}
                    width={imageWidth === 0 ? mobileMaxW : imageWidth}
                  />
                </XsToMobile>
                <XsMobile>
                  <Image
                    src={mobileSecondImage}
                    alt={!mobileSecondImageAltText ? secondImageAltText : mobileSecondImageAltText}
                    className="fifty-fifty__image fifty-fifty__secondImage"
                    height={imageHeight === 0 ? xsMobileMaxH : imageHeight}
                    width={imageWidth === 0 ? xsMobileMaxW : imageWidth}
                  />
                </XsMobile>
              </>
            )}
          </div>
        </div>
        {videoUrl && (
          <Modal show={modalOpen} handleClose={toggleModal}>
            <VideoIFrame video={videoUrl} title={videoTitle} />
          </Modal>
        )}
      </div>
    );
  } catch (e) {
    console.error("FiftyFiftyTextImage error", e);
    return <ErrorComponent error={e} componentName={"FiftyFiftyTextImage"} />;
  }
}

export default FiftyFiftyTextImage;
