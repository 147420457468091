import React from "react";
import { BrPageContext, BrComponentContext, BrManageContentButton } from "@bloomreach/react-sdk";
import "./SearchBanner.scss";

import { COMPONENT_THEME, HEADING_LEVELS } from "../Global/GlobalConstants";
import SearchTextbox from "../Global/SearchTextbox";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import Title from "../Global/Title/Title";
import sanitizeHtml from 'sanitize-html'

function SearchBanner() {
  const page = React.useContext(BrPageContext);
  const component = React.useContext(BrComponentContext);

  try {
    const { document: documentRef } = component.getModels();
    const document = page.getContent(documentRef);

    if (!document) return null;

    const { componentStyle } = component.getParameters();
    const theme = COMPONENT_THEME.find((e) => e.ComponentStyle === componentStyle);

    const {
      title = null,
      description = null,
      formSubmitUrl = null,
      formSubmitQueryParameter = null,
      searchPlaceholder = null,
    } = document.getData();

    return (
      <div
        className={`${page.isPreview() ? "has-edit-button" : ""} search-banner theme-${
          theme.ComponentStyle
        } `}
      >
        <BrManageContentButton content={document} />
        <div className="search-banner__wrapper">
          <div className="search-banner__left">
            {title && (
              <Title defaultLevel={HEADING_LEVELS.ONE} className="search-banner__header">
                {title}
              </Title>
            )}
            {description?.value && (
              <div
                className="search-banner__description-wrapper"
                dangerouslySetInnerHTML={{
                  __html: page?.rewriteLinks(sanitizeHtml(description.value)) ?? "",
                }}
              />
            )}
          </div>
          <div className="search-banner__right">
            <SearchTextbox
              submitUrl={formSubmitUrl}
              queryParameter={formSubmitQueryParameter}
              placeholder={searchPlaceholder}
              theme={componentStyle}
            />
          </div>
        </div>
      </div>
    );
  } catch (e) {
    console.error("SearchBanner error", e);
    return <ErrorComponent error={e} componentName={"SearchBanner"} />;
  }
}

export default SearchBanner;
