import React from "react";
import { BrPageContext } from "@bloomreach/react-sdk";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { getPageTitle } from "../../../../helpers/GlobalHelpers";
import AddProduct from "../../../Global/AddProduct/AddProduct";
import "./ProductTile.scss";
import { SIZE } from "../../../Global/AddProduct/constants";
import { Link } from "react-router-dom";
import ErrorComponent from "../../ErrorComponent/ErrorComponent";
import Image from "../../../Global/Image/Image";
import { getUrlPath } from "../../../../helpers/LinkHelper";

function ProductTile({
  tile,
  product,
  componentStyle,
  addToListAnalytics,
  removeFromListAnalytics,
}) {
  const sendDataToGTM = useGTMDispatch();
  const page = React.useContext(BrPageContext);

  if (!tile && !product) return null;
  try {
    const { catalogImage, childProductName, url, productCode, height, width } = product;

    const handleTileClick = () => {
      sendDataToGTM({
        event: "tileClick",
        tileTitle: `${childProductName}`,
        label: `${getPageTitle(page)}`,
      });
    };

    return (
      <Link
        to={getUrlPath(url)}
        className={`product-tile product-tile__cta product-tile--${componentStyle}`}
        onClick={() => handleTileClick()}
      >
        <AddProduct
          productCode={productCode}
          productName={childProductName}
          btnSize={SIZE.LARGE}
          addToListAnalytics={addToListAnalytics}
          removeFromListAnalytics={removeFromListAnalytics}
        />
        {catalogImage && (
          <div className="product-tile__image-wrapper">
            <Image
              src={catalogImage}
              className="product-tile__image"
              alt={childProductName}
              height={height}
              width={width}
            />
          </div>
        )}
        {childProductName && (
          <p className="product-tile__header product-tile--padding-side">{childProductName}</p>
        )}
      </Link>
    );
  } catch (e) {
    console.error("app error", e);
    return <ErrorComponent error={e} componentName={"Product Tile"} />;
  }
}

export default ProductTile;
