import { useRef, useState, useEffect } from "react";
import { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import AddProduct from "../Global/AddProduct/AddProduct";
import CtaLink from "../Global/CtaLink";
import LinkWrapper from "../Global/LinkWrapper";
import {
  DesktopNav as Desktop,
  MobileNav as Mobile,
  IsDesktopNav,
} from "../../media-queries/MediaQueries";
import { isAuthored, getUrlPath } from "../../helpers/LinkHelper";
import { SIZE } from "../Global/AddProduct/constants";
import { COMPATIBLEPRODUCTSTABID, HEADING_LEVELS } from "../Global/GlobalConstants";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import { Link } from "react-router-dom";
import "./CompatibleProducts.scss";
import { getProductsByCodes } from "../../helpers/GlobalHelpers";
import Image from "../Global/Image/Image";

import "swiper/swiper.scss";
import "swiper/modules/a11y/a11y.scss";
import "swiper/modules/navigation/navigation.scss";
import Title from "../Global/Title/Title";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import LoadingSpinner from "../Global/LoadingSpinner/LoadingSpinner";

const MAXPRODUCTS = 6;
const MOBILEPRODUCTSLIDES = 1;
const TABLETPRODUCTSLIDES = 2;
const DESKTOPPRODUCTSLIDES = 3;

function ProductTile({ product, id }) {
  const { catalogImage, childProductName, productCode, url } = product;
  const sendDataToGTM = useGTMDispatch();
  const isDesktop = IsDesktopNav();
  const viewDetailsForProduct = ((window.globalProperties && window.globalProperties['viewDetailsForProduct']) || 'View Details for') + ' ' +  productCode;

  const handleProductClick = () => {
    sendDataToGTM({
      event: "carouselClick",
      productName: childProductName,
      listType: "Compatible",
    });
  };

   return (
    (catalogImage || childProductName) && (
      <div className="compatible-products__carousel__card">
        <Link
          className="compatible-products__carousel__card__link"
          onClick={handleProductClick}
          to={getUrlPath(url)}
          id={id}
        >
          <div className="compatible-products__carousel__card__image-wrapper">
              <Image
                className="compatible-products__carousel__card__image"
                src={catalogImage}
                loading="lazy"
                alt={viewDetailsForProduct}
              />
              {childProductName && (
                <p className="compatible-products__carousel__card__name bold-extra-bold">
                  {childProductName}
                </p>
              )}
          </div>

        </Link>
        <AddProduct
          productCode={productCode}
          productName={childProductName}
          btnSize={isDesktop ? SIZE.LARGE : SIZE.MEDIUM}
          addToListAnalytics={{
            event: "listAdd",
            listType: "Compatible",
            productName: `${childProductName}`,
          }}
          removeFromListAnalytics={{
            event: "removeFromList",
            listType: "Compatible",
            productName: `${childProductName}`,
          }}
        />
      </div>
    )
  );
}

function SwiperCarousel({ limit = MAXPRODUCTS, productsList }) {
  if (productsList.length > limit) {
    productsList = productsList.slice(0, limit);
  }

  const [carouselHeight, setCarouselHeight] = useState("auto");
  const carouselContainerRef = useRef(null);

  const checkCardHeights = () => {
    if (!carouselContainerRef.current) return;

    const cards = carouselContainerRef.current.getElementsByClassName(
      "compatible-products__carousel__card"
    );
    //set to auto first then get tallest
    setCarouselHeight("auto");
    const tallestCardHeight = [...cards].reduce((acc, card) => {
      // Use scroll height in case the parent container is hiding the card overflow
      // due to it's explicit height
      const height = card.scrollHeight > card.offsetHeight ? card.scrollHeight : card.offsetHeight;

      if (height > acc) {
        acc = height;
      }

      return acc;
    }, 0);

    setCarouselHeight(tallestCardHeight);
  };

  return (
    <div
      className={`compatible-products__carousel ${productsList.length > 1 ? "" : "hide-arrows"}`}
      //style={{ height: carouselHeight }}
      ref={carouselContainerRef}
    >
      <Swiper
        navigation
        modules={[Navigation, A11y]}
        slidesPerView={MOBILEPRODUCTSLIDES}
        allowSlideNext={productsList.length > MOBILEPRODUCTSLIDES ? true : false}
        allowSlidePrev={productsList.length > MOBILEPRODUCTSLIDES ? true : false}
        loop={productsList.length > MOBILEPRODUCTSLIDES ? true : false}
        spaceBetween={10}
        a11y={{
          prevSlideMessage: (window.globalProperties && window.globalProperties["prevSlideMessage"]) || "Previous slide",
          nextSlideMessage: (window.globalProperties && window.globalProperties["nextSlideMessage"]) || "Next slide"
        }}
        breakpoints={{
          640: {
            slidesPerView: productsList.length > TABLETPRODUCTSLIDES ? TABLETPRODUCTSLIDES : productsList.length,
            allowSlideNext: productsList.length > TABLETPRODUCTSLIDES ? true : false,
            allowSlidePrev: productsList.length > TABLETPRODUCTSLIDES ? true : false,
            loop: productsList.length > TABLETPRODUCTSLIDES ? true : false,
            spaceBetween: 10,
          },
          992: {
            slidesPerView: productsList.length > DESKTOPPRODUCTSLIDES ? DESKTOPPRODUCTSLIDES : productsList.length,
            allowSlideNext: productsList.length > DESKTOPPRODUCTSLIDES ? true : false,
            allowSlidePrev: productsList.length > DESKTOPPRODUCTSLIDES ? true : false,
            loop: productsList.length > DESKTOPPRODUCTSLIDES ? true : false,
            spaceBetween: 10,
          },
          1150: {
            slidesPerView: productsList.length > DESKTOPPRODUCTSLIDES ? DESKTOPPRODUCTSLIDES : productsList.length,
            allowSlideNext: productsList.length > DESKTOPPRODUCTSLIDES ? true : false,
            allowSlidePrev: productsList.length > DESKTOPPRODUCTSLIDES ? true : false,
            loop: productsList.length > DESKTOPPRODUCTSLIDES ? true : false,
            spaceBetween: 40,
          }
        }}
        updateOnImagesReady={true}
        updateOnWindowResize={true}
        onImagesReady={(swiper) => {
          setCarouselHeight(swiper.height);
        }}
        onResize={(swipper) => {
          checkCardHeights();
        }}
      >
        {productsList.map((product, index) => {
          return (
            <SwiperSlide key={product.productCode}>
              <ProductTile
                product={product}
                addToListAnalytics={{
                  event: "listAdd",
                  listType: "Compatible",
                  productName: `${product.childProductName}`,
                }}
                removeFromListAnalytics={{
                  event: "removeFromList",
                  listType: "Compatible",
                  productName: `${product.childProductName}`,
                }}
                id={index == 0 ? `${COMPATIBLEPRODUCTSTABID}_focus` : null}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}

function ProductGrid({ limit, viewMoreLink, productsList, productCode }) {
  const productsListLength = productsList.length;
  if (productsList.length > limit) {
    productsList = productsList.slice(0, limit);
  }

  const showViewMoreLink = isAuthored(viewMoreLink);

  return (
    <div className="compatible-products__product-grid">
      <div className="compatible-products__product-grid__products">
        {productsList.map((product, index) => {
          return (
            <ProductTile
              key={product.productCode}
              product={product}
              addToListAnalytics={{
                event: "listAdd",
                listType: "Compatible",
                productName: `${product.childProductName}`,
              }}
              removeFromListAnalytics={{
                event: "removeFromList",
                listType: "Compatible",
                productName: `${product.childProductName}`,
              }}
              id={index == 0 ? `${COMPATIBLEPRODUCTSTABID}_focus` : null}
            />
          );
        })}
        {showViewMoreLink && productsListLength - limit > 0 && (
          <LinkWrapper
            icon="icon darticon-largearrow"
            link={viewMoreLink}
            queryString={`?filters=compatibleProducts:${productCode}`}
            className="compatible-products__carousel__card compatible-products__product-grid__products__view-more"
          />
        )}
      </div>
    </div>
  );
}

function ProductDetailCompatibleProducts({ component, page }) {
  const { document: documentRef } = component.getModels();
  const document = page.getContent(documentRef);
  const { product } = page.getComponent().getModels();
  const { productServletURL } = page.getComponent("header").getModels();
  const [compatibleProducts, setCompatibleProducts] = useState(null);
  const [loadingCompatible, setLoadingCompatible] = useState(true);

  useEffect(() => {
    getCompatibleProducts();
  }, [page]);

  useEffect(() => {
    if (product?.productData?.compatibleProducts) {
      getCompatibleProducts();
    }
  }, []);

  const getCompatibleProducts = async () => {
    await getProductsByCodes(product.productData.compatibleProducts, productServletURL).then(
      (data) => {
        setCompatibleProducts(data);
        setLoadingCompatible(false);
      }
    );
  };

  try {
    if (!product) return null;
    if (!document) return null;
    if (!product?.productData?.compatibleProducts) return null;

    const {
      title = null,
      maxProductsToShow = MAXPRODUCTS,
      viewAllLink = null,
      viewMoreLink = {},
    } = document.getData();
    const showViewAllLink = isAuthored(viewAllLink);

    let copiedViewMoreLink = Object.assign({}, viewMoreLink);
    if (
      copiedViewMoreLink &&
      copiedViewMoreLink.label &&
      compatibleProducts?.length - maxProductsToShow > 0
    ) {
      copiedViewMoreLink.label = copiedViewMoreLink.label.replace(
        "{}",
        compatibleProducts.length - maxProductsToShow
      );
    }

    return (
      <div className="compatible-products" id={COMPATIBLEPRODUCTSTABID}>
        <div className="compatible-products__inner">
          {title && (
            <div className="compatible-products__title-wrapper">
              <Title defaultLevel={HEADING_LEVELS.TWO} className="compatible-products__title">
                {title}
              </Title>
            </div>
          )}
          {loadingCompatible && <LoadingSpinner className="lds-default lds-blue" />}
          {compatibleProducts && compatibleProducts.length > 0 && (
            <>
              <Mobile>
                <SwiperCarousel limit={maxProductsToShow} productsList={compatibleProducts} />
              </Mobile>

              <Desktop>
                <ProductGrid
                  limit={maxProductsToShow}
                  viewMoreLink={copiedViewMoreLink}
                  productsList={compatibleProducts}
                  productCode={product.productCode}
                />
              </Desktop>
            </>
          )}
        </div>
        {showViewAllLink && (
          <Mobile>
            <div className="compatible-products__cta-wrapper">
              <CtaLink
                className="btn btn--secondary"
                link={viewAllLink}
                queryString={`?filters=compatibleProducts:${product.productCode}`}
              />
            </div>
          </Mobile>
        )}
      </div>
    );
  } catch (e) {
    console.error("ProductDetailCompatibleProducts error", e);
    return <ErrorComponent error={e} componentName={"ProductDetailCompatibleProducts"} />;
  }
}

export default ProductDetailCompatibleProducts;
