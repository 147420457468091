const IMAGE_SRC = "https://uat-rel.salesperson.dartcontainer.com/content/images/basetemplate/logo.png";
// checking if user has access to an image behind a firewall.
// if so, a local storage value is set for google tag manager
const DartTraffic = () => {
    // first check existing value
    const isDartTraffic = localStorage.getItem("isDartTraffic");
    if(isDartTraffic && isDartTraffic === "true") {
        return;
    }

    const onErrorEvent = () => {
        localStorage.setItem("isDartTraffic", false);
    }
    const onSuccessEvent = () => {
        localStorage.setItem("isDartTraffic", true);
    }

    const img = new Image();
    img.onload = onSuccessEvent;
    img.onerror = onErrorEvent;
    img.src = IMAGE_SRC;
}

export default DartTraffic;