import { HEADING_LEVELS } from "../Global/GlobalConstants";
import Title from "../Global/Title/Title";

function RedirectPreview({ valid, parsingResult, url }) {
  const showWarning = !valid || parsingResult === "ERROR";

  return (
    <div className="redirect--preview-mode">
      <div className="redirect--preview-mode__header">
        <Title
          defaultLevel={HEADING_LEVELS.THREE}
          className={`redirect--preview-mode__header__label ${showWarning ? "error-message" : ""}`}
        >
          {showWarning ? "PAGE WILL NOT REDIRECT TO:" : "PAGE REDIRECTS TO:"}
        </Title>{" "}
        <span className="redirect--preview-mode__header__value bold-bold">{url}</span>
      </div>
      {showWarning && (
        <div>
          <p>
            Please make sure the URL provided follows one of the following formats else the page
            will not redirect:
          </p>
          <ul>
            <li>
              Relative Path: <code>/page</code>
            </li>
            <li>
              URL (with either a <code>http</code> or <code>https</code> protocol and a domain
              value): <code>https://google.com</code>, <code>https://www.msn.com/</code>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default RedirectPreview;
