import { useContext } from "react";
import { SearchFilterContext } from "./context";

export const useSearchFilterContext = () => {
    const context = useContext(SearchFilterContext);
    if (!context) {
        throw new Error(
            "useSearchFilterContext must be used within a SearchFilterContext.Provider"
        );
    }

    return context;
};
