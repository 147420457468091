import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import { animateScroll } from "react-scroll";
import { Link } from "react-router-dom";
import "./ContactLink.scss";
import ErrorComponent from "../../Common/ErrorComponent/ErrorComponent";

function ContactLink({StickyButtonsContext}) {
  const isScrolled = useContext(StickyButtonsContext);

  const { contactLinkTitle = "Contact", contactLinkLabel = "Contact", contactLinkPath = "/contact", contactFormHash = "contactForm" } = window.globalProperties || {};

  const contactLink = `${contactLinkPath}#${contactFormHash}`;

  try {
return (
      <div className={`contact-link ${isScrolled ? "contact-link--scrolled" : ""}`}>
        <Link to={contactLink} className="contact-link__link" title={contactLinkTitle} aria-label={contactLinkLabel}>
          <span className="contact-link__link__wrapper">
            <i className="contact-link__link__icon darticon-email" />
            {contactLinkLabel && (
              <span className="contact-link__link__label bold-bold">{contactLinkLabel}</span>
            )}
          </span>
        </Link>
      </div>
    );
  } catch (e) {
    console.error("ContactLink error", e);
    return <ErrorComponent error={e} componentName={"ContactLink"} />;
  }
}

export default ContactLink;
