import { useState, useEffect, useRef, useContext } from "react";
import { BrPageContext, BrComponentContext, BrManageContentButton } from "@bloomreach/react-sdk";
import { Link } from "react-router-dom";
import "./ProductDetailHeader.scss";
import Modal from "../Common/Modal/Modal";
import VideoIFrame from "../Common/VideoIFrame/VideoIFrame";
import AddProductExpandable from "../Global/AddProduct/AddProductExpandable";
import { COMPATIBLEPRODUCTSTABID, HEADING_LEVELS } from "../Global/GlobalConstants";
import HeaderToolbar from "../Common/HeaderToolbar/HeaderToolbar";
import Image from "../Global/Image/Image";
import Title from "../Global/Title/Title";
import { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper.scss";
import "swiper/modules/a11y/a11y.scss";
import "swiper/modules/navigation/navigation.scss";

import { Large, Small, Medium, IsMobileNav } from "../../media-queries/MediaQueries";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import Message from "../SiteMessages/Message";
import { ALERT } from "../SiteMessages/constants";
import { Helmet, HelmetData } from "react-helmet-async";
import { getUrlPath } from "../../helpers/LinkHelper";
import { getProductsByCodes, scrollAndSetFocusOnPDP } from "../../helpers/GlobalHelpers";
import {useAlertScreenReader} from "../Global/ScreenReaderAlert/hooks";
import sanitizeHtml from 'sanitize-html'

const MOBILEMEDIASLIDES = 5;
const TABLETMEDIASLIDES = 7;
const DESKTOPMEDIASLIDES = 7;

function ProductDetailsHeader(props) {
  const WORDSTOSHOW = 15;

  const activeImageRef = useRef();
  const imageWrapperRef = useRef();
  const isFirstRender = useRef(true);

  const [activeMediaItem, setActiveMediaItem] = useState(0);
  const [activeVariationName, setActiveVariationName] = useState(null);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [variations, setVariations] = useState(false);

  const helmetData = new HelmetData({});
  const isMobileNav = IsMobileNav();

  const VIDEO_PREVIEW_IMAGE = "Video Preview Image";
  const VIDEO_LINK = "Product Vid";

  const MINZOOMLEVEL = 1;
  const MAXZOOMLEVEL = 5;
  const PANAMOUNT = .25;
  const PANMARGIN = .9;
  let zoomLevel = MINZOOMLEVEL;
  let currentImageX = 0;
  let currentImageY = 0;
  let dragging = false;
  let imageDragStartX = 0;
  let imageDragStartY = 0;

  const transformImage = () => {
    if (activeImageRef?.current) {
      activeImageRef.current.style.transform = "translate(" + currentImageX + "px ," + currentImageY + "px) scale(" + zoomLevel + ")";
    }
  }

  const imageZoom = () => {
    if (zoomLevel > MAXZOOMLEVEL) {
      zoomLevel = MAXZOOMLEVEL;
    } else if (zoomLevel < MINZOOMLEVEL) {
      zoomLevel = MINZOOMLEVEL;
    }
    transformImage();
  }

  const imagePan = (x, y) => {
    currentImageX = x;
    currentImageY = y;
    transformImage();
  }

  const imagePanDirected = (direction, value) => {
    if (activeImageRef?.current) {
      let img = activeImageRef.current;
      let newX = currentImageX;
      let newY = currentImageY;
      let r = img.getBoundingClientRect();
      if ("x" == (direction)) {
        newX = currentImageX + (r.width * value);
        if (newX < 0 - r.width * zoomLevel * PANMARGIN) {
          newX = 0 - r.width * zoomLevel * PANMARGIN;
        } else if (newX > r.width * zoomLevel * PANMARGIN) {
          newX = r.width * zoomLevel * PANMARGIN;
        }
      } else if ("y" == (direction)) {
        newY = currentImageY + (r.height * value);
        if (newY < 0 - r.height * zoomLevel * PANMARGIN) {
          newY = 0 - r.height * zoomLevel * PANMARGIN;
        } else if (newY > r.height * zoomLevel * PANMARGIN) {
          newY = r.height * zoomLevel * PANMARGIN;
        }
      } 
      imagePan(newX, newY);
    }
  }

  const imageZoomIn = () => {
    zoomLevel++;
    imageZoom();
  }
  const imageZoomOut = () => {
    zoomLevel--;
    imageZoom();
  }
  const imagePanRight = () => {
    imagePanDirected("x", PANAMOUNT);
  }
  const imagePanLeft = () => {
    imagePanDirected("x", -1 * PANAMOUNT);
  }
  const imagePanUp = () => {
    imagePanDirected("y", -1 * PANAMOUNT);
  }
  const imagePanDown = () => {
    imagePanDirected("y", PANAMOUNT);
  }

  const handleKeyDown = (event) => {
    //disable default action for arrow keys
    switch (event.keyCode) {
      case 37:
        //left arrow
      case 39:
        //right arrow
      case 38:
        //up arrow
      case 40:
        //down arrow
        event.preventDefault();
        return false;
      default:
    }
  }

  const handleKeyUp = (event) => {
    switch (event.keyCode) {
      case 37:
        //left arrow
        imagePanLeft();
        break;
      case 39:
        //right arrow
        imagePanRight();
        break;
      case 38:
        //up arrow
        imagePanUp();
        break;
      case 40:
        //down arrow
        imagePanDown();
        break;
      case 187: //chrome
      case 61:  //firefox
        //= or +
        imageZoomIn();
        break;
      case 189: //chrome
      case 173: //firefox
        //-
        imageZoomOut();
        break;
      default:
    }
  }

  const handleDragStart = (event) => {
    if (event.type != "touchstart") {
      event.preventDefault();
    }
    dragging = true;
    let img = activeImageRef.current;
    if (img) {
      imageDragStartX = window.event.clientX - currentImageX;
      imageDragStartY = window.event.clientY - currentImageY;
      img.classList.add('notransition');
    }
  }

  const handleDragEnd = (event) => {
    let img = activeImageRef.current;
    if (img) {
      img.classList.remove('notransition');
    }
    dragging = false;
  }

  const handleDragging = (event) => {  
    if (dragging) {
      imagePan(window.event.clientX - imageDragStartX, window.event.clientY - imageDragStartY);
    }
  }

  const page = useContext(BrPageContext);
  const component = useContext(BrComponentContext);
  let pfamLink = component.model.models.productCollectionLink;
  if(!pfamLink) pfamLink = window.location.href.substring(0,window.location.href.lastIndexOf('/'));
  const { channelDisplayName } = page.getComponent("globalproperties").getModels();
  const { productServletURL } = page.getComponent("header").getModels();
  const {setAlertMessage} = useAlertScreenReader();
  const scrollingTo = window.globalProperties.scrollingTo || "Scrolling to";
  const productReaderPrefix = window.globalProperties.productReaderPrefix || "View ";

  const switchProductImageAltTextPrefix = ((window?.globalProperties['switchProductImage']) || 'View product image');
  const { product } = page.getComponent().getModels();
  const { productData } = product ? product : {};
  //props did update aka linking to another page, even with same component
  //reset all state items
  useEffect(() => {
    isFirstRender.current = true;
    setActiveMediaItem(0);
    setActiveVariationName(null);
    setShowFullDescription(false);
    setShowVideoModal(false);
    setIsVideo(false);
    getVariations();
  }, [props]);

  useEffect(() => {
    if (productData?.variations) {
      getVariations();
    }
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // toggle flag after first render/mounting
      if (product && productData && productData.childProductName) {
        setActiveVariationName(productData.stockPrintDesign);
      }
    }
  });

  const getVariations = async () => {
    if (productData?.variations) {
      await getProductsByCodes(productData.variations, productServletURL).then((data) => {
        setVariations(data);
      });
    }
  };

  if (!productData) return null;

  try {
    const {
      hideBreadcrumbs = false,
      productCatalogImageHeight: productCatalogImageHeightOverride,
      productCatalogImageWidth: productCatalogImageWidthOverride,
    } = component.getParameters();

    const { document: documentRef, breadcrumbs } = component.getModels();

    const productPrintHeightOverride = "50";
    const productPrintWidthOverride = "50";
    const productCatalogImageHeight = "75";
    const productCatalogImageWidth = "75";

    if (!product) return null;
    const document = page.getContent(documentRef);
    if (!document) return null;

    const {
      compatibleProductsLabel = null,
      newLabel = null,
      customEmbossingAvailableLabel = null,
      customPrintAvailableLabel = null,
      printLabel = null,
      recyclableLabel = null,
      recyclableTooltipTitle = null,
      recyclableTooltipText = null,
      recycledContentLabel = null,
      recycledContentTooltipText = null,
      recycledContentTooltipTitle = null,
      compostableLabel = null,
      compostableTooltipText = null,
      compostableTooltipTitle = null,
      renewableContentLabel = null,
      renewableContentTooltipText = null,
      renewableContentTooltipTitle = null,
      sourceReducedLabel = null,
      sourceReducedTooltipText = null,
      sourceReducedTooltipTitle = null,
      madeInTheUSALabel = null,
      madeInTheUSATooltipText = null,
      madeInTheUSATooltipTitle = null,
      pfasFreeLabel = null,
      pfasFreeTooltipTitle = null,
      pfasFreeTooltipText = null,
      proPlanetSustainableLabel = null,
      proPlanetSustainableTitle = null,
      proPlanetSustainableText = null,
      designedForRecyclingLabel = null,
      designedForRecyclingTitle = null,
      designedForRecyclingText = null,
      fullClaimFootnoteText = null,
      discontinuedProductText = null,
      zoomInLabel = null,
      zoomOutLabel = null,
      panLeftLabel = null,
      panRightLabel = null,
      panUpLabel = null,
      panDownLabel = null,
    } = document.getData();

    const isActiveProduct = productData["publishStatus"]?.toString().toLowerCase() !== "inactive";

    //set pdp page title
    if (productData.browserPageTitle && channelDisplayName) {
      // If both title and channelDisplayName exist
      window.document.title = productData.browserPageTitle + " - " + channelDisplayName;
    } else if (productData.browserPageTitle) {
      window.document.title = productData.browserPageTitle;
    }

    let videoUrl = null
    const buildMediaItem = (itemNum, image, altText, isVideoPreviewImage, isVideoUrl, imageType, productCode) => {
      if (!image && !isVideoPreviewImage) return null;
      if (isVideoUrl) {
        videoUrl = image
        return null;
      }
      return {
        itemNum: itemNum,
        imageUrl: image,
        altText: switchProductImageAltTextPrefix + " " + altText,
        isVideoPreviewImage: isVideoPreviewImage,
        isVideo: isVideoUrl,
        imageType: imageType,
        productCode: productCode
      };
    };

    let mediaItems = [];
    productData.digitalAssets.forEach((da, index) => {
      mediaItems.push(buildMediaItem(index, da.url, da.alternateText, VIDEO_PREVIEW_IMAGE == da.type, VIDEO_LINK == da.type, da.type, productData.productCode));
    });
    mediaItems = mediaItems.filter((item) => item !== null);

    //consolidate featured bullets
    const featuredBullets = [
      productData?.featureBullet1 ? productData.featureBullet1 : null,
      productData?.featureBullet2 ? productData.featureBullet2 : null,
      productData?.featureBullet3 ? productData.featureBullet3 : null,
    ];
    
    //productFeatures
    const productExtras = [
      productData?.isProPlanet
      ? {
          id: "isProPlanet",
          iconClass: `darticon-proPlanet_Leaf ${!productData?.isCompostable && "blue"}`,
          label: proPlanetSustainableLabel,
          title: proPlanetSustainableTitle,
          text: proPlanetSustainableText,
        }
      : null,

      productData?.isDesignedForRecycling
      ? {
          id: "isDesignedForRecycling",
          iconClass: `darticon-proPlanet_Leaf ${!productData?.isCompostable && "blue"}`,
          label: designedForRecyclingLabel,
          title: designedForRecyclingTitle,
          text: designedForRecyclingText,
        }
      : null,

      productData?.isRecyclable
        ? {
            id: "isRecyclable",
            iconClass: "dartrecycle",
            label: recyclableLabel,
            title: recyclableTooltipTitle,
            text: recyclableTooltipText,
          }
        : null,

      productData?.isRecycledContent
        ? {
            id: "isRecycledContent",
            iconClass: "dartrecycledcontent",
            label: recycledContentLabel,
            title: recycledContentTooltipTitle,
            text: recycledContentTooltipText,
          }
        : null,

      productData?.isCompostable
        ? {
            id: "isCompostable",
            iconClass: "dartcommerciallycompostable",
            label: compostableLabel,
            title: compostableTooltipTitle,
            text: compostableTooltipText,
          }
        : null,
      productData?.isRenewableContent
        ? {
            id: "isRenewableContent",
            iconClass: "dartrenewablecontent",
            label: renewableContentLabel,
            title: renewableContentTooltipTitle,
            text: renewableContentTooltipText,
          }
        : null,
      productData?.isSourceReduced
        ? {
            id: "isSourceReduced",
            iconClass: "dartsourcereduced",
            label: sourceReducedLabel,
            title: sourceReducedTooltipTitle,
            text: sourceReducedTooltipText,
          }
        : null,
      productData?.isMadeInUSA
        ? {
            id: "isMadeInUSA",
            iconClass: "dartmadeinusa",
            label: madeInTheUSALabel,
            title: madeInTheUSATooltipTitle,
            text: madeInTheUSATooltipText,
          }
        : null,
        productData?.isPFASFree
        ? {
            id: "isPFASFree",
            iconClass: "darticon-PFASfree",
            label: pfasFreeLabel,
            title: pfasFreeTooltipTitle,
            text: pfasFreeTooltipText,
          }
        : null,
    ];

    const outputHeadingArea = () => {
      return (
        <div className="pdp-header__heading">
          {productData?.collection && (
            <Link
              to={getUrlPath(pfamLink)}
              className="pdp-header__heading-product-family-name"
            >
            {productData.collection ?? ""}
            </Link>
          )}
          {productData?.childProductName && (
            <Title defaultLevel={HEADING_LEVELS.ONE} className="pdp-header__heading-product-name">
              {productData.childProductName ?? ""}
            </Title>
          )}
          <div className="pdp-header__heading-info-line">
            <span className="pdp-header__heading-product-code">{product.productCode}</span>
            {productData?.compatibleProducts.length > 0 && (
              <>
                <span className="pdp-header__heading-product-code">&nbsp;-&nbsp;</span>
                <button
                  type="button"
                  aria-label={`${productReaderPrefix} ${productData.compatibleProducts.length} ${compatibleProductsLabel}`}
                  className="pdp-header__heading-compatible-products"
                  onClick={() => handleCompatibleProductsClick(COMPATIBLEPRODUCTSTABID, compatibleProductsLabel, isMobileNav)}
                >
                  {productData.compatibleProducts.length} {compatibleProductsLabel}
                </button>
              </>
            )}
          </div>
        </div>
      );
    };

    const toggleVideoModal = () => {
      setShowVideoModal(!showVideoModal);
      setIsVideo(!isVideo);
    };

    const outputActiveImageArea = () => {
      return (
        <div ref={imageWrapperRef} className="pdp-header__active-image-zoom-wrapper"
          tabIndex={0}
            onMouseDown={handleDragStart}
            onMouseUp={handleDragEnd}
            onMouseMove={handleDragging}
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            onMouseLeave={handleDragEnd}
        >
          {productData &&
            mediaItems &&
            mediaItems.length > 0 &&
            mediaItems.map((item, index) => {
              if (item.itemNum === activeMediaItem) {
                if (item.isVideoPreviewImage) {
                  return (
                    <div
                      key={`active-media__${index}`}
                      className={`pdp-header__active-image-wrapper`}
                    >
                        {item.imageUrl && (
                          <Image
                            className={"video-banner__image"}
                            loading="eager"
                            src={item.imageUrl}
                            alt={item.altText}
                            height={productCatalogImageHeightOverride}
                            width={productCatalogImageWidthOverride}
                          />
                        )}
                        <div className="video-banner__content-wrapper with-image">
                          <button 
                            className="video-banner__btn btn btn--play-video darticon-play"
                            onClick={toggleVideoModal}
                          >
                          </button>
                        </div>
                    </div>
                  );
                } else {
                  return (
                    <div key={`active-media__${index}`}>
                      <div className="pdp-header__imageControls left">
                        <span className="pdp-header__imageControls__zoomControls">
                          <button onClick={() => imageZoomIn()} aria-label={zoomInLabel ? zoomInLabel : "zoom in"}>+</button>
                          <button onClick={() => imageZoomOut()} aria-label={zoomInLabel ? zoomOutLabel : "zoom out"}>-</button>
                        </span>
                      </div>
                      <div className="pdp-header__imageControls right">
                        <span className="pdp-header__imageControls__panControls">
                          <button onClick={() => imagePanUp()} title={panUpLabel ? panUpLabel : "pan up"} aria-label={panUpLabel ? panUpLabel : "pan up"} className="pan-arrow-up"></button>
                          <div>
                            <button onClick={() => imagePanLeft()} title={panLeftLabel ? panLeftLabel : "pan left"} aria-label={panLeftLabel ? panLeftLabel : "pan left"} className="pan-arrow-left"></button>
                            <button onClick={() => imagePanRight()} title={panRightLabel ? panRightLabel : "pan right"} aria-label={panRightLabel ? panRightLabel : "pan right"} className="pan-arrow-right"></button>
                          </div>
                          <button onClick={() => imagePanDown()} title={panDownLabel ? panDownLabel : "pan down"} aria-label={panDownLabel ? panDownLabel : "pan down"} className="pan-arrow-down"></button>
                        </span>
                      </div>
                      <div className={`pdp-header__active-image-wrapper`}>
                        <Image
                          imageRef={activeImageRef}
                          className={`pdp-header__active-image`}
                          loading="eager"
                          src={item.imageUrl}
                          alt={item.altText}
                          height={productCatalogImageHeightOverride}
                          width={productCatalogImageWidthOverride}
                        />
                      </div>
                    </div>
                  );
                }
              } else {
                return null;
              }
            })}

          <div className="pdp-header__flag-section">
            {productData?.isNewProduct && (
              <span className="pdp-header__active-image-pdp-label">{newLabel}</span>
            )}
            {productData?.isProPlanet && (
              <p className="pdp-header__active-image-pdp-icon" aria-label={proPlanetSustainableLabel}>
                {productData?.isCompostable ? (
                  <img src="https://dart.widen.net/content/vsjmstuwtr/svg/ProPlanet-Leaf-CheckmarkwStroke-Green.svg?u=hsm95h" alt={proPlanetSustainableLabel}/>
                ):(
                  <img src="https://dart.widen.net/content/haqggj6fsh/svg/ProPlanet-Leaf-CheckmarkwStroke-Blue.svg?u=xd7jz4" alt={proPlanetSustainableLabel}/>
                )}
              </p>
            )}
          </div>
          {productData && videoUrl && (
            <Modal show={showVideoModal} handleClose={toggleVideoModal}>
              <VideoIFrame video={videoUrl} />
            </Modal>
          )}
        </div>
      );
    };

    const outputCustomizationMessage = () => {
      return (
        <>
          {productData?.isEmbossable && (
            <div className="pdp-header__customization">
              <span className="pdp-header__customization-text">
                {customEmbossingAvailableLabel}
              </span>
            </div>
          )}
          {productData?.isPrintable && (
            <div className="pdp-header__customization">
              <span className="pdp-header__customization-text">{customPrintAvailableLabel}</span>
            </div>
          )}
        </>
      );
    };

    const outputAltImagesSection = (isDesktop, maxMediaItems) => {
      if(mediaItems.length > maxMediaItems) {
        return (
          <ProductMediaThumbnailCarousel
            mediaItems={mediaItems}
            activeMediaItem={activeMediaItem}
            setActiveMediaItem={setActiveMediaItem}
            hasProductVideo={videoUrl}
            productCatalogImageHeight={productCatalogImageHeight}
            productCatalogImageWidth={productCatalogImageWidth}
            isVertical={isDesktop}
            toggleVideoModal={toggleVideoModal} />
        );
      }
      else {
        return (
          <ProductMediaThumbnailList
            mediaItems={mediaItems}
            activeMediaItem={activeMediaItem}
            setActiveMediaItem={setActiveMediaItem}
            hasProductVideo={videoUrl}
            productCatalogImageHeight={productCatalogImageHeight}
            productCatalogImageWidth={productCatalogImageWidth}
            toggleVideoModal={toggleVideoModal} />
        );
      }
    };

    const outputDescription = () => {
      if (showFullDescription || productData.catalogDescription.split(" ").length < WORDSTOSHOW) {
        return productData.catalogDescription;
      } else {
        return productData.catalogDescription.split(" ").slice(0, WORDSTOSHOW).join(" ") + "...";
      }
    };

    const handleCompatibleProductsClick = (id, label, isMobileNav) => {
      const top = scrollAndSetFocusOnPDP(id, isMobileNav);
      setAlertMessage(`${scrollingTo} ${label}`);
    };

    return (
      <>
        {!isActiveProduct && (
          <Message
            message={discontinuedProductText}
            alertLevel={ALERT}
            canClose={false}
            canExpire={false}
          />
        )}

        <HeaderToolbar
          hideBreadcrumbs={hideBreadcrumbs}
          breadcrumbs={breadcrumbs}
        />
        <div className={`${page.isPreview() ? "has-edit-button" : ""} pdp-header`}>
          <BrManageContentButton content={document} />
          <Helmet helmetData={helmetData}>
            {productData.catalogImage && (
              <meta property="og:image" content={productData.catalogImage} />
            )}

            {productData.browserPageTitle && (
              <meta property="og:title" content={productData.browserPageTitle} />
            )}
            {productData.metaDescription && (
              <meta property="og:description" content={productData.metaDescription} />
            )}
            {productData.metaDescription && (
              <meta name="description" content={productData.metaDescription} />
            )}
          </Helmet>
          <div className="pdp-header__heading-wrapper">
            <Small>
              {outputHeadingArea()}
              {outputActiveImageArea()}
              {outputCustomizationMessage()}
              {outputAltImagesSection(false, MOBILEMEDIASLIDES)}
            </Small>
            <Medium>
              {outputHeadingArea()}
              {outputActiveImageArea()}
              {outputCustomizationMessage()}
              {outputAltImagesSection(false, TABLETMEDIASLIDES)}
            </Medium>
            <Large>
              <div className="pdp-header__imaging-wrapper">
                {outputAltImagesSection(true, DESKTOPMEDIASLIDES)}
                <div className="pdp-header__desktop-active-image-wrapper">
                  {outputActiveImageArea()}
                  {outputCustomizationMessage()}
                </div>
              </div>
            </Large>
            <div className="pdp-header__information-wrapper">
              <Large>{outputHeadingArea()}</Large>
              {productData && featuredBullets.filter((item) => item !== null).length > 0 && (
                <div className="pdp-header__features-wrapper">
                  <ul className="pdp-header__features-list">
                    {featuredBullets
                      .filter((item) => item !== null)
                      .map((item, index) => (
                        <li key={`featured-bullet__${index}`} className="pdp-header__feature-item">
                          {item}
                        </li>
                      ))}
                  </ul>
                </div>
              )}
              {variations && variations.length > 0 && (
                <div className="pdp-header__product-prints-wrapper">
                  <div className="pdp-header__product-prints-items">
                    {productData.variantSwatchUrl && productData.stockPrintDesign && (
                      <span className="pdp-header__product-print-item active">
                        <Image
                          src={productData.variantSwatchUrl}
                          alt={productData.stockPrintDesign ? productData.stockPrintDesign : ""}
                          height={productPrintHeightOverride}
                          width={productPrintWidthOverride}
                        />
                      </span>
                    )}
                    {variations.map((item, index) => {
                      if (item.variantSwatchUrl && item.stockPrintDesign) {
                        return (
                          <Link
                            key={`variation_${index}`}
                            to={getUrlPath(item.url)}
                            className="pdp-header__product-print-item"
                            onMouseEnter={() => {
                              setActiveVariationName(item.stockPrintDesign);
                            }}
                            onMouseLeave={() => {
                              setActiveVariationName(productData.stockPrintDesign);
                            }}
                          >
                            <Image
                              height={productPrintHeightOverride}
                              width={productPrintWidthOverride}
                              src={item.variantSwatchUrl}
                              alt={item.stockPrintDesign ? item.stockPrintDesign : ""}
                            />
                          </Link>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                  {activeVariationName && printLabel && (
                    <span className="pdp-header__product-print-current">
                      <span className="pdp-header__product-print-label">{printLabel}:&nbsp;</span>
                      <span className="pdp-header__product-print-value">{activeVariationName}</span>
                    </span>
                  )}
                </div>
              )}
              {isActiveProduct && (
                <div className="pdp-header__add-wrapper">
                  <AddProductExpandable
                    productCode={product.productCode}
                    productName={sanitizeHtml(productData.childProductName)}
                    discontinued={false}
                    addToListAnalytics={{
                      event: "listAdd",
                      listType: "Main",
                      productName: `${sanitizeHtml(productData.childProductName)}`,
                    }}
                    removeFromListAnalytics={{
                      event: "removeFromList",
                      listType: "Main",
                      productName: `${sanitizeHtml(productData.childProductName)}`,
                    }}
                  />
                </div>
              )}
              {productData?.catalogDescription && (
                <div className="pdp-header__description-wrapper">
                  <p className="pdp-header__description">
                    {outputDescription()}&nbsp;&nbsp;
                    {/* //only show readmore readless if we are greater than equal to words to show */}
                    {productData.catalogDescription.split(" ").length >= WORDSTOSHOW && (
                      <button
                        className="pdp-header__description-toggle"
                        onClick={() => setShowFullDescription(!showFullDescription)}
                      >
                        <span className="pdp-header__description-toggle-text">
                          {showFullDescription
                            ? window.globalProperties["readLessLabel"]
                            : window.globalProperties["readMoreLabel"]}
                        </span>
                        {showFullDescription ? (
                          <span className="pdp-header__description-toggle-icon darticon-arrow-up"></span>
                        ) : (
                          <span className="pdp-header__description-toggle-icon darticon-arrow-down"></span>
                        )}
                      </button>
                    )}
                  </p>
                </div>
              )}
              <div className="pdp-header__product-tags-wrapper">
                {productData &&
                  productExtras.filter((item) => item !== null).length > 0 &&
                  productExtras
                    .filter((item) => item !== null)
                    .map((item, index) => (
                      <div key={`product-tag__${index}`} className="pdp-header__product-tag">
                        <span className={`pdp-header__product-tag-icon ${item.iconClass}`}></span>
                        <span className="pdp-header__product-tag-name">{item.label}</span>
                      </div>
                    ))}
                {productData && productExtras.filter((item) => item !== null).length > 0 && (
                  <div className="pdp-header__product-tags-more-message-wrapper">
                    <span className="pdp-header__product-tags-more-message">
                      {fullClaimFootnoteText}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } catch (e) {
    console.error("ProductDetailHeader error", e);
    return <ErrorComponent error={e} componentName={"ProductDetailHeader"} />;
  }
}

function ProductMediaThumbnailCarousel({ mediaItems, activeMediaItem, setActiveMediaItem, hasProductVideo, productCatalogImageHeight, productCatalogImageWidth, isVertical, toggleVideoModal }) {
  return (
    <div
      className="pdp-header__carousel"
    >
      <Swiper
        navigation={{
          nextEl: ".pdp-header__swiper-button-next"
        }}
        modules={[Navigation, A11y]}
        slidesPerView={MOBILEMEDIASLIDES - 1}
        allowSlidePrev={true}
        allowSlideNext={true}
        loop={true}
        direction={isVertical ? 'vertical' : 'horizontal'}
        spaceBetween={10}
        a11y={{
          prevSlideMessage: (window.globalProperties && window.globalProperties["prevSlideMessage"]) || "Previous slide",
          nextSlideMessage: (window.globalProperties && window.globalProperties["nextSlideMessage"]) || "Next slide"
        }}
        breakpoints={{
          640: {
            slidesPerView: TABLETMEDIASLIDES - 1,
          },
          992: {
            slidesPerView: DESKTOPMEDIASLIDES - 1,
          },
        }}
        updateOnImagesReady={true}
        updateOnWindowResize={true}
        onAfterInit={function(swiper){
          swiper.$el.find(".swiper-slide-duplicate").find(".pdp-header__alt-image").attr("tabindex","-1");
        }}
      >
        {mediaItems.map((item, index) => {
          //check if video exists
          if (item.isVideoPreviewImage && !hasProductVideo) {
            return null;
          }
          return (
            <SwiperSlide key={index}>
              <button
                type="button"
                aria-pressed={activeMediaItem === item.itemNum ? "true" : "false"}
                title={item.altText ? item.altText : ""}
                className={`${activeMediaItem === item.itemNum ? "active" : ""} ${
                  item.isVideoPreviewImage ? "pdp-header__alt-image--video" : ""
                } pdp-header__alt-image`}
                onClick={item.isVideoPreviewImage ? () => setActiveMediaItem(item.itemNum) : toggleVideoModal}
              >
                {item.isVideoPreviewImage && (
                  <span className="pdp-header__alt-image-icon darticon-play"></span>
                )}
                {item.imageUrl && (
                  <Image
                    src={item.imageUrl}
                    alt={item.altText}
                    height={productCatalogImageHeight}
                    width={productCatalogImageWidth}
                    id={buildPDPHeaderImageIdValue(item, index)}
                  />
                )}
              </button>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <button tabIndex="0" type="button" role="button" className="pdp-header__swiper-button-next">
        <span className="pdp-header__swiper-button-next__content">
          &rsaquo;
        </span>
      </button>
    </div>
  );
}

function ProductMediaThumbnailList({ mediaItems, activeMediaItem, setActiveMediaItem, hasProductVideo, productCatalogImageHeight, productCatalogImageWidth, toggleVideoModal }) {
  return (
    <ul className="pdp-header__alt-images-wrapper">
      {mediaItems.length > 0 && (
        <>
          {mediaItems.map((item, index) => {
            //check if video exists
            if (item.isVideoPreviewImage && !hasProductVideo) {
              return null;
            }
            const imageId = buildPDPHeaderImageIdValue(item, index)
            return (
              <li className="pdp-header__alt-image--list-item"
                  key={`alt-images__${index}`}>
                <button
                  type="button"
                  aria-pressed={activeMediaItem === item.itemNum ? "true" : "false"}
                  title={item.altText ? item.altText : ""}
                  className={`${activeMediaItem === item.itemNum ? "active" : ""} ${
                    item.isVideoPreviewImage ? "pdp-header__alt-image--video" : ""
                  } pdp-header__alt-image`}
                  onClick={() => setActiveMediaItem(item.itemNum)}
                  id={imageId ? "button-" + imageId : ""}
                >
                  {item.isVideoPreviewImage && (
                    <span className="pdp-header__alt-image-icon darticon-play"></span>
                  )}
                  {item.imageUrl && (
                    <Image
                      src={item.imageUrl}
                      alt={item.altText}
                      height={productCatalogImageHeight}
                      width={productCatalogImageWidth}
                      id={imageId}
                    />
                  )}
                </button>
              </li>
            );
          })}
        </>
      )}
    </ul>
  );
}

function buildPDPHeaderImageIdValue(mediaItem, index) {
  return `${mediaItem.productCode}-${mediaItem.imageType.replace(" ","-")}-Position-${(index + 1)}`
}

export default ProductDetailsHeader;
