/* eslint-disable */
import React from "react";
import { BrPageContext, BrComponent } from "@bloomreach/react-sdk";

function GlobalStyleSheetLayout() {
  const page = React.useContext(BrPageContext);
  console.log("BrPageContext", BrPageContext);
  return (
    <BrComponent path="layout">
      <div className="layout global-styles-layout">
        <BrComponent path="header"></BrComponent>
        <div className="app-wrapper">
          <h1>H1 Testing display of HTML elements</h1>
          <h2>This is 2nd level heading</h2>

          <h3>This is 3rd level heading</h3>

          <h4>This is 4th level heading</h4>

          <h5>This is 5th level heading©</h5>

          <h6>This is 6th level heading</h6>
          <p>This is a test paragraph.</p>
          <p className="small">SMALL This is a test paragraph.</p>
          <h6>Font Primary</h6>
          <p className="primary-font">The quick brown fox jumped over the lazy dog</p>
          <h6>Font Secondary</h6>
          <p className="secondary-font">The quick brown fox jumped over the lazy dog</p>
          <h6>bold levels</h6>
          <p className="bold-normal">bold normal 400</p>
          <p className="bold-normal-plus">bold normal-plus 500</p>
          <p className="bold-medium">bold medium 600</p>
          <p className="bold-bold">bold bold 700</p>
          <p className="bold-extra-bold">bold extra bold 800</p>
          <div>
            <h6>buttons</h6>
            <button className="btn">primary</button>
            <button className="btn btn--secondary">secondary Button</button>
            <div className="special-background">
              <button className="btn btn--tertiary">tertiary Button</button>
            </div>
            <button className="btn btn--link">Neutral Button</button>

            <a className="btn">primary</a>
            <a className="btn btn--secondary">secondary Button</a>
            <div className="special-background">
              <a className="btn btn--tertiary">tertiary Button</a>
            </div>
            <a className="btn btn--link">Neutral Button</a>
          </div>
          <hr />
          <div>
            <h6>buttons disabled</h6>
            <button className="btn" disabled>
              primary
            </button>
            <button className="btn btn--secondary" disabled>
              secondary Button
            </button>
            <div className="special-background">
              <button className="btn btn--tertiary" disabled>
                tertiary Button
              </button>
            </div>
            <button className="btn btn--link" disabled>
              Neutral Button
            </button>

            <a className="btn" disabled>
              primary
            </a>
            <a className="btn btn--secondary" disabled>
              secondary Button
            </a>
            <div className="special-background">
              <a className="btn btn--tertiary" disabled>
                tertiary Button
              </a>
            </div>
            <a className="btn btn--link" disabled>
              Neutral Button
            </a>
          </div>
          <hr />
          <div>
            <a href="#" className="">
              regular link
            </a>
          </div>
          <hr />
          <h2>Basic block level elements</h2>

          <p>
            This is a normal paragraph (<code>p</code> element). To add some length to it, let us
            mention that this page was primarily written for testing the effect of{" "}
            <strong>user style sheets</strong>. You can use it for various other purposes as well,
            like just checking how your browser displays various HTML elements by default. It can
            also be useful when testing conversions from HTML format to other formats, since some
            elements can go wrong then.
          </p>
          <p>
            This is another paragraph. I think it needs to be added that the set of elements tested
            is not exhaustive in any sense. I have defaultValue those elements for which it can make
            sense to write user style sheet rules, in my opionion.
          </p>
          <div>
            This is a <code>div</code> element. Authors may use such elements instead of paragraph
            markup for various reasons. (End of <code>div</code>.)
          </div>
          <blockquote>
            <p>
              This is a block quotation containing a single paragraph. Well, not quite, since this
              is not <em>really</em> quoted text, but I hope you understand the point. After all,
              this page does not use HTML markup very normally anyway.
            </p>
          </blockquote>
          <p>
            The following contains address information about the author, in an <code>address</code>{" "}
            element.
          </p>
          <address>
            <a href="../personal.html" lang="fi" hrefLang="en">
              Jukka Korpela
            </a>
            , <a href="mailto:jukkakk@gmail.com">jukkakk@gmail.com</a>
            <br />
            Päivänsäteenkuja 4 A, Espoo, Finland
          </address>

          <h2>Lists</h2>

          <p>
            This is a paragraph before an <strong>unnumbered</strong> list (<code>ul</code>). Note
            that the spacing between a paragraph and a list before or after that is hard to tune in
            a user style sheet. You can't guess which paragraphs are logically related to a list,
            e.g. as a "list header".
          </p>
          <ul>
            <li>One.</li>
            <li>Two.</li>
            <li>
              Three. Well, probably this list item should be longer. Note that for short items lists
              look better if they are compactly presented, whereas for long items, it would be
              better to have more vertical spacing between items.
            </li>

            <li>
              Four. This is the last item in this list. Let us terminate the list now without making
              any more fuss about it.
            </li>
          </ul>

          <p>
            The following is a <code>menu</code> list:
          </p>
          <menu>
            <li>One.</li>
            <li>Two.</li>
            <li>
              Three. Well, probably this list item should be longer so that it will probably wrap to
              the next line in rendering.
            </li>
          </menu>

          <p>
            The following is a <code>dir</code> list:
          </p>
          <dir>
            <li>One.</li>
            <li>Two.</li>
            <li>
              Three. Well, probably this list item should be longer so that it will probably wrap to
              the next line in rendering.
            </li>
          </dir>

          <p>
            This is a paragraph before a <strong>numbered</strong> list (<code>ol</code>). Note that
            the spacing between a paragraph and a list before or after that is hard to tune in a
            user style sheet. You can't guess which paragraphs are logically related to a list, e.g.
            as a "list header".
          </p>
          <ol>
            <li>One.</li>
            <li>Two.</li>
            <li>
              Three. Well, probably this list item should be longer. Note that if items are short,
              lists look better if they are compactly presented, whereas for long items, it would be
              better to have more vertical spacing between items.
            </li>

            <li>
              Four. This is the last item in this list. Let us terminate the list now without making
              any more fuss about it.
            </li>
          </ol>

          <p>
            This is a paragraph before a <strong>definition</strong> list (<code>dl</code>). In
            principle, such a list should consist of <em>terms</em> and associated definitions. But
            many authors use <code>dl</code> elements for fancy "layout" things. Usually the effect
            is not <em>too</em> bad, if you design user style sheet rules for <code>dl</code> which
            are suitable for real definition lists.
          </p>

          <dl>
            <dt>recursion</dt>
            <dd>see recursion</dd>
            <dt>recursion, indirect</dt>
            <dd>see indirect recursion</dd>
            <dt>indirect recursion</dt>
            <dd>see recursion, indirect</dd>
            <dt>term</dt>
            <dd>
              a word or other expression taken into specific use in a well-defined meaning, which is
              often defined rather rigorously, even formally, and may differ quite a lot from an
              everyday meaning
            </dd>
          </dl>

          <h2>Text-level markup</h2>

          <ul>
            <li>
              <abbr title="Cascading Style Sheets">CSS</abbr> (an abbreviation; <code>abbr</code>{" "}
              markup used)
            </li>

            <li>
              <acronym title="radio detecting and ranging">radar</acronym> (an acronym;{" "}
              <code>acronym</code> markup used)
            </li>
            <li>
              <b>bolded</b> (<code>b</code> markup used - just bolding with unspecified semantics)
            </li>

            <li>
              <big>big thing</big> (<code>big</code> markup used)
            </li>
            <li>
              <font size="6">large size</font> (<code>font size=6</code> markup used)
            </li>
            <li>
              <font face="Courier">Courier font</font> (<code>font face=Courier</code> markup used)
            </li>
            <li>
              <font color="red">red text</font> (<code>font color=red</code> markup used)
            </li>
            <li>
              <cite>Origin of Species</cite> (a book title; <code>cite</code> markup used)
            </li>

            <li>
              <code>a[i] = b[i] + c[i);</code> (computer code; <code>code</code> markup used)
            </li>
            <li>
              here we have some <del>deleted</del> text (<code>del</code> markup used)
            </li>
            <li>
              an <dfn>octet</dfn> is an entity consisting of eight bits (<code>dfn</code> markup
              used for the term being defined)
            </li>

            <li>
              this is <em>very</em> simple (<code>em</code> markup used for emphasizing a word)
            </li>

            <li>
              <i lang="la">Homo sapiens</i> (should appear in italics; <code>i</code> markup used)
            </li>
            <li>
              here we have some <ins>inserted</ins> text (<code>ins</code> markup used)
            </li>
            <li>
              type <kbd>yes</kbd> when prompted for an answer (<code>kbd</code> markup used for text
              indicating keyboard input)
            </li>

            <li>
              <q>Hello!</q> (<code>q</code> markup used for quotation)
            </li>
            <li>
              He said:{" "}
              <q>
                She said <q>Hello!</q>
              </q>{" "}
              (a quotation inside a quotation)
            </li>
            <li>
              you may get the message <samp>Core dumped</samp> at times (<code>samp</code> markup
              used for sample output)
            </li>

            <li>
              <small>this is not that important</small> (<code>small</code> markup used)
            </li>
            <li>
              <strike>overstruck</strike> (<code>strike</code> markup used; note: <code>s</code> is
              a nonstandard synonym for <code>strike</code>)
            </li>

            <li>
              <strong>this is highlighted text</strong> (<code>strong</code> markup used)
            </li>

            <li>
              In order to test how subscripts and superscripts (<code>sub</code> and{" "}
              <code>sup</code> markup) work inside running text, we need some dummy text around
              constructs like x<sub>1</sub> and H<sub>2</sub>O (where subscripts occur). So here is
              some fill so that you will (hopefully) see whether and how badly the subscripts and
              superscripts mess up vertical spacing between lines. Now superscripts: M<sup>lle</sup>
              , 1<sup>st</sup>, and then some mathematical notations: e<sup>x</sup>, sin<sup>2</sup>{" "}
              <i>x</i>, and some nested superscripts (exponents) too: e
              <sup>
                x<sup>2</sup>
              </sup>{" "}
              and f(x)
              <sup>
                g(x)<sup>a+b+c</sup>
              </sup>{" "}
              (where 2 and a+b+c should appear as exponents of exponents).
            </li>

            <li>
              <tt>text in monospace font</tt> (<code>tt</code> markup used)
            </li>
            <li>
              <u>underlined</u> text (<code>u</code> markup used)
            </li>
            <li>
              the command <code>cat</code> <var>filename</var> displays the file specified by the{" "}
              <var>filename</var> (<code>var</code> markup used to indicate a word as a variable).
            </li>
          </ul>

          <p>
            Some of the elements tested above are typically displayed in a monospace font, often
            using the <em>same</em> presentation for all of them. This tests whether that is the
            case on your browser:
          </p>

          <ul>
            <li>
              <code>This is sample text inside code markup</code>
            </li>
            <li>
              <kbd>This is sample text inside kbd markup</kbd>
            </li>
            <li>
              <samp>This is sample text inside samp markup</samp>
            </li>
            <li>
              <tt>This is sample text inside tt markup</tt>
            </li>
          </ul>

          <h2>Links</h2>
          <ul>
            <li>
              <a href="../index.html">main page</a>
            </li>
            <li>
              <a
                href="http://www.unicode.org/versions/Unicode4.0.0/ch06.pdf"
                title="Writing Systems and Punctuation"
                type="application/pdf"
              >
                Unicode Standard, chapter&nbsp;6
              </a>
            </li>
          </ul>

          <p>
            This is a text paragraph that contains some inline links. Generally, inline links (as
            opposite to e.g. links lists) are problematic from the{" "}
            <a href="http://www.useit.com">usability</a> perspective, but they may have use as
            &#8220;incidental&#8221;, less relevant links. See the document{" "}
            <cite>
              <a href="links.html">Links Want To Be Links</a>
            </cite>
            .
          </p>

          <h2>Forms</h2>

          <form action="http://jkorpela.fi/cgi-bin/echo.cgi">
            <div>
              <input type="hidden" name="hidden field" defaultValue="42" />
              This is a form containing various fields (with some initial values (defaults) set, so
              that you can see how input text looks like without actually typing it):
            </div>
            <div>
              <label htmlFor="but">Button:</label>
              <button id="but" type="submit" name="foo" defaultValue="bar" className="btn">
                A cool
                <br />
                button
              </button>
            </div>
            <div>
              <label htmlFor="f0">Reset button:</label>
              <input className="btn" id="f0" type="reset" name="reset" defaultValue="Reset" />
            </div>
            <div>
              <label htmlFor="f1">input field:</label>
              <input id="f1" name="text" size="20" defaultValue="Default text." />
            </div>
            <div>
              <label htmlFor="f1">input field:</label>
              <input id="f1" name="text" size="20" placeholder="placeholder text." />
            </div>
            <div>
              <label htmlFor="f1">error input field:</label>
              <input className="error" id="f1" name="text" size="20" defaultValue="Default text." />
              <span className="error error-message">error message</span>
            </div>
            <div>
              <label htmlFor="f2">input field (textarea):</label>
              <textarea
                id="f2"
                name="textarea"
                rows="2"
                cols="20"
                defaultValue="Default text."
              ></textarea>
            </div>
            <div>
              <label htmlFor="f2">error input field (textarea):</label>
              <textarea
                className="error"
                id="f2"
                name="textarea"
                rows="2"
                cols="20"
                defaultValue="Default text."
              ></textarea>
              <span className="error error-message">error message</span>
            </div>
            <div>
              <label htmlFor="f10">A select element with (dropdown box):</label>
              <select id="f10" name="select1" size="1">
                <option>one </option>
                <option defaultValue>two (default) </option>
                <option>three </option>
              </select>
            </div>
            <div>
              <label htmlFor="f10">Error A select element with (dropdown box):</label>
              <select className="error" id="f10" name="select1" size="1">
                <option>one </option>
                <option defaultValue>two (default) </option>
                <option>three </option>
              </select>
              <span className="error error-message">error message</span>
            </div>
            <div>
              <label htmlFor="f99">Submit button:</label>
              <input
                className="btn"
                id="f99"
                type="submit"
                name="submit"
                defaultValue="Just a test"
              />
            </div>
            <fieldset>
              <legend>RADIO BUTTONS Legend</legend>
              <label className="radio-checkbox-contain">
                <span>First radio</span>
                <input type="radio" name="radio1" />
                <div className="radio-checkbox-input"></div>
              </label>
              <label className="radio-checkbox-contain">
                <span>Second radio</span>
                <input type="radio" name="radio1" defaultChecked />
                <div className="radio-checkbox-input"></div>
              </label>
              <label className="radio-checkbox-contain">
                <input type="radio" name="radio2" disabled />
                <span>Third radio</span>
                <div className="radio-checkbox-input"></div>
              </label>
              <label className="radio-checkbox-contain">
                <span>Fourth radio</span>
                <input type="radio" name="radio2" defaultChecked disabled />
                <div className="radio-checkbox-input"></div>
              </label>
            </fieldset>
            <fieldset>
              <legend>CHECKBOXES Check those that apply</legend>
              <label className="radio-checkbox-contain">
                <span>First checkbox</span>
                <input type="checkbox" />
                <div className="radio-checkbox-input"></div>
              </label>
              <label className="radio-checkbox-contain">
                <span>Second checkbox</span>
                <input type="checkbox" defaultChecked />
                <div className="radio-checkbox-input"></div>
              </label>
              <label className="radio-checkbox-contain">
                <span>Third checkbox</span>
                <input type="checkbox" disabled />
                <div className="radio-checkbox-input"></div>
              </label>
              <label className="radio-checkbox-contain">
                <span>Fourth checkbox</span>
                <input type="checkbox" checked disabled />
                <div className="radio-checkbox-input"></div>
              </label>
            </fieldset>
          </form>

          <div className="">
            <h3>Not in icon font but looks like an icon</h3>
            <p>info icon</p>
            <span className="info-icon"></span>
          </div>
          <div className="bgc1 clearfix icon-fonts">
            <h1 className="mhmm mvm">
              <span className="fgc1">Font Name:</span>
            </h1>
          </div>
          <div className="clearfix mhl ptl">
            <h1 className="mvm mtn fgc1">Grid Size: Unknown</h1>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-largearrow"></span>
                <span className="mls"> darticon-largearrow</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-download"></span>
                <span className="mls"> darticon-download</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="dartmadeinusa"></span>
                <span className="mls"> dartmadeinusa</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="dartsourcereduced"></span>
                <span className="mls"> dartsourcereduced</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="dartrenewablecontent"></span>
                <span className="mls"> dartrenewablecontent</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="dartrecycle"></span>
                <span className="mls"> dartrecycle</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="dartrecycledcontent"></span>
                <span className="mls"> dartrecycledcontent</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="dartcommerciallycompostable"></span>
                <span className="mls"> dartcommerciallycompostable</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="dartpaper"></span>
                <span className="mls"> dartpaper</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="dartfiber"></span>
                <span className="mls"> dartfiber</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="dartps"></span>
                <span className="mls"> dartps</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="dartpm"></span>
                <span className="mls"> dartpm</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="dartops"></span>
                <span className="mls"> dartops</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darthips"></span>
                <span className="mls"> darthips</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darteps"></span>
                <span className="mls"> darteps</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="dartpp"></span>
                <span className="mls"> dartpp</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="dartrpet"></span>
                <span className="mls"> dartrpet</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="dartpete"></span>
                <span className="mls"> dartpete</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-map"></span>
                <span className="mls"> darticon-map</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-youtube"></span>
                <span className="mls"> darticon-youtube</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-alert"></span>
                <span className="mls"> darticon-alert</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-print"></span>
                <span className="mls"> darticon-print</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-filter"></span>
                <span className="mls"> darticon-filter</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-sort"></span>
                <span className="mls"> darticon-sort</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-clock"></span>
                <span className="mls"> darticon-clock</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-fax"></span>
                <span className="mls"> darticon-fax</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-location"></span>
                <span className="mls"> darticon-location</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-phone"></span>
                <span className="mls"> darticon-phone</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-play"></span>
                <span className="mls"> darticon-play</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-viewlist"></span>
                <span className="mls"> darticon-viewlist</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-cart"></span>
                <span className="mls"> darticon-cart</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-email"></span>
                <span className="mls"> darticon-email</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-webpages"></span>
                <span className="mls"> darticon-webpages</span>
              </div>

              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-twitter"></span>
                <span className="mls"> darticon-twitter</span>
              </div>

              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-search"></span>
                <span className="mls"> darticon-search</span>
              </div>

              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-region"></span>
                <span className="mls"> darticon-region</span>
              </div>

              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-products"></span>
                <span className="mls"> darticon-products</span>
              </div>

              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-plus"></span>
                <span className="mls"> darticon-plus</span>
              </div>

              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-pinterest"></span>
                <span className="mls"> darticon-pinterest</span>
              </div>

              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-pdf"></span>
                <span className="mls"> darticon-pdf</span>
              </div>

              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-menu"></span>
                <span className="mls"> darticon-menu</span>
              </div>

              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-linkedin"></span>
                <span className="mls"> darticon-linkedin</span>
              </div>

              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-headersearch"></span>
                <span className="mls"> darticon-headersearch</span>
              </div>

              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-facebook"></span>
                <span className="mls"> darticon-facebook</span>
              </div>

              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-close"></span>
                <span className="mls"> darticon-close</span>
              </div>

              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-checkmark"></span>
                <span className="mls"> darticon-checkmark</span>
              </div>

              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-backtotop"></span>
                <span className="mls"> darticon-backtotop</span>
              </div>

              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-article"></span>
                <span className="mls"> darticon-article</span>
              </div>

              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-arrow"></span>
                <span className="mls"> darticon-arrow</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-arrow-down"></span>
                <span className="mls"> darticon-arrow-down</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-arrow-up"></span>
                <span className="mls"> darticon-arrow-up</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-arrow-back"></span>
                <span className="mls"> darticon-arrow-back</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
            <div className="glyph fs1">
              <div className="clearfix bshadow0 pbs">
                <span className="darticon-account"></span>
                <span className="mls"> darticon-account</span>
              </div>
              <div className="fs0 bshadow0 clearfix hidden-true">
                <span className="unit pvs fgc1">liga: </span>
                <input type="text" readOnly defaultValue="" className="liga unitRight" />
              </div>
            </div>
          </div>
          <style
            dangerouslySetInnerHTML={{
              __html: `
            .special-background{
              padding:20px;
              display:inline-flex;
              background:#0054A6;
            }
            .icon-fonts small {
              font-size: .66666667em;
            }
            .icon-fonts .bshadow0, .icon-fonts input {
              box-shadow: inset 0 -2px #e7e7e7;
            }
            .icon-fonts input:hover {
              box-shadow: inset 0 -2px #ccc;
            }
            .icon-fonts input, .icon-fonts fieldset {
              font-family: sans-serif;
              font-size: 1em;
              margin: 0;
              padding: 0;
              border: 0;
            }
            .icon-fonts input {
              color: inherit;
              line-height: 1.5;
              height: 1.5em;
              padding: .25em 0;
            }
            .icon-fonts input:focus {
              outline: none;
              box-shadow: inset 0 -2px #449fdb;
            }
            .glyph {
              font-size: 16px;
              width: 15em;
              padding-bottom: 1em;
              margin-right: 4em;
              margin-bottom: 1em;
              float: left;
            }
            .liga {
              width: 80%;
              width: calc(100% - 2.5em);
            }
            .talign-right {
              text-align: right;
            }
            .talign-center {
              text-align: center;
            }
            .bgc1 {
              background: #f1f1f1;
            }
            .fgc1 {
              color: #999;
            }
            .fgc0 {
              color: #000;
            }
            p {
              margin-top: 1em;
              margin-bottom: 1em;
            }
            .mvm {
              margin-top: .75em;
              margin-bottom: .75em;
            }
            .mtn {
              margin-top: 0;
            }
            .mtl, .mal {
              margin-top: 1.5em;
            }
            .mbl, .mal {
              margin-bottom: 1.5em;
            }
            .mal, .mhl {
              margin-left: 1.5em;
              margin-right: 1.5em;
            }
            .mhmm {
              margin-left: 1em;
              margin-right: 1em;
            }
            .mls {
              margin-left: .25em;
            }
            .ptl {
              padding-top: 1.5em;
            }
            .pbs, .pvs {
              padding-bottom: .25em;
            }
            .pvs, .pts {
              padding-top: .25em;
            }
            .unit {
              float: left;
            }
            .unitRight {
              float: right;
            }
            .size1of2 {
              width: 50%;
            }
            .size1of1 {
              width: 100%;
            }
            .clearfix:before, .clearfix:after {
              content: " ";
              display: table;
            }
            .clearfix:after {
              clear: both;
            }
            .hidden-true {
              display: none;
            }
            .textbox0 {
              width: 3em;
              background: #f1f1f1;
              padding: .25em .5em;
              line-height: 1.5;
              height: 1.5em;
            }
            #testDrive {
              display: block;
              padding-top: 24px;
              line-height: 1.5;
            }
            .fs0 {
              font-size: 16px;
            }
            .fs1 {
              font-size: 32px;
            }
            `,
            }}
          />

          <h2>Tables</h2>

          <p>
            The following table has a caption. The first row and the first column contain table
            header cells (<code>th</code> elements) only; other cells are data cells (
            <code>td</code> elements), with <code>align="right"</code> attributes:
          </p>

          <table summary="Each row names a Nordic country and specifies its total area and land area, in square kilometers">
            <caption>Sample table: Areas of the Nordic countries, in sq km</caption>
            <thead></thead>
            <tbody>
              <tr>
                <th scope="col">Country</th>
                <th scope="col">Total area</th>
                <th scope="col">Land area</th>
              </tr>
              <tr>
                <th scope="row">Denmark</th>
                <td align="RIGHT">43,070</td>
                <td align="RIGHT">42,370</td>
              </tr>
              <tr>
                <th scope="row">Finland</th>
                <td align="RIGHT">337,030</td>
                <td align="RIGHT">305,470</td>
              </tr>
              <tr>
                <th scope="row">Iceland</th>
                <td align="RIGHT">103,000</td>
                <td align="RIGHT">100,250</td>
              </tr>
              <tr>
                <th scope="row">Norway</th>
                <td align="RIGHT">324,220</td>
                <td align="RIGHT">307,860</td>
              </tr>
              <tr>
                <th scope="row">Sweden</th>
                <td align="RIGHT">449,964</td>
                <td align="RIGHT">410,928</td>
              </tr>
            </tbody>
          </table>

          <h2>Character test</h2>
          <p>
            The following table has some sample characters with annotations. If the browser&#8217;s
            default font does not contain all of them, they may get displayed using backup fonts.
            This may cause stylistic differences, but it should not prevent the characters from
            being displayed at all.
          </p>

          <hr title="Information about this document" />

          <address>
            <a href="../personal.html" hrefLang="en" lang="fi">
              Jukka Korpela
            </a>
          </address>
          <div>
            <a title="ISO 8601, the date and time representation standard" href="../iso8601.html">
              {" "}
              Date
            </a>{" "}
            of creation: 2000-09-15. Last update: 2013-03-21.
          </div>
        </div>
        <BrComponent path="footer"></BrComponent>
      </div>
    </BrComponent>
  );
}

export default GlobalStyleSheetLayout;
