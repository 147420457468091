import { useState, useLayoutEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { BrPageContext, BrComponentContext, BrManageContentButton } from "@bloomreach/react-sdk";
import HeaderToolbar from "../Common/HeaderToolbar/HeaderToolbar";
import LinkWrapper from "../Global/LinkWrapper";
import { GetGeneralLinkUrl, isAuthored } from "../../helpers/LinkHelper";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import Image from "../Global/Image/Image";
import { HEADING_LEVELS, PRIMARY, TERTIARY, TEXT_ALIGNMENT } from "../Global/GlobalConstants";
import "./ShopTheLook.scss";
import Title from "../Global/Title/Title";
import { IsTabletOrSmallerContent, SmallMedium } from "../../media-queries/MediaQueries";

function ShopTheLook() {
  const [contentHeight, setContentHeight] = useState("auto");
  const contentRef = useRef();
  const isTabletOrSmaller = IsTabletOrSmallerContent();

  let stlMediaHeight = 0;
  let stlMediaWidth = 0;

  function resizeImage() {
    if (isTabletOrSmaller) {
      setContentHeight("auto");
      return;
    }

    const actualContentHeight =
      contentRef && contentRef.current && contentRef.current.clientHeight
        ? contentRef.current.clientHeight
        : "auto";

    if (!stlMediaHeight) {
      setContentHeight(actualContentHeight);
    }
    else if (stlMediaHeight > actualContentHeight) {
      setContentHeight(stlMediaHeight);
    }
  }

  useLayoutEffect(()=> {
    resizeImage()
    window.addEventListener('resize', resizeImage);
    return () => window.removeEventListener('resize', resizeImage);
  }, [isTabletOrSmaller]);

  const page = useContext(BrPageContext);
  const component = useContext(BrComponentContext);

  try {
    const { document: documentRef } = component.getModels();
    const document = page.getContent(documentRef);

    if (!document) return null;

    const {
      callToActionLink = {},
      description = {},
      imageLink = "",
      imageAltText = "",
      specialLink = {},
      specialLinkDescription = "",
      title = {},
      video = ""
    } = document.getData();

    const {
      componentStyle = PRIMARY,
      mediaPosition = TEXT_ALIGNMENT.LEFT,
      mediaHeight = 0,
      mediaWidth = 0
    } = component.getParameters();

    stlMediaHeight = mediaHeight;
    stlMediaWidth = mediaWidth;

    const showCta = isAuthored(callToActionLink);
    const specialLinkUrl = GetGeneralLinkUrl(specialLink);
    const specialLinkAuthored = specialLinkUrl && specialLink.label;
    const headerTextFakeDiv = window.document.createElement('div');
    headerTextFakeDiv.innerHTML = page?.rewriteLinks(page.sanitize(title.value));
    const headerText = headerTextFakeDiv.textContent.replaceAll("\n\n", "\n");

    return (
      <div className={`shop-the-look shop-the-look--${componentStyle} ${specialLinkAuthored ? "with-message" : ""}`} >
        {specialLinkAuthored && (
          <div className="shop-the-look__message shop">
            <Link to={specialLinkUrl} className="shop-the-look__message__link">
              {specialLink.label}
            </Link>
            {specialLinkDescription && (
              <>
                &nbsp;
                <span className="shop-the-look__message__text">
                  {specialLinkDescription}
                </span>
              </>
            )}
          </div>
        )}
        <div className="shop-the-look__inner">
          <div className={`shop-the-look__banner shop-the-look__banner--${componentStyle} media-${mediaPosition}`} ref={contentRef}>

            {title.value && (
              <Title
                defaultLevel={HEADING_LEVELS.ONE}
                className={`shop-the-look__banner__header shop-the-look__banner__header--${componentStyle}`}
                aria-label={headerText && headerText.replaceAll("\n"," ")}
              >
                {headerText.replaceAll("\n","<br/>")}
              </Title>
            )}
            <span
              className={`shop-the-look__banner__description shop-the-look__banner__description--${componentStyle}`}
              dangerouslySetInnerHTML={{
                __html: page?.rewriteLinks(page.sanitize(description.value)),
              }}>
            </span>
            {showCta && (
              <LinkWrapper
                className={`shop-the-look__banner__cta btn btn--${componentStyle}`}
                link={callToActionLink}
              />
            )}
          </div>
          <div className={`shop-the-look__media media-${mediaPosition}`}>
            <Image
              className="shop-the-look__media__image"
              src={imageLink}
              alt={imageAltText}
              onLoad={resizeImage}
              style={stlMediaHeight ? {} : { height: contentHeight }}
              height={stlMediaHeight}
              width={stlMediaWidth}
            />
            <SmallMedium>
              <LinkWrapper
                className={`shop-the-look__media__cta btn btn--${componentStyle}`}
                link={{
                  label: "Shop these Products",
                  externalLink: "www.google.com"
                }}
              />
            </SmallMedium>
          </div>
        </div>
      </div>
    );
  } catch (e) {
    console.error("ShopTheLook error", e);
    return <ErrorComponent error={e} componentName={"ShopTheLook"} />;
  }
}

export default ShopTheLook;
