import React, { useState } from "react";
import { BrPageContext } from "@bloomreach/react-sdk";
import "./FooterLinkGroup.scss";

import { MobileFooter } from "../../media-queries/MediaQueries";
import { GetGeneralLinkUrl } from "../../helpers/LinkHelper";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

function FooterLinkGroup({ header, links, linkType }) {
  const [linksOpen, setLinksOpen] = useState(false);
  const sendDataToGTM = useGTMDispatch();

  const page = React.useContext(BrPageContext);

  const handleFooterLinkClick = (label) => {
    sendDataToGTM({
      event: "navigationClick",
      navigationType: "footer",
      navigationLevel: label,
    });
  };

  const items = links
    ? links.map((item, index) => {
        return (
          <li
            className="footer-link-group__nav-list-item"
            key={`${index.toString()}-${item.label}`}
          >
            <a
              onClick={() => handleFooterLinkClick(item.label)}
              href={GetGeneralLinkUrl(item, page)}
              className={`footer-link-group__nav-list-item-link ${
                linkType === "login" ? "footer-link-group__nav-list-item-login" : ""
              }`}
              target={item.newWindow ? "_blank" : "_self"}
              rel={item.newWindow ? "noopener noreferrer" : ""}
            >
              {linkType === "login" ? (
                <span className="footer-link-group__nav-list-item-link-icon darticon-account"></span>
              ) : (
                ""
              )}
              {item.label}
            </a>
          </li>
        );
      })
    : null;

      function toggleItems(e) {
        setLinksOpen(!linksOpen);
      }
    let classNameLinks = linksOpen ? "darticon-arrow-up" : "darticon-arrow-down";
    let classNameList = linksOpen ? "" : "footer-link-group__nav-list--display-toggle";
    classNameLinks += " footer-link-group__header-icon";
    classNameList += " footer-link-group__nav-list";
  return (
    <>
      {header && links && links.length > 0 && (
        <div className="footer-link-group">
          {header && linkType === "login" ? (
            <MobileFooter>
                 <button className="footer-link-group__header footer-link-group__header--mobile footer--font-white " onClick={toggleItems}>
                  <span className="footer-link-group__header-text">{header}</span>
                  <span className={classNameLinks}></span>
                </button>
                <div className="footer-link-group__header footer-link-group__header--desktop footer--font-white">
                    <span className="footer-link-group__header-text">{header}</span>
                    <span className={classNameLinks}></span>
                </div>
            </MobileFooter>
          ) : (
            <div>
                <button className="footer-link-group__header footer-link-group__header--mobile footer--font-white " onClick={toggleItems}>
                    <span className="footer-link-group__header-text">{header}</span>
                    <span className={classNameLinks}></span>
                </button>
                <div className="footer-link-group__header footer-link-group__header--desktop footer--font-white">
                    <h3 className="footer-link-group__header-text">{header}</h3>
                    <span className={classNameLinks}></span>
                </div>
            </div>
          )}
           <ul className={classNameList}>
            {items && items}
          </ul>
        </div>
      )}
    </>
  );
}

export default FooterLinkGroup;
