import {
  isWidenResource,
  stripWidenQueryDimensionParams,
  hasDimensionsToOverride,
  replaceWidenQueryRetina,
  replaceWidenQueryQuality
} from "./helpers";

const getDimensionStyles = (width, height) => {
  const dimensions = {};

  if (width !== 0) {
    dimensions.width = width;
  }

  if (height !== 0) {
    dimensions.height = height;
  }

  return dimensions;
};

function Image({
  src = "",
  alt = "",
  width = 0,
  height = 0,
  loading = "lazy",
  imageRef = null,
  id = null,
  overrideDimensions = null,
  ...props
}) {
  let source = src;
  if (source) source = source.replace("/jpeg/", "/webp/");
  if (source) source = source.replace("/png/", "/webp/");
  let dimensions;

  const isWidenImage = isWidenResource(src);

  if (isWidenImage) {
    source = stripWidenQueryDimensionParams(source);

    if (hasDimensionsToOverride(width, height)) {
      source = source + `&w=${width}&h=${height}`;
    }

    source = replaceWidenQueryRetina(source, "true");
    source = replaceWidenQueryQuality(source, "100");
  }

  if(overrideDimensions) {
    if(overrideDimensions.width === 0 && overrideDimensions.height === 0) {
      overrideDimensions = null;
    }
    else {
      if(overrideDimensions.width === 0) {
        overrideDimensions.width = "auto";
      }
      if(overrideDimensions.height === 0) {
        overrideDimensions.height = "auto";
      }
    }
  }

  if (!overrideDimensions && !isWidenImage && hasDimensionsToOverride(width, height)) {
    dimensions = getDimensionStyles(width, height);
  }

  return (
    <img id={id} ref={imageRef} src={source} alt={alt} style={dimensions || overrideDimensions} loading={loading} {...props} />
  );
}

export default Image;
