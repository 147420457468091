import { useState, useContext } from "react";
import { BrPageContext } from "@bloomreach/react-sdk";
import CtaLink from "../Global/CtaLink";
import { IsMobileNav } from "../../media-queries/MediaQueries";
import { MARKETFLYOUTTYPE, PRODUCTFLYOUTTYPE } from "../Global/GlobalConstants";
import NavigationProductTile from "./NavigationProductTile";
import NavigationArticleTile from "./NavigationArticleTile";
import { useOverrideDimensions } from "./hooks";
import NavigationContext from "./context";
import "./HeaderComplexFlyout.scss";

function HeaderComplexFlyout({
  flyoutHeaderLabel,
  flyoutRef,
  closeActiveSubNav,
  setMenuOpen,
  type,
}) {
  const page = useContext(BrPageContext);
  const navContext = useContext(NavigationContext);

  const {
    featuredArticlesMobileWidth,
    featuredArticlesMobileHeight,
    featuredArticlesDesktopWidth,
    featuredArticlesDesktopHeight,
    featuredProductMobileWidth,
    featuredProductMobileHeight,
    featuredProductDesktopWidth,
    featuredProductDesktopHeight,
  } = useOverrideDimensions();

  const _isMobileNav = IsMobileNav();
  const [activeSubNavIndex, setActiveSubNavIndex] = useState(-1);
  const flyoutRefDocument = page.getContent(flyoutRef);

  // Handles Link Click
  // If internal link, close hamburger menu and reset to primary nav list
  const handleLinkClick = (item) => {
    if (setMenuOpen) {
      setMenuOpen(false);
    }
    closeActiveSubNav("");
  };

  const {
    marketSegmentNavItems = null,
    popularArticleLinkPosition1 = null,
    popularArticleLinkPosition2 = null,
    popularArticleLinkPosition3 = null,
    popularArticlesHeader = null,
    productNavItems = null,
    popularProductsHeader = null,
    popularProductLinkPosition1 = null,
    popularProductLinkPosition2 = null,
    popularProductLinkPosition3 = null,
    viewAllProductsLink = null,
  } = flyoutRefDocument.getData();

  const headerPopularProducts = navContext.flyoutPopularProducts;

  //get each of the articles if they exists
  const flyoutRefpopularArticleLinkPosition1Document = popularArticleLinkPosition1
    ? page.getContent(popularArticleLinkPosition1)
    : null;
  const article1ArticleDetails = flyoutRefpopularArticleLinkPosition1Document
    ? flyoutRefpopularArticleLinkPosition1Document.getData()
    : null;

  const flyoutRefpopularArticleLinkPosition2Document = popularArticleLinkPosition2
    ? page.getContent(popularArticleLinkPosition2)
    : null;
  const article2ArticleDetails = flyoutRefpopularArticleLinkPosition2Document
    ? flyoutRefpopularArticleLinkPosition2Document.getData()
    : null;

  const flyoutRefpopularArticleLinkPosition3Document = popularArticleLinkPosition3
    ? page.getContent(popularArticleLinkPosition3)
    : null;
  const article3ArticleDetails = flyoutRefpopularArticleLinkPosition3Document
    ? flyoutRefpopularArticleLinkPosition3Document.getData()
    : null;

  //get each of the products if they exists
  const flyoutRefpopularProductLinkPosition1Document = popularProductLinkPosition1
    ? page.getContent(popularProductLinkPosition1)
    : null;
  const product1ProductDetails = flyoutRefpopularProductLinkPosition1Document
    ? flyoutRefpopularProductLinkPosition1Document.getData()
    : null;

  const flyoutRefpopularProductLinkPosition2Document = popularProductLinkPosition2
    ? page.getContent(popularProductLinkPosition2)
    : null;
  const product2ProductDetails = flyoutRefpopularProductLinkPosition2Document
    ? flyoutRefpopularProductLinkPosition2Document.getData()
    : null;

  const flyoutRefpopularProductLinkPosition3Document = popularProductLinkPosition3
    ? page.getContent(popularProductLinkPosition3)
    : null;
  const product3ProductDetails = flyoutRefpopularProductLinkPosition3Document
    ? flyoutRefpopularProductLinkPosition3Document.getData()
    : null;

  //display article tile
  function articleDisplay(article, document, isMobile) {
    const articleLink = document.getUrl();

    const {
      articleDetails: { primaryTitle, description, link },
      promoImage,
    } = article;

    const handleClick = () => handleLinkClick(link);

    // Values mapped as models to 'header' component are being returned as strings
    // Convert them to ints
    const width = Number(isMobile ? featuredArticlesMobileWidth : featuredArticlesDesktopWidth);
    const height = Number(isMobile ? featuredArticlesMobileHeight : featuredArticlesDesktopHeight);

    return (
      <NavigationArticleTile
        pageLink={articleLink}
        title={primaryTitle}
        description={description}
        image={promoImage}
        imageWidth={width}
        imageHeight={height}
        link={link}
        onClick={handleClick}
      />
    );
  }

  //display product tile
  function productDisplay(product, isMobile) {
    //with the documents popular products we get the header level popular product that matches the code
    //get pop articles and normalize them to be like the other returned articles
    let productInfo = null;

    const width = Number(isMobile ? featuredProductMobileWidth : featuredProductDesktopWidth);
    const height = Number(isMobile ? featuredProductMobileHeight : featuredProductDesktopHeight);
    if (
      product.productCode &&
      headerPopularProducts &&
      headerPopularProducts[product.productCode]
    ) {
      productInfo = {
        content_type: "product",
        id: product.productCode,
        title: headerPopularProducts[product.productCode].childProductName,
        thumb_image: headerPopularProducts[product.productCode].catalogImage,
        url: headerPopularProducts[product.productCode].url,
        isProPlanet: headerPopularProducts[product.productCode].isProPlanet,
        isCompostable: headerPopularProducts[product.productCode].isCompostable
      };
    }
    
    if (productInfo && productInfo.title) {
      return (
        <NavigationProductTile
          product={{
            id: product.productCode,
            name: productInfo.title,
            location: productInfo.url,
            image: productInfo.thumb_image,
            imageWidth: width,
            imageHeight: height,
            isProPlanet: productInfo.isProPlanet,
            isNewProduct: productInfo.isNewProduct,
            isPrintable: productInfo.isPrintable,
            isEmbossable: productInfo.isEmbossable,
            isCompostable: productInfo.isCompostable,
            onClickProduct: () => {
              if (setMenuOpen) setMenuOpen(false);
              if (closeActiveSubNav) closeActiveSubNav("");
            },
          }}
          isPreview={page.isPreview()}
        ></NavigationProductTile>
      );
    } else {
      return null;
    }
  }
  //puts the html into sub nav
  function outputSubNavItem() {
    //don't show if not active
    if (activeSubNavIndex === -1) {
      return null;
    }

    let item;
    if (type === PRODUCTFLYOUTTYPE) {
      item = productNavItems[activeSubNavIndex];
    }
    if (type === MARKETFLYOUTTYPE) {
      item = marketSegmentNavItems[activeSubNavIndex];
    }

    const subNavRefpopularArticleLinkPosition1Document =
      item && item.popularArticleLinkPosition1
        ? page.getContent(item.popularArticleLinkPosition1)
        : null;
    const subNavArticle1ArticleDetails = subNavRefpopularArticleLinkPosition1Document
      ? subNavRefpopularArticleLinkPosition1Document.getData()
      : null;

    const subNavRefpopularArticleLinkPosition2Document =
      item && item.popularArticleLinkPosition2
        ? page.getContent(item.popularArticleLinkPosition2)
        : null;
    const subNavArticle2ArticleDetails = subNavRefpopularArticleLinkPosition2Document
      ? subNavRefpopularArticleLinkPosition2Document.getData()
      : null;

    const subNavRefpopularProductLinkPosition1Document =
      item && item.popularProductLinkPosition1
        ? page.getContent(item.popularProductLinkPosition1)
        : null;
    const subNavPopularProduct1ProductDetails = subNavRefpopularProductLinkPosition1Document
      ? subNavRefpopularProductLinkPosition1Document.getData()
      : null;

    const subNavRefpopularProductLinkPosition2Document =
      item && item.popularProductLinkPosition2
        ? page.getContent(item.popularProductLinkPosition2)
        : null;
    const subNavPopularProduct2ProductDetails = subNavRefpopularProductLinkPosition2Document
      ? subNavRefpopularProductLinkPosition2Document.getData()
      : null;

    return (
      <div className="complex-flyout__sub-nav-wrapper">
        <div className="complex-flyout__header-wrapper">
          <div className="complex-flyout__header-line-wrapper">
            <button className="complex-flyout__back-btn" onClick={() => setActiveSubNavIndex(-1)}>
              <span className="complex-flyout__back-btn-icon darticon-arrow-back"></span>
            </button>
            <span className="complex-flyout__header">{item.navItemLink.label}</span>
          </div>
        </div>
        <div className="complex-flyout__content-wrapper">
          <div className="complex-flyout__list-wrapper">
            {item.childLinks && (
              <ul className="complex-flyout__list">
                {item.childLinks.map((item, index) => (
                  <li className="complex-flyout__list-item" key={`${index}_sub_${item.label}`}>
                    <CtaLink
                      link={item}
                      className={`complex-flyout__list-item-link`}
                      onClick={() => handleLinkClick(item)}
                    />
                  </li>
                ))}
                {/* //need to test if viewall link has a label else don't display */}
                {item.viewAllLink && item.viewAllLink.label && (
                  <li className="complex-flyout__list-item">
                    <CtaLink
                      link={item.viewAllLink}
                      className={`complex-flyout__list-item-link`}
                      onClick={() => handleLinkClick(item.viewAllLink)}
                    />
                  </li>
                )}
              </ul>
            )}
          </div>
          <div className="complex-flyout__articles-popular-wrapper">
            {(popularProductsHeader || popularArticlesHeader) &&
              (subNavPopularProduct1ProductDetails ||
                subNavPopularProduct2ProductDetails ||
                subNavArticle1ArticleDetails ||
                subNavArticle2ArticleDetails) && (
                <span className="complex-flyout__articles-popular-header">
                  {type === MARKETFLYOUTTYPE && popularArticlesHeader}
                  {type === PRODUCTFLYOUTTYPE && popularProductsHeader}
                </span>
              )}
            {item.popularArticleLinkPosition1 &&
              subNavArticle1ArticleDetails &&
              articleDisplay(
                subNavArticle1ArticleDetails,
                subNavRefpopularArticleLinkPosition1Document,
                _isMobileNav
              )}
            {item.popularArticleLinkPosition2 &&
              subNavArticle2ArticleDetails &&
              articleDisplay(
                subNavArticle2ArticleDetails,
                subNavRefpopularArticleLinkPosition2Document,
                _isMobileNav
              )}
            {(subNavPopularProduct1ProductDetails || subNavPopularProduct2ProductDetails) && (
              <div className="complex-flyout__articles-popular-items-wrapper">
                {subNavPopularProduct1ProductDetails &&
                  productDisplay(subNavPopularProduct1ProductDetails, _isMobileNav)}
                {subNavPopularProduct2ProductDetails &&
                  productDisplay(subNavPopularProduct2ProductDetails, _isMobileNav)}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  let navItems;
  if (type === PRODUCTFLYOUTTYPE) {
    navItems = productNavItems;
  }
  if (type === MARKETFLYOUTTYPE) {
    navItems = marketSegmentNavItems;
  }

  const renderCTALink = (link, tabIndex) => (
     <CtaLink
       link={link}
       tabIndex={tabIndex}
       className={`complex-flyout__list-item-link`}
       onClick={() => handleLinkClick(link)}
     />
  )

  const renderArrow = (bool) =>{
    let str = 'darticon-arrow';
    if(!bool) str += "-down"
    return <span className={`complex-flyout__list-item-link-icon ${str}`} aria-hidden="true"></span>
  }

  const renderNavItems = () => {
    return navItems.map((item, index) => {
        const isActive = index === activeSubNavIndex;
        const label = item.navItemLink.label;
        const key = `${index}_${label}`
        const hasChildren = item.childLinks && item.childLinks.length > 0;

        if(!isActive && hasChildren){
            return (
                <div className={`complex-flyout__list-item`} key={key}>
                     <button
                       className={`complex-flyout__list-item-link`}
                       tabIndex={activeSubNavIndex === -1 ? 0 : -1}
                       aria-hidden={activeSubNavIndex !== -1}
                       aria-label={label}
                       onClick={() => {
                                setActiveSubNavIndex(index);
                                setTimeout(()=>{},100);
                            }
                        }

                       aria-expanded={false}
                     >
                       {label}
                       {renderArrow(true)}
                     </button>
                </div>
            )
        }

        if(isActive && hasChildren){
             return (
                 <div className={`complex-flyout__list-item active complex-flyout__list-item--grid`} key={key}>
                     <button
                       className={`complex-flyout__list-item-link`}
                       aria-expanded={true}
                       aria-label={label}
                       onClick={() => setActiveSubNavIndex(-1)}
                     >
                       <span aria-hidden="true">{label}</span>
                       {renderArrow(false)}
                     </button>
                 </div>
             )
        }

        return (
            <div className={`complex-flyout__list-item`} key={key} aria-hidden={activeSubNavIndex !== -1}>
                {renderCTALink(item.navItemLink, activeSubNavIndex !== -1 ? -1 : 0)}
            </div>
        )
     })
  }



  return (
    <div className={`complex-flyout`}>
      <div className="complex-flyout__header-wrapper">
        <div className="complex-flyout__header-line-wrapper">
          <button className="complex-flyout__back-btn" onClick={() => closeActiveSubNav("")}>
            <span className="complex-flyout__back-btn-icon darticon-arrow-back"></span>
          </button>
          <span className="complex-flyout__header">{flyoutHeaderLabel}</span>
        </div>
      </div>
      <div className="complex-flyout__content-wrapper">
        <div className="complex-flyout__list-wrapper">
          {navItems && (
            <div className="complex-flyout__list">
                {renderNavItems()}
            </div>
          )}
        </div>
        {activeSubNavIndex !== -1 && outputSubNavItem()}
        {activeSubNavIndex === -1 && (
          <div className="complex-flyout__articles-popular-wrapper">
            {(popularProductsHeader || popularArticlesHeader) &&
              (popularProductLinkPosition1 ||
                popularProductLinkPosition2 ||
                popularProductLinkPosition3 ||
                popularArticleLinkPosition1 ||
                popularArticleLinkPosition2 ||
                popularArticleLinkPosition3) && (
                <h3 className="complex-flyout__articles-popular-header">
                  {type === MARKETFLYOUTTYPE && popularArticlesHeader}
                  {type === PRODUCTFLYOUTTYPE && popularProductsHeader}
                </h3>
              )}
            {popularArticleLinkPosition1 &&
              article1ArticleDetails &&
              articleDisplay(
                article1ArticleDetails,
                flyoutRefpopularArticleLinkPosition1Document,
                _isMobileNav
              )}
            {popularArticleLinkPosition2 &&
              article2ArticleDetails &&
              articleDisplay(
                article2ArticleDetails,
                flyoutRefpopularArticleLinkPosition2Document,
                _isMobileNav
              )}
            {popularArticleLinkPosition3 &&
              article3ArticleDetails &&
              articleDisplay(
                article3ArticleDetails,
                flyoutRefpopularArticleLinkPosition3Document,
                _isMobileNav
              )}

            {(popularProductLinkPosition1 ||
              popularProductLinkPosition2 ||
              popularProductLinkPosition3) && (
              <div className="complex-flyout__articles-popular-items-wrapper complex-flyout__articles-popular-items-wrapper--full">
                {product1ProductDetails && productDisplay(product1ProductDetails, _isMobileNav)}
                {product2ProductDetails && productDisplay(product2ProductDetails, _isMobileNav)}
                {product3ProductDetails && productDisplay(product3ProductDetails, _isMobileNav)}
              </div>
            )}
          </div>
        )}
      </div>
      {viewAllProductsLink && (
        <div className="complex-flyout__view-all-link-wrapper">
          <CtaLink
            onClick={() => handleLinkClick(viewAllProductsLink)}
            link={viewAllProductsLink}
            iconPost="darticon-arrow"
            aria-hidden="true"
            className="complex-flyout__view-all-link"
          />
        </div>
      )}
    </div>
  );
}

export default HeaderComplexFlyout;
