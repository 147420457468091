import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import NavigationSearch from "./NavigationSearch";
import CtaLink from "../Global/CtaLink";
import Flyout from "./Flyout";
import Image from "../Global/Image/Image";
import { useOverrideDimensions } from "./hooks";
import "./MobileNavigation.scss";
import { GetChannelRoot } from "../../helpers/LinkHelper";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

const NAV_ONE = "nav-1";
const NAV_TWO = "nav-2";
const NAV_THREE = "nav-3";
const NAV_FOUR = "nav-4";

function MobileNavigation({ navDocument }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [activeNav, setActiveNav] = useState("");
  const isFirstRender = useRef(true);

  const sendDataToGTM = useGTMDispatch();

  useEffect(() => {
    //if menu is open in mobile we turn off scrolling
    if (menuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    if (isFirstRender.current) {
      isFirstRender.current = false; // toggle flag after first render/mounting
      return;
    }
  });

  const {
    logoAltText = null,
    logo = null,
    mainMenuLinkLabelOne = null,
    mainMenuLinkLabelTwo = null,
    mainMenuLinkLabelThree = null,
    mainMenuLinkLabelFour = null,
    regionSelectLink = null,
    howToBuyLink = null,
    viewMyListLink = null,
    mainMenuLinkFlyoutOne = null,
    mainMenuLinkFlyoutTwo = null,
    mainMenuLinkFlyoutThree = null,
    mainMenuLinkFlyoutFour = null,
    searchButtonLabel = null,
  } = navDocument.getData();

  const { logoWidth = 0, logoHeight = 0 } = useOverrideDimensions();
  const mobileHeaderLogoWidth = "125";
  const mobileHeaderLogoHeight = "45";

  // Handles Link Click
  // If internal link, close hamburger menu and reset to primary nav list
  const handleLinkClick = (item) => {
    if (item.internalLink) {
      if (setMenuOpen) {
        setMenuOpen(false);
      }
      setActiveNav("");
    }
  };
  //set active nav item, or if same nav item unset
  const _toggleNavItems = (navItem) => {
    if (activeNav === navItem) {
      setActiveNav("");
    } else {
      setActiveNav(navItem);
    }
  };

  // Toggle Hamburger Menu
  const toggleHamburgerMenu = () => {
    setMenuOpen(!menuOpen);

    // If menuOpen = false, reset to primary nav list
    if (menuOpen) {
      setActiveNav("");
    }
  };

  const sendMobileHeaderGtm = (level) => {
    sendDataToGTM({
      event: "navigationClick",
      navigationType: "header",
      navigationLevel: level,
    });
  };

  let howToBuyCheck = true;
  if (!howToBuyLink.internalLink && !howToBuyLink.externalLink) {
    howToBuyCheck = false;
  } else if (!howToBuyLink.label) {
    howToBuyCheck = false;
  }

  return (
    <div className={`mobile-navigation`}>
      <div className="mobile-navigation__top-bar">
        <div className="mobile-navigation__top-bar-wrapper">
          <button
            className={`mobile-navigation__hamburger ${
              !menuOpen ? "darticon-menu" : "darticon-close"
            } `}
            id="goTop"
            onClick={toggleHamburgerMenu}
            aria-label={menuOpen ? "Close Menu" : "Open Menu"}
          ></button>
          <Link
            to={GetChannelRoot}
            onClick={() => {
              setMenuOpen(false);
              setActiveNav("");
            }}
          >
            <Image
              className="mobile-navigation__logo"
              src={logo}
              alt={logoAltText}
              width={Number(logoWidth) === 0 ? mobileHeaderLogoWidth : logoWidth}
              height={Number(logoHeight) === 0 ? mobileHeaderLogoHeight : logoHeight}
            />
          </Link>
          <button
            className="mobile-navigation__search darticon-headersearch"
            onClick={() => {
              sendMobileHeaderGtm(searchButtonLabel);
              setSearchOpen(!searchOpen);
            }}
            aria-label={searchOpen ? "Close Searchbar" : "Open Searchbar"}
          ></button>
        </div>
      </div>
      {menuOpen && (
        <nav className="mobile-navigation__nav">
          <div className="mobile-navigation__nav-wrapper">
            <ul className="mobile-navigation__nav-list">
              {mainMenuLinkLabelOne && (
                <li className="mobile-navigation__nav-list-item">
                  <button
                    className="mobile-navigation__nav-list-item-link"
                    onClick={() => {
                      sendMobileHeaderGtm(mainMenuLinkLabelOne);
                      _toggleNavItems(NAV_ONE);
                    }}
                  >
                    {mainMenuLinkLabelOne}
                    <span className="mobile-navigation__nav-list-item-link-icon darticon-arrow"></span>
                  </button>
                </li>
              )}
              {mainMenuLinkLabelTwo && (
                <li className="mobile-navigation__nav-list-item">
                  <button
                    className="mobile-navigation__nav-list-item-link"
                    onClick={() => {
                      sendMobileHeaderGtm(mainMenuLinkLabelTwo);
                      _toggleNavItems(NAV_TWO);
                    }}
                  >
                    {mainMenuLinkLabelTwo}
                    <span className="mobile-navigation__nav-list-item-link-icon darticon-arrow"></span>
                  </button>
                </li>
              )}
              {mainMenuLinkLabelThree && (
                <li className="mobile-navigation__nav-list-item">
                  <button
                    className="mobile-navigation__nav-list-item-link"
                    onClick={() => {
                      sendMobileHeaderGtm(mainMenuLinkLabelThree);
                      _toggleNavItems(NAV_THREE);
                    }}
                  >
                    {mainMenuLinkLabelThree}
                    <span className="mobile-navigation__nav-list-item-link-icon darticon-arrow"></span>
                  </button>
                </li>
              )}
              {mainMenuLinkLabelFour && (
                <li className="mobile-navigation__nav-list-item">
                  <button
                    className="mobile-navigation__nav-list-item-link"
                    onClick={() => {
                      sendMobileHeaderGtm(mainMenuLinkLabelFour);
                      _toggleNavItems(NAV_FOUR);
                    }}
                  >
                    {mainMenuLinkLabelFour}
                    <span className="mobile-navigation__nav-list-item-link-icon darticon-arrow"></span>
                  </button>
                </li>
              )}
            </ul>
            <ul className="mobile-navigation__utility-list">
              {regionSelectLink && (
                <li className="mobile-navigation__utility-list-item">
                  <CtaLink
                    link={regionSelectLink}
                    icon="mobile-navigation__utility-list-item-link-icon darticon-region"
                    className={`mobile-navigation__utility-list-item-link`}
                    onClick={() => handleLinkClick(regionSelectLink)}
                  />
                </li>
              )}
              {howToBuyCheck && (
                <li className="mobile-navigation__utility-list-item">
                  <CtaLink
                    link={howToBuyLink}
                    icon="mobile-navigation__utility-list-item-link-icon darticon-webpages"
                    className={`mobile-navigation__utility-list-item-link`}
                    onClick={() => handleLinkClick(howToBuyLink)}
                  />
                </li>
              )}
              {viewMyListLink && (
                <li className="mobile-navigation__utility-list-item">
                  <CtaLink
                    link={viewMyListLink}
                    icon="mobile-navigation__utility-list-item-link-icon darticon-viewlist"
                    className={`mobile-navigation__utility-list-item-link`}
                    onClick={() => handleLinkClick(viewMyListLink)}
                  />
                </li>
              )}
            </ul>
            {activeNav && (
              <div className="mobile-navigation__flyout-wrapper">
                {activeNav === NAV_ONE && mainMenuLinkFlyoutOne && (
                  <Flyout
                    flyoutHeaderLabel={mainMenuLinkLabelOne}
                    flyoutRef={mainMenuLinkFlyoutOne}
                    closeActiveSubNav={setActiveNav}
                    setMenuOpen={setMenuOpen}
                  />
                )}
                {activeNav === NAV_TWO && mainMenuLinkFlyoutTwo && (
                  <Flyout
                    flyoutHeaderLabel={mainMenuLinkLabelTwo}
                    flyoutRef={mainMenuLinkFlyoutTwo}
                    closeActiveSubNav={setActiveNav}
                    setMenuOpen={setMenuOpen}
                  />
                )}
                {activeNav === NAV_THREE && mainMenuLinkFlyoutThree && (
                  <Flyout
                    flyoutHeaderLabel={mainMenuLinkLabelThree}
                    flyoutRef={mainMenuLinkFlyoutThree}
                    closeActiveSubNav={setActiveNav}
                    setMenuOpen={setMenuOpen}
                  />
                )}
                {activeNav === NAV_FOUR && mainMenuLinkFlyoutFour && (
                  <Flyout
                    flyoutHeaderLabel={mainMenuLinkLabelFour}
                    flyoutRef={mainMenuLinkFlyoutFour}
                    closeActiveSubNav={setActiveNav}
                    setMenuOpen={setMenuOpen}
                  />
                )}
              </div>
            )}
          </div>
        </nav>
      )}
      {searchOpen && (
        <div className="mobile-navigation__search-wrapper">
          <NavigationSearch
            setSearchOpen={setSearchOpen}
            navDocument={navDocument}
          ></NavigationSearch>
        </div>
      )}
    </div>
  );
}

export default MobileNavigation;
