import { useSearchToolbarContext } from "./hooks";
import "./SearchSortSelector.scss";

function SearchSortSelector() {
  const {
    sort: { hide, currentValue, options, handleChange },
  } = useSearchToolbarContext();
  if (hide) return null;

  const { sortByLabel = "" } = window.globalProperties || {};

  return (
    <div className="sort-by bold-extra-bold">
      <fieldset className="sort-by__select-set">
        <label htmlFor="sort-select" className="sort-by__select__label">
          {sortByLabel && `${sortByLabel}:`}
        </label>
        <div className="sort-by__select-wrapper">
          <select
            id="sort-select"
            name="sort-select"
            className="sort-by__select"
            onChange={handleChange}
            value={currentValue}
          >
            {options.length > 0 &&
              options
                .filter((item) => item.value !== null && item.display !== null)
                .map(({ value, display }) => {
                  return (
                    <option className="sort-by__select__option" key={value} value={value}>
                      {display}
                    </option>
                  );
                })}
          </select>
        </div>
      </fieldset>
    </div>
  );
}

export default SearchSortSelector;
