import { useRef, useState, useEffect } from "react";
import { HEADING_LEVELS } from "../GlobalConstants";

function Title({ defaultLevel, headingLevel, children, ...props }) {
  const [headerValue, setHeaderValue] = useState(children);
  const level = headingLevel || defaultLevel;

  const hRef = useRef();

  let addSuperScriptAroundSpecialCharacters = false;

  useEffect(() => {
    setHeaderValue(children)
    let heading = hRef.current;

    if (heading) {
      addSuperScriptAroundSpecialCharacters = window
        .getComputedStyle(heading)
        .getPropertyValue("font-family")
        .toLowerCase()
        .includes("bicyclette");

      if (addSuperScriptAroundSpecialCharacters && children) {
        setHeaderValue(children.replaceAll("®", "<sup>®</sup>").replaceAll("™", "<sup>™</sup>"));
      }
    }
  });

  switch (level) {
    case HEADING_LEVELS.SIX:
      return (
        <h6
          ref={hRef}
          {...props}
          dangerouslySetInnerHTML={{
            __html: headerValue,
          }}
        ></h6>
      );
    case HEADING_LEVELS.FIVE:
      return (
        <h5
          ref={hRef}
          {...props}
          dangerouslySetInnerHTML={{
            __html: headerValue,
          }}
        ></h5>
      );
    case HEADING_LEVELS.FOUR:
      return (
        <h4
          ref={hRef}
          {...props}
          dangerouslySetInnerHTML={{
            __html: headerValue,
          }}
        ></h4>
      );
    case HEADING_LEVELS.THREE:
      return (
        <h3
          ref={hRef}
          {...props}
          dangerouslySetInnerHTML={{
            __html: headerValue,
          }}
        ></h3>
      );
    case HEADING_LEVELS.TWO:
      return (
        <h2
          ref={hRef}
          {...props}
          dangerouslySetInnerHTML={{
            __html: headerValue,
          }}
        ></h2>
      );
    case HEADING_LEVELS.ONE:
    default:
      return (
        <h1
          ref={hRef}
          {...props}
          dangerouslySetInnerHTML={{
            __html: headerValue,
          }}
        ></h1>
      );
  }
}

export default Title;
