import { useContext } from "react";
import { BrPageContext, BrComponentContext } from "@bloomreach/react-sdk";
import LinkWrapper from "../Global/LinkWrapper";
import "./RegionSelector.scss";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import { isAuthored } from "../../helpers/LinkHelper";
import Title from "../Global/Title/Title";
import { HEADING_LEVELS } from "../Global/GlobalConstants";
import sanitizeHtml from 'sanitize-html'

// If label matches 'Country (Language)' format, style accordingly
const WITH_LANGUAGE_PATTERN = /^(?:.+\s)+(?<lang>\(.*\))$/;

function RegionColumnListItem({ link = {} }) {
  const page = useContext(BrPageContext);
  const { label = "" } = link;

  // Check if new string matches
  const matches = label.match(WITH_LANGUAGE_PATTERN);

  // Check if internal or external link exists
  const hasLink = link.internalLink || link.externalLink ? "" : "no-link";

  if (matches) {
    const { lang = "" } = matches.groups;

    const styledLanguage = `<span>${lang}</span>`;
    const styledLabel = label.replace(lang, styledLanguage);

    return (
      <li className="region-selector__regions__column__list__item 1">
        <LinkWrapper link={link}>
          <p
            className={`region-selector__regions__column__list__item__link ${hasLink}`}
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(styledLabel),
            }}
          />
        </LinkWrapper>
      </li>
    );
  }

  return (
    <li className="region-selector__regions__column__list__item 2">
      <LinkWrapper
        className={`region-selector__regions__column__list__item__link ${hasLink}`}
        link={link}
      >
        {label}
      </LinkWrapper>
    </li>
  );
}

function RegionColumn({ header = "", links = [] }) {
  //create array of links with an authored label and internal or external link
  const authoredLinks = [];
  links.forEach((item) => {
    if (isAuthored(item)) {
      authoredLinks.push(item);
    }
  });

  // If authoredLinks is empty, don't return that column
  if (authoredLinks.length === 0) return null;

  // Else if, at least one link, return column
  return (
    <div className="region-selector__regions__column">
      {header && (
        <Title
          defaultLevel={HEADING_LEVELS.FIVE}
          className="region-selector__regions__column__header bold-bold"
        >
          {header}
        </Title>
      )}
      {authoredLinks.length > 0 && (
        <ul className="region-selector__regions__column__list">
          {authoredLinks.map((link, index) => {
            return <RegionColumnListItem key={index} link={link} />;
          })}
        </ul>
      )}
    </div>
  );
}

function RegionSelector() {
  const page = useContext(BrPageContext);
  const component = useContext(BrComponentContext);

  try {
    const { document: documentRef } = component.getModels();
    const document = page.getContent(documentRef);
    if (!document) return null;

    const {
      title = null,
      subTitle = null,
      column1Header = null,
      column1Links = null,
      column2Header = null,
      column2Links = null,
      column3Header = null,
      column3Links = null,
    } = document.getData();

    const getRegion = (header, links = []) => {
      if (header && links.length > 0) {
        return { header, links };
      }

      return null;
    };

    const regions = [
      getRegion(column1Header, column1Links),
      getRegion(column2Header, column2Links),
      getRegion(column3Header, column3Links),
    ].filter((region) => region);

    return (
      <div className="region-selector__wrapper">
        <div className="region-selector content-bound">
          {title && (
            <Title defaultLevel={HEADING_LEVELS.ONE} className="region-selector__title">
              {title}
            </Title>
          )}
          {subTitle && <p className="region-selector__subtitle bold-extra-bold">{subTitle}</p>}
          {regions.length > 0 && (
            <div className="region-selector__regions">
              {regions.map(({ header, links }) => {
                return <RegionColumn key={header} header={header} links={links} />;
              })}
            </div>
          )}
        </div>
      </div>
    );
  } catch (e) {
    console.error("RegionSelector error", e);
    return <ErrorComponent error={e} componentName={"RegionSelector"} />;
  }
}

export default RegionSelector;
