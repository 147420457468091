import { SAVED_PRODUCTS, regexpLocale, SAVED_PRODUCTS_MUTATED_EVENT_NAME } from "../../components/Global/GlobalConstants";
const { KEY, DELIMETER } = SAVED_PRODUCTS;

// add and retrieve separate local storage items based on the channel
// example keys: "saved:products", "saved:products:en-ca"
let matchLocale = window.location.pathname.split("/")[1].match(regexpLocale) || "";
if (matchLocale) matchLocale = ":" + matchLocale;

export default function SavedProducts() {
    const add = async (productCode) => {
        try {
            const codes = localStorage.getItem(KEY + matchLocale);

            if (!codes) {
                localStorage.setItem(KEY + matchLocale, productCode);
                dispatchUpdateEvent();
            } else {
                const set = new Set(codes.split(DELIMETER));

                if (!set.has(productCode)) {
                    set.add(productCode);
                    localStorage.setItem(KEY + matchLocale, [...set].join(DELIMETER));
                    dispatchUpdateEvent();
                }
            }
        } catch (e) {
            throw e;
        }
    };
    
    const remove = async function (productCode) {
        try {
            const codes = localStorage.getItem(KEY + matchLocale);

            if (!codes) {
                localStorage.removeItem(KEY + matchLocale);
                dispatchUpdateEvent();
            } else {
                const saved = codes
                    .split(DELIMETER)
                    .filter((code) => code !== productCode)
                    .join(DELIMETER);
                localStorage.setItem(KEY + matchLocale, saved);
                dispatchUpdateEvent();
            }
        } catch (e) {
            throw e;
        }
    };
    
    const removeAll = function() {
        localStorage.removeItem(KEY + matchLocale);
        dispatchUpdateEvent();
    };
    
    const has = function (productCode) {
        const set = new Set(localStorage.getItem(KEY + matchLocale)?.split(DELIMETER));

        return set.has(productCode);
    };

    const toString = function () {
        const codes = localStorage.getItem(KEY + matchLocale);

        if (codes) return codes;

        return "";
    };

    function dispatchUpdateEvent() {
        const event = new CustomEvent(SAVED_PRODUCTS_MUTATED_EVENT_NAME);

        window.dispatchEvent(event);
    }

    return { add, remove, removeAll, has, toString };
};

