import LinkWrapper from "../Global/LinkWrapper";
import Image from "../Global/Image/Image";
import Description from "../Global/Description";

function NavigationArticleTile({
  pageLink,
  title,
  description,
  image,
  imageWidth,
  imageHeight,
  link,
  onClick,
}) {
  return (
    <LinkWrapper
      className={`complex-flyout__article ${pageLink ? "cta" : ""}`}
      link={link}
      onClick={onClick}
    >
      {image && (
        <Image
          className="complex-flyout__article-image"
          src={image}
          role="none"
          alt="promo"
          width={imageWidth}
          height={imageHeight}
          loading="eager"
        />
      )}
      <div className="complex-flyout__article-info">
        {title && <span className="complex-flyout__article-title">{title}</span>}
        {description?.value && (
          <Description className="complex-flyout__article-description" value={description.value} />
        )}
      </div>
    </LinkWrapper>
  );
}

export default NavigationArticleTile;
