import { useContext } from "react";
import { BrPageContext } from "@bloomreach/react-sdk";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import sanitizeHtml from 'sanitize-html'

function Description({ value, ...props }) {
  const page = useContext(BrPageContext);

  try {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: page?.rewriteLinks(sanitizeHtml(value)),
        }}
        {...props}
      />
    );
  } catch (e) {
    console.error("Description error", e);
    return <ErrorComponent error={e} componentName={"Description"} />;
  }
}

export default Description;
