import React from "react";
import "./FooterColumn.scss";

import FooterLinkGroup from "./FooterLinkGroup";

function FooterColumn({ header, links, linkType, children }) {
  return (
    <div className="footer-column">
      {children && children}
      <FooterLinkGroup header={header} links={links} linkType={linkType} />
    </div>
  );
}

export default FooterColumn;
