import React from "react";
import { BrPageContext } from "@bloomreach/react-sdk";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { getPageTitle } from "../../../../helpers/GlobalHelpers";
import { GetGeneralLinkUrl } from "../../../../helpers/LinkHelper";
import "./SimpleAuthoredTile.scss";
import LinkWrapper from "../../../Global/LinkWrapper";
import Image from "../../../Global/Image/Image";
import { Mobile, Desktop } from "../../../../media-queries/MediaQueries";

function SimpleAuthoredTile({ tile, componentStyle, basicHeight, basicWidth }) {
  const sendDataToGTM = useGTMDispatch();
  const page = React.useContext(BrPageContext);
  if (!tile) return null;

  let linkRef = GetGeneralLinkUrl(tile.link, page);
  let link = tile.link;

  const mobileImgSize = 130;

  const handleTileClick = () => {
    sendDataToGTM({
      event: "tileClick",
      tileTitle: `${tile.primaryTitle ? tile.primaryTitle : tile.secondaryTitle}`,
      label: `${getPageTitle(page)}`,
    });
  };

  return (
    <LinkWrapper
      link={link}
      className={`${
        linkRef ? "simple-authored-tile--cta" : ""
      } simple-authored-tile simple-authored-tile--border-radius simple-authored-tile--${componentStyle}`}
      onClick={() => handleTileClick()}
    >
      {tile.primaryTitle && (
        <p className="simple-authored-tile__heading simple-authored-tile--padding-side">
          {tile.primaryTitle}
        </p>
      )}
      {tile.secondaryTitle && (
        <p className="simple-authored-tile__subheading simple-authored-tile--padding-side">
          {tile.secondaryTitle}
        </p>
      )}
      <div className="simple-authored-tile__image-wrapper">
        {tile.image && (
          <>
          <Desktop>
            <Image
              src={tile.image}
              className="simple-authored-tile__image simple-authored-tile--border-radius-bottom"
              alt={tile.imageAltText || ""}
              height={basicHeight}
              width={basicWidth}
            />
          </Desktop>
          <Mobile>
            <Image
              src={tile.image}
              className="simple-authored-tile__image simple-authored-tile--border-radius-bottom"
              alt={tile.imageAltText || ""}
              height={basicHeight === 0 ? mobileImgSize : basicHeight}
              width={basicWidth === 0 ? mobileImgSize : basicWidth}
            />
          </Mobile>
          </>
        )}
      </div>
    </LinkWrapper>
  );
}

export default SimpleAuthoredTile;
