import { POPULAR_PRODUCTS } from "../components/Global/GlobalConstants";
import { animateScroll } from "react-scroll";
const { DELIMETER } = POPULAR_PRODUCTS;

//Get the pages title
export const getPageTitle = (page) => {
  if (page.getComponent("globalproperties") && page.getComponent("globalproperties").getModels()) {
    const { channelDisplayName } = page.getComponent("globalproperties").getModels();

    // Getting and setting browser page title
    const pageTitle = page.getTitle();
    if (pageTitle && channelDisplayName) {
      // If both pageTitle and channelDisplayName exist
      return pageTitle + " - " + channelDisplayName;
    } else if (channelDisplayName) {
      // If only channelDisplayName exists
      return channelDisplayName;
    }
    return "";
  }
};

// Gets all properties mapped as either models or parameters for a component dependent on name
// Returns an object with said properties
export const getProperties = (component) => (name) => {
  if (!component) return {};

  if (component.getName() === name) {
    return component.getParameters();
  }

  return component.getModels();
};

export const getProductsByCodes = async (productCodes, productServletURL, includeAllData = null, includeInactive = null) => {
  if (!productCodes || !productServletURL) return [];  
  if (productCodes.every((prod) => prod === undefined)) return [];

  productCodes = productCodes.join(DELIMETER);

  let products;

  let servletUrl = `${productServletURL}?productCodes=${productCodes}`;
  if (includeAllData){
    servletUrl += `&includeAllData=${includeAllData}`;
  }
  if (includeInactive){
    servletUrl += `&includeInactive=${includeInactive}`;
  }
  
  await fetch(servletUrl)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw response;
    })
    .then((data) => {
      products = data;
    })
    .catch((error) => {
      console.error(error);
    });
  return products;
}

export  const parseActiveFacet = (id) => {
  const { 0: groupId, 1: facetId } = id.split(":");
  return { groupId, facetId };
};

export const removeSpecialCharacters = (string) => {
  return string ? string.replace(/[^a-zA-Z0-9]/g, '') : '';
};

export const scrollAndSetFocusOnPDP = (id, isMobileNav) => {
  const focusId = id + "_focus";
  const headerId = "desktop-navigation__content";
  let targetElement;
  let headerElement;
  let focusElement;
  let headerHeight = 0;
  
  try {
    const { scrollY, pageYOffset } = window;
    targetElement = window.document.getElementById(id);

    // Account for static header height if applicable
    headerElement = window.document.getElementsByClassName(headerId).item(0);

    if (null != headerElement && window.getComputedStyle(headerElement).position == "fixed") {
       headerHeight = headerElement.clientHeight;
    }
    // Get distance from top of visual viewport
    const whereToScroll = isMobileNav ? targetElement.getBoundingClientRect().top : (targetElement.getBoundingClientRect().top - headerHeight);
    // Account for any vertically distance scrolled from top
    const top = whereToScroll + (scrollY || pageYOffset);

    animateScroll.scrollTo(top);

    focusElement = window.document.getElementById(focusId);
    focusElement.focus();

    return top;
  } catch (e) {
    console.error(e);
    if(null === targetElement) {
      console.error(`${id} section not found.`);
    } else if (null === focusElement) {
      console.error(`${focusId} section not found.`);
    } else if (null === headerElement) {
      console.error(`${headerId} section not found.`);
    } else {
      //eating the error
    } 
  }
};