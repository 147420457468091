export const getAlertCookie = (alert, cookieService) => {
    const value = cookieService.get(alert.id);

    if (!value) return;

    cookieService.update(alert);

    return value;
};

export const setAlertCookie = (alert, cookieService) => cookieService.set(alert);
