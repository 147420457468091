import { getAlertCookie, setAlertCookie } from "./siteMessagesHelpers";
import {
  FILTER_BY_VISIBILITY,
  FILTER_BY_LOCATION,
  FILTER_OUT_BY_ID,
  SITEWIDE_ALERT,
} from "./constants";

// Returns reducer function with reference to a Cookie Service - which return a state (array of messages)
//
// Reducer Action - Description
// --------------------
// FILTER_BY_VISIBILITY - Called when on home page, use messages provided from page context (reset state)
// FILTER_BY_LOCATION   - Called when not on the home page, use messages in state to filter
// FILTER_OUT_BY_ID     - Filter out message from state (hide message)

const makeReducer = (cookieService) => {
  const reducer = (state = [], action) => {
    const { type, payload } = action;

    if (!payload && !state) {
      return [];
    }
    switch (type) {
      case FILTER_BY_VISIBILITY: {
        return payload.filter((message) => !getAlertCookie(message, cookieService));
      }
      case FILTER_BY_LOCATION: {
        let x = state
          .filter((message) => message.type === SITEWIDE_ALERT)
          .filter((message) => !getAlertCookie(message, cookieService));
        return x;
        // return state
        //   .filter((message) => message.type === SITEWIDE_ALERT)
        //   .filter((message) => !getAlertCookie(message, cookieService));
      }
      case FILTER_OUT_BY_ID: {
        setAlertCookie(payload, cookieService);
        return state.filter((message) => message.id !== payload.id);
      }
      default: {
        return state;
      }
    }
  };

  return reducer;
};

export default makeReducer;
