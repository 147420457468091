import React, { useState, useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";

function ConsumeHashChange({listenFor, onTrigger}) {
  try {
    // On component mount, add the listener.
    // On component dismount, remove the listener.
    useEffect(() => {
      window.addEventListener("click", listenForHashChange);
      return () => {
        window.removeEventListener("click", listenForHashChange);
      };
    }, []);

    const listenForHashChange = (event) => {
      if(!event || !event.target || !event.target.href || !event.target.href.endsWith ||
        !event.target.href.endsWith(listenFor)) {
        return;
      }

      event.preventDefault();
      onTrigger && onTrigger();
    };

    return null;
  } catch (e) {
    console.error("ConsumeHashChange error", e);
    return <ErrorComponent error={e} componentName={"ConsumeHashChange"} />;
  }
}

export default ConsumeHashChange;
