import React, { useState, useEffect } from "react";
import { BrPageContext, BrComponentContext, BrManageContentButton } from "@bloomreach/react-sdk";

import "./PlantLocations.scss";

import PlantMap from "./PlantMap";
import { MapContainer, TileLayer } from "react-leaflet";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";

import { GLOBAL_TAXONOMY_MAP } from "../Global/GlobalConstants";
import { Helmet, HelmetData } from "react-helmet-async";

function PlantLocations() {
  const defaultPosition = [35.79117204458569, -79.8164100153436]; //dart headquarters
  const MAXZOOM = 8;
  const [map, setMap] = useState(null);
  const [activePlantFilter, setActivePlantFilter] = useState("");
  const [plantFilterSelectorOpen, setPlantFilterSelectorOpen] = useState(true);
  const [activeLatLong, setActiveLatLong] = useState(null);

  const page = React.useContext(BrPageContext);
  const component = React.useContext(BrComponentContext);

  const locationsArray = [];
  //gets the active list of items
  const getActiveLocations = () => {
    if (!locationsArray || locationsArray.length === 0) {
      return null;
    }
    return locationsArray.filter(
      (item) => item.plantTypes.includes(activePlantFilter) || activePlantFilter === ""
    );
  };
  useEffect(() => {
    //on change of plant filter we want to redo the active bounds in the map
    if (map) {
      var res = getActiveLocations().map((item) => [item.plantLatitude, item.plantLongitude]);
      map.fitBounds(res, { maxZoom: 8 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePlantFilter]);
  useEffect(() => {
    if (activeLatLong && activeLatLong[0] && activeLatLong[1]) {
      try {
        // const parent = window.document.getElementById("plant-locations__current-results");
        const child = window.document.getElementById(`LL_${activeLatLong[0]}_${activeLatLong[1]}`);
        child.scrollIntoView({ behavior: "smooth", block: "nearest" });
      } catch (e) {
        console.error("failed scroll to active item", e);
      }
    }
  });
  try {
    const { document: documentRef, plantLocations } = component.getModels();
    const document = page.getContent(documentRef);

    if (!document || !plantLocations) return null;
    const { allLocations, contactHeader, locationHeader, phoneLabel, faxLabel } =
      document.getData();
    const regions = [{ value: "", name: allLocations }];
    const taxonomyComponent = page.getComponent(GLOBAL_TAXONOMY_MAP);
    const { taxonomy: taxonomies = {} } = taxonomyComponent.getModels();
    const helmetData = new HelmetData({});

    //make sure we have unique values in the regions selector
    function addUniqeObjToRegions(data) {
      let index = -1;

      for (let i = 0; i < regions.length; i++) {
        if (regions[i].value === data.value) {
          index = i;
        }
      }

      if (index > -1) {
        regions[index] = data;
      } else {
        regions.push(data);
      }
    }
    //need to get all the plant locations and put them in the locations list
    if (plantLocations && plantLocations.length > 0) {
      plantLocations.forEach((item) => {
        const location = page.getContent(item);
        if (location) {
          const {
            plantTitle = null,
            plantStreet = null,
            plantCity = null,
            plantState = null,
            plantZip = null,
            plantTypes = null,
            plantPhone = null,
            plantFax = null,
            plantLatitude = null,
            plantLongitude = null,
          } = location.getData();

          locationsArray.push({
            plantTitle,
            plantStreet,
            plantCity,
            plantState,
            plantZip,
            plantTypes,
            plantPhone,
            plantFax,
            plantLatitude,
            plantLongitude,
          });
          //setup regions
          plantTypes.forEach((item) => {
            addUniqeObjToRegions({ value: item, name: taxonomies[item] || item });
          });
        }
      });
    }

    //item address is used in the nav view and on click of the icon in the map
    const itemAddress = (item) => {
      return (
        <div className="plant-locations__current-results-item-sections">
          {(item.plantStreet || item.plantCity || item.plantZip) && (
            <div className="plant-locations__current-results-item-section">
              <span className="plant-locations__current-results-item-content-label">
                {locationHeader}
              </span>
              <span className="plant-locations__current-results-item-content-value">
                {item.plantStreet}
              </span>
              <span className="plant-locations__current-results-item-content-value">
                {item.plantCity}
                {item.plantState ? ", " + item.plantState : null} {item.plantZip}
              </span>
            </div>
          )}
          {(item.plantFax || item.plantPhone) && (
            <div className="plant-locations__current-results-item-section">
              <span className="plant-locations__current-results-item-content-label">
                {contactHeader}
              </span>
              {item.plantPhone && (
                <span className="plant-locations__current-results-item-content-value">
                  {phoneLabel}:{item.plantPhone}
                </span>
              )}
              {item.plantFax && (
                <span className="plant-locations__current-results-item-content-value">
                  {faxLabel}:{item.plantFax}
                </span>
              )}
            </div>
          )}
        </div>
      );
    };

    //actions to take when we change location option
    const locationChange = (item) => {
      setActiveLatLong(null);
      setActivePlantFilter(item.value);
      map.closePopup();
    };

    const handleKeyPressOnLocationList = (event, latlong) => {
      if(event.key === 'Enter' || event.key === ' '){
        handleOpenLocationPopup(latlong);
        event.preventDefault();
      }
    };

    const handleOpenLocationPopup = (latlong) => {
      if (latlong) {
        //find layers and see if popup and matches latlong
        map.eachLayer(function (layer) {
          if (
            layer._popup &&
            layer._latlng &&
            layer._latlng.lat === latlong[0] &&
            layer._latlng.lng === latlong[1]
          ) {
            //if open close and set null active
            //else open
            if (layer._popup.isOpen()) {
              setActiveLatLong(null);
              layer.closePopup();
            } else {
              setActiveLatLong(latlong[0], latlong[1]);
              layer.openPopup();
            }
          }
        });
      }
    };

    return (
      <>
        <Helmet helmetData={helmetData}>
          <link
            rel="stylesheet"
            href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
            integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
            crossorigin=""
          />
        </Helmet>
        <div className={`${page.isPreview() ? "has-edit-button" : ""} plant-locations`}>
          <BrManageContentButton content={document} />
          <div className="plant-locations__location-selector-wrapper">
            <button
              className={`plant-locations__location-selector-btn ${
                plantFilterSelectorOpen ? "active" : ""
              }`}
              onClick={() => setPlantFilterSelectorOpen(!plantFilterSelectorOpen)}
            >
              {activePlantFilter
                ? regions[
                    regions.findIndex((item) => {
                      return item.value === activePlantFilter;
                    })
                  ].name
                : regions[0].name}
              {plantFilterSelectorOpen ? (
                <span className="plant-locations__location-selector-btn-icon darticon-arrow-up"></span>
              ) : (
                <span className="plant-locations__location-selector-btn-icon darticon-arrow-down"></span>
              )}
            </button>
            {plantFilterSelectorOpen && (
              <ul className="plant-locations__location-selector">
                {regions &&
                  regions.map((item, index) => {
                    return (
                      <li
                        className="plant-locations__location-selector-item"
                        key={`${item.value}_${item.name}`}
                      >
                        <button
                          className="plant-locations__location-selector-item-link"
                          onClick={() => {
                            locationChange(item);
                            setPlantFilterSelectorOpen(false);
                          }}
                        >
                          {item.name}
                        </button>
                      </li>
                    );
                  })}
              </ul>
            )}
          </div>
          <div className="plant-locations__wrapper">
            <div className="plant-locations__desktop-location-selector-wrapper">
              {plantFilterSelectorOpen && (
                <ul className="plant-locations__desktop-location-selector">
                  {regions &&
                    regions.map((item, index) => {
                      return (
                        <li
                          className={`plant-locations__desktop-location-selector-item ${
                            activePlantFilter === item.value ? "active" : ""
                          }`}
                          key={`desktop_${item.value}_${item.name}`}
                        >
                          <button
                            className="plant-locations__desktop-location-selector-item-link"
                            onClick={() => {
                              locationChange(item);
                            }}
                            type="button"
                            title={item.name}
                            aria-label={item.name}
                            aria-pressed={activePlantFilter == item.value ? "true" : "false"}
                          >
                            {item.name}
                            <span className="plant-locations__desktop-location-selector-item-link-icon darticon-arrow"></span>
                          </button>
                        </li>
                      );
                    })}
                </ul>
              )}
            </div>
            <div className="plant-locations__current-results-wrapper">
              <ul
                className="plant-locations__current-results"
                id="plant-locations__current-results"
              >
                {locationsArray &&
                  locationsArray
                    .filter(
                      (item) =>
                        item.plantTypes.includes(activePlantFilter) || activePlantFilter === ""
                    )
                    .map((item, index) => {
                      return (
                        <li
                          tabIndex={0}
                          aria-label={item.plantTitle}
                          role="button"
                          className={`plant-locations__current-results-item ${
                            activeLatLong &&
                            Number.parseFloat(item.plantLatitude) === activeLatLong[0] &&
                            Number.parseFloat(item.plantLongitude) === activeLatLong[1]
                              ? "active"
                              : ""
                          }`}
                          id={`LL_${Number.parseFloat(item.plantLatitude)}_${Number.parseFloat(
                            item.plantLongitude
                          )}`}
                          key={`${item.plantTitle}_${index}`}
                          onClick={() =>
                            handleOpenLocationPopup([
                              Number.parseFloat(item.plantLatitude),
                              Number.parseFloat(item.plantLongitude),
                            ])
                          }
                          onKeyPress={(event) => { handleKeyPressOnLocationList(event,[
                              Number.parseFloat(item.plantLatitude),
                              Number.parseFloat(item.plantLongitude),
                          ]); }}
                        >
                          <div className="plant-locations__current-results-item-number-wrapper">
                            <span className="plant-locations__current-results-item-number-icon darticon-map"></span>
                            <span className="plant-locations__current-results-item-number">
                              {/* //adding one because starts base 0 */}
                              {index + 1}
                            </span>
                          </div>
                          <div className="plant-locations__current-results-item-content-wrapper">
                            <span className="plant-locations__current-results-item-content-name">
                              {item.plantTitle}
                            </span>
                            <span className="plant-locations__current-results-item-content-type">
                              {item.plantTypes.map((item, index) => {
                                return (
                                  <span
                                    className="plant-locations__current-results-item-content-type-item"
                                    key={`${item}__${index}`}
                                  >
                                    {taxonomies[item] || item}
                                  </span>
                                );
                              })}
                            </span>

                            {itemAddress(item)}
                          </div>
                        </li>
                      );
                    })}
              </ul>
            </div>
            {/* popups have been hidden in the _global.scss file, remove the css line if you want them back */}
            <div className="plant-locations__map-wrapper">
              <MapContainer
                center={defaultPosition}
                zoom={1}
                scrollWheelZoom={false}
                whenCreated={setMap}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <PlantMap
                  activeLocations={getActiveLocations()}
                  itemAddress={itemAddress}
                  activeLatLong={activeLatLong}
                  setActiveLatLong={setActiveLatLong}
                  maxZoom={MAXZOOM}
                ></PlantMap>
              </MapContainer>
            </div>
          </div>
        </div>
      </>
    );
  } catch (e) {
    console.error("PlantLocations error", e);
    return <ErrorComponent error={e} componentName={"PlantLocations"} />;
  }
}
export default PlantLocations;
