import React, { useEffect, useRef, useState } from "react";
import { BrPageContext, BrComponentContext } from "@bloomreach/react-sdk";
import { Desktop } from "../../../media-queries/MediaQueries";
import "./SubscriptionForm.scss";
import LinkWrapper from "../../Global/LinkWrapper";
import { HEADING_LEVELS, PRIMARY } from "../../Global/GlobalConstants";
import Image from "../../Global/Image/Image";
import { animateScroll } from "react-scroll";

import Description from "../../Global/Description";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
import { isAuthored } from "../../../helpers/LinkHelper";
import Title from "../../Global/Title/Title";
import { Helmet, HelmetData } from "react-helmet-async";
import { GTMProvider, useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";



function SubscriptionForm({ showGatedContent = false, theme = PRIMARY }) {
  const [formVisible, setFormVisible] = useState(false);

  const formEmbedBodyContentRef = useRef();

  const page = React.useContext(BrPageContext);
  const component = React.useContext(BrComponentContext);
  const { componentStyle = theme, imageWidth, imageHeight } = component.getParameters();
  const helmetData = new HelmetData({});

  const FORMASSEMBLYTYPE = "formassembly";
  const MAILCHIMPTYPE = "mailchimp";

  const sendDataToGTM = useGTMDispatch();
  const thankYouFormConfirm = "Form successfully submitted";

  try {
    useEffect(() => {
      if (!showGatedContent) {
        if (formEmbedBodyContentRef.current) {
          if (formProvider === FORMASSEMBLYTYPE) {
            const iframeElement = document.createElement("iframe");
            iframeElement.classList = "form-embed__iframe-wrapper";
            iframeElement.frameBorder = "0";
            iframeElement.title = primaryTitle;
            iframeElement.id = formId();
            const srcString = String(htmlSource).trim();
            iframeElement.src = srcString;
            formEmbedBodyContentRef.current.appendChild(iframeElement);
            setFormVisible(true);
          } else if (formProvider === MAILCHIMPTYPE) {
            loadMailChimpFormEmbed(function () {
              formEmbedBodyContentRef.current.appendChild(formEmbedBodyfragment);
              overrideMailChimpValidator();
            });
            setFormVisible(true);
          }
        }
      }
      //delayed hash follow
      let location = window.location;
      const { contactFormHash = "contactForm" } = window.globalProperties || {};

      if(location.hash && location.hash.length > 1 && location.hash.substring(1) == contactFormHash){
        var jumpLink = formEmbedBodyContentRef.current;
        if(jumpLink){
          const { scrollY, pageYOffset } = window;
          animateScroll.scrollTo(jumpLink.getBoundingClientRect().top + (scrollY || pageYOffset) - 230);
          jumpLink.scrollIntoView(true);
          // a flag set for ScrollTop.jsx to not scroll to the top automatically
          window.noScrollTo = true;
        }
      }
    }, []);

    function loadMailChimpFormEmbed(method) {
      if (window.jQuery) {
        method();
      } else {
        setTimeout(function () {
          loadMailChimpFormEmbed(method);
        }, 50);
      }
    }

    function overrideMailChimpValidator() {
      if (!window.mc || !window.mc.mce_validator) {
        setTimeout(overrideMailChimpValidator, 50);
        return;
      }
      const mcv = window.mc.mce_validator;
      mcv._override_showLabel = mcv.showLabel;
      mcv.showLabel = function (input, text) {
        if(!input.labels || !input.labels.length || !input.labels[0] || !input.labels[0].innerText) {
          return mcv._override_showLabel(input, text);
        }
        const name = input.labels[0].innerText.replace("*","");
        text = text.replace(".", "") + ": " + name;
        mcv._override_showLabel(input,text);
      };
      const mcv_settings = window.mc.mce_validator.settings;
      mcv_settings._override_errorPlacement = mcv_settings.errorPlacement;
      mcv_settings.errorPlacement = function ($error, $element) {
        const error = $error[0];
        const element = $element[0];
        error.setAttribute("role","alert");
        error.setAttribute("aria-describedby",element.id);
        mcv_settings._override_errorPlacement(error,element);
      };
    }

    const formId = () => {
      // get the form id from the FormAssembly url provided.
      // the url follows the pattern https://dart.tfaforms.net/[ID]?[field]=[value]
      // the id value in the url starts at position 26
      // take the substring of the url, starting at position 26, ending at the "?" character
      let end = htmlSource?.indexOf("?") > -1 ? htmlSource.indexOf("?") : htmlSource.length;
      return "fa" + htmlSource.substring(26, end);
    };

    window.addEventListener(
      "message",
      function (e) {
        // get the message that was passed from iframe page.
        // that message contains the height of the scroll height of the form
        // set the height of the iframe to the height from message to remove vertical scroll
        if (formProvider === FORMASSEMBLYTYPE) {
          let message = e.data;
          let id = formId();
          let iframe = document.querySelector("#" + id);
          const formSubmitEvent = message.formSubmit;

          if (message.height && message.formId && id === message.formId) {
            iframe.style.height = message.height + "px";
          }
          // checks for FormAssembly form submission and confirms it matches custom Thank You page message
          if (formSubmitEvent && formSubmitEvent === thankYouFormConfirm) {
           sendDataToGTM({ 
            event: "formSubmit", 
            "gtm.elementId": id 
          }); 
        };
        };
      },
      false
    );

    const { document: documentRef } = component.getModels();
    const brDocument = page.getContent(documentRef);

    if (!brDocument) return null;
    const {
      formDetails = {},
      thankYouDetails = {},
      logo = null,
      logoAltText = null,
      htmlSource = null,
      formProvider = null,
    } = brDocument.getData();

    const {
      primaryTitle = null,
      secondaryTitle = null,
      description = null,
      image = null,
      imageAltText = null,
    } = formDetails || {};
    const {
      primaryTitle: thankYouTitle = "",
      description: thankYouDescription = null,
      link: thankYouLink,
    } = thankYouDetails || {};

    const formEmbedBodyfragment = document.createRange().createContextualFragment(htmlSource);

    const showThankYouCta = isAuthored(thankYouLink);

    return (
      <>
        <Helmet helmetData={helmetData}>
          <script
            defer
            src="https://code.jquery.com/jquery-3.6.0.min.js"
            integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4="
            crossorigin="anonymous"
          ></script>
        </Helmet>

        <div className={`form-embed theme-${componentStyle}`}>
          <div className={`form-embed__wrapper`}>
            <div className={`form-embed__left`}>
              {logo && <Image src={logo} alt={logoAltText} className="form-embed__logo" />}
              {primaryTitle && (
                <Title defaultLevel={HEADING_LEVELS.TWO} className="form-embed__header">
                  {primaryTitle}
                </Title>
              )}
              {secondaryTitle && <p className="form-embed__sec-title">{secondaryTitle}</p>}
              <Description className="form-embed__description" value={description?.value} />
              {image && (
                <Desktop>
                  <Image
                    src={image}
                    alt={imageAltText}
                    className="form-embed__image"
                    width={imageWidth}
                    height={imageHeight}
                  />
                </Desktop>
              )}
            </div>
            <div
              className={`form-embed__right ${
                componentStyle === PRIMARY ? "form-embed__right--primary" : ""
              }`}
            >
              {!showGatedContent && (
                <div
                  className={formVisible ? "" : "form-embed__hidden"}
                  ref={formEmbedBodyContentRef}
                ></div>
              )}
              {showGatedContent && (
                <div className="form-embed__confirmation">
                  <div className="form-embed__confirmation-icon">
                    <span className="darticon-checkmark" />
                  </div>
                  {thankYouTitle && (
                    <Title
                      defaultLevel={HEADING_LEVELS.THREE}
                      className="form-embed__confirmation-title"
                    >
                      {thankYouTitle}
                    </Title>
                  )}
                  {thankYouDescription && (
                    <Description
                      className="form-embed__confirmation-description"
                      value={thankYouDescription?.value}
                    />
                  )}
                  {showThankYouCta && (
                    <LinkWrapper className="form-embed__confirmation-cta btn" link={thankYouLink}>
                      <span className="form-embed__confirmation-cta-icon darticon-download" />
                      {thankYouLink.label}
                    </LinkWrapper>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  } catch (e) {
    console.error("SubscriptionForm error", e);
    return <ErrorComponent error={e} componentName={"SubscriptionForm"} />;
  }
}

export default SubscriptionForm;
