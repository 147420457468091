import LinkWrapper from "../Global/LinkWrapper";
import Description from "../Global/Description";
import Image from "../Global/Image/Image";

function IconGridItem({ item = {}, contentType }) {
  const {
    image,
    imageAltText,
    primaryTitle,
    description,
    link,
    internalLink,
    externalLink,
    label,
  } = item;

  const GENERALLINKEDLISTTYPE = "dartcontainercombrxm:GeneralLinkList";
  const BASICCONTENTTYPE = "dartcontainercombrxm:BasicContentList";

  const linkExists = () => {
    if (contentType === GENERALLINKEDLISTTYPE) {
      return link.internalLink || link.externalLink ? " has-link" : "";
    } else {
      return internalLink || externalLink ? " has-link" : "";
    }
  };

  const headerOutput = contentType === GENERALLINKEDLISTTYPE ? label : primaryTitle;
  const linkOutput = contentType === GENERALLINKEDLISTTYPE ? item : link;

  return (
    <li className="icon-grid__grid__item">
      <LinkWrapper link={linkOutput} className="icon-grid__grid__item__link">
        <div className="icon-grid__grid__item__image-wrapper">
          {image && (
            <Image
              className="icon-grid__grid__item__image"
              src={image}
              alt={imageAltText || ""}
              loading="lazy"
              height={80}
              width={95}
            />
          )}
        </div>
        {(headerOutput || description?.value) && (
          <div className="icon-grid__grid__item__text-wrapper">
            {headerOutput && <h2 className="icon-grid__grid__item__label">{headerOutput}</h2>}
            {contentType === BASICCONTENTTYPE && description?.value && (
              <Description
                value={description.value}
                className={`icon-grid__grid__item__description${linkExists()}`}
              />
            )}
          </div>
        )}
      </LinkWrapper>
    </li>
  );
}

export default IconGridItem;
