import { useContext } from "react";
import { SearchToolbarContext } from "./context";

export const useSearchToolbarContext = () => {
    const context = useContext(SearchToolbarContext);

    if (!context) {
        throw new Error(
            "useSearchToolbarContext must be used within a SearchToolbarContext.Provider"
        );
    }

    return context;
};
