import { useEffect, useState, useContext } from "react";
import { BrPageContext } from "@bloomreach/react-sdk";
import { ENTERKEYCODE } from "../../Global/GlobalConstants";
import "./SearchInput.scss";

function SearchInput({ setSearchTerm, setReRunSearch, reRunSearch, autoCorrectOverride, setAutoCorrectOverride }) {
  const [searchInput, setSearchInput] = useState("");
  const page = useContext(BrPageContext);
  const { pixelProps } = page.getComponent().getModels();

  // Handle value and "Enter" input
  const handleInput = (e) => {
    if(!e.target || typeof e.target.id !== "string" || e.target.id !== "search-input")
    {
      return;
    }
    const key = e.keyCode;

    if (searchInput && searchInput.length !== 0 && key === ENTERKEYCODE) {
      var brSearchData = {};
      const BrTrk = window.BrTrk;
      brSearchData["q"] = searchInput;
      brSearchData["catalogs"] = [{ "name" : pixelProps.productCatalog }];
  
      BrTrk.getTracker().logEvent("suggest", "submit",brSearchData,{},true);
      setSearchTerm(searchInput || '');
      setReRunSearch(!reRunSearch);
    } else {
      if(autoCorrectOverride) {
        setAutoCorrectOverride(null)
      }
      setSearchInput(e.target.value)
    }
  };

  // Handle search on clicking the icon
  const handleSearchClick = () => {
    var brSearchData = {};
    const BrTrk = window.BrTrk;
    brSearchData["q"] = searchInput;
    brSearchData["catalogs"] = [{ "name" : pixelProps.productCatalog }];

    BrTrk.getTracker().logEvent("suggest", "submit",brSearchData,{},true);
    setSearchTerm(searchInput);
    setReRunSearch(!reRunSearch);
  }

  useEffect(() => {
    window.addEventListener("keyup", handleInput);
    return () => {
      window.removeEventListener("keyup", handleInput);
    };
  });

  return (
    <div className="search-input">
      <div className="search-input__wrapper">
        <span
          className="search-input__icons search darticon-headersearch"
          onClick={() => handleSearchClick()}
        ></span>
        <input
          id="search-input"
          className="search-input__input"
          aria-label="Search"
          placeholder="Search"
          onChange={(e) => handleInput(e)}
          value={autoCorrectOverride ? autoCorrectOverride : searchInput}
          autoComplete="Off"
        />
      </div>
    </div>
  );
}

export default SearchInput;
