import React from "react";
import Description from "../Global/Description";
import { HEADING_LEVELS } from "../Global/GlobalConstants";
import Title from "../Global/Title/Title";
import Image from "../Global/Image/Image";
import "./SingleTypeSearchCard.scss";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { Mobile, Desktop } from "../../media-queries/MediaQueries";

function SingleTypeSearchCard({ result, isSingleTypeSearch }) {
  const ARTICLECONTENTTYPE = "article";
  const WEBPAGECONTENTTYPE = "webpage";
  const PDFCONTENTTYPE = "pdf";
  const MEDIACONTENTTYPE = "mediaCollection";
  const DOCCONTENTTYPE = "document";
  let mobileImgSize = 140;

  const sendDataToGTM = useGTMDispatch();

  // Getting Content Type
  let contentType = result.contentType && result.contentType.length > 0 ? result.contentType : "";

  if (contentType === ARTICLECONTENTTYPE) mobileImgSize = 100;

  //  Getting Preview Image
  const imagePreview = () => {
    return result.imageUrl && result.imageUrl.length > 0 ? result.imageUrl : result.promoImage;
  };

  // Getting Categories
  const category = result.categories && result.categories.length > 0 ? result.categories[0] : [];

  // Getting Description
  const description = result.description && result.description.length > 0 ? result.description : "";

  // Determining CTA Label
  const {
    viewArticleLabel,
    previewFileLabel,
    viewPageLabel,
    viewCollectionLabel,
    registerToViewFileLabel,
  } = window.globalProperties;

  const ctaLabel = () => {
    switch (contentType) {
      case ARTICLECONTENTTYPE:
        return viewArticleLabel;
      case PDFCONTENTTYPE:
        return previewFileLabel;
      case WEBPAGECONTENTTYPE:
        return viewPageLabel;
      case MEDIACONTENTTYPE:
        return viewCollectionLabel;
      case DOCCONTENTTYPE:
        return registerToViewFileLabel;
      default:
        return viewArticleLabel;
    }
  };

  // Getting Link
  const linkToOpen = () => {
    // If contentType is document, get link from result.documentUrl
    // Else get link from result.url
    switch (contentType) {
      case DOCCONTENTTYPE:
        return result.previewUrl && result.previewUrl.length > 0 ? result.previewUrl : null;
      case MEDIACONTENTTYPE:
        return result.documentUrl && result.documentUrl.length > 0 ? result.documentUrl : null;
      default:
        return result.url && result.url.length > 0 ? result.url : null;
    }
  };

  // Handles CTA click
  const handleCTAClick = (url) => {
    if (ctaLabel === viewCollectionLabel) {
      sendDataToGTM({
        event: "viewCollection",
        collectionName: result.title,
      });
    }
    if (contentType === PDFCONTENTTYPE || contentType === DOCCONTENTTYPE || contentType === MEDIACONTENTTYPE) {
      window.open(url, "_blank");
    } else {
      window.open(url, "_self");
    }
  };

  // Needed for CSS naming convention
  const classToUse =
    contentType === DOCCONTENTTYPE && isSingleTypeSearch
      ? "document"
      : contentType === DOCCONTENTTYPE
      ? "pdf"
      : contentType === MEDIACONTENTTYPE
      ? "media"
      : contentType;

  return (
    <div className="single-type-search">
      <div className={`single-type-search__wrapper ${classToUse}`}>
        {/* Image Section */}
        {imagePreview() && imagePreview().length > 0 && (
          <div className={`single-type-search__image-container ${classToUse}`}>
            <Desktop>
              <Image
                className={`single-type-search__image ${classToUse}`}
                src={imagePreview()}
                alt={result.title}
              />
            </Desktop>
            <Mobile>
              <Image
                className={`single-type-search__image ${classToUse}`}
                src={imagePreview()}
                alt={result.title}
                width={mobileImgSize}
                height={mobileImgSize}
              />
            </Mobile>
          </div>
        )}

        {/* Result Body Section */}
        <div className={`single-type-search__content-container ${classToUse}`}>
          {/* Category, Published Date, Title, Description */}
          <div className={`single-type-search__info-container ${classToUse}`}>
            {(category || result.lastUpdatedDate) && (
              <p className="single-type-search__category-date">
                {/* Category */}
                {(contentType === ARTICLECONTENTTYPE ||
                  contentType === PDFCONTENTTYPE ||
                  contentType === DOCCONTENTTYPE) &&
                  category &&
                  category.name &&
                  category.name.length > 0 && <span>{category.name}</span>}

                {/* Category & Last Updated Date Separator - "|" */}
                {contentType === ARTICLECONTENTTYPE &&
                  category &&
                  category.name &&
                  category.name.length > 0 &&
                  result.lastUpdatedDate &&
                  result.lastUpdatedDate.length > 0 && (
                    <span className="single-type-search__separator">|</span>
                  )}

                {/* Last Updated Date */}
                {(contentType === ARTICLECONTENTTYPE || contentType === WEBPAGECONTENTTYPE) &&
                  result.lastUpdatedDate &&
                  result.lastUpdatedDate.length > 0 && <span>{result.lastUpdatedDate}</span>}
              </p>
            )}

            {/* Title */}
            {result.title && result.title.length > 0 && (
              <Title
                defaultLevel={HEADING_LEVELS.FOUR}
                className={`single-type-search__header ${contentType}`}
              >
                {result.title}
              </Title>
            )}

            {/* Description */}
            {(contentType === WEBPAGECONTENTTYPE || contentType === MEDIACONTENTTYPE) &&
              result.description &&
              result.description.length > 0 && (
                <Description className="single-type-search__description" value={description} />
              )}
          </div>

          {/* CTA */}

          {linkToOpen() && linkToOpen().length > 0 && (
            <button
              aria-label={`${ctaLabel()} ${result.title}`}
              className={`single-type-search__cta btn ${classToUse}`}
              onClick={() => {
                handleCTAClick(linkToOpen());
              }}
            >
              {ctaLabel()}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default SingleTypeSearchCard;
