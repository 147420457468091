import React, { useState, useEffect } from "react";
import { BrPageContext, BrComponentContext } from "@bloomreach/react-sdk";
import {Desktop, IsMobileNav} from "../../media-queries/MediaQueries";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import {useAlertScreenReader} from "../Global/ScreenReaderAlert/hooks";
import {scrollAndSetFocusOnPDP} from "../../helpers/GlobalHelpers";

import {
  SPECSDOCTABID,
  COMPATIBLEPRODUCTSTABID,
  PRODCOLLECTIONTABID,
  FEATURESBENEFITSTABID,
} from "../Global/GlobalConstants";
import "./ProductDetailTabs.scss";

function ProductDetailTabs() {
  const [activeIndex, setActiveIndex] = useState(-1);

  const page = React.useContext(BrPageContext);
  const component = React.useContext(BrComponentContext);

  const {setAlertMessage} = useAlertScreenReader();
  const scrollingTo = window.globalProperties.scrollingTo || "Scrolling to ";
  const productReaderPrefix = window.globalProperties.productReaderPrefix || "View ";

  const isMobileNav = IsMobileNav();

  try {
    useEffect(() => {
      if (hideSection) {
        hideSection();
      }
    });

    const { document: documentRef } = component.getModels();
    const document = page.getContent(documentRef);

    if (!document) return null;

    const handleTabClick = (index, id, label, isMobileNav) => {
      setActiveIndex(index); // Set active index to that of clicked tab
      scrollAndSetFocusOnPDP(id, isMobileNav);
      setAlertMessage(`${scrollingTo} ${label}`);
    };

    // Getting booleans to conditinally display tabs
    const {
      showCompatibleProducts,
      showFeaturesAndBenefits,
      showProductCollection,
      showSpecificationsAndDocumentation,
    } = component.getModels();

    const {
      specificationsAndDocumentationLabel = null,
      compatibleProductsLabel = null,
      productCollectionLabel = null,
      featuresAndBenefitsLabel = null,
    } = document.getData();


    // Hide content section if show... comes back as 'false'
    const hideSection = () => {
      const compatibleProductsSection = window.document.getElementById(COMPATIBLEPRODUCTSTABID);
      const featuresBenefitsSection = window.document.getElementById(FEATURESBENEFITSTABID);
      const productCollectionSection = window.document.getElementById(PRODCOLLECTIONTABID);
      const specsAndDocumentationSection = window.document.getElementById(SPECSDOCTABID);

      const sectionsArray = [
        {
          section: compatibleProductsSection,
          display: showCompatibleProducts,
        },
        {
          section: featuresBenefitsSection,
          display: showFeaturesAndBenefits,
        },
        {
          section: productCollectionSection,
          display: showProductCollection,
        },
        {
          section: specsAndDocumentationSection,
          display: showSpecificationsAndDocumentation,
        },
      ];

      sectionsArray.forEach((item) => {
        if (item.section) {
          if (item.display === false) {
            item.section.style.display = "none";
          } else {
            item.section.style.display = "block";
          }
        }
      });
    };

    const tabsOutput = () => {
      // Setting conditions for tab labels and ids
      // NOTE: IDs will be hardcoded and added to other components later
      const tabsArray = [
        {
          displayTab: showSpecificationsAndDocumentation,
          label: specificationsAndDocumentationLabel || null,
          id: SPECSDOCTABID,
        },
        {
          displayTab: showCompatibleProducts,
          label: compatibleProductsLabel || null,
          id: COMPATIBLEPRODUCTSTABID,
        },
        {
          displayTab: showProductCollection,
          label: productCollectionLabel || null,
          id: PRODCOLLECTIONTABID,
        },
        {
          displayTab: showFeaturesAndBenefits,
          label: featuresAndBenefitsLabel || null,
          id: FEATURESBENEFITSTABID,
        },
      ];

      return tabsArray
        .filter((item) => item.displayTab !== false)
        .map((item, index) => {
          return (
            <div
              role="listitem"
              style={{"padding": 0}}
              className={`product-detail-tabs__item ${activeIndex === index ? "active" : ""}`}
              key={index}>
              <button
                tabIndex="0"
                aria-label={`${productReaderPrefix} ${item.label}`}
                className={`product-detail-tabs__item ${activeIndex === index ? "active" : ""}`}
                onClick={() => handleTabClick(index, item.id, item.label, isMobileNav)}
              >
                {item.label}
              </button>
            </div>
          );
        });
    };

    return (
      <Desktop>
        <div className="product-detail-tabs">
          <div className="product-detail-tabs__wrapper" role="list">{tabsOutput()}</div>
        </div>
      </Desktop>
    );
  } catch (e) {
    console.error("ProductDetailTabs error", e);
    return <ErrorComponent error={e} componentName={"ProductDetailTabs"} />;
  }
}

export default ProductDetailTabs;
