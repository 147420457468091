import { useSearchRefinementContext } from "./hook";
import "./MobileSearchRefinement.scss";

function MobileSearchRefinement() {
  const {
    refinements: { addRefinement, options, count, activeRefinement, setActiveRefinement },
    searchTerm,
    labels,
    labelIconInfo,
  } = useSearchRefinementContext();

  const handleRefinementChange = (e) => {
    setActiveRefinement(null);
    addRefinement(e.target.value);
  };

  const refinementsOutput = () => {
    return options.map((item, index) => {
      let refinementInfo = labelIconInfo.find((e) => e.id === item.id);
      return (
        <option className="mobile-search-refinement__option" key={index} value={item.id}>
          {refinementInfo?.label} ({item.count})
        </option>
      );
    });
  };

  return (
    <div className="mobile-search-refinement" aria-live="polite">
      {searchTerm && (
        <p className="mobile-search-refinement__results-count">
          {`${count} ${labels.resultsForLabel}`} <b>{`'${searchTerm}'`}</b>
        </p>
      )}
      {options && (
        <>
        <fieldset className="mobile-search-refinement__select-set">
          <div className="mobile-search-refinement__wrapper">
            <select
              className="mobile-search-refinement__select"
              onChange={handleRefinementChange}
              value={activeRefinement}
            >
              {refinementsOutput()}
            </select>
          </div>
        </fieldset>
        </>
      )}
    </div>
  );
}

export default MobileSearchRefinement;
