import React from "react";
import { BrPageContext, BrComponentContext } from "@bloomreach/react-sdk";
import { Desktop } from "../../media-queries/MediaQueries";
import { PRIMARY, TERTIARY, HEADING_LEVELS } from "../Global/GlobalConstants";
import CtaLink from "../Global/CtaLink";
import "./PromoBanner.scss";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import Title from "../Global/Title/Title";
import Image from "../Global/Image/Image";
import sanitizeHtml from 'sanitize-html'

function PromoBanner() {
  const page = React.useContext(BrPageContext);
  const component = React.useContext(BrComponentContext);

  try {
    const { document: documentRef } = component.getModels();
    const {
      componentStyle = PRIMARY,
      headerLevel,
      basicHeight,
      basicWidth,
    } = component.getParameters();
    const document = page.getContent(documentRef);

    if (!document) return null;

    const {
      primaryTitle = null,
      description = null,
      link = null,
      image: imageUrl = null,
      imageAltText = null,
    } = document.getData();
    const showCta = link?.label && (link?.internalLink || link?.externalLink);

    return (
      <div
        className={`promo-banner theme-${componentStyle} ${
          page.isPreview() ? "has-edit-button" : ""
        }`}
      >
        <div className="promo-banner__inner">
          <div className={`promo-banner__left theme-${componentStyle}`}>
            {primaryTitle && (
              <Title
                defaultLevel={HEADING_LEVELS.TWO}
                headingLevel={headerLevel}
                className={`promo-banner__heading theme-${componentStyle}`}
              >
                {primaryTitle}
              </Title>
            )}
            {description && description.value && (
              <div
                className={`promo-banner__description theme-${componentStyle}`}
                dangerouslySetInnerHTML={{
                  __html: page?.rewriteLinks(sanitizeHtml(description.value)),
                }}
              />
            )}
          </div>
          <div className="promo-banner__right">
            {showCta && (
              <CtaLink
                link={link}
                className={`btn promo-banner__cta ${
                  componentStyle === TERTIARY ? "btn--" + componentStyle : ""
                }`}
              />
            )}
            {imageUrl && (
              <Desktop>
                <Image
                  className="promo-banner__image"
                  src={imageUrl}
                  alt={imageAltText}
                  height={basicHeight}
                  width={basicWidth}
                />
              </Desktop>
            )}
          </div>
        </div>
      </div>
    );
  } catch (e) {
    console.error("PromoBanner error", e);
    return <ErrorComponent error={e} componentName={"PromoBanner"} />;
  }
}

export default PromoBanner;
