import React from "react";
import { BrPageContext, BrComponentContext } from "@bloomreach/react-sdk";
import "./GlobalProperties.scss";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";

//this gets put on all pages, and pushes a window var window.globalProperties
function GlobalSearchProperties() {
  const page = React.useContext(BrPageContext);
  const component = React.useContext(BrComponentContext);

  try {
    const { document: documentRef } = component.getModels();
    const document = page.getContent(documentRef);

    if (!document) return null;

    return page.isPreview() ? (
      <div className={`has-edit-button global-props`}>
        <span>Global Search Properties - Click to View and Edit Values</span>
      </div>
    ) : null;
  } catch (e) {
    console.error("GlobalSearchProperties error", e);
    return <ErrorComponent error={e} componentName={"GlobalSearchProperties"} />;
  }
}

export default GlobalSearchProperties;
