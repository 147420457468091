import LinkWrapper from "../Global/LinkWrapper";
import { useDesktopMediaQuery, Desktop } from "../../media-queries/MediaQueries";
import { isAuthored } from "../../helpers/LinkHelper";
import { HEADING_LEVELS, PRIMARY, TERTIARY } from "../Global/GlobalConstants";
import Title from "../Global/Title/Title";
import Image from "../Global/Image/Image";

function RelatedDocumentItem({ document = {}, theme = PRIMARY }) {
  const { image, category, title, link } = document;
  const isDesktop = useDesktopMediaQuery();
  const showCta = isAuthored(link);

  return (
    <li className="related-documents__list__item clearfix">
      {image && (
        <div className="related-documents__list__item__left">
          <div
            className="related-documents__list__item__image-wrapper"
            style={{
              backgroundImage: isDesktop ? "none" : `url(${image})`,
            }}
          >
            <Desktop>
              <Image src={image} alt="" loading="lazy" />
            </Desktop>
          </div>
        </div>
      )}
      <div className="related-documents__list__item__right">
        <div>
          {category && (
            <p className="related-documents__list__item__category bold-bold">{category}</p>
          )}
          <p className="related-documents__list__item__title bold-extra-bold">{title}</p>
        </div>
        {showCta && (
          <LinkWrapper
            className={`related-documents__list__item__cta btn ${
              theme === TERTIARY ? "btn--" + theme : ""
            }`}
            link={link}
          />
        )}
      </div>
    </li>
  );
}

function RelatedDocumentList({ theme = PRIMARY, title, documents = [] }) {
  return (
    <div className={`related-documents__wrapper theme-${theme}`}>
      <div className="related-documents">
        {title && (
          <Title defaultLevel={HEADING_LEVELS.TWO} className="related-documents__title">
            {title}
          </Title>
        )}
        {documents.length > 0 && (
          <ul className="related-documents__list">
            {documents.map((doc, i) => {
              return <RelatedDocumentItem key={i} document={doc} style={theme} />;
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

export default RelatedDocumentList;
