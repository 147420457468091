export const NOTIFICATION_TIMEOUT = 1500;

export const ACTIONS = {
    ADD_PRODUCT_SUCCESS: "ADD_PRODUCT_SUCCESS",
    ADD_PRODUCT_ERROR: "ADD_PRODUCT_ERROR",
    REMOVE_PRODUCT_SUCCESS: "REMOVE_PRODUCT_SUCCESS",
    REMOVE_PRODUCT_ERROR: "REMOVE_PRODUCT_ERROR",
    SAVED: "SAVED",
    RESET: "RESET",
};


// TODO: According to PBI #249398, only small and large sizes will be required,
// Including MEDIUM temporarily to not further "break" current existing styles
export const SIZE = {
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large",
};

export const STATUS = {
    IDLE: "idle",
    ADDED: "added",
    REMOVED: "removed",
    SAVED: "saved",
};

export const INITIAL_STATE = {
    status: STATUS.IDLE,
};

export const STATUS_ICON_MAP = {
    [STATUS.IDLE]: {
        icon: "darticon-plus",
    },
    [STATUS.ADDED]: {
        icon: "darticon-checkmark",
    },
    [STATUS.REMOVED]: {
        icon: "darticon-checkmark",
    },
    [STATUS.SAVED]: {
        icon: "darticon-close",
    },
};

export const HOVERABLE_STATUS_ICON_MAP = {
    [STATUS.IDLE]: {
        ...STATUS_ICON_MAP[STATUS.IDLE],
        iconOnHover: "darticon-plus",
    },
    [STATUS.ADDED]: {
        ...STATUS_ICON_MAP[STATUS.ADDED],
        iconOnHover: "darticon-checkmark",
    },
    [STATUS.REMOVED]: {
        ...STATUS_ICON_MAP[STATUS.REMOVED],
        icon: "darticon-plus",
        iconOnHover: "darticon-checkmark",
    },
    [STATUS.SAVED]: {
        ...STATUS_ICON_MAP[STATUS.SAVED],
        icon: "darticon-checkmark",
        iconOnHover: "darticon-close",
    },
};
