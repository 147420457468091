import SearchController from "../Search/SearchController";

function SiteSearchResults() {
  return (
    <div className="site-search-results">
      <SearchController />
    </div>
  );
}

export default SiteSearchResults;
