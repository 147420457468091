import React, { useEffect, useState } from "react";
import { BrPageContext, BrComponentContext, BrManageContentButton } from "@bloomreach/react-sdk";
import Toggle from "../Global/Toggle";
import "./ProductDetailSpecifications.scss";
import { Desktop, Mobile, IsMobileContent } from "../../media-queries/MediaQueries";
import Description from "../Global/Description";
import { HEADING_LEVELS, SPECSDOCTABID } from "../Global/GlobalConstants";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import Title from "../Global/Title/Title";

function ProductDetailSpecifications() {
  const [activeTab, setActiveTab] = useState(null);
  const [specSelectorOpen, setSpecSelectorOpen] = useState(true);
  const [activeToggles, setActiveToggles] = useState([]);
  const [visibleTabs, setVisibleTabs] = useState([]);

  const page = React.useContext(BrPageContext);
  const component = React.useContext(BrComponentContext);
  const isMobile = IsMobileContent();

  //Consts for Outlier - dynamically populating UOM for EffectiveUnitsPerCase
  const FIELDNAME_ITEMSPERCASE = "effectiveUnitsPerCase";
  const FIELDNAME_EFFECTIVEUNITSPERCASEUOM = "effectiveUnitsPerCaseUOM";

  //Fields that should display a 0 value
  const FIELDSTODISPLAYZEROVALUE = [
    "temperatureMinimumDegF",
    "temperatureMinimumDegC",
    "temperatureMaximumDegF",
    "temperatureMaximumDegC",
  ];

  try {
    useEffect(() => {
      //determine tab visibility
      _determineTabVisibility();

      //default to first tab selected
      if (tabs && tabs.length > 0) {
        setActiveTab(tabs[0]);
      }

      const checkIsDesktop = () => {
        if (window.innerWidth >= 768) {
          // If desktop, set spec selector table to be open
          setSpecSelectorOpen(true);
        }
      };
      // Set resize listener
      window.addEventListener("resize", checkIsDesktop);
      return () => {
        // Remove resize listener
        window.removeEventListener("resize", checkIsDesktop);
      };
    }, []);

    // Handles Spec click
    const handleSpecClick = (item) => {
      setActiveTab(item); // set active spec item

      if (isMobile) {
        // If mobile view, close specs selector after selection
        setSpecSelectorOpen(!setSpecSelectorOpen);
      }
    };

    const _determineTabVisibility = () => {
      if (tabs) {
        tabs.forEach((tab) => {
          const { table1Rows, table2Rows } = tab;
          let table1HasValues = false;
          let table2HasValues = false;

          for (let row of table1Rows) {
            if (row.fieldName.split(",").find(tableHasValue)) {
              table1HasValues = true;
              break;
            }
          }

          for (let row of table2Rows) {
            if (row.fieldName.split(",").find(tableHasValue)) {
              table2HasValues = true;
              break;
            }
          }

          if (
            (table1HasValues || table2HasValues) &&
            visibleTabs.findIndex((item) => item.label === tab.label) === -1
          ) {
            setVisibleTabs((oldArray) => oldArray.concat(tab));
          }
        });
      }
    };

    function tableHasValue(element) {
      let value = product.productData[element.trim()];
      if (Array.isArray(value) && value.length === 0) {
        return false;
      } else {
        return !!value;
      }
    }

    const { document: documentRef } = component.getModels();
    const document = page.getContent(documentRef);
    const { product } = page.getComponent().getModels();

    //field name from the product data used to compare against a table row's field name to handle building the patent table data differently
    const FIELD_NAME_PATENTS = "patents";

    if (!document || !product || !product.productData) return null;

    const { title = null, tab: tabs = null } = document.getData();

    const buildSpecificationTable = () => {
      const {
        label,
        table1ToggleLabel,
        table2ToggleLabel,
        specValueColumnHeader: headerValue,
        specTitleColumnHeader: headerTitle,
        table1Rows,
        table2Rows,
      } = activeTab;

      const hasToggle =
        table1ToggleLabel && table2ToggleLabel && table1Rows?.length > 0 && table2Rows?.length > 0;
      const activeToggleTableRow = activeToggles.find((i) => i === `${label}-${table2ToggleLabel}`)
        ? table2Rows
        : table1Rows;

      let rows = !hasToggle ? table1Rows : activeToggleTableRow;
      let isPatentTable = rows.filter((i) => i.fieldName === FIELD_NAME_PATENTS)?.length > 0;

      let cleanedHeaderTitle = headerTextToId(headerTitle);
      let cleanedHeaderValue = headerTextToId(headerValue);

      return (
        <table className="product-spec__table">
          <caption className="product-spec__table-caption">{label}</caption>
          {headerTitle && headerValue ? (
            <thead className="product-spec__table--header">
              <tr className="product-spec__table--row">
                <th className="bold-bold" id={cleanedHeaderTitle} scope="col">{headerTitle}</th>
                <th className="bold-semi-bold" id={cleanedHeaderValue} scope="col">{headerValue}</th>
              </tr>
            </thead>
          ) : null}
          <tbody>
            {isPatentTable ? patentTableRows() : null}
            {!isPatentTable ? rows?.length > 0 && specTableRows(rows) : null}
          </tbody>
        </table>
      );
    };

    const patentTableRows = () => {
      return product.productData[FIELD_NAME_PATENTS].map((item, index) => {
        let row = item.split("|");
        //value is passed in as an array to match how non-patent data is output to the table row function
        return tableRow(index, row[0], new Array(row[1]));
      });
    };

    const specTableRows = (rows) => {
      return rows.map((row, index) => {
        //account for multiple field names being authored in a table row, ex. "recycledContentClaim, renewableContentClaim, sourceReducedClaim" vs just "recycledContentClaim"
        //the value we get from the fieldname(s) could be a number, string, or array of values so we also need to account for the type of value returned from the field(s)
        //loop through authored field names to get all values and build an array of values to display.
        const displayValues = [];

        row.fieldName.split(",").forEach(function (field) {
          let trimmedFieldName = field.trim();
          let fieldValue = product.productData[trimmedFieldName];

          if (
            trimmedFieldName === FIELDNAME_ITEMSPERCASE &&
            product.productData[trimmedFieldName] &&
            product.productData[FIELDNAME_EFFECTIVEUNITSPERCASEUOM]
          ) {
            fieldValue = fieldValue + " " + product.productData[FIELDNAME_EFFECTIVEUNITSPERCASEUOM];
          }

          if (fieldHasValue(trimmedFieldName, fieldValue) && Array.isArray(fieldValue)) {
            fieldValue.forEach((item) => {
              displayValues.push(item);
            });
          } else if (fieldHasValue(trimmedFieldName, fieldValue)) {
            displayValues.push(fieldValue);
          }
        });

        return tableRow(index, row.fieldTitle, displayValues, row.fieldUnits);
      });
    };

    function fieldHasValue(fieldName, fieldValue) {
      return !!(
        fieldValue ||
        (FIELDSTODISPLAYZEROVALUE.includes(fieldName) &&
          fieldValue !== undefined &&
          fieldValue !== null)
      );
    }

    const tableRow = (index, label, values, unit) => {
      if (values) {
        const cleanedLabel = headerTextToId(label);
        return (
          values.length > 0 && (
            <tr className="product-spec__table--row" key={`${activeTab.id}-${label}-${values.join("_")}-${index}`}>
              <th id={cleanedLabel} className="product-spec__table-label bold-bold" scope="row">
                <div className="product-spec__table-cell--line">{label}</div>
              </th>
              <td className="bold-semi-bold" header={cleanedLabel}>
                {values.map((item, index) => {
                  return (
                    <div className="product-spec__table-cell--line" key={`${index}-${item}`}>
                      {item} {unit}
                    </div>
                  );
                })}
              </td>
            </tr>
          )
        );
      }
      return null;
    };

    function headerTextToId(text) {
      return (text.replace(/\W/g,'').toLowerCase());
    }

    function handleToggle(e, specLabel, leftLabel, rightLabel) {
      if(specLabel === e.target.name) {
        if (rightLabel === e.target.value) {
          //toggle to right position - remove toggle left label and add toggle right label
          setActiveToggles((oldArray) =>
            oldArray
              .concat(`${specLabel}-${rightLabel}`)
              .filter((value) => value !== `${specLabel}-${leftLabel}`)
          );
        } else {
          //toggle to left position - remove toggle right label and add toggle left label
          setActiveToggles((oldArray) =>
            oldArray
              .concat(`${specLabel}-${leftLabel}`)
              .filter((value) => value !== `${specLabel}-${rightLabel}`)
          );
        }
      }
    }

    return (
      <div
        id={SPECSDOCTABID}
        className={`${page.isPreview() ? "has-edit-button" : ""} product-spec content-bound`}
      >
        <BrManageContentButton content={document} />

        <Desktop>
          <Title defaultLevel={HEADING_LEVELS.TWO} className="product-spec__title">
            {title}
          </Title>
        </Desktop>
        <div className="product-spec__wrapper">
          <div className="product-spec__list-wrapper">
            {activeTab && (
              <Mobile>
                <button
                  className={`product-spec__mobile-selected-spec-btn ${
                    specSelectorOpen ? "active" : ""
                  }`}
                  onClick={() => setSpecSelectorOpen(!specSelectorOpen)}
                >
                  {activeTab.label}
                  {specSelectorOpen ? (
                    <span className="darticon-arrow-up"></span>
                  ) : (
                    <span className="darticon-arrow-down"></span>
                  )}
                </button>
              </Mobile>
            )}
            {specSelectorOpen && (
              <ul className="product-spec__list">
                {visibleTabs &&
                  visibleTabs.length > 0 &&
                  visibleTabs.map((item, index) => {
                    item.id = `${index}-${item.label}`;
                    return (
                      <li
                        key={item.id}
                        className={`product-spec__list-item ${
                          activeTab?.label === item.label ? "active" : ""
                        }`}
                      >
                        <button
                          className="product-spec__list-item-link"
                          onClick={() => handleSpecClick(item)}
                          aria-pressed={item == activeTab ? "true" : "false"}
                          type="button"
                          aria-label={item.label}
                          title={item.label}
                          id={index == 0 ? `${SPECSDOCTABID}_focus` : null}
                        >
                          {item.label}
                          <Desktop>
                            <span aria-hidden="true" className="darticon-arrow"></span>
                          </Desktop>
                        </button>
                      </li>
                    );
                  })}
              </ul>
            )}
          </div>
          {activeTab && (
            <div className="product-spec__info-wrapper">
              {activeTab.table1ToggleLabel &&
                activeTab.table2ToggleLabel &&
                activeTab.table1Rows?.length > 0 &&
                activeTab.table2Rows?.length > 0 && (
                  <Toggle
                    selected={
                      activeToggles.filter(
                        (i) => i === `${activeTab.label}-${activeTab.table2ToggleLabel}`
                      ).length > 0
                        ? activeTab.table2ToggleLabel
                        : activeTab.table1ToggleLabel
                    }
                    name={activeTab.label}
                    label1={activeTab.table1ToggleLabel}
                    label2={activeTab.table2ToggleLabel}
                    onChange={(e) =>
                      handleToggle(
                        e,
                        activeTab.label,
                        activeTab.table1ToggleLabel,
                        activeTab.table2ToggleLabel
                      )
                    }
                    onKeyUp={handleToggle}
                    item={activeTab}
                    ariaLabel={activeTab.label}
                  />
                )}
              {buildSpecificationTable()}
              {activeTab.disclaimer?.value && (
                <Description
                  className="product-spec__disclaimer"
                  value={activeTab.disclaimer?.value}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  } catch (e) {
    console.error("ProductDetailSpecifications error", e);
    return <ErrorComponent error={e} componentName={"ProductDetailSpecifications"} />;
  }
}
export default ProductDetailSpecifications;
