import "./FilterOptions.scss";
import { Desktop, Mobile } from "../../../media-queries/MediaQueries";
import MobileFilterHeading from "./MobileFilterHeading";
import { useSearchFilterContext } from "./hooks";

function FilterOptions({ group }) {
  const {
    facets: { activeFacets, addFacet, removeFacet },
    closeFilters,
  } = useSearchFilterContext();

  function handleCheckboxChange(e) {
    if (e.target.checked) {
      addFacet(e.target.value);
    } else {
      removeFacet(e.target.value);
    }
  }

  return (
    <div className="filter-options">
      {group && (
        <Mobile>
          <MobileFilterHeading
            title={group.name ? group.name : group.mobileName}
            groupType={group.type}
            showBackBtn={true}
          />
        </Mobile>
      )}
      <fieldset className="filter-options__item-wrapper">
        {group?.facets &&
          group.facets.map((item, index) => {
            const labelId = `filter-options-${item.id}-label-${index}`;
            const inputId = `filter-options-${item.id}-input-${index}`;
            const value = `${group.id}:${item.id}`;
            const checked = activeFacets.filter((i) => i.id === value).length > 0;
            return  (
              <div key={index} className="radio-checkbox-contain">
                <label htmlFor={inputId}>
                  <Mobile>
                    {item.mobileName ? item.mobileName : item.name}
                  </Mobile>
                  <Desktop>
                    {item.desktopName ? item.desktopName : item.name}
                  </Desktop>
                </label>
                <div className="radio-checkbox-wrapper">
                  <input
                    id={inputId}
                    type="checkbox"
                    value={value}                
                    onChange={(e) => handleCheckboxChange(e)}
                    checked={checked}
                  />
                  <span aria-hidden="true" className="radio-checkbox-input"></span>
                </div>
              </div>
            )
          })}
        <Mobile>
          <button className="btn filter-options__btn" onClick={closeFilters}>
            {window.globalProperties?.["viewResultsLabel"]
              ? window.globalProperties?.["viewResultsLabel"]
              : "View Results"}
          </button>
        </Mobile>
      </fieldset>
    </div>
  );
}

export default FilterOptions;
