import { useContext, useEffect, useState } from "react";
import { BrPageContext, BrComponentContext } from "@bloomreach/react-sdk";
import NavigationContext from "./context";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import { DesktopNav, MobileNav } from "../../media-queries/MediaQueries";
import DesktopNavigation from "./DesktopNavigation";
import MobileNavigation from "./MobileNavigation";
import { getProperties } from "../../helpers/GlobalHelpers";
import { getProductsByCodes } from "../../helpers/GlobalHelpers";
import "./Navigation.scss";

const GLOBAL_CONFIG_HEADER_COMPONENT_NAME = "Global Header";

function Navigation() {
  const page = useContext(BrPageContext);
  const component = useContext(BrComponentContext);
  const { popularProductCodes, productServletURL, flyoutPopularProductCodes } =
    component.getModels("header");
  const [popularProducts, setPopularProducts] = useState([]);
  const [flyoutPopularProducts, setFlyoutPopularProducts] = useState([]);

  useEffect(() => {
    getPopularProducts();
  }, [page]);

  useEffect(() => {
    try {
      getPopularProducts();
    } catch {
      return;
    }
  }, []);

  const getPopularProducts = () => {
    getProductsByCodes(popularProductCodes, productServletURL).then((data) => {
      setPopularProducts(data);
    });

    getProductsByCodes(flyoutPopularProductCodes, productServletURL).then((data) => {
      setFlyoutPopularProducts(data || []);
    });
  };

  try {
    const { document: documentRef, hideComponent } = component.getModels();
    const document = page.getContent(documentRef);
    if (!document) return null;
    if (hideComponent) return null;

    const value = getProperties(component)(GLOBAL_CONFIG_HEADER_COMPONENT_NAME);

    const newFlyouts = {};
    flyoutPopularProducts.forEach((p) => {
      newFlyouts[p.productCode] = p;
    });

    value.popularProducts = popularProducts;
    value.flyoutPopularProducts = newFlyouts;

    return (
      <header className={`${page.isPreview() ? "has-edit-button" : ""} navigation`}>
        <div className="navigation__wrapper">
          <NavigationContext.Provider value={value}>
            <DesktopNav>
              <DesktopNavigation navDocument={document} />
            </DesktopNav>
            <MobileNav>
              <MobileNavigation navDocument={document} />
            </MobileNav>
          </NavigationContext.Provider>
        </div>
      </header>
    );
  } catch (e) {
    console.error("Navigation error", e);
    return <ErrorComponent error={e} componentName={"Navigation"} />;
  }
}

export default Navigation;
