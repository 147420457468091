import PropTypes from "prop-types";
import CtaLink from "./CtaLink";

// OO 12-28-2021
// Solution to avoid writing duplicate internal/external link logic already in CtaLink Component.
function LinkWrapper({ link, icon, queryString, children, ...props }) {
  return (
    <CtaLink link={link} queryString={queryString} {...props} aria-label={link.label} icon={icon}>
      {children}
    </CtaLink>
  );
}

LinkWrapper.propTypes = {
  link: PropTypes.object,
};

export default LinkWrapper;
