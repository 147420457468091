import React from "react";
import { BrPageContext, BrComponentContext } from "@bloomreach/react-sdk";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import { Helmet, HelmetData } from "react-helmet-async";

function SEOComponent() {

  const page = React.useContext(BrPageContext);
  const component = React.useContext(BrComponentContext);
  const { channelDisplayName } = page.getComponent("globalproperties").getModels();
  const helmetData = new HelmetData({});

  try {
    if (!document) return null;

    const { description = "", keywords = "", image = "", title = "", canonicalLink = "" } = component.getParameters();

    //this overrides the pages props one
    if (title && channelDisplayName) {
      // If both title and channelDisplayName exist
      window.document.title = title + " - " + channelDisplayName;
    } else if (title) {
      window.document.title = title;
    }
    let canonicalHref = window.location.href;
    if (canonicalLink) {
      canonicalHref = canonicalLink;
    }
    if (page.isPreview()) {
      return "Setup SEO head tags here";
    } else {
      return (
        <Helmet helmetData={helmetData}>
          {image && <meta property="og:image" content={image} />}

          {canonicalHref && <link rel="canonical" href={canonicalHref} />}
          {title && <meta property="og:title" content={title} />}
          {description && <meta property="og:description" content={description} />}
          {description && <meta name="description" content={description} />}
          {keywords && <meta name="keywords" content={keywords} />}
        </Helmet>
      );
    }
  } catch (e) {
    console.error("SEO Component error", e);
    return <ErrorComponent error={e} componentName={"SEOComponent"} />;
  }
}

export default SEOComponent;
