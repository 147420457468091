import SiteMessages from "./SiteMessages";
import CookieServiceProvider from "./CookieServiceProvider";
import SiteMessagesProvider from "./SiteMessagesProvider";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";

// Use when 'sitemessages' component mapped to layout
function ContainerSiteMessages({ component, page }) {
  try {
    const { document: documentRef } = component.getModels();
    const document = page.getContent(documentRef);

    if (!document) return null;

    const { siteMessages = null } = document.getData();

    return (
      <CookieServiceProvider>
        <SiteMessagesProvider siteMessages={siteMessages}>
          <SiteMessages />
        </SiteMessagesProvider>
      </CookieServiceProvider>
    );
  } catch (e) {
    console.error("ContainerSiteMessages error", e);
    return <ErrorComponent error={e} componentName={"ContainerSiteMessages"} />;
  }
}

export default ContainerSiteMessages;
