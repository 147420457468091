import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BrPageContext, BrComponentContext, BrManageContentButton } from "@bloomreach/react-sdk";
import "./RichText.scss";
import ErrorComponent from "../ErrorComponent/ErrorComponent";

function RichText() {
  const page = useContext(BrPageContext);
  const component = useContext(BrComponentContext);
  let location = useLocation();

  useEffect(()=>{
    //delayed hash follow
    if(location.hash && location.hash.length > 1){
      var jumpLink = window.document.getElementById(location.hash.substring(1));
      if(jumpLink){
        jumpLink.scrollIntoView(true);
        // a flag set for ScrollTop.jsx to not scroll to the top automatically
        window.noScrollTo = true;
      }
    }
  }, []);

  try {
    const { document: documentRef } = component.getModels();
    const document = page.getContent(documentRef);

    if (!document) return null;

    const { componentStyle } = component.getParameters();
    const { richText = null } = document.getData();

    return (
      <div
        className={`${
          page.isPreview() ? "has-edit-button" : ""
        } rich-text rich-text--${componentStyle}`}
      >
        <BrManageContentButton content={document} />
        {richText?.value && (
          <div
            className="rich-text__wrapper"
            dangerouslySetInnerHTML={{
              __html: page?.rewriteLinks(richText.value) ?? "",
            }}
          />
        )}
      </div>
    );
  } catch (e) {
    console.error("RichText error", e);
    return <ErrorComponent error={e} componentName={"RichText"} />;
  }
}

export default RichText;
