import React, { useState, useEffect, createContext } from "react";
import { animateScroll } from "react-scroll";
import { useLocation } from "react-router-dom";
import ScrollTop from "./ScrollTop/ScrollTop";
import ContactLink from "./ContactLink/ContactLink";

const StickyButtonsContext = createContext();

function StickyButtons() {
  const [isScrolled, setIsScrolled] = useState(false);

  // On component mount, add the toggle listener.
  // On component dismount, remove the listener.
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const toggleVisibility = () => {
    // Get the content distance above the current view if the user has scrolled
    const scrolledDistance = window.pageYOffset || document.documentElement.scrollTop;

    // Show button once second viewport content is in view
    if (scrolledDistance > window.innerHeight) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };


  return (
    <StickyButtonsContext.Provider value={isScrolled}>
      <ScrollTop StickyButtonsContext={StickyButtonsContext} />
      <ContactLink StickyButtonsContext={StickyButtonsContext} />
    </StickyButtonsContext.Provider>
  );
}

export default StickyButtons;
