import React from "react";
import { BrPageContext, BrComponentContext } from "@bloomreach/react-sdk";
import "./AuthoredTileContainer.scss";
import { PRIMARY, HEADING_LEVELS } from "../../../Global/GlobalConstants";
import SimpleAuthoredTile from "../SimpleAuthoredTile/SimpleAuthoredTile";
import DetailedAuthoredTile from "../DetailedAuthoredTile/DetailedAuthoredTile";
import ErrorComponent from "../../ErrorComponent/ErrorComponent";
import Title from "../../../Global/Title/Title";

function AuthoredTileContainer() {
  const page = React.useContext(BrPageContext);
  const component = React.useContext(BrComponentContext);

  try {
    const { document: documentRef } = component.getModels();
    const { componentStyle = PRIMARY, layout, headerLevel, basicWidth, basicHeight } = component.getParameters();
    const document = page.getContent(documentRef);

    if (!document) return null;

    const { title = null, listContents = null } = document.getData();

    return (
      <div className={`authored-tile-container theme-${componentStyle}`}>
        <div className="authored-tile-container__wrapper">
          <div className="authored-tile-container__header">
            {title && 
              <Title
                defaultLevel={HEADING_LEVELS.TWO}
                headingLevel={headerLevel}
                className="authored-tile-container__heading"
              >
                {title}
              </Title>
            }
          </div>

          <div
            className={`authored-tile-container__tiles authored-tile-container__tiles--${layout}`}
          >
            {listContents &&
              listContents.map((item, index) =>
                layout === "detailed" ? (
                  <DetailedAuthoredTile
                    tile={item}
                    key={`${index}_${item.id}`}
                    componentStyle={componentStyle}
                    basicHeight={basicHeight}
                    basicWidth={basicWidth}
                  />
                ) : (
                  <SimpleAuthoredTile
                    tile={item}
                    key={`${index}_${item.id}`}
                    componentStyle={componentStyle}
                    basicHeight={basicHeight}
                    basicWidth={basicWidth}
                  />
                )
              )}
          </div>
        </div>
      </div>
    );
  } catch (e) {
    console.error("AuthoredTileContainer error", e);
    return <ErrorComponent error={e} componentName={"AuthoredTileContainer"} />;
  }
}

export default AuthoredTileContainer;
