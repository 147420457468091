import { useContext, useEffect, useState } from "react";
import { BrPageContext, BrComponentContext } from "@bloomreach/react-sdk";
import Description from "../Global/Description";
import { AnchorProductTile } from "../Common/Tiles/ProductTile/NonFeatured/NonFeaturedProductTile";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import "./NoResults.scss";
import Title from "../Global/Title/Title";
import { HEADING_LEVELS } from "../Global/GlobalConstants";
import { getProductsByCodes } from "../../helpers/GlobalHelpers";
import { getUrlPath } from "../../helpers/LinkHelper";

const PRODUCT_LIMIT = 4;
const PLACEHOLDER = "{}";

function NoResults({ searchValue = "", suggestions = [], noResultsPopularProductCodes, productServletURL}) {
  const page = useContext(BrPageContext);
  const component = useContext(BrComponentContext);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    async function getProductData() {
      await getProductsByCodes(noResultsPopularProductCodes, productServletURL).then((data) => {
        setProducts(data)
      })
    }
    getProductData();
  }, [])

  try {
    const { document: documentRef } = component.getModels();
    const document = page.getContent(documentRef);
    if (!document) return null;
    const {
      noResultsTitle: title = "",
      noResultsDescription: description = "",
      noResultsDidYouMeanTitle: suggestionsTitle = "",
      noResultsNeedHelpTitle: guideTitle = "",
      noResultsNeedHelpDescription: guideDescription = {},
      noResultsPopularProductsTitle: popularProductsTitle = "",
      noResultsPopularProducts: popularProducts = [],
    } = document.getData();

    const styledSearchValue = `<span class="no-results__header__caption__search-value bold-bold">&ldquo;${searchValue}&rdquo;</span>`;

    return (
      <section className="no-results">
        <div className="no-results__header">
          {title && (
            <Title defaultLevel={HEADING_LEVELS.ONE} className="no-results__header__title">
              {title}
            </Title>
          )}
          {description && (
            <p
              className="no-results__header__caption bold-medium"
              dangerouslySetInnerHTML={{
                __html: description.replace(PLACEHOLDER, styledSearchValue),
              }}
            />
          )}
        </div>
        <div className="no-results__aid">
          {suggestions.length > 0 && (
            <div className="no-results__aid__suggestions">
              <Title
                defaultLevel={HEADING_LEVELS.THREE}
                className="no-results__aid__suggestions__title"
              >
                {suggestionsTitle}
              </Title>
              <ul className="no-results__aid__suggestions__list">
                {suggestions.map((suggestion, index) => {
                  return (
                    <li
                      key={`${suggestion}-${index}`}
                      className="no-results__aid__suggestions__list__item bold-semi-bold"
                    >
                      {suggestion}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <div className="no-results__aid__guide">
            <Title defaultLevel={HEADING_LEVELS.THREE} className="no-results__aid__guide__title">
              {guideTitle}
            </Title>
            {guideDescription?.value && (
              <Description
                className="no-results__aid__guide__caption  bold-medium"
                value={guideDescription.value}
              />
            )}
          </div>
        </div>
        <div className="no-results__popular-products">
          {popularProductsTitle && (
            <Title
              defaultLevel={HEADING_LEVELS.THREE}
              className="no-results__popular-products__title"
            >
              {popularProductsTitle}
            </Title>
          )}
          {products.length > 0 && (
            <div className="no-results__popular-products__grid">
              {products.map((product, index) => {
                return (
                  <AnchorProductTile
                    key={`${product.productCode}-${index}`}
                    product={{
                      productCode: product.productCode,
                      image: product.catalogImage,
                      title: product.childProductName,
                      url: getUrlPath(product.url)
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>
      </section>
    );
  } catch (e) {
    return <ErrorComponent error={e} componentName={NoResults.displayName || NoResults.name} />;
  }
}

export default NoResults;
