import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import NavigationSearch from "./NavigationSearch";
import CtaLink from "../Global/CtaLink";
import Flyout from "./Flyout";
import Image from "../Global/Image/Image";
import { useOverrideDimensions } from "./hooks";
import "./DesktopNavigation.scss";
import { GetChannelRoot } from "../../helpers/LinkHelper";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

const NAV_ONE = "nav-1";
const NAV_TWO = "nav-2";
const NAV_THREE = "nav-3";
const NAV_FOUR = "nav-4";

function DesktopNavigation({ navDocument }) {
  const sendDataToGTM = useGTMDispatch();
  const [searchOpen, setSearchOpen] = useState(false);
  //DEFAULT OPEN NAV IS NONE
  const [activeNav, setActiveNav] = useState("");
  const isFirstRender = useRef(true);
  const desktopRef = useRef();
  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(desktopRef, () => {
    setSearchOpen(false);
    setActiveNav("");
  });

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // toggle flag after first render/mounting
      return;
    }
  });

  const {
    logoAltText = null,
    logo = null,
    mainMenuLinkLabelOne = null,
    mainMenuLinkLabelTwo = null,
    mainMenuLinkLabelThree = null,
    mainMenuLinkLabelFour = null,
    howToBuyLink = null,
    regionSelectLink = null,
    viewMyListLink = null,
    mainMenuLinkFlyoutOne = null,
    mainMenuLinkFlyoutTwo = null,
    mainMenuLinkFlyoutThree = null,
    mainMenuLinkFlyoutFour = null,
    searchButtonLabel = null,
  } = navDocument.getData();

  const { logoWidth = 0, logoHeight = 0 } = useOverrideDimensions();
  const defaultHeaderLogoWidth = "170";
  const defaultHeaderLogoHeight = "62";

  //set active nav item, or if same nav item unset
  const _toggleNavItems = (navItem) => {
    if (activeNav === navItem || navItem === -1) {
      setActiveNav("");
    }
    else {
      setActiveNav(navItem);
    }
  };

  let howToBuyCheck = true;
  if (!howToBuyLink.internalLink && !howToBuyLink.externalLink) {
    howToBuyCheck = false;
  } else if (!howToBuyLink.label) {
    howToBuyCheck = false;
  }

  const sendHeaderToGtm = (level) => {
    sendDataToGTM({
      event: "navigationClick",
      navigationType: "header",
      navigationLevel: level,
    });
  };

  return (
    <div className={`desktop-navigation`} ref={desktopRef}>
      <div className="desktop-navigation__content">
        <div className="desktop-navigation__utility-nav">
          <div className="desktop-navigation__utility-nav-inner">
            <ul className="desktop-navigation__utility-list">
              {regionSelectLink && (
                <li className="desktop-navigation__utility-list-item">
                  <CtaLink
                    link={regionSelectLink}
                    id="goTop"
                    icon="desktop-navigation__utility-list-item-link-icon darticon-region"
                    className={`desktop-navigation__utility-list-item-link`}
                  />
                </li>
              )}
              {viewMyListLink && (
                <li className="desktop-navigation__utility-list-item">
                  <CtaLink
                    link={viewMyListLink}
                    icon="desktop-navigation__utility-list-item-link-icon darticon-viewlist"
                    className={`desktop-navigation__utility-list-item-link`}
                  />
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="desktop-navigation__main-nav-area">
          <Link to={GetChannelRoot}>
            <Image
              className="desktop-navigation__logo"
              src={logo}
              alt={logoAltText}
              width={Number(logoWidth) === 0 ? defaultHeaderLogoWidth : logoWidth}
              height={Number(logoHeight) === 0 ? defaultHeaderLogoHeight : logoHeight}
            />
          </Link>
          <nav className="desktop-navigation__main-nav">
            <ul className="desktop-navigation__main-nav-list">
              {mainMenuLinkLabelOne && (
                <li className="desktop-navigation__main-nav-list-item">
                  <button
                    type="button"
                    aria-expanded={activeNav === NAV_ONE ? true : false}
                    className={`desktop-navigation__main-nav-list-item-link ${
                      activeNav === NAV_ONE ? "active" : ""
                    }`}
                    onClick={() => {
                      sendHeaderToGtm(mainMenuLinkLabelOne);
                      _toggleNavItems(NAV_ONE);
                    }}
                    onFocus={()=>{
                      if(activeNav !== NAV_ONE) _toggleNavItems(-1);
                    }}
                  >
                    {mainMenuLinkLabelOne}
                    <span className="desktop-navigation__main-nav-list-item-link-icon-dropdown darticon-arrow-down" aria-hidden="true"></span>
                    <span className="desktop-navigation__main-nav-list-item-link-icon-active darticon-arrow-up" aria-hidden="true"></span>
                  </button>
                    {activeNav && activeNav === NAV_ONE && mainMenuLinkFlyoutOne && (
                        <div className="desktop-navigation__flyout-wrapper">
                          <Flyout
                            flyoutHeaderLabel={mainMenuLinkLabelOne}
                            flyoutRef={mainMenuLinkFlyoutOne}
                            closeActiveSubNav={setActiveNav}
                          />
                        </div>
                    )}
                </li>
              )}
              {mainMenuLinkLabelTwo && (
                <li className="desktop-navigation__main-nav-list-item">
                  <button
                    type="button"
                    aria-expanded={activeNav === NAV_TWO ? true : false}
                    className={`desktop-navigation__main-nav-list-item-link ${
                      activeNav === NAV_TWO ? "active" : ""
                    }`}
                    onClick={() => {
                      sendHeaderToGtm(mainMenuLinkLabelTwo);
                      _toggleNavItems(NAV_TWO);
                    }}
                    onFocus={()=>{
                      if(activeNav !== NAV_TWO) _toggleNavItems(-1);
                    }}
                  >
                    {mainMenuLinkLabelTwo}
                    <span className="desktop-navigation__main-nav-list-item-link-icon-dropdown darticon-arrow-down" aria-hidden="true"></span>
                    <span className="desktop-navigation__main-nav-list-item-link-icon-active darticon-arrow-up" aria-hidden="true"></span>
                  </button>
                    {activeNav && activeNav === NAV_TWO && mainMenuLinkFlyoutTwo && (
                        <div className="desktop-navigation__flyout-wrapper">
                          <Flyout
                            flyoutHeaderLabel={mainMenuLinkLabelTwo}
                            flyoutRef={mainMenuLinkFlyoutTwo}
                            closeActiveSubNav={setActiveNav}
                          />
                        </div>
                    )}
                </li>
              )}
              {mainMenuLinkLabelThree && (
                <li className="desktop-navigation__main-nav-list-item">
                  <button
                    type="button"
                    aria-expanded={activeNav === NAV_THREE ? true : false}
                    className={`desktop-navigation__main-nav-list-item-link ${
                      activeNav === NAV_THREE ? "active" : ""
                    }`}
                    onClick={() => {
                      sendHeaderToGtm(mainMenuLinkLabelThree);
                      _toggleNavItems(NAV_THREE);
                    }}
                    onFocus={()=>{
                      if(activeNav !== NAV_THREE) _toggleNavItems(-1);
                    }}
                  >
                    {mainMenuLinkLabelThree}
                    <span className="desktop-navigation__main-nav-list-item-link-icon-dropdown darticon-arrow-down" aria-hidden="true"></span>
                    <span className="desktop-navigation__main-nav-list-item-link-icon-active darticon-arrow-up" aria-hidden="true"></span>
                  </button>
                    {activeNav && activeNav === NAV_THREE && mainMenuLinkFlyoutThree && (
                        <div className="desktop-navigation__flyout-wrapper">
                          <Flyout
                            flyoutHeaderLabel={mainMenuLinkLabelThree}
                            flyoutRef={mainMenuLinkFlyoutThree}
                            closeActiveSubNav={setActiveNav}
                          />
                        </div>
                    )}
                </li>
              )}
              {mainMenuLinkLabelFour && (
                <li className="desktop-navigation__main-nav-list-item">
                  <button
                    type="button"
                    aria-expanded={activeNav === NAV_FOUR ? true : false}
                    className={`desktop-navigation__main-nav-list-item-link ${
                      activeNav === NAV_FOUR ? "active" : ""
                    }`}
                    onClick={() => {
                      sendHeaderToGtm(mainMenuLinkLabelFour);
                      _toggleNavItems(NAV_FOUR);
                    }}
                    onFocus={()=>{
                      if(activeNav !== NAV_FOUR) _toggleNavItems(-1);
                    }}
                  >
                    {mainMenuLinkLabelFour}
                    <span className="desktop-navigation__main-nav-list-item-link-icon-dropdown darticon-arrow-down" aria-hidden="true"></span>
                    <span className="desktop-navigation__main-nav-list-item-link-icon-active darticon-arrow-up" aria-hidden="true"></span>
                  </button>
                    {activeNav && activeNav === NAV_FOUR && mainMenuLinkFlyoutFour && (
                        <div className="desktop-navigation__flyout-wrapper">
                          <Flyout
                            flyoutHeaderLabel={mainMenuLinkLabelFour}
                            flyoutRef={mainMenuLinkFlyoutFour}
                            closeActiveSubNav={setActiveNav}
                          />
                        </div>
                    )}
                </li>
              )}
            </ul>
          </nav>
          <div className="desktop-navigation__actions-wrapper" onFocus={()=>{_toggleNavItems(-1)}}>
            {howToBuyCheck && (
              <CtaLink
                onClick={() => sendHeaderToGtm(howToBuyLink.label)}
                link={howToBuyLink}
                onFocus={()=>{_toggleNavItems(-1)}}
                icon="desktop-navigation__how-to-buy-btn-icon darticon-webpages"
                className={`desktop-navigation__how-to-buy-btn btn btn--secondary`}
              />
            )}
            <button
              type="button"
              aria-expanded={searchOpen}
              className="desktop-navigation__serach-btn btn btn--secondary"
              onFocus={()=>{_toggleNavItems(-1)}}
              onClick={() => {
                sendHeaderToGtm(searchButtonLabel);
                setSearchOpen(!searchOpen);
              }}
            >
              <span className="desktop-navigation__serach-btn-icon-dropdown darticon-search"></span>
              {searchButtonLabel}
            </button>
          </div>
        </div>
        {searchOpen && (
          <div className="desktop-navigation__search-wrapper">
            <NavigationSearch
              setSearchOpen={setSearchOpen}
              navDocument={navDocument}
            ></NavigationSearch>
          </div>
        )}
      </div>
      <div className="desktop-navigation__spacer"></div>
    </div>
  );
}

export default DesktopNavigation;
