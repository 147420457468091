import { useContext } from "react";
import { SearchInputFilterContext } from "./context";

export const useSearchInputFilterContext = () => {
  const context = useContext(SearchInputFilterContext);
  if (!context) {
    throw new Error(
      "useSearchInputFilterContext must be used within a SearchInputFilterContext.Provider"
    );
  }

  return context;
};
