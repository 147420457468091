import React, { useState } from "react";
import "./DesktopFilter.scss";
import FilterOptions from "./FilterOptions";
import Toggle from "../../Global/Toggle";
import { useSearchFilterContext } from "./hooks";

function DesktopFilter() {
  const {
    filterTitle,
    defaultToggleValues,
    facetGroups: { groups },
  } = useSearchFilterContext();

  const [expandedFilterGroups, setExpandedFilterGroups] = useState([]);
  //tracks the active values for all toggle switches
  const [toggleValues, setToggleValues] = useState(defaultToggleValues);

  const getOptions = (fg) => {
    let selectedGroup = null;
    if (expandedFilterGroups.includes(fg.name)) {
      if (!fg.toggleGroups) {
        selectedGroup = fg;
      } else if (fg.toggleGroups.length === 1 && fg.toggleGroups[0]) {
        selectedGroup = fg.toggleGroups[0];
      } else {
        fg.toggleGroups.forEach((tg) => {
          if (toggleValues.includes(tg.desktopName)) {
            selectedGroup = tg;
          }
        });
      }
    }
    return (
      <div className="desktop-filter__option-wrapper">
        <FilterOptions group={selectedGroup} />
      </div>
    );
  };

  function updateVisibleFilters(item) {
    if (expandedFilterGroups.includes(item.name)) {
      //remove item
      setExpandedFilterGroups((oldArray) => oldArray.filter((value) => value !== item.name));
    } else {
      //add item
      setExpandedFilterGroups((oldArray) => oldArray.concat(item.name));
    }
  }

  function handleSwitchChange(e, group) {
    if(!group || !group.toggleGroups) return;
    if (group.name === e.target.name) {
      if (group.toggleGroups[1].desktopName === e.target.value) {
        //toggle to right position - remove toggle left label and add toggle right label
        setToggleValues((oldArray) =>
          oldArray
            .concat(group.toggleGroups[1].desktopName)
            .filter((value) => value !== group.toggleGroups[0].desktopName)
        );
      } else {
        //toggle to left position - remove toggle right label and add toggle left label
        setToggleValues((oldArray) =>
          oldArray
            .concat(group.toggleGroups[0].desktopName)
            .filter((value) => value !== group.toggleGroups[1].desktopName)
        );
      }
    }
  }

  return (
    <div className={`desktop-filter`}>
      <div className="desktop-filter__header-wrapper">
        <div className="desktop-filter__header">
          <span className="desktop-filter__title" role="heading" aria-level="3">{filterTitle}</span>
        </div>
      </div>

      {groups && (
        <div className="desktop-filter__group-wrapper">
          {groups.map((item, index) => (
            <fieldset className="desktop-filter__group" key={item.id}>
              <div className="desktop-filter__group-heading">
                <button
                  className="desktop-filter__group-btn"
                  onClick={() => updateVisibleFilters(item)}
                  aria-expanded={expandedFilterGroups.includes(item.name)}
                >
                  <span
                    className={`desktop-filter__group-title-icon ${
                      expandedFilterGroups.includes(item.name)
                        ? "darticon-arrow-up"
                        : "darticon-arrow-down"
                    }`}
                  ></span>
                  <legend role="heading" aria-level="1" className="desktop-filter__group-title">{item.name}</legend>
                </button>
                {item.toggleGroups && item.toggleGroups.length === 2 && (
                  <Toggle
                    selected={
                      toggleValues.filter((i) => i === item.toggleGroups[1].desktopName).length > 0
                        ? item.toggleGroups[1]?.desktopName
                        : item.toggleGroups[0]?.desktopName
                    }
                    label1={item.toggleGroups[0]?.desktopName}
                    label2={item.toggleGroups[1]?.desktopName}
                    onChange={(e) => handleSwitchChange(e, item)}
                    onKeyUp={handleSwitchChange}
                    item={item}
                    name={item.name}
                    ariaLabel={item.name}
                  />
                )}
              </div>

              {expandedFilterGroups.includes(item.name) && getOptions(item)}
            </fieldset>
          ))}
        </div>
      )}
    </div>
  );
}

export default DesktopFilter;
