import React from "react";
import SearchTextbox from "../../../Global/SearchTextbox";
import Description from "../../../Global/Description";
import "./SearchTile.scss";
import Title from "../../../Global/Title/Title";
import { HEADING_LEVELS } from "../../../Global/GlobalConstants";

function SearchTile({ tile, componentStyle, isCondensed }) {
  if (!tile) return null;

  const {
    title,
    newWindow,
    description,
    formSubmitUrl,
    formSubmitQueryParameter,
    searchPlaceholder,
    searchInputLabel,
  } = tile.getData();

  return (
    <div className={`search-tile search-tile--${componentStyle} ${isCondensed ? "condensed" : ""}`}>
      {title && (
        <Title defaultLevel={HEADING_LEVELS.THREE} className={`search-tile__header ${isCondensed ? "condensed" : ""}`}>
          {title}
        </Title>
      )}
      {description?.value && (
        <Description className="search-tile__description" value={description.value} />
      )}
      <div className={`search-tile__search ${isCondensed ? "condensed" : ""}`}>
        {searchInputLabel && <label className="search-tile__label">{searchInputLabel}</label>}
        <SearchTextbox
          submitUrl={formSubmitUrl}
          queryParameter={formSubmitQueryParameter}
          placeholder={searchPlaceholder}
          theme={componentStyle}
          newWindow={newWindow}
        />
      </div>
    </div>
  );
}

export default SearchTile;
