import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDesktopMediaQuery } from "../../../media-queries/MediaQueries";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import "./HeaderToolbar.scss";
import ErrorComponent from "../ErrorComponent/ErrorComponent";

function HeaderToolbar({ hideBreadcrumbs = true, breadcrumbs = [] }) {
  const isDesktop = useDesktopMediaQuery();
  const { pathname } = useLocation();
  const [condensedBreadCrumbs, setCondensedBreadCrumbs] = useState(!isDesktop);

  const expandBreadCrumbs = () => {
    setCondensedBreadCrumbs(false);
  };

  useEffect(() => {
    // Expand breadcrumbs on viewport change (Mobile -> Desktop)
    if (isDesktop) {
      setCondensedBreadCrumbs(false);
    } else {
      setCondensedBreadCrumbs(true);
    }
  }, [isDesktop]);

  useEffect(() => {
    // Condense breadcrumbs on page navigation when on mobile
    if (!isDesktop) {
      setCondensedBreadCrumbs(true);
    }
  }, [pathname, isDesktop]);
  try {
    if (hideBreadcrumbs) return null;

    return (
      <div
        className={`header-toolbar ${
          condensedBreadCrumbs ? "header-toolbar--breadcrumbs-condensed" : ""
        }`}
      >
        {!hideBreadcrumbs && (
          <BreadCrumbs
            breadcrumbs={breadcrumbs}
            condensed={condensedBreadCrumbs}
            expandCondensed={expandBreadCrumbs}
          />
        )}
      </div>
    );
  } catch (e) {
    console.error("HeaderToolbar error", e);
    return <ErrorComponent error={e} componentName={"HeaderToolbar"} />;
  }
}

export default HeaderToolbar;
