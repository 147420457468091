import React, { useState, useEffect, useRef } from "react";
import { BrPageContext, BrComponentContext, BrManageContentButton } from "@bloomreach/react-sdk";
import SearchToolbar from "./SearchToolbar/SearchToolbar";
import SearchToolbarInfo from "./SearchToolbar/SearchToolbarInfo";
import Filter from "./Filter/Filter";
import ProductAndProductFamilyCard from "./ProductAndProductFamilyCard";
import SingleTypeSearchCard from "./SingleTypeSearchCard";
import MaterialGuideCard from "./MaterialGuideCard";
import { SearchToolbarContext } from "./SearchToolbar/context";
import { SearchFilterContext } from "./Filter/context";
import { SearchInputFilterContext } from "./SearchInputFilter/context";
import { SearchRefinementContext } from "./SearchRefinementController/context";
import { REFINEMENTS, SORT_OPTION, CONTENT_TYPE } from "../Global/GlobalConstants";
import "./SearchController.scss";
import SearchInputFilter from "./SearchInputFilter/SearchInputFilter";
import SearchRefinementsController from "./SearchRefinementController/SearchRefinementsController";
import { Desktop, Mobile } from "../../media-queries/MediaQueries";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import SearchInput from "./SearchInput/SearchInput";
import NoResults from "../SiteSearchResults/NoResults";
import ProductPatentCard from "./ProductPatentCard";
import { parseActiveFacet } from "./../../helpers/GlobalHelpers";
import LoadingSpinner from "../Global/LoadingSpinner/LoadingSpinner";

function SearchController({ isSingleTypeSearch }) {
  const page = React.useContext(BrPageContext);
  const component = React.useContext(BrComponentContext);

  const NUMBERTOLOADMORE = 10;
  const NUMOFROWS = 10; // this should get replaced with a returned value for how many products per page
  const urlParams = new URLSearchParams(window.location.search);

  const CONTENTTYPEPRODUCT = "product";
  const CONTENTTYPEPRODUCTFAMILY = "productFamily";
  let {
    document: documentRef,
    servletUrl, // curated listing only
    brsmSearchURL, // primary search url
    patentSearchServletURL, // patent only
    componentConfigurationPath,
    noResultsPopularProductCodes,
    productServletURL,
  } = component.getModels();

  let configurationPath;
  if (componentConfigurationPath) {
    configurationPath =
      componentConfigurationPath?.substring(0, componentConfigurationPath.lastIndexOf("/") + 1) +
      encodeURIComponent(
        componentConfigurationPath?.substring(componentConfigurationPath.lastIndexOf("/") + 1)
      );
  }
  const {
    hideSort,
    defaultSort = "featured",
    productHeight,
    productWidth,
  } = component.getParameters();

  // coming from location x, y, etc - configPath (home in BE, so BE knows it's doing search under specific section)

  const { CURATEDLISTING, SITESEARCHRESULTS, PATENTSEARCHLISTING } = CONTENT_TYPE;

  const SORTALPHABETICAL = "alphaAtoZ";
  const SORTMOSTRECENT = "mostRecent";

  const CONTENTTYPEARTICLE = "article";
  const CONTENTTYPEMEDIA = "mediaCollection";
  const CONTENTTYPEDOCUMENT = "document";
  const CONTENTTYPEWEBPAGE = "webpage";
  const CONTENTTYPEMATERIAL = "material";
  const CONTENTTYPEPATENTFS = "foodservicePatent";
  const CONTENTTYPEPATENTRETAIL = "retailPatent";
  const CATEGORYFILTER = "categories:";

  const FILTERS_URL_PARAMETER_NAME = "filters";
  const EXTENDED_FILTERS_URL_PARAMETER_NAME = "useExtendedFilters";

  const [searchResults, setSearchResults] = useState(null);
  const [autoCorrectOverride, setAutoCorrectOverride] = useState(null);
  const [activeFacets, setActiveFacets] = useState([]);
  const [sortValue, setSortValue] = useState(defaultSort);
  const [searchTerm, setSearchTerm] = useState(urlParams.get("q") ? urlParams.get("q") : "");
  const [renderedSearchResults, setRenderedSearchResults] = useState(null);
  const [numberToShow, setNumberToShow] = useState(NUMOFROWS);
  const [mobileFilterMenuOpen, setMobileFilterMenuOpen] = useState(false);
  const [mobileSelectedFacetGroup, setMobileSelectedFacetGroup] = useState(null);
  const [mobileSelectedToggleGroup, setMobileSelectedToggleGroup] = useState(null);
  const [filterFacetGroups, setFilterFacetGroups] = useState(null);
  const [refinements, setRefinements] = useState({ counts: [], didYouMean: [] });
  const [activeRefinement, setActiveRefinement] = useState(null);
  const [gotRefinements, setGotRefinements] = useState(false);
  const [loadingResults, setLoadingResults] = useState(true);
  const [pageNum, setPageNum] = useState(1);
  const [categoryFilter, setCategoryFilter] = useState("")

  //need a special var of when to listen for search updated needed
  const [reRunSearch, setReRunSearch] = useState(true);

  const isFirstRender = useRef(true);
  const isFirstRefinementGetRender = useRef(true);
  const isFirstReRender = useRef(true);
  const facetNameMap = useRef({});

  //props did update aka linking to another page, even with same component
  //reset all state items
  useEffect(() => {
    if (!isFirstReRender.current) {
      setSortValue(defaultSort);
      setActiveFacets([]);
      setSearchResults(null);
      setAutoCorrectOverride(null);
      setSearchTerm(urlParams.get("q") ? urlParams.get("q") : "");
      setRenderedSearchResults(null);
      setNumberToShow(NUMOFROWS);
      setMobileFilterMenuOpen(false);
      setMobileSelectedFacetGroup(null);
      setMobileSelectedToggleGroup(null);
      setFilterFacetGroups(null);
      setRefinements({ counts: [], didYouMean: [] });
      setActiveRefinement(null);
      setGotRefinements(false);
      setLoadingResults(true);
      setPageNum(1);
      setReRunSearch(!reRunSearch);
    }
  }, [page]);

  useEffect(() => {
    try {
      if (isFirstRender.current) {
        isFirstRender.current = false; // toggle flag after first render/mounting
        if (servletUrl || isSingleTypeSearch) {
          // if this is a curated listing or single type search, do not run the refinements
          _getSearchResults();
        } else {
          _getRefinements();
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    try {
      setActiveRefinement(null);
      setActiveFacets([]);
    } catch (e) {
      console.error(e);
    }
  }, [searchTerm]);

  // on facet change
  useEffect(() => {
    try {
      //don't want this running twice on initial load
      if (isFirstReRender.current) {
        isFirstReRender.current = false; // toggle flag after first render/mounting
      } else {
        if (!servletUrl) {
          if (isFirstRefinementGetRender)
            if (isFirstRefinementGetRender.current) {
              isFirstRefinementGetRender.current = false; // toggle flag after first render/mounting of isFirstRefinementGetRender
              _getSearchResults();
            } else {
              _getSearchResults(() => _getRefinements(false));
            }
        } else {
          _getSearchResults();
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [reRunSearch]);

  //loop through returned facets and find what is active
  const _setupActiveFacets = (facetGroups) => {
    let tempFacetArray = [];
    let groupMap = {};

    let toggleFacetGroups = window.globalProperties.toggleFacetGroups;

    facetGroups.forEach((group) => {
      const groupId = group.id;
      groupMap[groupId] = {};

      let matchingToggleGroup1 = toggleFacetGroups.find(facetGroup => facetGroup.option1SearchFacetGroupId === groupId);
      let matchingToggleGroup2 = toggleFacetGroups.find(facetGroup => facetGroup.option2SearchFacetGroupId === groupId)

      if (group.facets) {
        group.facets.forEach((facet) => {
          // build global map to retrieve name property later on when needed
          groupMap[groupId][facet.id] = { name: facet.name };

          if (facet.selected) {
            if (matchingToggleGroup1) {
              facet.mobileName = matchingToggleGroup1.option1Label ? `${facet.name} ${matchingToggleGroup1.option1Label}` : null;
              facet.desktopName = matchingToggleGroup1.option1ToggleLabel ? `${facet.name} ${matchingToggleGroup1.option1ToggleLabel}` : null;
            }

            if (matchingToggleGroup2) {
              facet.mobileName = matchingToggleGroup2.option2Label ? `${facet.name} ${matchingToggleGroup2.option2Label}` : null;
              facet.desktopName = matchingToggleGroup2.option2ToggleLabel ? `${facet.name} ${matchingToggleGroup2.option2ToggleLabel}` : null;
            }

            tempFacetArray.push({
              id: groupId + ":" + facet.id,
              name: facet.name,
              mobileName: facet.mobileName,
              desktopName: facet.desktopName
            });
          }
        });
      }
    });

    facetNameMap.current = groupMap;
    setActiveFacets(tempFacetArray);
  };

  //remove a facet
  //facets look like facet:value
  const _removeFacet = (facet) => {
    setLoadingResults(true);

    //This _removeFilterFromPageURL might still be needed
    //_removeFilterFromPageURL();

    var activeFacetsClone = Symbol.iterator in Object(activeFacets) ? [...activeFacets] : [];
    var index = activeFacetsClone.findIndex((obj) => obj.id === facet);
    if (index !== -1) {
      activeFacetsClone.splice(index, 1);
      setActiveFacets(activeFacetsClone);
      setPageNum(1);
      setReRunSearch(!reRunSearch);
    }
  };

  //add item fo facet list
  const _addFacet = (facet) => {
    setLoadingResults(true);
    const activeFacetsClone = Symbol.iterator in Object(activeFacets) ? [...activeFacets] : [];

    const index = activeFacetsClone.findIndex((obj) => obj.id === facet);

    if (index === -1) {
      const { groupId, facetId } = parseActiveFacet(facet);

      activeFacetsClone.push({
        id: facet,
        name: facetNameMap.current[groupId][facetId].name || "",
      });

      setActiveFacets(activeFacetsClone);
      setPageNum(1);
      setReRunSearch(!reRunSearch);
    }
  };

  //remove all facets
  const _removeAllFacet = ( removeUrlFilters = true ) => {
    setLoadingResults(true);

    setActiveFacets([]);

    if (removeUrlFilters) {
      _removeFilterFromPageURL();
    }

    setPageNum(1);
    setReRunSearch(!reRunSearch);
  };

  const _removeUrlFilter = () => {
    setLoadingResults(true);
    _removeFilterFromPageURL();

    setPageNum(1);
    setReRunSearch(!reRunSearch);
  };

  const _removeFilterFromPageURL = () => {
    urlParams.delete(FILTERS_URL_PARAMETER_NAME);
    urlParams.delete(EXTENDED_FILTERS_URL_PARAMETER_NAME);
    const queryString = urlParams.toString().length > 0 ? '?' + urlParams.toString() : '';

    window.history.pushState(null, '', window.location.pathname + queryString);
  }

  const _getFacetsQueryParam = () => {
    return encodeURIComponent(activeFacets.map((facet) => facet.id).join(","));
  };

  const _getRefinementsQueryParam = () => {
    return encodeURIComponent(activeRefinement);
  };

  const _getFiltersQueryParam = () => {
    return encodeURIComponent(urlParams.get(FILTERS_URL_PARAMETER_NAME));
  };

  const _getFilterObjectsFromQueryParam = () => {
    const filtersParam = urlParams.get(FILTERS_URL_PARAMETER_NAME);

    if (!filtersParam) return [];

    const filtersArray = filtersParam.split(',');
    let filters = [];
    filtersArray.map(filter => {
      const  { groupId, facetId } = parseActiveFacet(filter);
      filters.push({
        id: filter,
        name: facetId
      });
    });

    return filters;
  }

  const _buildFiltersQueryString = () => {
    let filterQueryString = "";

    if (activeFacets.length > 0) {
      filterQueryString = filterQueryString + "&filters=" + _getFacetsQueryParam();
    }

    //if we came to the search page from a "view more" cta on the pdp compatible products component, a "filters" query string was passed in to use in the servet call.
    // add additional filter from page's query string
    if (urlParams.get("filters") && !searchTerm) {
      filterQueryString =
        filterQueryString +
        (filterQueryString.includes("filters")
          ? `,${_getFiltersQueryParam()}`
          : "&filters=" + _getFiltersQueryParam());
    }

    filterQueryString = filterQueryString + "&url=" + window.location.href;

    let currentCookieString = document.cookie;
    if (currentCookieString) {
      let cookieArray = currentCookieString.split('; ');
      for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        let index = cookie.indexOf('=');
        if (index > 0) {
          let cookieName = cookie.substring(0, index);
          try {
            cookieName = decodeURIComponent(cookieName);
          } catch (e) {
            // do nothing;
          }
          if (cookieName === "_br_uid_2") {
            let cookieValue = decodeURIComponent(cookie.substring(index + 1))
            filterQueryString = filterQueryString + "&brUid2=" + cookieValue;
          }
        }
      }
    }

    return filterQueryString;
  };

  let refinementServlet;
  if (patentSearchServletURL) {
    refinementServlet = `${patentSearchServletURL}?componentConfigPath=${configurationPath}${
      searchTerm ? "&q=" + encodeURIComponent(searchTerm) : ""
    }${_buildFiltersQueryString()}`;
  } else {
    refinementServlet = `${brsmSearchURL}?componentConfigPath=${configurationPath}${
      searchTerm ? "&q=" + encodeURIComponent(searchTerm) : ""
    }${_buildFiltersQueryString()}`;
  }

  const _getRefinements = (reRun = true) => {
    if (isSingleTypeSearch) return;
    fetch(refinementServlet)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      })
      .then((data) => {
        if (data) {
          const { counts = [], didYouMean = [] } = data;
          if (data.autoCorrectQuery) {
            setSearchTerm(data.autoCorrectQuery);
            setAutoCorrectOverride(data.autoCorrectQuery);
          }

          // Remove mediaCollection refinement, not needed for Site Search
          const countsArray = counts.filter((item) => item.id !== CONTENTTYPEMEDIA);
          setRefinements({ counts: countsArray, didYouMean: didYouMean });

          // If we get refinements, set to true
          if (countsArray.length > 0) {
            setGotRefinements(true);
            // determine whether or not search results need to be ran again, if not
            // add refinement and clear facets without triggering rerun
            //find first refinement with items in it
            let firstFound = countsArray.find((element) => element.count > 0);
            //if none use first in array
            if (!firstFound) {
              firstFound = countsArray[0];
            }
            if (reRun) {
              _addRefinement(firstFound["id"]);
            } else {
              if (activeRefinement === null) {
                setActiveRefinement(firstFound["id"]);
                setReRunSearch(!reRunSearch);
              }
            }
          }
        }
      })
      .catch((error) => {
        setRefinements({ counts: [], didYouMean: [] });
        console.error(error);
      });
  };

  // Adding Refinement
  const _addRefinement = (refinement) => {
    setActiveRefinement(refinement);
    setPageNum(1);
    setNumberToShow(NUMOFROWS);
    setReRunSearch(!reRunSearch);
  };

  let completeServletURL =
    servletUrl + "?componentConfigPath=" + configurationPath + _buildFiltersQueryString();

  //get Search results
  const _getSearchResults = (callback) => {
    const document = page.getContent(documentRef);
    if (!document) return null;
    const { contentType = null } = document.getData();
    // reset rendered results if it's page 1 and there are already rendered results
    if (pageNum === 1 && searchResults !== null) {
      setRenderedSearchResults(null);
    }
    let sortServletURL = completeServletURL;
    if (contentType !== CURATEDLISTING) {
      sortServletURL = sortServletURL + `&page=${pageNum}`;
      if (sortValue?.toLowerCase() === SORT_OPTION.PUBLISHEDDATE) {
        sortServletURL = sortServletURL + `&sort=${SORTMOSTRECENT}`;
      } else if (sortValue?.toLowerCase() === SORT_OPTION.ALPHABETICAL) {
        sortServletURL = sortServletURL + `&sort=${SORTALPHABETICAL}`;
      }
    }

    fetch(sortServletURL)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setSearchResults(data);
        if (pageNum !== 1) {
          setRenderedSearchResults([...renderedSearchResults, ...data.results]);
        } else {
          if(data.results){
            setRenderedSearchResults([...data.results]);
          }
        }
        if (data && data.facetgroups) {
          _setupActiveFacets(data.facetgroups);
          _buildFinalFacetGroups(data.facetgroups);
        }
        const filterQueryParam = urlParams.get("filters")
        if (filterQueryParam && filterQueryParam.startsWith(CATEGORYFILTER)) {
          setCategoryFilter(filterQueryParam.substring(CATEGORYFILTER.length).replaceAll("-"," "))
        }
        else {
          setCategoryFilter("")
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoadingResults(false);
        if (callback) {
          callback();
        }
      });
  };

  const _handleSortChange = (e) => {
    setPageNum(1);
    setNumberToShow(NUMOFROWS);
    var newSortValue = e.target.value;
    setSortValue(newSortValue);
    setReRunSearch(!reRunSearch);
  };
  const _handleLoadMore = (contentType) => {
    setPageNum(pageNum + 1);
    if (contentType !== CURATEDLISTING) {
      setReRunSearch(!reRunSearch);
    }
    setNumberToShow(numberToShow + NUMBERTOLOADMORE);
  };
  const _handleSearchTermChange = (value) => {
    setSearchTerm(value);
    setPageNum(1);
    setReRunSearch(!reRunSearch);
  };

  const _closeFilters = () => {
    setMobileFilterMenuOpen(false);
    setMobileSelectedFacetGroup(null);
    setMobileSelectedToggleGroup(null);
  };

  const _buildFinalFacetGroups = (facetGroups) => {
    //build a new object that contains non-toggle facet groups and facet groups with toggles
    const toggleFacetGroups = window.globalProperties.toggleFacetGroups;
    let finalGroups = [];
    let toggleProperties = [];

    toggleFacetGroups.forEach((tg) => {
      facetGroups.forEach((fg) => {
        let toggleGroupParent = finalGroups.find((t) => t.name === tg.name);

        let newFacetGroup = null;
        let togglePropertyFound = false;

        let toggle1PropertyFound =
          fg.id === tg.option1FacetGroup[0] || fg.id === tg.option1SearchFacetGroupId;
        let toggle2PropertyFound =
          fg.id === tg.option2FacetGroup[0] || fg.id === tg.option2SearchFacetGroupId;

        //determine if we need to create a toggle group
        if (toggle1PropertyFound) {
          toggle2PropertyFound =
            facetGroups.findIndex((item) => item.id === tg.option2FacetGroup[0]) !== -1 ||
            facetGroups.findIndex((item) => item.id === tg.option2SearchFacetGroupId) !== -1;
        } else if (toggle2PropertyFound) {
          toggle1PropertyFound =
            facetGroups.findIndex((item) => item.id === tg.option1FacetGroup[0]) !== -1 ||
            facetGroups.findIndex((item) => item.id === tg.option1SearchFacetGroupId) !== -1;
        }

        if (toggle1PropertyFound && toggle2PropertyFound) {
          if (toggleProperties.indexOf(fg.id) === -1) {
            toggleProperties.push(fg.id);
          }
          //create a new togglegroup object
          let toggleGroup = {
            id: fg.id,
            type: "toggle",
            facets: [],
          };

          let togglePosition = null;

          //add correct labels
          if (fg.id === tg.option1FacetGroup[0] || fg.id === tg.option1SearchFacetGroupId) {
            togglePosition = 1;
            toggleGroup.mobileName = tg.option1Label;
            toggleGroup.desktopName = tg.option1ToggleLabel;
          }
          if (fg.id === tg.option2FacetGroup[0] || fg.id === tg.option2SearchFacetGroupId) {
            togglePosition = 2;
            toggleGroup.mobileName = tg.option2Label;
            toggleGroup.desktopName = tg.option2ToggleLabel;
          }
          //add facets from facet group to toggle group
          fg.facets.forEach((f) => {
            f.mobileName = toggleGroup.mobileName ? `${f.name} ${toggleGroup.mobileName}` : f.name;
            f.desktopName = toggleGroup.desktopName ? `${f.name} ${toggleGroup.desktopName}` : f.name;
            toggleGroup.facets.push(f);
          });
          //create a parent facet group for toggleGroups if it doesn't already exist
          if (!toggleGroupParent) {
            newFacetGroup = {};
            newFacetGroup.name = tg.name;
            newFacetGroup.id = tg.name.toLowerCase();
            newFacetGroup.toggleGroups = [];
          }
          //add toggle group to parent facet group
          if (newFacetGroup) {
            newFacetGroup.toggleGroups.push(toggleGroup);
          } else {
            //inserts the toggle group to align with the order of the toggle labels
            toggleGroupParent.toggleGroups.splice(togglePosition - 1, 0, toggleGroup);
          }

          togglePropertyFound = true;
        }
        //add the facet group to the array
        if (togglePropertyFound && !toggleGroupParent) {
          newFacetGroup.facets = null;
          finalGroups.push(newFacetGroup);
        } else if (!togglePropertyFound) {
          //only add the facet group if it doesn't already exist in finalGroups
          if (finalGroups.findIndex((item) => item.id === fg.id) === -1) {
            finalGroups.push(fg);
          }
        }
      });
    });

    //because we potentially looped through multiple toggle group properties above, we may have duplicated values between facet groups and facet groups within a toggle group.
    //remove those facet groups that are part of a toggle group.
    toggleProperties.forEach((item) => {
      let fgIndex = finalGroups.findIndex((fg) => fg.id === item);
      if (fgIndex > -1) {
        finalGroups.splice(fgIndex, 1);
      }
    });
    setFilterFacetGroups(finalGroups);
  };

  const defaultDesktopToggleValues = () => {
    let defaultValues = [];
    window.globalProperties.toggleFacetGroups.forEach((tfg) => {
      defaultValues.push(tfg.option1ToggleLabel);
    });
    return defaultValues;
  };

  try {
    const document = page.getContent(documentRef);
    if (!document) return null;

    const {
      type = null,
      contentType = null,
      resultsForLabel = null,
      showResultsForLabel = null,
      articlesRefinementLabel = null,
      productsRefinementLabel = null,
      webpagesRefinementLabel = null,
      documentRefinementLabel = null,
      foodserviceRefinementLabel = null,
      retailRefinementLabel = null,
      categoryNoResultsTitle = null,
      categoryNoResultsNeedHelpDescription = {},
    } = document.getData();
    if (!contentType) return null;

    const showSearchBar = contentType === SITESEARCHRESULTS || contentType === PATENTSEARCHLISTING;
    const showResultsFilter =
      contentType !== SITESEARCHRESULTS &&
      contentType !== CURATEDLISTING &&
      contentType !== PATENTSEARCHLISTING;
    const showRefinements =
      contentType === SITESEARCHRESULTS || contentType === PATENTSEARCHLISTING;
    const showFilter =
      contentType !== PATENTSEARCHLISTING &&
      activeRefinement !== CONTENTTYPEWEBPAGE &&
      filterFacetGroups?.length > 0;
    const hideSortSelect = contentType === PATENTSEARCHLISTING ? true : hideSort;

    // If contentType = SiteSearchResults
    if (contentType === SITESEARCHRESULTS) {
      completeServletURL =
        brsmSearchURL +
        "?contentType=" +
        _getRefinementsQueryParam() +
        "&componentConfigPath=" +
        configurationPath +
        "&rows=" +
        NUMOFROWS +
        _buildFiltersQueryString();
      if (searchTerm)
        completeServletURL = completeServletURL + "&q=" + encodeURIComponent(searchTerm);
    }

    //set servlet URL for Patent Search Listing
    if (contentType === PATENTSEARCHLISTING) {
      const query = encodeURIComponent(searchTerm);
      completeServletURL =
        patentSearchServletURL +
        "?contentType=" +
        _getRefinementsQueryParam() +
        "&componentConfigPath=" +
        configurationPath +
        "&q=" +
        query +
        "&rows=" +
        NUMOFROWS;
    }

    // If contentType = SingleTypeListing
    if (
      contentType !== SITESEARCHRESULTS &&
      contentType !== CURATEDLISTING &&
      contentType !== PATENTSEARCHLISTING
    ) {
      var searchContentType;
      switch (contentType) {
        case "Products":
          searchContentType = CONTENTTYPEPRODUCT;
          break;
        case "Articles":
          searchContentType = CONTENTTYPEARTICLE;
          break;
        case "PDFs":
          searchContentType = CONTENTTYPEDOCUMENT;
          break;
        case "Webpages":
          searchContentType = CONTENTTYPEWEBPAGE;
          break;
        case "Media Collections":
          searchContentType = CONTENTTYPEMEDIA;
          break;
        default:
          searchContentType = CONTENTTYPEMEDIA;
          break;
      }

      const singleTypeContentType = encodeURIComponent(searchContentType);
      completeServletURL =
        brsmSearchURL +
        "?contentType=" +
        singleTypeContentType +
        "&componentConfigPath=" +
        configurationPath +
        "&rows=" +
        NUMOFROWS +
        _buildFiltersQueryString();
      if (searchTerm) {
        completeServletURL = completeServletURL + "&q=" + encodeURIComponent(searchTerm);
      }
    }

    const sortCuratedListingAlpha = (array) => {
      return array.sort((a, b) => {
        var aCompare = "";
        var bCompare = "";
        if (a.contentType === CONTENTTYPEPRODUCT) {
          aCompare = a.childProductName;
        } else if (a.contentType === CONTENTTYPEPRODUCTFAMILY) {
          aCompare = a.promoTitle;
        } else if (a.title) {
          aCompare = a.title;
        }
        if (b.contentType === CONTENTTYPEPRODUCT) {
          bCompare = b.childProductName;
        } else if (b.contentType === CONTENTTYPEPRODUCTFAMILY) {
          bCompare = b.promoTitle;
        } else if (b.title) {
          bCompare = b.title;
        }
        if (aCompare && bCompare) {
          return aCompare.localeCompare(bCompare);
        } else {
          return aCompare;
        }
      });
    };

    //sorts if needed, and chooses the right type of result card as well
    const resultsOutput = () => {
      let localArray = [...renderedSearchResults];

      // check for frontend sort for curated listing
      if (contentType === CURATEDLISTING) {
        if (sortValue?.toLowerCase() === SORT_OPTION.ALPHABETICAL) {
          localArray = sortCuratedListingAlpha(localArray);
        }
        localArray = localArray.slice(0, numberToShow);
      }

      if (localArray && localArray.length > 0) {
        return localArray
          .map((item, index) => {
            if (item) {
              switch (item.contentType) {
                case CONTENTTYPEMATERIAL:
                  return (
                    <li key={`search-result-${index}`} className="search__results-list-item">
                      <MaterialGuideCard materialType={item} />
                    </li>
                  );
                case CONTENTTYPEDOCUMENT:
                case CONTENTTYPEARTICLE:
                case CONTENTTYPEMEDIA:
                case CONTENTTYPEWEBPAGE:
                  return (
                    <li
                      key={`search-result-${index}`}
                      className={`search__results-list-item${
                        item.contentType === CONTENTTYPEDOCUMENT && isSingleTypeSearch
                          ? "document"
                          : ""
                      }`}
                    >
                      <SingleTypeSearchCard isSingleTypeSearch={isSingleTypeSearch} result={item} />
                    </li>
                  );

                case CONTENTTYPEPATENTFS:
                case CONTENTTYPEPATENTRETAIL:
                  return (
                    <li key={`search-result-${index}`} className="search__results-list-item">
                      <ProductPatentCard
                        product={item}
                        productImageHeight={productHeight}
                        productImageWidth={productWidth}
                      />
                    </li>
                  );
                case CONTENTTYPEPRODUCT:
                case CONTENTTYPEPRODUCTFAMILY:
                default:
                  if (
                    item.contentType === CONTENTTYPEPRODUCT ||
                    item.contentType === CONTENTTYPEPRODUCTFAMILY
                  ) {
                    return (
                      <li key={`search-result-${index}`} className="search__results-list-item">
                        <ProductAndProductFamilyCard
                          product={item}
                          productImageHeight={productHeight}
                          productImageWidth={productWidth}
                        />
                      </li>
                    );
                  } else {
                    return null;
                  }
              }
            }

            return null; // gets rid of eslint warning
          })
          .filter((listitem) => listitem);
      }
    };

    const { counts = [], didYouMean = [] } = refinements;
    //gets the count of all the refinements
    const totalSearchItemsCount = () => {
      if (gotRefinements === true) {
        return counts.reduce((totalCount, category) => {
          const total = totalCount + category.count;
          return total;
        }, 0);
      } else {
        return null;
      }
    };

    const searchResultsExist = searchResults && renderedSearchResults;
    const searchResultsCount = (searchResultsExist && searchResults.total) || 0;

    const { sortOptionAlphabetical, sortOptionFeatured, sortOptionPublishedDate } =
      window.globalProperties || {};
    const sortOptions = [
      {
        value: SORT_OPTION.ALPHABETICAL,
        display: sortOptionAlphabetical,
      },
      {
        value: SORT_OPTION.FEATURED,
        display: sortOptionFeatured,
      },
    ];

    if (activeRefinement === CONTENTTYPEARTICLE || searchContentType === CONTENTTYPEARTICLE) {
      sortOptions.push({
        value: SORT_OPTION.PUBLISHEDDATE,
        display: sortOptionPublishedDate,
      });
    }

    const getRefinementLabelIconInfo = () => {
      return [
        {
          id: REFINEMENTS.PRODUCT.id,
          label: productsRefinementLabel,
          icon: REFINEMENTS.PRODUCT.icon,
        },
        {
          id: REFINEMENTS.ARTICLE.id,
          label: articlesRefinementLabel,
          icon: REFINEMENTS.ARTICLE.icon,
        },
        {
          id: REFINEMENTS.DOCUMENT.id,
          label: documentRefinementLabel,
          icon: REFINEMENTS.DOCUMENT.icon,
        },
        {
          id: REFINEMENTS.WEBPAGE.id,
          label: webpagesRefinementLabel,
          icon: REFINEMENTS.WEBPAGE.icon,
        },
        {
          id: REFINEMENTS.FOODSERVICE.id,
          label: foodserviceRefinementLabel,
          icon: REFINEMENTS.FOODSERVICE.icon,
        },
        {
          id: REFINEMENTS.RETAIL.id,
          label: retailRefinementLabel,
          icon: REFINEMENTS.RETAIL.icon,
        },
      ];
    };

    const toolbarContextValue = {
      results: {
        count:
          (contentType === SITESEARCHRESULTS || contentType === PATENTSEARCHLISTING) &&
          gotRefinements === true
            ? totalSearchItemsCount()
            : searchResultsCount,
        label: type,
        searchTerm: searchTerm,
        resultsForLabel: resultsForLabel,
        loadingResults: loadingResults,
        categoryFilter: categoryFilter,
      },
      pageType: contentType,
      sort: {
        hide: hideSortSelect,
        currentValue: sortValue?.toLowerCase(),
        handleChange: _handleSortChange,
        options: sortOptions,
      },
      showUrlFilters: (contentType === CURATEDLISTING || isSingleTypeSearch) ? true : false,
      clearURLFiltersOnRemoveAllFacets: (contentType === CURATEDLISTING || isSingleTypeSearch ) ? true : false,
      urlFilters: {
        filters: _getFilterObjectsFromQueryParam(),
        removeFilter: _removeUrlFilter,
        removeAllFilters: _removeAllFacet
      },
      filters: {
        facets: activeFacets,
        removeFacet: _removeFacet,
        removeAllFacets: _removeAllFacet,
      },
      setFilterMenuOpen: setMobileFilterMenuOpen,
      showFilterBy: showFilter,
    };

    const searchInputContextValue = {
      search: _handleSearchTermChange,
    };

    const searchRefinementsContextValue = {
      refinements: {
        addRefinement: _addRefinement,
        getRefinements: _getRefinements,
        options: refinements.counts,
        count: gotRefinements === true ? totalSearchItemsCount() : 0,
        activeRefinement: activeRefinement,
        setActiveRefinement: setActiveRefinement,
      },
      searchTerm: searchTerm,
      labels: {
        showResultsForLabel,
        resultsForLabel,
      },
      labelIconInfo: getRefinementLabelIconInfo(),
    };

    const TITLE_FILTER_BY = window["globalProperties"]?.["filterByLabel"] + ":";
    const filterContextValue = {
      filterTitle: TITLE_FILTER_BY,
      defaultToggleValues: defaultDesktopToggleValues(),
      facetGroups: {
        groups: filterFacetGroups,
        selectedFacetGroup: mobileSelectedFacetGroup,
        selectedToggleGroup: mobileSelectedToggleGroup,
        updateSelectedFacetGroup: setMobileSelectedFacetGroup,
        updateSelectedToggleGroup: setMobileSelectedToggleGroup,
      },
      facets: {
        activeFacets,
        removeFacet: _removeFacet,
        addFacet: _addFacet,
      },
      filterMenuOpen: mobileFilterMenuOpen,
      setFilterMenuOpen: setMobileFilterMenuOpen,
      closeFilters: _closeFilters,
    };

    if (
      contentType === SITESEARCHRESULTS &&
      renderedSearchResults &&
      !urlParams.get("filters") &&
      (totalSearchItemsCount() === null ||
        totalSearchItemsCount() <= 0 ||
        searchTerm === undefined ||
        searchTerm === "")
    ) {
      return (
        <>
          <SearchInput
            setAutoCorrectOverride={setAutoCorrectOverride}
            autoCorrectOverride={autoCorrectOverride}
            setSearchTerm={setSearchTerm}
            setReRunSearch={setReRunSearch}
            reRunSearch={reRunSearch}
          />
          <NoResults
            noResultsPopularProductCodes={noResultsPopularProductCodes}
            productServletURL={productServletURL}
            searchValue={searchTerm}
            suggestions={didYouMean}
          />
        </>
      );
    }

    return (
      <div className={`${page.isPreview() ? "has-edit-button" : ""} search`}>
        {loadingResults && (
          <div className="search__loading-overlay">
            <div className="search__loading-overlay-background"></div>
            <LoadingSpinner
              showAlert={true}
              alertMessage={(searchTerm || activeRefinement)
                ? `Loading results for ${searchTerm || activeRefinement}`
                : `Loading results`}>
            </LoadingSpinner>
          </div>
        )}
        <BrManageContentButton content={document} />

        {/* Search Input */}
        {showSearchBar && (
          <SearchInput
            setAutoCorrectOverride={setAutoCorrectOverride}
            autoCorrectOverride={autoCorrectOverride}
            setSearchTerm={setSearchTerm}
            setReRunSearch={setReRunSearch}
            reRunSearch={reRunSearch}
          />
        )}
        <div className="search__inner">
          <div className="search__top-bar">
            {showRefinements && (
              <Mobile>
                <SearchRefinementContext.Provider value={searchRefinementsContextValue}>
                  <SearchRefinementsController />
                </SearchRefinementContext.Provider>
              </Mobile>
            )}

            <SearchToolbarContext.Provider value={toolbarContextValue}>
              <SearchToolbarInfo />
            </SearchToolbarContext.Provider>
          </div>
          <div className="search__left-rail">
            {showRefinements && (
              <Desktop>
                <SearchRefinementContext.Provider value={searchRefinementsContextValue}>
                  <SearchRefinementsController />
                </SearchRefinementContext.Provider>
              </Desktop>
            )}

            {showResultsFilter && (
              <SearchInputFilterContext.Provider value={searchInputContextValue}>
                <SearchInputFilter />
              </SearchInputFilterContext.Provider>
            )}

            {showFilter && (
              <SearchFilterContext.Provider value={filterContextValue}>
                <Filter />
              </SearchFilterContext.Provider>
            )}
          </div>
          <div className="search__results-wrapper">
            {searchResultsExist && searchResultsCount > 0 && (
              <div>
                <SearchToolbarContext.Provider value={toolbarContextValue}>
                  <SearchToolbar />
                </SearchToolbarContext.Provider>
                <ul
                  className="search__results-list"
                  id="searchResultsList"
                  role={contentType === PATENTSEARCHLISTING ? "tabpanel" : ""}
                  aria-labelledby={contentType === PATENTSEARCHLISTING ? activeRefinement : ""}>

                  {resultsOutput()}
                </ul>
              </div>
            )}
            {searchResultsExist && searchResultsCount > numberToShow && (
              <div className="search__load-more-wrapper">
                <button
                  onClick={() => {
                    _handleLoadMore(contentType);
                  }}
                  className="search__load-more btn btn--secondary"
                  tabIndex={0}
                >
                  {window.globalProperties["loadMoreLabel"]
                    ? window.globalProperties["loadMoreLabel"]
                    : "Load More"}
                </button>
              </div>
            )}
            {searchResultsExist && searchResultsCount === 0 && (
              <div className="no-results__aid"> 
                {categoryNoResultsTitle && (
                  <h2 className="no-results__aid__guide__title">{categoryNoResultsTitle}</h2>
                )}
                {categoryNoResultsNeedHelpDescription && categoryNoResultsNeedHelpDescription.value && (
                  <div className="no-results__aid__suggestions"
                    dangerouslySetInnerHTML={{
                    __html: categoryNoResultsNeedHelpDescription.value,
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } catch (e) {
    console.error("SearchController error", e);
    return <ErrorComponent error={e} componentName={"SearchController"} />;
  }
}

export default SearchController;
