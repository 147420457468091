import { useContext } from "react";
import { MessagesContext, CookieServiceContext } from "./context";

// Hook which makes use of Messages Context
export function useMessages() {
    const context = useContext(MessagesContext);

    if (!context) {
        throw new Error(
            "useMessages must be used within a SiteMessagesProvider"
        );
    }

    return context;
}

// Hook which makes use of Cookie Service Context
export function useCookieService() {
    const context = useContext(CookieServiceContext);

    if (!context) {
        throw new Error(
            "useCookieService must be used within a CookieServiceProvider"
        );
    }

    return context;
}
