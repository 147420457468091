import React, { useEffect, useState } from "react";
import { BrPageContext, BrComponentContext, BrManageContentButton } from "@bloomreach/react-sdk";
import "./VideoBanner.scss";

import Modal from "../Modal/Modal";
import { Desktop, Mobile } from "../../../media-queries/MediaQueries";
import VideoIFrame from "../VideoIFrame/VideoIFrame";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
import Title from "../../Global/Title/Title";
import { HEADING_LEVELS } from "../../Global/GlobalConstants";
import Image from "../../Global/Image/Image";
import sanitizeHtml from 'sanitize-html'

function VideoBanner() {
  const [modalOpen, setModalOpen] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [imagesExist, setImagesExist] = useState("");

  const page = React.useContext(BrPageContext);
  const component = React.useContext(BrComponentContext);

  try {
    useEffect(() => {
      const resizeListener = () => {
        widthOnLoad();
      };

      // Set resize listener
      window.addEventListener("resize", resizeListener);
      widthOnLoad();

      // Clean up function
      return () => {
        // Remove listener
        window.removeEventListener("resize", resizeListener);
      };
    }, []);

    const { document: documentRef } = component.getModels();

    const document = page.getContent(documentRef);
    if (!document) return null;

    const {
      primaryTitle = null,
      description = null,
      image = null,
      mobileImage = null,
      videoLink = null,
      videoTitle = null,
      imageAltText = null,
      mobileImageAltText = null,
    } = document.getData();

    function toggleModal() {
      setModalOpen(!modalOpen);
      setIsVideo(!isVideo);
    }
    const videoUrl = isVideo === true ? videoLink : "";

    function widthOnLoad() {
      if (window.innerWidth < 768 && !mobileImage) {
        setImagesExist("no-image");
      } else if (window.innerWidth >= 768 && !image) {
        setImagesExist("no-image");
      } else {
        setImagesExist("with-image");
      }
    }

    return (
      <div className={`${page.isPreview() ? "has-edit-button" : ""} video-banner`}>
        <BrManageContentButton content={document} />
        <div className={`video-banner__image-wrapper content-bound ${imagesExist}`}>
          {image && (
            <Desktop>
              <Image src={image} alt={imageAltText} className="video-banner__image" />
            </Desktop>
          )}
          {mobileImage && (
            <Mobile>
              <Image
                src={mobileImage}
                alt={!mobileImageAltText ? imageAltText : mobileImageAltText}
                className="video-banner__image"
              />
            </Mobile>
          )}

          <div className={`video-banner__content-wrapper ${imagesExist}`}>
            {primaryTitle && (
              <Title defaultLevel={HEADING_LEVELS.TWO} className="video-banner__header">
                {primaryTitle}
              </Title>
            )}
            {description?.value && (
              <Desktop>
                <div
                  className="video-banner__description-wrapper"
                  dangerouslySetInnerHTML={{
                    __html: page?.rewriteLinks(sanitizeHtml(description.value)) ?? "",
                  }}
                />
              </Desktop>
            )}
            {videoLink && (
              <button
                aria-label={window.globalProperties["playButtonAriaLabel"] !== undefined ? window.globalProperties["playButtonAriaLabel"] : "Play Button Aria Label Default"}
                onClick={toggleModal}
                className="video-banner__btn btn btn--play-video darticon-play"
              ></button>
            )}
          </div>
        </div>
        <Modal show={modalOpen} handleClose={toggleModal}>
          {videoUrl && <VideoIFrame video={videoUrl} title={videoTitle} />}
        </Modal>
      </div>
    );
  } catch (e) {
    console.error("VideoBanner error", e);
    return <ErrorComponent error={e} componentName={"VideoBanner"} />;
  }
}

export default VideoBanner;
