import SearchController from "../Search/SearchController";

function SingleTypeSearchListing() {
  return (
    <div className="single-type-search-listing">
      <SearchController isSingleTypeSearch={true} />
    </div>
  );
}

export default SingleTypeSearchListing;
