import { useState } from "react";
import ScreenReaderAlertContext from "./context";

function ScreenReaderAlert({children}) {
  const [alerts, setAlerts] = useState([]);

  const setAlertMessage = (alertMessage) => {
    setAlerts([...alerts, alertMessage]);
  };

  return (
    <>
      <ScreenReaderAlertContext.Provider value={{setAlertMessage}}>
        {children}
      </ScreenReaderAlertContext.Provider>
      <span style={{opacity:0,position:"fixed"}} >
        {alerts.map((message,index)=>{
          return (
            <span key={index} role="alert" aria-live="assertive">{message}</span>
          );
        })}
      </span>
    </>
  );
}

export default ScreenReaderAlert;