import React, { useState, useReducer, useEffect, useRef, useCallback } from "react";
import { BrPageContext } from "@bloomreach/react-sdk";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import LinkWrapper from "../LinkWrapper";
import { reducer } from "./reducer";
import SavedProducts from "../../../services/SavedProducts";
import { getLabel } from "./helpers";
import { ACTIONS, INITIAL_STATE, NOTIFICATION_TIMEOUT, STATUS_ICON_MAP, STATUS } from "./constants";
import ErrorComponent from "../../Common/ErrorComponent/ErrorComponent";
import { getPageTitle } from "../../../helpers/GlobalHelpers";

/**
 * Dropdown
 * - Renders a list with two options, to remove product from
 *  saved list and navigate to page where list of saved products is viewable
 */
function Dropdown({ productCode, removeProduct, onClickOutside = () => {} }) {
  const dropdownRef = useRef();

  useEffect(() => {
    const handleClickOutside = (e) => {
      const { target } = e;

      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        onClickOutside();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [onClickOutside]);

  const handleClick = () => {
    removeProduct(productCode);
  };

  const label = getLabel(STATUS.SAVED);
  const viewListLink = window.globalProperties?.["viewMyListLink"];

  return (
    <div ref={dropdownRef} className="add-product__dropdown">
      <ul className="add-product__dropdown__list">
        <li className="add-product__dropdown__list__item">
          <button
            className="add-product__dropdown__list__item__button"
            data-code={productCode}
            onClick={handleClick}
          >
            <span className="add-product__dropdown__list__item__button__icon darticon-close" />
            {label}
          </button>
        </li>
        <li className="add-product__dropdown__list__item">
          <LinkWrapper className="add-product__dropdown__list__item__anchor" link={viewListLink}>
            <span className="add-product__dropdown__list__item__anchor__icon darticon-menu" />
            {viewListLink.label}
          </LinkWrapper>
        </li>
      </ul>
    </div>
  );
}

/**
 * ExpandableButton
 * - Returns button with appearance of other call to action buttons, label displayed dependent on status
 * - When in ADDED state, allows user to view a Dropdown component
 * - To be used on ProductDetailHeader component
 */
function AddProductExpandable({
  productCode = "",
  discontinued = false,
  addToListAnalytics,
  removeFromListAnalytics,
  productName,
}) {
  const sendDataToGTM = useGTMDispatch();
  const page = React.useContext(BrPageContext);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [showDropdown, setShowDropdown] = useState(false);
  const { add, remove, has } = SavedProducts();

  const hideDropdown = useCallback(() => {
    setShowDropdown(false);
  }, []);

  try {
    useEffect(() => {
      if (has(productCode)) {
        dispatch({ type: ACTIONS.ADD_PRODUCT_SUCCESS });
      } else {
        dispatch({ type: ACTIONS.RESET });
      }
    }, [productCode]);

    const { status } = state;

    const addProduct = (code) => {
      if (status !== STATUS.IDLE) return;

      add(code)
        .then(() => {
          dispatch({ type: ACTIONS.ADD_PRODUCT_SUCCESS });
          //notify analytics
          if (addToListAnalytics) {
            sendDataToGTM(addToListAnalytics);
          } else {
            sendDataToGTM({
              event: "listAdd",
              listType: "Other",
              label: `${getPageTitle(page)}`,
            });
          }
        })
        .catch((e) => {
          console.error(e);
          dispatch({ type: ACTIONS.ADD_PRODUCT_ERROR });
        });
    };

    const removeProduct = (code) => {
      if (status !== STATUS.ADDED) return;

      remove(code)
        .then(() => {
          dispatch({ type: ACTIONS.REMOVE_PRODUCT_SUCCESS });
          hideDropdown();
          //notify analytics
          if (removeFromListAnalytics) {
            sendDataToGTM(removeFromListAnalytics);
          } else {
            sendDataToGTM({
              event: "removeFromList",
              listType: "Other",
              label: `${getPageTitle(page)}`,
            });
          }

          setTimeout(() => {
            dispatch({ type: ACTIONS.RESET });
          }, NOTIFICATION_TIMEOUT);
        })
        .catch((e) => {
          console.error(e);
          dispatch({ type: ACTIONS.ADDED });
        });
    };

    const handleClick = (e) => {
      e.stopPropagation();
      e.preventDefault();

      if (status === STATUS.ADDED) {
        setShowDropdown((prevState) => !prevState);
        return;
      }

      const {
        currentTarget: {
          dataset: { code },
        },
      } = e;

      if (code) {
        if (has(code)) {
          removeProduct(code);
        } else {
          addProduct(code);
          window.BrTrk.getTracker().logEvent("cart", "click-add", {'prod_id': code, 'sku' : ''});
        }
      }
    };

    const { icon } = STATUS_ICON_MAP[status];
    const label = getLabel(status);
    const onlyAllowToRemove = discontinued && status === STATUS.IDLE;

    return (
      <div className="add-product add-product--full">
        <button
          className={`add-product__add-link-full btn ${
            status === STATUS.ADDED ? "add-product__add-link-full--expandable" : ""
          } ${onlyAllowToRemove ? "add-product__add-link-full--discontinued" : ""}`}
          aria-label={label + " " + productName}
          data-code={productCode}
          onClick={handleClick}
          aria-expanded={status === STATUS.ADDED && showDropdown}
          disabled={status === STATUS.REMOVED || onlyAllowToRemove}
        >
          <div>
            <span className={`add-product__add-link-icon ${icon}`} />
            {label}
          </div>
          {status === STATUS.ADDED &&
            (showDropdown ? (
              <span className="add-product__add-link-icon darticon-arrow-up" />
            ) : (
              <span className="add-product__add-link-icon darticon-arrow-down" />
            ))}
        </button>
        {status === STATUS.ADDED && showDropdown && (
          <Dropdown
            productCode={productCode}
            removeProduct={removeProduct}
            onClickOutside={hideDropdown}
          />
        )}
      </div>
    );
  } catch (e) {
    console.error("AddProductExpandable error", e);
    return <ErrorComponent error={e} componentName={"AddProductExpandable"} />;
  }
}

export default AddProductExpandable;
