import { useContext } from "react";
import NavigationContext from "./context";

export const useOverrideDimensions = () => {
  const context = useContext(NavigationContext);

  if (!context) {
    throw new Error(
      "useOverrideDimensions must be used within a NavigationContext.Provider"
    );
  }

  return context;
};
