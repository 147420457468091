import { useRef } from "react";
import HeaderToolbar from "../Common/HeaderToolbar/HeaderToolbar";
import Description from "../Global/Description";
import CtaLink from "../Global/CtaLink";
import {
  useDesktopFooterMediaQuery,
  MobileFooter as Mobile,
} from "../../media-queries/MediaQueries";
import { isAuthored } from "../../helpers/LinkHelper";
import { TERTIARY, GLOBAL_TAXONOMY_MAP, HEADING_LEVELS } from "../Global/GlobalConstants";
import "./ArticleHeader.scss";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import Image from "../Global/Image/Image";
import { getDesktopBackgroundImageStyles } from "../Global/Image/helpers";
import Title from "../Global/Title/Title";

function ArticleHeader({ component, page }) {
  const contentRef = useRef();

  const isDesktop = useDesktopFooterMediaQuery();

  try {
    const { document: documentRef, breadcrumbs } = component.getModels();

    const {
      componentStyle = TERTIARY,
      hideBreadcrumbs,
      imageWidth,
      imageHeight,
      hasTextAreaBackground
    } = component.getParameters();

    const document = page.getContent(documentRef);
    if (!document) return null;

    const {
      articleDetails: {
        primaryTitle = null,
        description = null,
        image = null,
        mobileImage = null,
        mobileImageAltText = null,
        link = null,
      },
      categories = [],
      lastUpdatedDate = null,
      publishedDate = null,
    } = document.getData();

    const taxonomyComponent = page.getComponent(GLOBAL_TAXONOMY_MAP);
    const { taxonomy: taxonomyMap = {} } = taxonomyComponent.getModels();

    const taxonomies =
      categories
        ?.map((key) => {
          return taxonomyMap[key] || "";
        })
        .filter((value) => value) || [];

    const formatDate = (epoch) => {
      if (!epoch) return "";

      const date = new Date(epoch);
      const formatted = date.toLocaleString("default", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });

      return formatted;
    };

    const showCta = isAuthored(link);

    const desktopImageStyles = getDesktopBackgroundImageStyles(
      image,
      imageWidth,
      imageHeight,
      isDesktop
    );

    return (
      <>
        <HeaderToolbar
          hideBreadcrumbs={hideBreadcrumbs}
          breadcrumbs={breadcrumbs}
        />
        <section
          style={desktopImageStyles}
          className={`article-header__wrapper theme-${componentStyle} article-header__wrapper--bg-${hasTextAreaBackground} ${
            isDesktop && image ? "article-header__wrapper--with-image" : ""
          }`}
        >
          <div className="article-header">
            <div ref={contentRef} className="article-header__content">
              {publishedDate && (
                <p className="article-header__published-date bold-bold">
                  Published: {formatDate(publishedDate)}
                </p>
              )}
              {lastUpdatedDate && (
                <p className="article-header__updated-date">
                  Last Updated: {formatDate(lastUpdatedDate)}
                </p>
              )}

              <Title defaultLevel={HEADING_LEVELS.ONE} className="article-header__title">
                {primaryTitle}
              </Title>
              {taxonomies.length > 0 && (
                <ul className="article-header__categories bold-bold">
                  {taxonomies.map((taxonomy) => {
                    return (
                      <li key={taxonomy} className="article-header__categories__item">
                        {taxonomy}
                      </li>
                    );
                  })}
                </ul>
              )}
              {description?.value && (
                <Description className="article-header__description" value={description.value} />
              )}
              {showCta && (
                <CtaLink
                  className={`article-header__cta btn ${
                    componentStyle === TERTIARY ? "btn--" + componentStyle : ""
                  }`}
                  link={link}
                />
              )}
            </div>
          </div>
          {mobileImage && (
            <Mobile>
              <Image
                className="article-header__mobile-image"
                src={mobileImage}
                alt={mobileImageAltText}
                loading="lazy"
                height={imageHeight}
                width={imageWidth}
              />
            </Mobile>
          )}
        </section>
      </>
    );
  } catch (e) {
    console.error("ArticleHeader error", e);
    return <ErrorComponent error={e} componentName={"ArticleHeader"} />;
  }
}

export default ArticleHeader;
