import React from "react";
import { Link } from "react-router-dom";
import { BrPageContext, BrComponentContext } from "@bloomreach/react-sdk";
import "./Footer.scss";

import { DesktopFooter, MobileFooter } from "../../media-queries/MediaQueries";
import { GetGeneralLinkUrl } from "../../helpers/LinkHelper";
import { getProperties } from "../../helpers/GlobalHelpers";
import FooterColumn from "./FooterColumn";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import Image from "../Global/Image/Image";
import { GetChannelRoot } from "../../helpers/LinkHelper";

const GLOBAL_CONFIG_FOOTER_COMPONENT_NAME = "Global Footer";

function Footer() {
  const page = React.useContext(BrPageContext);
  const component = React.useContext(BrComponentContext);

  const properties = getProperties(component)(GLOBAL_CONFIG_FOOTER_COMPONENT_NAME);
  const { logoWidth = 0, logoHeight = 0 } = properties;
  let defaultFooterLogoWidth = "198";
  let defaultFooterLogoHeight = "70";

  try {
    const { document: documentRef, hideComponent } = component.getModels();
    const document = page.getContent(documentRef);

    if (!document) return null;
    if (hideComponent) return null;

    const {
      logo = null,
      logoAltText = null,
      copyright = null,
      socialIconLinks = null,
      loginColumnHeader = null,
      loginLinks = null,
      column2Header = null,
      column2Links = null,
      column3Header = null,
      column3Links = null,
      column4Header = null,
      column4Links = null,
    } = document.getData();

    const socialIcons = socialIconLinks
      ? socialIconLinks.map((item) => (
          <a
            key={item.label}
            href={GetGeneralLinkUrl(item, page)}
            className={`footer-font-white footer__social-icon darticon-${item.icon} `}
            target={item.newWindow ? "_blank" : "_self"}
            rel={item.newWindow ? "noopener noreferrer" : ""}
            aria-label={item.label}
          ></a>
        ))
      : null;

    return (
      <footer className={`${page.isPreview() ? "has-edit-button" : ""} footer footer--font-white`}>
        <div className="footer__wrapper content-bound">
          <div className="footer__columns">
            <FooterColumn header={loginColumnHeader} links={loginLinks} linkType="login">
              <div className="footer__logo-wrapper">
                <Link to={GetChannelRoot}>
                  <Image
                    className="footer__logo"
                    src={logo}
                    alt={logoAltText}
                    width={Number(logoWidth) === 0 ? defaultFooterLogoWidth : logoWidth}
                    height={Number(logoHeight) === 0 ? defaultFooterLogoHeight : logoHeight}
                  />
                </Link>
              </div>
              <DesktopFooter>
                <div className="footer__social-wrapper">{socialIcons && socialIcons}</div>
              </DesktopFooter>
            </FooterColumn>
            <FooterColumn header={column2Header} links={column2Links} />
            <FooterColumn header={column3Header} links={column3Links} />
            <FooterColumn header={column4Header} links={column4Links} />
          </div>
          <MobileFooter>
            <div className="footer__social-wrapper">{socialIcons && socialIcons}</div>
          </MobileFooter>

          {copyright && (
            <div className="footer__copyright">
              {copyright.replace("((copyrightYear))", new Date().getFullYear())}
            </div>
          )}
        </div>
      </footer>
    );
  } catch (e) {
    console.error("Footer error", e);
    return <ErrorComponent error={e} componentName={"Footer"} />;
  }
}

export default Footer;
