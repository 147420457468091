import { STATUS, ACTIONS } from "./constants";

export const reducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.ADD_PRODUCT_SUCCESS:
            return {
                ...state,
                status: STATUS.ADDED,
            };
        case ACTIONS.REMOVE_PRODUCT_SUCCESS:
            return {
                ...state,
                status: STATUS.REMOVED,
            };
        case ACTIONS.REMOVE_PRODUCT_ERROR:
        case ACTIONS.SAVED:
            return {
                ...state,
                status: STATUS.SAVED,
            };
        case ACTIONS.ADD_PRODUCT_ERROR:
        case ACTIONS.RESET:
        default:
            return {
                ...state,
                status: STATUS.IDLE,
            };
    }
};