// THEMES
export const PRIMARY = "primary";
export const SECONDARY = "secondary";
export const TERTIARY = "tertiary";

export const COMPONENT_THEME = [
  {
    ComponentStyle: PRIMARY,
    CtaClasses: "btn",
  },
  {
    ComponentStyle: SECONDARY,
    CtaClasses: "btn",
  },
  {
    ComponentStyle: TERTIARY,
    CtaClasses: "btn btn--tertiary",
  },
];

export const PRIMARYCOLORBLUE = "Blue";

export const TEXT_ALIGNMENT = {
  LEFT: "left",
  RIGHT: "right",
};

export const GLOBAL_PROPS_COMPONENT = "globalproperties";
export const GLOBAL_TAXONOMY_MAP = "taxonomy";

export const GLOBAL_PROPS = {
  BREADCRUMB_HOME_PAGE_LABEL: "homepageBreadcrumbLabel",
  BREADCRUMB_LABEL: "breadcrumbLabel",
  MATERIAL_LISTING: {
    VIEW_ALL_BUTTON_LABEL: "mlViewAllProducts",
    ACCESS_RECYCLING_LABEL: "mlRecyclingExistsLabel",
    COMMERCIAL_COMPOSTING_LABEL: "mlCommerciallyCompostable",
    CONTAINS_RECYCLABES_LABEL: "mlRecycledContentLabel",
    RENEWABLE_RESOURCES_LABEL: "mlRenewableResourcesLabel",
    SOURCE_REDUCED_LABEL: "mlSourceReduced",
  },
};

export const HOME_PATH = "/";

export const SORT_OPTION = {
  ALPHABETICAL: "alphabetical",
  FEATURED: "featured",
  PUBLISHEDDATE: "publisheddate",
};

export const SAVED_PRODUCTS = {
  KEY: "products:saved",
  DELIMETER: "__",
};

export const POPULAR_PRODUCTS = {
  DELIMETER: "__",
};

// Product Detail Tab IDs
export const SPECSDOCTABID = "specifications-documentation";
export const COMPATIBLEPRODUCTSTABID = "compatible-products";
export const PRODCOLLECTIONTABID = "product-collection";
export const FEATURESBENEFITSTABID = "features-benefits";

export const ENTERKEYCODE = 13;

export const MARKETFLYOUTTYPE = "market flyout";
export const PRODUCTFLYOUTTYPE = "product flyout";

// Page / Document / Content Types
export const CONTENT_TYPE = {
  CURATEDLISTING: "dartcontainercombrxm:CuratedListing",
  SITESEARCHRESULTS: "dartcontainercombrxm:SiteSearchResults",
  PATENTSEARCHLISTING: "dartcontainercombrxm:PatentSearchListing",
};


export const REFINEMENTS = {
  PRODUCT: { icon: "products", id: "product" },
  ARTICLE: {icon: "article", id: "article"},
  DOCUMENT: {icon: "pdf",  id: "document"},
  WEBPAGE:{icon: "webpages", id: "webpage"},
  RETAIL:{icon: "", id: "retailPatent"},
  FOODSERVICE: {icon: "",  id: "foodservicePatent"}
};

export const HEADING_LEVELS = {
  ONE: "H1",
  TWO: "H2",
  THREE: "H3",
  FOUR: "H4",
  FIVE: "H5",
  SIX: "H6",
};

export const regexpLocale = /^\w{2}-\w{2}$/;

export const SAVED_PRODUCTS_MUTATED_EVENT_NAME = "savedProductsListMutated";
