import { useContext } from "react";
import { BrPageContext } from "@bloomreach/react-sdk";
import { Link } from "react-router-dom";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import Image from "../Global/Image/Image";
import AddProduct from "../Global/AddProduct/AddProduct";
import { getPageTitle } from "../../helpers/GlobalHelpers";
import { SIZE } from "../Global/AddProduct/constants";
import "./NavigationProductTile.scss";
import { getUrlPath } from '../../helpers/LinkHelper';

function NavigationProductTile({ product }) {
  const sendDataToGTM = useGTMDispatch();
  const page = useContext(BrPageContext);

  const { location, id, name, image, imageWidth, imageHeight, onClickProduct, isProPlanet, isNewProduct, isPrintable, isEmbossable, isCompostable } = product;

  const handleTileClick = () => {
	sendDataToGTM({
	  event: "tileClick",
	  tileTitle: `${name}`,
	  label: `${getPageTitle(page)}`,
	});
  };

  return (
    <div className={`${page.isPreview() ? "has-edit-button" : ""} navigation-product-tile`}>
       <Link
        to={getUrlPath(location)}
        className={`navigation-product-tile__link`}
        onClick={() => {
        handleTileClick();
        onClickProduct();
        }}
      >
        <div className="navigation-product-tile__img-wrapper">
          <Image
          className="navigation-product-tile__img"
          src={image}
          alt={name}
          width={imageWidth}
          height={imageHeight}
          loading="eager"
          />
          <span className="navigation-product-tile__product-title">{name}</span>
        </div>
      </Link>
      <div className="navigation-product-tile__banners">
          {/* "New!" */}
          {isNewProduct && (
            <p className="navigation-product-tile__banner primary">
              {window.globalProperties["newProductLabel"] || "New!"}
            </p>
          )}
          {/* "ProPlanet" */}
          {isProPlanet && (
            <p className="navigation-product-tile__banner icon" aria-label="pro planet">
              {isCompostable ? (
                <img width="50" src="https://dart.widen.net/content/vsjmstuwtr/svg/ProPlanet-Leaf-CheckmarkwStroke-Green.svg?u=hsm95h"/>
              ):(
                <img width="50" src="https://dart.widen.net/content/haqggj6fsh/svg/ProPlanet-Leaf-CheckmarkwStroke-Blue.svg?u=xd7jz4"/>
              )}
            </p>
          )}
          {/* "Add Your Brand" */}
          {(isPrintable || isEmbossable) && (
            <p className="navigation-product-tile__banner secondary bottom">
              {window.globalProperties["addYourBrandLabel"] || "Add Your Brand"}
            </p>
          )}
      </div>
      <AddProduct className="navigation-product-tile__add" productCode={id} btnSize={SIZE.MEDIUM} productName={name} />
    </div>
  );
}

export default NavigationProductTile;
