import { HEADING_LEVELS } from "../../Global/GlobalConstants";
import Title from "../../Global/Title/Title";
import "./DesktopSearchRefinement.scss";
import { useSearchRefinementContext } from "./hook";

function DesktopSearchRefinement() {

  const {
    refinements: { addRefinement, options, activeRefinement, setActiveRefinement},
    labels,
    labelIconInfo
  } = useSearchRefinementContext();

  const { showResultsForLabel } = labels;

  const handleRefinementClick = (index, refinementId) => {
    setActiveRefinement(null);
    addRefinement(refinementId);
  };
   
  const refinementsOutput = () => {
    return options.map((item, index) => {
      let refinementInfo = labelIconInfo.find((e) => e.id === item.id);
      return (
        <div
          className={`desktop-search-refinement__item ${
            activeRefinement === item.id ? "active" : ""
          }`}
          role="tab"
          aria-controls="searchResultsList"
          aria-selected={activeRefinement === item.id ? "true" : "false"}
          aria-label={refinementInfo?.label}
          id={item.id}
          tabIndex="0"
          key={index}
          onClick={() => handleRefinementClick(index, item.id)}
          onKeyDown={(e) => (e.key === "Enter") && handleRefinementClick(index, item.id)}
        >
          <span
            className={`desktop-search-refinement__icon darticon-${refinementInfo?.icon}`}
          ></span>
          <p className="desktop-search-refinement__label" role="alert">
            {`${refinementInfo?.label} (${item.count})`}
          </p>
        </div>
      );
    });
  };

  return (
    <div className="desktop-search-refinement">
      <div className="desktop-search-refinement__wrapper">
        {showResultsForLabel && (
          <Title defaultLevel={HEADING_LEVELS.THREE} className="desktop-search-refinement__title">
            {showResultsForLabel}
          </Title>
        )}
        {options && (
          <div className="desktop-search-refinement__options-wrapper" role="tablist" aria-orientation="vertical">{refinementsOutput()}</div>
        )}
      </div>
    </div>
  );
}

export default DesktopSearchRefinement;