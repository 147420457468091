import { useEffect, useState } from 'react';
import { Mobile, IsDesktopContent, Desktop } from "../../../media-queries/MediaQueries";
import { useSearchToolbarContext } from "./hooks";
import { CONTENT_TYPE } from "../../Global/GlobalConstants";
import "./ResultsCount.scss";

function ResultsCount() {
  const isDesktop = IsDesktopContent();
  const {
    results: { count, label, searchTerm, resultsForLabel, loadingResults, categoryFilter },
    setFilterMenuOpen,
    pageType,
    showFilterBy
  } = useSearchToolbarContext();

  const [resultsMessage, setResultsMessage] = useState("");
  const [curatedResultsMessage, setCuratedResultsMessage] = useState("");

  useEffect(()=>{
    if(loadingResults) {
      return;
    }

    if(!searchTerm && categoryFilter) {
      setResultsMessage(`${count} ${resultsForLabel} '${categoryFilter}'`);
      setCuratedResultsMessage(`${count} ${categoryFilter}`);
    }
    else if(!searchTerm || !searchTerm.length) {
      setResultsMessage("");
      setCuratedResultsMessage(`${count} ${label || "Products"}`);
    }
    else {
      setResultsMessage(`${count} ${resultsForLabel} '${searchTerm}'`);
      setCuratedResultsMessage(`${count} ${searchTerm}`);
    }
  },[count,loadingResults]);

  const onFilterClick = () => {
    setFilterMenuOpen(true);
  };

  const { CURATEDLISTING, SITESEARCHRESULTS, PATENTSEARCHLISTING } = CONTENT_TYPE;

  return (
    <div className={`filter-count ${isDesktop ? "bold-semi-bold" : "bold-extra-bold"}`}>
      {pageType !== PATENTSEARCHLISTING && showFilterBy && (
        <Mobile>
          <button className="filter-count__mobile-button" onClick={onFilterClick}>
            <span className="darticon-filter" />
          </button>
        </Mobile>
      )}

      {pageType === SITESEARCHRESULTS && showFilterBy && (
        <Mobile>
          <span className="filter-count__value">{window["globalProperties"]["filterByLabel"]}</span>
        </Mobile>
      )}

      {/* Display for Curated Listing & Single Type Listing */}
      {curatedResultsMessage && curatedResultsMessage.length &&
        (pageType === CURATEDLISTING ||
          (pageType !== SITESEARCHRESULTS && pageType !== PATENTSEARCHLISTING)) && (
          <span className="filter-count__value" aria-live="assertive">
            {curatedResultsMessage}
          </span>
        )}

      {/* Display for Patent Search */}
      {resultsMessage && resultsMessage.length && pageType === PATENTSEARCHLISTING && (
        <Desktop><span className="filter-count__value" aria-live="assertive">
          {resultsMessage}
        </span>
        </Desktop>
      )}

      <Desktop>
        {/* Display for Site Search Results */}
        {resultsMessage && resultsMessage.length && pageType === SITESEARCHRESULTS && (
          <span className="filter-count__value" aria-live="assertive">
            {resultsMessage}
          </span>
        )}
      </Desktop>
    </div>
  );
}

export default ResultsCount;
