import {
  isReference
} from "@bloomreach/spa-sdk";
import {
  regexpLocale
} from "../components/Global/GlobalConstants";

const {
  REACT_APP_BASE_URL
} = process.env;

export const GetChannelRoot = () => {
  //get path and parse by regex
  const matchLocale = window.location.pathname.split("/")[1].match(regexpLocale);
  if (matchLocale) {
    return "/" + matchLocale[0];
  } else {
    return "/";
  }
}

/*
  using link info return back link url
  example link object
  "link": {
    "name": "dartcontainercombrxm:link",
    "displayName": "dartcontainercombrxm:link",
    "image": null,
    "externalLink": "https://www.google.com",
    "altText": "",
    "newWindow": false,
    "internalLink": {
      "$ref": "/content/u6407c2f1d0154af99b024319db066d1d"
    },
    "label": "Hero CTA",
    "icon": "",
    "contentType": "dartcontainercombrxm:GeneralLinkField"
  }
*/
export const GetGeneralLinkUrl = (link, page) => {
  const {
    internalLink = null, externalLink = null
  } = link || {};

  if (internalLink) {
    // Allows for value to be either a `ref` object or a string
    //need to get relative path, as if they are links to other channels, the links points to the cms
    let internalURL = isReference(internalLink) ? page.getContent(internalLink).getUrl() : internalLink;
    try {
      let relativeInternalURL = new URL(internalURL);
      let relativeInternalURLPath = relativeInternalURL.pathname;
      //if the links starts with /site we need to remove it
      if (relativeInternalURLPath.indexOf("/site") === 0) {
        relativeInternalURLPath = relativeInternalURLPath.replace("/site", "");
      }
      return relativeInternalURLPath;
    }catch(e){
      return internalURL;
    }

  } else if (externalLink) {
    return externalLink;
  }

  return null;
};

export const parseURL = (text) => {
  if (!text)
    return {
      result: "ERROR",
      message: "Value not provided",
      parsed: {}
    };

  try {
    const url = new URL(text, REACT_APP_BASE_URL);
    const {
      pathname,
      hash,
      search,
      searchParams,
      href
    } = url;
    let {
      origin
    } = url;

    let internal = false;

    if (origin === REACT_APP_BASE_URL) {
      origin = "";
      internal = true;
    }

    let subPageNav = false;
    if (internal && pathname == window.location.pathname) {
      subPageNav = true;
    }

    const path = `${pathname}${search}${hash}`;

    return {
      result: "SUCCESS",
      message: "",
      parsed: {
        internal,
        origin,
        pathname,
        search,
        searchParams,
        path,
        href,
        subPageNav,
      },
    };
  } catch (e) {
    console.error(e);
    return {
      result: "ERROR",
      message: e.message,
      parsed: {}
    };
  }
};

export const getUrlPath = url => {
  try {
    const urlObject = new URL(url);
    const path = url.replace(urlObject.origin, "");

    return path;
  } catch (e) {
    return url;
  }
}

export const removeQueryParam = (uri, param) => {
  const url = new URL(uri);

  if (url.searchParams.has(param)) {
    url.searchParams.delete(param);
  }

  return url.href;
};

export const setQueryParam = (uri, param, value) => {
  const url = new URL(uri);

  url.searchParams.set(param, value)

  return url.href
};

export const isAuthored = (link) => {
    return link?.label && (link?.internalLink || link?.externalLink);
};
