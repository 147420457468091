import { DesktopNav as Desktop, MobileNav as Mobile } from "../../../media-queries/MediaQueries";
import { useSearchToolbarContext } from "./hooks";
import ActiveFilterPills from "./ActiveFilterPills";
import SearchSortSelector from "./SearchSortSelector";
import "./SearchToolbar.scss";

function SearchToolbar() {
  const {
    filters: { facets },
    urlFilters: { filters }
  } = useSearchToolbarContext();
  const showFilters = facets.length > 0 || filters.length > 0;

  return (
    <div className="curated-list-toolbar">
      <div className="curated-list-toolbar__content">
        {showFilters && (
          <Desktop>
            <div className="curated-list-toolbar__content__pills">
              <ActiveFilterPills />
            </div>
          </Desktop>
        )}
        <div className="curated-list-toolbar__content__sort">
          <SearchSortSelector />
        </div>
      </div>
      {showFilters && (
        <Mobile>
          <div className="curated-list-toolbar__mobile-pills">
            <ActiveFilterPills />
          </div>
        </Mobile>
      )}
    </div>
  );
}

export default SearchToolbar;
