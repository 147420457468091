import React, { useEffect, useRef } from "react";
import { BrPageContext } from "@bloomreach/react-sdk";
import HeaderGeneralFlyout from "./HeaderGeneralFlyout";
import HeaderComplexFlyout from "./HeaderComplexFlyout";
import "./Flyout.scss";
import { IsDesktopNav } from "../../media-queries/MediaQueries";
import { MARKETFLYOUTTYPE, PRODUCTFLYOUTTYPE } from "../Global/GlobalConstants";

function Flyout({ flyoutHeaderLabel, flyoutRef, closeActiveSubNav, setMenuOpen }) {
  const isDesktopNav = IsDesktopNav();
  const CONTENT_TYPE_GENERAL = "dartcontainercombrxm:HeaderGeneralFlyout";
  const CONTENT_TYPE_MARKETING = "dartcontainercombrxm:HeaderMarketSegmentFlyout";
  const CONTENT_TYPE_PRODUCT = "dartcontainercombrxm:HeaderProductFlyout";

  const page = React.useContext(BrPageContext);
  const flyoutRefDocument = page.getContent(flyoutRef);
  // console.log('flyoutRefDocument', flyoutRefDocument);
  // console.log('flyoutRefDocument.getData()', flyoutRefDocument.getData());
  const { contentType = null } = flyoutRefDocument.getData();
  const ref = useRef();

  //in this click on document we want to see if we click inside a flyout or outside
  const handleClickDocument = (e) => {
    //only close flyout this way if in desktop view
    if (isDesktopNav) {
      if (ref.current && !ref.current.contains(e.target)) {
        closeActiveSubNav("");
      }
    }
  };

  //setup document click
  useEffect(() => {
    document.addEventListener("click", handleClickDocument);

    return () => {
      document.removeEventListener("click", handleClickDocument);
    };
  });
  return (
    <div className={`flyout`} ref={ref}>
      {contentType === CONTENT_TYPE_GENERAL && (
        <HeaderGeneralFlyout
          flyoutHeaderLabel={flyoutHeaderLabel}
          flyoutRef={flyoutRef}
          closeActiveSubNav={closeActiveSubNav}
          setMenuOpen={setMenuOpen}
        />
      )}
      {contentType === CONTENT_TYPE_MARKETING && (
        <HeaderComplexFlyout
          flyoutHeaderLabel={flyoutHeaderLabel}
          flyoutRef={flyoutRef}
          closeActiveSubNav={closeActiveSubNav}
          setMenuOpen={setMenuOpen}
          type={MARKETFLYOUTTYPE}
        />
      )}
      {contentType === CONTENT_TYPE_PRODUCT && (
        <HeaderComplexFlyout
          flyoutHeaderLabel={flyoutHeaderLabel}
          flyoutRef={flyoutRef}
          closeActiveSubNav={closeActiveSubNav}
          setMenuOpen={setMenuOpen}
          type={PRODUCTFLYOUTTYPE}
        />
      )}
    </div>
  );
}

export default Flyout;
