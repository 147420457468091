import { Desktop, Mobile } from "../../../media-queries/MediaQueries";
import DesktopFilter from "./DesktopFilter";
import { useSearchFilterContext } from "./hooks";
import MobileFilter from "./MobileFilter";

function Filter() {
  const {    
    facetGroups: { groups },
  } = useSearchFilterContext();
   
  if (!groups ||groups.length === 0){
    return null;
  }  

  return (
    <div className={`filter`}>
      <div className="filter__wrapper">
        <Desktop>
          <DesktopFilter />
        </Desktop>
        <Mobile>
          <MobileFilter />
        </Mobile>
      </div>
    </div>
  );
}

export default Filter;
