import React from "react";
import { BrPageContext } from "@bloomreach/react-sdk";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { getPageTitle } from "../../../../helpers/GlobalHelpers";
import { GetGeneralLinkUrl } from "../../../../helpers/LinkHelper";
import Description from "../../../Global/Description";
import { GLOBAL_TAXONOMY_MAP } from "../../../Global/GlobalConstants";
import "./ArticleTile.scss";
import LinkWrapper from "../../../Global/LinkWrapper";
import Image from "../../../Global/Image/Image";

function ArticleTile({ tile, componentStyle, height, width }) {
  const sendDataToGTM = useGTMDispatch();
  const page = React.useContext(BrPageContext);

  if (!tile) return null;

  const {
    articleDetails: { primaryTitle, description, link },
    promoImage,
    promoImageAltText,
    categories,
    publishedDate,
    lastUpdatedDate,
  } = tile.getData();
  const { 0: firstCategoryKey = "" } = categories || [];

  const taxonomyComponent = page.getComponent(GLOBAL_TAXONOMY_MAP);
  const { taxonomy: taxonomyMap = {} } = taxonomyComponent.getModels();
  const taxonomy = taxonomyMap[firstCategoryKey] || "";

  let linkRef = GetGeneralLinkUrl(link, page);

  // Date Outputs - example "Mar 17, 2022"
  const publishedDateOutput = publishedDate
    ? new Date(publishedDate).toLocaleDateString(navigator.language, {
        month: "short",
        day: "numeric",
        year: "numeric",
      })
    : null;
  const lastUpdatedDateOutput = lastUpdatedDate
    ? new Date(lastUpdatedDate).toLocaleDateString(navigator.language, {
        month: "short",
        day: "numeric",
        year: "numeric",
      })
    : null;

  const handleTileClick = () => {
    sendDataToGTM({
      event: "tileClick",
      tileTitle: `${tile.primaryTitle ? tile.primaryTitle : tile.secondaryTitle}`,
      label: `${getPageTitle(page)}`,
    });
  };

  return (
    <LinkWrapper
      link={link}
      className={`${
        linkRef ? "article-tile__cta" : ""
      } article-tile article-tile--${componentStyle}`}
      onClick={() => handleTileClick()}
    >
      {promoImage && (
        <Image
          src={promoImage}
          className="article-tile__image "
          alt={promoImageAltText}
          height={height}
          width={width}
        />
      )}
      <p className="article-tile__publication-info tile--padding-side">
        {taxonomy && <span>{`${taxonomy}`}&nbsp;&nbsp;|&nbsp;&nbsp;</span>}
        {(publishedDate || lastUpdatedDate) && (
          <span>{lastUpdatedDateOutput || publishedDateOutput}</span>
        )}
      </p>
      {primaryTitle && <p className="article-tile__header tile--padding-side">{primaryTitle}</p>}
      {description?.value && (
        <Description
          className="article-tile__description tile--padding-side"
          value={description.value}
        />
      )}
    </LinkWrapper>
  );
}

export default ArticleTile;
