const SetPrimaryColor = {
  setGreenPrimary: function () {
    const colorPrimaryMexico = getComputedStyle(document.documentElement).getPropertyValue(
      "--color-primary-mexico"
    );
    document.documentElement.style.setProperty("--color-primary", colorPrimaryMexico);
    const colorPrimaryDarkMexico = getComputedStyle(document.documentElement).getPropertyValue(
      "--color-primary-mexico-dark"
    );
    document.documentElement.style.setProperty("--color-primary-dark", colorPrimaryDarkMexico);
    const colorPrimaryLightMexico = getComputedStyle(document.documentElement).getPropertyValue(
      "--color-primary-mexico-light"
    );
    document.documentElement.style.setProperty("--color-primary-light", colorPrimaryLightMexico);
  },
  setBluePrimary: function () {
    const colorPrimary = getComputedStyle(document.documentElement).getPropertyValue(
      "--color-regular"
    );
    document.documentElement.style.setProperty("--color-primary", colorPrimary);
    const colorPrimaryDark = getComputedStyle(document.documentElement).getPropertyValue(
      "--color-regular-dark"
    );
    document.documentElement.style.setProperty("--color-primary-dark", colorPrimaryDark);
    const colorPrimaryLight = getComputedStyle(document.documentElement).getPropertyValue(
      "--color-regular-light"
    );
    document.documentElement.style.setProperty("--color-primary-light", colorPrimaryLight);
  },
};

export default SetPrimaryColor;