import React, { useState } from "react";
import { BrComponentContext } from "@bloomreach/react-sdk";
import { Desktop } from "../../../media-queries/MediaQueries";
import { animateScroll } from "react-scroll";
import "./ContentTabs.scss";
import ErrorComponent from "../ErrorComponent/ErrorComponent";

function ContentTabs() {
  const component = React.useContext(BrComponentContext);

  // Active Index
  const [activeIndex, setActiveIndex] = useState(-1);

  try {
    const {
      tabOneLabel,
      tabTwoLabel,
      tabThreeLabel,
      tabFourLabel,
      tabFiveLabel,
      tabOneAnchorIdentifier,
      tabTwoAnchorIdentifier,
      tabThreeAnchorIdentifier,
      tabFourAnchorIdentifier,
      tabFiveAnchorIdentifier,
    } = component.getParameters();

    // Setting conditions for tab labels and ids
    const tabsArray = [
      { label: tabOneLabel || null, id: tabOneAnchorIdentifier || null },
      { label: tabTwoLabel || null, id: tabTwoAnchorIdentifier || null },
      { label: tabThreeLabel || null, id: tabThreeAnchorIdentifier || null },
      { label: tabFourLabel || null, id: tabFourAnchorIdentifier || null },
      { label: tabFiveLabel || null, id: tabFiveAnchorIdentifier || null },
    ];

    const handleClick = (index, id) => {
      setActiveIndex(index); // Set active index to that of clicked tab

      try {
        const { scrollY, pageYOffset } = window;

        const element = window.document.getElementById(id);
        // Get distance from top of visual viewport
        const whereToScroll = element.getBoundingClientRect().top;
        // Account for any vertically distance scrolled from top
        const top = whereToScroll + (scrollY || pageYOffset);

        animateScroll.scrollTo(top);
      } catch (e) {
        // Triggers if getElementById returns null (element with id doesn't exist)
        console.error(`${id} not found.`);
      }
    };

    const tabsOutput = tabsArray
      .filter((item) => item.id !== null && item.label !== null)
      .map((item, index) => {
        return (
          <button
            key={index}
            className={`content-tabs__item ${activeIndex === index ? "active" : ""}`}
            onClick={() => handleClick(index, item.id)}
          >
            {item.label}
          </button>
        );
      });

    return (
      <Desktop>
        <div className="content-tabs">
          <div className="content-tabs__wrapper">{tabsOutput}</div>
        </div>
      </Desktop>
    );
  } catch (e) {
    console.error("ContentTabs error", e);
    return <ErrorComponent error={e} componentName={"ContentTabs"} />;
  }
}

export default ContentTabs;
